(function () {
    angular.module('Plania').controller('ComponentEquipmentController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', '$filter', 'TranslationService', 'ListService', controller]);

    function controller($scope, $modalInstance, params, repository, ngTableParams, $filter, translationService, listService) {
        $scope.equipmentColumns = [
            { Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
            { Position: 2, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'string' },
            { Position: 3, Title: translationService.translate('web-equipment-id'), Property: 'Id', PropertyType: 'string' },
            { Position: 4, Title: translationService.translate('web-equipment-description'), Property: 'Description', PropertyType: 'string' },
        ];

        $scope.equipmentTable = new ngTableParams({
            page: 1,
            count: 10,
            sorting: { Id: 'asc' },
        },
        {
            total: 0,
            counts: [10, 20, 50],
            paginationMaxBlocks:5,
            getData: function ($defer, params) {
                $scope.isLoading = true;
                var columns = [];
                $scope.equipmentColumns.forEach(function (col) {
                    columns.push(col.Property);
                });
                repository.GetPaginated(repository.apiData.equipment.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns)).then(
                    function (result) {
                        params.total(result.TotalCount);
                        $defer.resolve(result.List);
                        $scope.isLoading = false;
                    },
                    function (error) {
                        repository.growl(error, 'danger');
                    });
            }
        });

        $scope.getPropertyValue = function (item, column) {
            return listService.GetPropertyValue(item, column);
        };

        $scope.selectedEquipments = [];

        $scope.saveEquipment = function () {
            postData = {
                GuidComponent: params.guidComponent,
                GuidEquipments: $scope.selectedEquipments,
            };

            repository.createSingle(repository.apiData.componentEquipment.url, postData)
                .then(function (result) {
                    repository.growl(translationService.translate('component-equipment-add-success', 'Anlegg har blitt lagt til'), 'success');
                    $modalInstance.close('OK');
                });
        };

        $scope.addEquipment = function (guid) {
            if (!_.contains($scope.selectedEquipments, guid))
                $scope.selectedEquipments.push(guid);
            else {
                for (var i = 0; i < $scope.selectedEquipments.length; i++) {
                    if ($scope.selectedEquipments[i] === guid) {
                        $scope.selectedEquipments.splice(i, 1);
                        return;
                    }
                }
            }
        };

        $scope.isEquipmentSelected = function (guid) {
            return _.contains($scope.selectedEquipments, guid);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }
})();
