(function () {
    angular.module('Plania').controller('IfcController', ['$scope', '$stateParams', 'Repository', controller]);

    function controller($scope, $stateParams, repository) {
        var canvas = document.getElementById('renderCanvas');
        var engine = new BABYLON.Engine(canvas, true);
        var debug = false;
        var scene;

        $scope.guid = $stateParams.guid;

        engine.loadingUIText = "Loading";

        setTimeout(function () {
            setContainerHeight();
            engine.displayLoadingUI();
        }, 200);

        var setContainerHeight = function () {
            var jCanvas = $('#renderCanvas');
            var currentHeight = jCanvas.height();
            var height = $(window).innerHeight() - 70;
            if (currentHeight !== height) {
                jCanvas.height(height);
            }

            engine.resize();
        };

        var createScene = function (modelData) {
            var scene = new BABYLON.Scene(engine);

            var startingPos = new BABYLON.Vector3(2, 6, 10);

            //Setup camera
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                camera = new BABYLON.TouchCamera("TouchCamera", startingPos, scene);
            }
            else {
                camera = new BABYLON.FreeCamera("FreeCamera", startingPos, scene);
            }

            camera.setTarget(BABYLON.Vector3.Zero());
            //camera.checkCollisions = true;
            camera.attachControl(canvas, false);
            camera.speed = 0.2;

            //Gravity & Collision
            //scene.gravity = new BABYLON.Vector3(0, -4, 0);
            //camera.applyGravity = true;
            //camera.ellipsoid = new BABYLON.Vector3(0.3, 0.6 , 0.3);
            //scene.collisionsEnabled = true;

            //Setting up navigation puttons
            scene.activeCamera.keysUp.push(87); // W
            scene.activeCamera.keysDown.push(83); // S
            scene.activeCamera.keysLeft.push(65); // A
            scene.activeCamera.keysRight.push(68); // D

            //Setup skybox
            var skybox = BABYLON.Mesh.CreateBox("skybox", 150.0, scene);
            var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
            skyboxMaterial.backFaceCulling = false;
            skyboxMaterial.disableLighting = true;
            skybox.material = skyboxMaterial;
            skybox.infiniteDistance = true;
            skybox.renderingGroupId = 0;

            skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
            skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
            skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("textures/TropicalSunnyDay", scene);
            skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;

            //Fog
            //scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
            //scene.fogDensity = 0.01;
            //scene.fogColor = new BABYLON.Color3(0.9, 0.9, 0.85);

            //Lighting and shadow
            var light = new BABYLON.HemisphericLight("Hemi0", new BABYLON.Vector3(0, 1, 0), scene);


            //var material = new BABYLON.StandardMaterial("myMaterial", scene);
            //material.diffuseTexture = new BABYLON.Texture("textures/Ground.jpg", scene);

            //var ground = BABYLON.Mesh.CreateGround('ground1', 128, 128, 64, scene);
            //ground.material = material;

            scene.ambientColor = new BABYLON.Color3(0.3, 0.3, 0.3);


            //BABYLON.SceneLoader.ImportMesh("", "static/", "HIBO.json", scene, function (newMeshes) {
            BABYLON.SceneLoader.ImportMesh("", "", "data:" + modelData, scene, function (newMeshes) {
                engine.hideLoadingUI();
            }, function (evt) {
                var percent = (evt.loaded * 100 / evt.total).toFixed();
                engine.loadingUIText = "Laster, vennligst vent... <br/>" +
                    '<div class="progress" style="width:300px; margin:auto; margin-top:8px; margin-bottom:8px"><div class="progress-bar' + (percent === 100 ? 'progress-bar-success' : '') + '" role="progressbar" aria-valuenow="' + percent + '" aria-valuemin="0" aria-valuemax="100" style="width:' + percent + '%"></div></div>' +
                    (evt.loaded * 100 / evt.total).toFixed() + "%";
            }, function (scene, error) {
                engine.loadingUIText = "Innlasting feilet! <br/>" +
                    '<div class="progress" style="width:300px; margin:auto; margin-top:8px; margin-bottom:8px"><div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%"></div></div>' +
                    error;
            });

            return scene;
        };

        repository.getSingle(repository.apiData.ifcFile.urlData, $stateParams.guid).then(
            function (response) {
                scene = createScene(JSON.stringify(response));

                engine.runRenderLoop(function () {
                    scene.render();
                });
            },
            function (error) {
                repository.growl(error, 'danger');
            });

        var setCameraSpeed = function (speed) {
            if (scene.activeCamera)
                scene.activeCamera.speed = speed;
        };

        document.addEventListener("keydown", function (e) {
            if (e.key === "Shift") setCameraSpeed(0.4);
            if (e.keyCode === 88) toggleDebugLayer();
        }, false);

        document.addEventListener("keyup", function (e) {
            if (e.key === "Shift") setCameraSpeed(0.2);
        }, false);

        window.addEventListener('resize', function () {
            engine.resize();
            setContainerHeight();
        });

        var toggleDebugLayer = function () {
            if (!debug) {
                scene.debugLayer.show();
                debug = true;
            } else {
                scene.debugLayer.hide();
                debug = false;
            }
        };

        $scope.$on('$destroy', function () {
            scene.dispose();
            engine.dispose();
        });
    }
})();
