(function () {

	angular.module('Plania').controller('OperationalMessageModalController', ['$scope', 'Repository', 'params', '$modalInstance', '$modal', '$localStorage', 'TranslationService', 'Constants', controller]);

	function controller($scope, repository, params, $modalInstance, $modal, $localStorage, translationService, constants) {
		$scope.isEdit = params.guid ? true : false;

		$scope.showEntityConnection = !params.guid && !params.parentEntity;
		$scope.selections = { selectedEntityType: null };

		$scope.entities = [
			{ value: 'Estate', translation: translationService.translate('web-estate') },
			{ value: 'Building', translation: translationService.translate('web-building') },
			{ value: 'Equipment', translation: translationService.translate('web-equipment') },
			{ value: 'Area', translation: translationService.translate('web-area') },
		];

		$scope.onSelectEntity = function () {
			var entityType = $scope.selections.selectedEntityType;
			if (entityType !== 'Estate') {
				$scope.model.GuidEstate = null;
				$scope.model.Estate = null;
			}
			if (entityType !== 'Building') {
				$scope.model.GuidBuilding = null;
				$scope.model.Building = null;
			}
			if (entityType !== 'Equipment') {
				$scope.model.GuidEquipment = null;
				$scope.model.Building = null;
			}
			if (entityType !== 'Area') {
				$scope.model.GuidArea = null;
				$scope.model.Area = null;
			}
		};

		$scope.hstep = 1;
		$scope.mstep = 5;

		var columns = [
			'Person.FirstName', 'Person.LastName'
		];

		var getMessage = function () {
			repository.getSingle(repository.apiData.operationalMessage.url, params.guid, JSON.stringify(columns)).then(
				function (result) {
					$scope.model = result.Data;
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});
		};

		if (params.guid) {
			getMessage();
		} else {
			$scope.model = {};
			if (params.parentGuid)
				$scope.model['Guid' + params.parentEntity] = params.parentGuid;

			$scope.model.StartDate = new Date().setMinutes(0);
			$scope.model.DueDate = new Date().setMinutes(0);
			$scope.activateAutocomplete = true;
		}

		$scope.save = function (action) {
			if ($scope.model.DueDate) $scope.model.DueDate = new Date($scope.model.DueDate).toISOString();
			if ($scope.model.StartDate) $scope.model.StartDate = new Date($scope.model.StartDate).toISOString();

			if (params.guid) {
				repository.updateSingleDictionary(repository.apiData.operationalMessage.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-operationalMessage-updated', 'Driftsmelding oppdatert'), 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingleDictionary(repository.apiData.operationalMessage.url, $scope.model).then(function (result) {
					repository.growl(translationService.translate('web-operationalMessage-created', "Driftsmeldingen ble lagt til"), 'success');
					$modalInstance.close('ok');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "person":
					return filter;
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
