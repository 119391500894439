(function () {
	angular.module('Plania').directive('plTableWidget', ['TranslationService', 'ListService', '$timeout', function (translationService, listService, $timeout) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);
				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-table-message', "Tabellen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-table-confirm', 'Ja, fjern tabellen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-table-success', 'Tabellen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};

				$('.grid-stack').on('resizestop', function (event, ui) {
					if ($(event.target).find('.table-widget').length > 0) {
						$timeout(function () {
							scope.setHeight();
						}, 350);
					}
				});

				scope.setHeight = function () {
					var bodyHeight = element.height() - (element.find('.card-header').height() + 40);
					element.find('.card-body').height(bodyHeight);
				};

				// Timeout to allow for UI to be drawn slightly. Height here would be 22, but at load some fields that should be hidden causes the height to be larger than normal.
				$timeout(function () {
					scope.setHeight();
				}, 10);
			},
			templateUrl: 'app/dashboard/directives/widgets/tableWidget.html',
			controller: ['$scope', 'Repository', 'NgTableParams', '$modal', '$rootScope', 'states', '$interval', '$localStorage', '$modal',
				function ($scope, repository, ngTableParams, modal, $rootScope, states, $interval, $localStorage, $modal) {
				$scope.model = $scope.widget;
				if (!$scope.model.WidgetData) $scope.model.WidgetData = {};

				//Object to store data broadcasted from other widgets
				var dataReceivedFromBroadcast = {};

				$scope.showSearch = false;
				var navState = '';
				$scope.canCreateNew = false;
				$scope.filterConfirmationIsCollapsed = true;

				if (typeof ($scope.model.WidgetData) === "string") {
					$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
				}

				var refreshInterval;

				var setNavState = function () {
					navState = $scope.model.WidgetData.NavigationState;
					if (!navState) return;

					navState = navState.replace('.edit', '.create');
					var entityName = $scope.model.WidgetData.SelectedListSetup ? $scope.model.WidgetData.SelectedListSetup.EntityName : 'Undefined';
					$scope.canCreateNew = (_.find(states.list, function (o) { return o.name === navState; }) ? true : false) && $rootScope.hasCreateAccess(entityName ? entityName : $scope.model.WidgetData.SelectedListSetup.EntityType);
				};

				setNavState();

				$scope.getPropertyValue = function (item, column) {
					return listService.GetPropertyValue(item, column);
				};

				$scope.addNew = function () {
					if ($scope.model.WidgetData.NavigationState.indexOf('deviation') > -1) {
						$scope.createEquipment();
					} else {
						$rootScope.navigation.go(navState, { reload: true });
					}
				};

				$scope.markRow = function (item) {
					return $localStorage.generalOptions.RequestShowUnreadComment && item.hasUnreadComments;
				};

				var groupByFunction = function (obj) {
					if (!$scope.model.WidgetData.SelectedListSetup.GroupBy) {
						return false;
					}

					var attrs = $scope.model.WidgetData.SelectedListSetup.GroupBy.split('.');
					attrs.forEach(function (attr) {
						obj = obj[attr];
					});

					return obj;
				};

				var getTable = function () {
					var listParams = {};
					var entityName = $scope.model.WidgetData.SelectedListSetup.EntityName;
					var entityType = $scope.model.WidgetData.SelectedListSetup.EntityType;
					if (entityName === repository.apiData.userGroup.prefix || entityType === repository.apiData.userGroup.prefix)
						listParams.UserGroup = 1;

					//Currently only used by workOrder to handle activity status filters
					if (entityName === repository.apiData.workOrder.prefix || entityType === repository.apiData.workOrder.prefix) {
						var filter = _.filter(listParams.PropertyFilter, function (row) {
							return row.Property.toLowerCase() === 'stage';
						});
						if (filter[0])
							listParams.ActivityStatus = filter[0].Value;
						else
							listParams.ActivityStatus = 'Active';
					}

					if ((entityName === repository.apiData.request.prefix || entityType === repository.apiData.request.prefix) && !$rootScope.userInfo.isSystemAdministrator) {
						listParams.PropertyFilter = _.union(listParams.PropertyFilter, [{ Property: 'IsTemplate', Operator: '=', Value: '0' }]);
					}

					if ((entityName === repository.apiData.project.prefix || entityType === repository.apiData.project.prefix)) {
						var isTemplate = $scope.model.WidgetData.NavigationState.indexOf('projectTemplate') > -1;
						listParams.PropertyFilter = _.union(listParams.PropertyFilter, [{ Property: 'IsTemplate', Operator: '=', Value: isTemplate ? '1' : '0' }]);
					}


					if (entityName === repository.apiData.workOrderXResourceGroup.prefix || entityType === repository.apiData.workOrderXResourceGroup.prefix)
						listParams.PropertyFilter = _.union(listParams.PropertyFilter, [{ Property: 'GuidPerson', Operator: '=', Value: repository.authService.getUserData().guidPerson }]);

					if (entityName === repository.apiData.controlListItemAnswer.prefix || entityType === repository.apiData.controlListItemAnswer.prefix)
						listParams.FilterAnswerList = true;

					var sorting = $scope.model.WidgetData.SelectedListSetup.Sorting;
					if (typeof (sorting) === 'string') {
						sorting = JSON.parse(sorting);
					}

					$scope.widgetTable = new ngTableParams({
						page: 1,
						count: $scope.model.WidgetData.defaultPaginationCount ? $scope.model.WidgetData.defaultPaginationCount : 10,
						sorting: sorting,
						filter: listParams
					},
						{
							total: 0,
							counts: [10, 25, 50, 100],
							groupBy: $scope.model.WidgetData.SelectedListSetup.GroupBy ? groupByFunction : '',
							paginationMaxBlocks: 6,
							getData: function ($defer, params) {
								var filter = params.$params.filter, columns = [];
								if (!filter.PropertyFilter)
									filter.PropertyFilter = [];

								$scope.model.WidgetData.SelectedListSetup.VisibleColumns = _.filter($scope.model.WidgetData.SelectedListSetup.Columns, function (c) {
									if (c.Filter && c.Filter.some(function (f) { return f.Hidden; }))
										return false;
									return true;
								});

								$scope.model.WidgetData.SelectedListSetup.Columns.forEach(function (col) {
									if (col.Filter) {
										if (typeof (col.Filter) === 'string') {
											col.Filter = JSON.parse(col.Filter);
										}
										filter.PropertyFilter = _.union(filter.PropertyFilter, col.Filter);
									}
									columns.push(col.Property);
								});

								if (!$scope.hideTable) {
									repository.GetPaginated($scope.widget.WidgetData.ServiceUrl, params.page() - 1, params.count(), params.sorting(), filter, '', JSON.stringify(columns)).then(
										function (result) {
											params.total(result.TotalCount);
											$defer.resolve(result.List);
										},
										function (error) {
											repository.growl(error, 'danger');
										});
								}
							}
						});

					if ($scope.model.WidgetData.AutoRefresh && $scope.model.WidgetData.RefreshInterval) {
						if (refreshInterval) {
							cancelInterval(refreshInterval);
						}

						refreshInterval = $interval(function () {
							$scope.widgetTable.reload();
						}, $scope.model.WidgetData.RefreshInterval * 1000);

						$scope.$on('$destroy', function () {
							cancelInterval(refreshInterval);

						});
					} else {
						if (refreshInterval) {
							cancelInterval(refreshInterval);
						}
					}
				};

				function cancelInterval(interval) {
					$interval.cancel(interval);
					interval = undefined;
				}

				if ($scope.model.WidgetData.SelectedListSetup)
					getTable();


				$scope.navigate = function (item) {
					var itemPrefix = item.Prefix;
					if (itemPrefix === 'ControlListXEntity' || itemPrefix === 'ControlListItemAnswer') {
						var params = { restrictEdit: true };
						params.workOrderCaption = itemPrefix === 'ControlListXEntity' ? item.WorkOrder.Caption : item.ControlListXEntity.WorkOrder.Caption;
						params.controlList = itemPrefix === 'ControlListXEntity' ? item.ControlList : item.ControlListXEntity.ControlList;
						params.controlListXEntity = itemPrefix === 'ControlListXEntity' ? item : { Guid: item.GuidControlListXEntity };

						$modal.open({
							templateUrl: 'app/controlList/views/controlListCompletionModal.html',
							size: 'lg',
							controller: 'ControlListCompletionModalController',
							resolve: {
								params: function () {
									return params;
								}
							}
						});
						return;
					}
					if (itemPrefix === 'BuildingXPerson') {
						$modal.open({
							templateUrl: 'app/buildingPerson/views/editBuildingPerson.html',
							controller: 'BuildingPersonController',
							resolve: {
								params: function () {
									return {
										guid: item.Guid,
										modalParam: { isUpdate: true }
									};
								}
							}
						});
						return;
					}
					$rootScope.navigation.go($scope.model.WidgetData.NavigationState, { guid: item.Guid }, { reload: true });
				};

				$scope.$watch('model.WidgetData', function (newValue, oldValue) {
					if (newValue === oldValue) return;

					if (typeof (newValue) === 'string') return;

					if (newValue.ServiceUrl !== oldValue.ServiceUrl) {
						$scope.widgetTable.filter().searchString = '';
						setNavState();
					}

					if (newValue.SelectedListSetup !== oldValue.SelectedListSetup) {
						getTable();

						$scope.widgetTable.$params.sorting = $scope.model.WidgetData.SelectedListSetup.Sorting;
						if (newValue.SelectedListSetup.GroupBy) {
							$scope.widgetTable.settings().groupBy = function () {
								$scope.widgetTable.settings().groupBy = groupByFunction;
							};
						} else {
							$scope.widgetTable.settings().groupBy = null;
						}
					}

					$scope.widgetTable.reload();
				}, true);

				$rootScope.$on($rootScope.events.newSelection, function () {
					$scope.widgetTable.reload();
				});

				$scope.editWidget = function () {
					modal.open({
						controller: 'AddWidgetModalController',
						templateUrl: 'app/dashboard/addWidgetModal.html',
						resolve: {
							widget: function () {
								return $scope.model;
							}
						}
					}).result.then(function (widgetModel) {
						$scope.saveFunction(false);
					}, function () {
						//Dismissed
					});
				};

				$scope.selectedList = [];

				$scope.addSelected = function (guid) {
					if (!_.contains($scope.selectedList, guid)) {
						$scope.selectedList.push(guid);
					} else {
						for (var i = 0; i < $scope.selectedList.length; i++) {
							var p = $scope.selectedList[i];
							if (p === guid) {
								$scope.selectedList.splice(i, 1);
								break;
							}
						}
					}
				};

				$scope.isSelected = function (guid) {
					return _.contains($scope.selectedList, guid);
				};

				$scope.$watch('selectedList', function (newValue, oldValue) {
					if (newValue === oldValue) return;

					if ($scope.selectedList.length > 0) {
						$scope.filterConfirmationIsCollapsed = false;
					} else {
						if ($scope.filterConfirmationIsCollapsed === false)
							$scope.broadcastNewFilter();

						$scope.filterConfirmationIsCollapsed = true;
					}
				}, true);

				$scope.broadcastNewFilter = function () {
					var objects = [];

					$scope.selectedList.forEach(function (guid) {
						objects.push(_.find($scope.widgetTable.data, function (o) { return o.Guid === guid; }));
					});

					var entityName = $scope.model.WidgetData.SelectedListSetup.EntityName;

					$scope.$root.$broadcast('dashboard.newFilter', { entity: entityName ? entityName : $scope.model.WidgetData.SelectedListSetup.EntityType, objects: objects });
				};

				if ($scope.model.WidgetData.FilterOnSelectionEvent) {
					$scope.hideTable = true;
					$scope.$on('dashboard.newFilter', function (evt, data) {
						dataReceivedFromBroadcast = data;

						if (data.objects.length === 0)
							$scope.hideTable = true;
						else
							$scope.hideTable = false;

						var filter = $scope.widgetTable.filter();
						if (!filter) filter = {};

						if (!filter.PropertyFilter) filter.PropertyFilter = [];

						var previousFilterIndex = _.findIndex(filter.PropertyFilter, function (o) { return o.Property === 'Guid' + data.entity; });

						if (previousFilterIndex > -1) {
							filter.PropertyFilter.splice(previousFilterIndex, 1);
						}

						if (data.objects.length > 0)
							filter.PropertyFilter.push({ Property: 'Guid' + data.entity, Operator: 'in', Value: _.map(data.objects, 'Guid').join(',') });

						if (data.objects.length === 1 && $scope.model.WidgetData.NavigationState.indexOf('deviation') > -1) {
							$scope.canCreateNew = true;
						} else {
							$scope.canCreateNew = false;
						}
					});
				}

				$scope.createEquipment = function () {
					var building = dataReceivedFromBroadcast.objects[0].Building;

					building = building.Id + (building.Description ? ' - ' + building.Description : '');

					modal.open({
						templateUrl: 'app/deviation/views/editDeviationModal.html',
						controller: 'DeviationController',
						resolve: {
							$stateParams: function () {
								return {
									guid: '',
									building: { Caption: building, guid: dataReceivedFromBroadcast.objects[0].GuidBuilding },
									GuidInspectionWorkOrder: dataReceivedFromBroadcast.objects[0].Guid,
									isModal: true
								};
							}
						}
					}).result.then(function () {
						$scope.widgetTable.reload();
					}, function () {
						//cancel
					});
				};
			}],
		};
	}]);
})();
