(function () {
    angular.module('Plania').controller('PlaniaSystemController', ['$scope', 'Repository', 'GeneralOptionsService', controller]);

    function controller($scope, repository, generalOptionsService) {

		$scope.update = true;
		$scope.isClientCollapsed = true;
		$scope.model = {};
		$scope.modules = {};
		repository.getSingleWithNoParameter(repository.apiData.planiaSystem.url).then(
			function (response) {
				$scope.reload = true;
				$scope.license = response.LicenseNumber;
				$scope.dbv = response.DataBaseVersion;
				$scope.webapiv = response.WebAPIVersion;
				$scope.licensedClients = response.LicenseInformation.NumberOfDataOwners; //response.LicensedClients;
				$scope.definedUsers = response.LicenseInformation.NumberOfDefinedUsers;
				$scope.databaseType = response.DatabaseType;
				$scope.webserver = response.WebServerName;
				$scope.databaserver = response.DataBaseServer;
				$scope.database = response.DataBaseName;
				$scope.simpleUsers = response.LicenseInformation.NumberOfSimpleUsers;
				$scope.modules = response.LicenseInformation.Modules;
				$scope.products = response.LicenseInformation.Packages;
				$scope.existingClient = response.UsedClients;
				$scope.existingDefinedUsers = response.UsedDefinedUsers;
				$scope.existingSimpleUsers = response.UsedSimpleUsers;

				$scope.packageArray = [];
				$scope.moduleArray = [];
				var str = $scope.products;
				var strModules = $scope.modules;
				$scope.packageArray = str.split(', ');
				$scope.moduleArray = strModules.split(', ');
			},
			function (error) {
				repository.growl(error, 'danger');
			});

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.Undefined)
			.then(function (result) {
				$scope.modules = result;
			});

		$scope.hasPackage = function (plPackage) {
			return _.contains($scope.packageArray, plPackage);
		};

		$scope.hasModule = function (plModule) {
			return _.contains($scope.moduleArray, plModule);
		};

		$scope.getModuleIcon = function (moduleName) {
			return repository.moduleService.getModuleIcon(moduleName);
		};

		generalOptionsService.refreshGeneralOptions();

		$scope.hasModule = $scope.$root.hasModule;

		$scope.exceededLicense = function (usedAmount, licensedAmount) {
			if (!usedAmount) return false;
			return parseInt(usedAmount) > parseInt(licensedAmount);
		};
	}
})();
