// IMPORTANT - Do not remove $location since it is used in the locationChangeSuccess event
(function () {
	angular.module('Plania').controller('ProjectController', ['$scope', 'Repository', '$stateParams', '$modal', '$state', 'TranslationService', 'SignalR', 'ListService', '$window', 'config', '$location', controller]);

	function controller($scope, repository, $stateParams, $modal, $state, translationService, signalR, listService, $window, config, $location) {
		$scope.model = {
			Guid: $stateParams.guid,
			IsTemplate: $scope.navigation.current.name === 'project.edit' ? false : true
		};
		$scope.viewMode = $scope.navigation.current.name === 'project.edit' ? true : 'projectTemplate.edit' ? true : false; // needed?
		$scope.isModelLoading = true;
		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
			$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Project, checkOtherDO);
			$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Project, checkOtherDO);
			var user = repository.authService.getUserData();
			$scope.isProjectAdministrator = $scope.model.GuidOwnerPerson === user.guidPerson || $scope.model.GuidManagerPerson === user.guidPerson || user.isSystemAdministrator;

			$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Project, checkOtherDO) || !$scope.isProjectAdministrator;
		};

		//Set default page from url
		var hash = window.location.hash;
		if (hash)
			$('#projectTabs a[href="' + hash + '"]').tab('show');
		else
			$('#projectTabs a[href="#info"]').tab('show');

		$scope.currentPage = !!hash ? hash.substr(1) : 'info';

		$scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
			var hash = window.location.hash;
			if (hash)
				$('#projectTabs a[href="' + hash + '"]').tab('show');
			else
				$('#projectTabs a[href="#info"]').tab('show');

			$scope.currentPage = !!hash ? hash.substr(1) : 'info';
		});

		var columns = [
			'Estate.Id', 'Estate.Description', 'Estate.Guid', 'Building.Id', 'Building.Description',
			'Area.Id', 'Area.Description', 'CostCenter.Id', 'CostCenter.Description',
			'ProjectCategory.Description', 'Department.Id', 'Department.Description',
			'Supplier.Id', 'Supplier.Description', 'Customer.Id', 'Customer.Description', 'ProjectType.Id',
			'Person.FirstName', 'Person.LastName', 'Person.Email', 'Person.TelephoneWork', 'Person.Cellphone', 'Person.User.GuidImage',
			'Person1.FirstName', 'Person1.LastName', 'Person1.Email', 'Person1.TelephoneWork', 'Person1.Cellphone', 'Person1.User.GuidImage',
			'Person2.FirstName', 'Person2.LastName', 'Person2.Email', 'Person2.TelephoneWork', 'Person2.Cellphone', 'Person2.User.GuidImage',
			'Person3.FirstName', 'Person3.LastName', 'Person3.Email', 'Person3.TelephoneWork', 'Person3.Cellphone', 'Person3.User.GuidImage',
			'ProjectStatus.Id', 'ProjectStatus.Description', 'ProjectStatus.Stage',
			'ProjectStatus.ProjectPhase.Id', 'ProjectStatus.ProjectPhase.Description', 'ProjectStatus.ProjectPhase.Guid', 'ProjectStatus.ProjectPhase.IndexPosition'
		];

		var getMainModel = function () {
			if (!$scope.hasReadAccess('Project')) return;
			repository.getMainModel(repository.apiData.project.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					$scope.reload = true;
					$scope.reloadAlarmLogTable = true;
					$scope.isModelLoading = false;
					updateAccess();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});
		};
		getMainModel();

		$scope.update = function (destination) {
			if (!$scope.hasCreateAccess('Project')) return;
			if ($scope.isModelLoading) {
				repository.growl(translationService.translate('web-wait-for-model', 'Kan ikke lagre før all data er lastet ned'), 'success');
				return;
			}

			var success = function (result) {
				delete $scope.model.IsClosing;
				repository.growl("Prosjekt har blitt oppdatert", 'success');
				repository.commonService.setLastRegisterGuid(destination, $scope.model.Guid);
				if (destination.includes('.list')) {
					$state.go(destination, {}, { reload: true });
				} else {
					$scope.goBack(destination, { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
					$scope.canChangePhase = $scope.userInfo.guidPerson === $scope.model.GuidManagerPerson || $scope.userInfo.guidPerson === $scope.model.GuidOwnerPerson || $scope.userInfo.isSystemAdministrator;
				}
			};

			var error = function (error) {
				repository.growl(typeof (error) === "string" ? error : error.Data.Message, 'danger');
				if ($scope.model.IsClosing)
					$scope.goBack(destination, { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
				delete $scope.model.IsClosing;
			};

			if ($scope.model.IsTemplate)
				repository.updateSingleDictionary(repository.apiData.project.endpoint.updateTemplate, $scope.model).then(success).catch(error);
			else
				repository.updateSingleDictionary(repository.apiData.project.url, $scope.model).then(success).catch(error);
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-project-message', "Prosjektet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-project-button-confirm', 'Ja, fjern prosjektet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				if (!$scope.hasDeleteAccess('Project')) return;
				repository.deleteSingle(repository.apiData.project.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-project-delete-success', 'Prosjektet ble slettet!'), '', "success");
						$scope.goBack('project.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};


		$scope.sendEmail = function () {

			var filter = { GuidProject: $scope.model.Guid };
			filter.ChildDocumentsOnProject = [];
			filter.ChildDocumentsOnProject.push(repository.commonService.prefix.Project);
			filter.ChildDocumentsOnProject.push(repository.commonService.prefix.WorkOrder);
			filter.ChildDocumentsOnProject.push(repository.commonService.prefix.PurchaseOrder);
			filter.ChildDocumentsOnProject.push(repository.commonService.prefix.Deviation);
			filter.ChildDocumentsOnProject.push(repository.commonService.prefix.Condition);

			$modal.open({
				templateUrl: 'app/common/views/emailModal.html',
				controller: 'EmailModalController',
				size: 'lg',
				resolve: {
					params: function () {
						return {
							entity: 'Project',
							columns: columns,
							model: $scope.model,
							attachments: [],
							getAllDocuments: true,
							documentFilter: filter
						};
					}
				}
			}).result.then(function () {
				$scope.reloadAlarmLogTable = true;
			});
		};

		signalR.on('UpdatedProjectSums' + $scope.model.Guid, function (economyColumns) {
			if (!$scope.hasReadAccess('Project')) return;
			$scope.updateBudgetFunctions.isCalculating = true;
			repository.getSingle(repository.apiData.project.url, $stateParams.guid).then(
				function (response) {
					// Only update sums
					economyColumns.forEach(function (field) {
						$scope.model[field] = response.Data[field];
					});

					$scope.updateBudgetFunctions.isCalculating = false;
				},
				function (error) {
					repository.growl(error, 'danger');
					$scope.updateBudgetFunctions.isCalculating = false;
				});
		});

		$scope.$on('$destroy', function () {
			signalR.off('UpdatedProjectSums' + $scope.model.Guid);
		});

		// Contains rules and functions for directives to call for updating economy tables
		$scope.updateBudgetFunctions = {
			isCalculating: false,
			update: function () {
				if ($scope.updateBudgetFunctions.isCalculating && !$scope.hasReadAccess('Project'))
					return;

				repository.getSingle(repository.apiData.project.endpoint.updateSingleSum, $scope.model.Guid).then(
					function () {
						$scope.updateBudgetFunctions.isCalculating = true;
						repository.growl(translationService.translate('web-project-update-sum-started', 'Rekalkulering av summer har startet'), 'success');
					},
					function (error) {
						repository.growl(error, 'danger');
					});
			}
		};

		$scope.hasEditAccessBudget = function () {
			return repository.authService.getUserData().isUserAdministrator ||
				repository.authService.getUserData().guidPerson === $scope.model.GuidOwnerPerson ||
				repository.authService.getUserData().guidPerson === $scope.model.GuidManagerPerson;
		};

		$scope.modifyBudget = function () {
			$modal.open({
				templateUrl: 'app/project/views/editProjectBudgetModal.html',
				controller: 'EditProjectBudgetModalController',
				resolve: {
					modalParams: function () {
						return {
							budget: $scope.model.BudgetAmount,
							guid: $scope.model.Guid
						};
					}
				}
			}).result.then(function (newBudget) {
				$scope.model.BudgetAmount = newBudget;
			});
		};

		$scope.showHourTypes = function () {
			$modal.open({
				templateUrl: 'app/hourType/views/listHourTypeModal.html',
				controller: 'HourTypeModalController',
				resolve: {
					modalParams: function () {
						return {
							Project: $scope.model
						};
					}
				},
				size: 'lg'
			});
		};

	}
})();
