(function () {

	var app = angular.module('Plania');

	app.directive('projectReportDirective', function () {
		return {
			restrict: 'E',
			scope: {
				reportParams: '=',
				reportName: '=',
				reload: '='
			},
			controller: ['$scope', 'CommonService', '$localStorage', '$rootScope', '$window', '$timeout', controller],
			templateUrl: 'app/project/views/projectReportDirective.html'
		};
	});

	function controller($scope, commonService, $localStorage, $rootScope, $window, $timeout) {
		$scope.sharedController = {};

		$scope.refreshReport = function () {
			$scope.sharedController.refresh();
		};

		$scope.toggleReportFilters = function () {
			$scope.sharedController.toggleFilters();
		};

		$scope.toggleZoomMode = function () {
			$scope.sharedController.toggleZoomMode();
		};


		$scope.printLabel = function () {
			var params = $scope.reportParams;

			$localStorage.reportParams = JSON.stringify(params);
			var reportName = $scope.reportName;

			var url = $rootScope.navigation.href('report.print', {
				reportName: reportName,
				reportParams: JSON.stringify(params)
			});

			$window.open(url, '_blank');
		};

		$scope.$watch('reload', function (newVal, oldVal) {
			if (newVal === oldVal) return;
			// Use timeout since we need to wait for the tab animation to end.
			$timeout(function () {
				if (!$scope.sharedController.getReportId) return;
				// Call every resize method for the report, won't do anything if there has been no changes.
				// This is done because Telerik calculates width and height, but since some reports are not drawn in the DOM (Display: None), then we need to resize it.
				kendo.resize($('#' + $scope.sharedController.getReportId()));

				// If the report is properly loaded, then it should have received a calculated width.
				var properlyLoaded = $('#' + $scope.sharedController.getReportId() + ' .trv-report-page[style*="width"]')[0];

				// After a proper resize, then it is possible to reload the report.
				if (!properlyLoaded) {
					$scope.refreshReport();
					$scope.reportReloaded = true;
				}
			}, 200);
		});
	}
})();
