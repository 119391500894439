(function () {

	var app = angular.module('Plania');

	app.directive('projectResource', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				viewMode: '=viewMode',
				update: '=update',
				restrictEdit: '=restrictEdit',
				budget: '=budget',
				isModelLoading: '=isModelLoading',
				currentPage: '=currentPage'
			},
			controller: ['$scope', '$rootScope', 'Repository', 'TranslationService', '$modal', 'AutoCompleteService', 'ListService', 'ngTableParams', '$localStorage', 'RegisteredFieldService', controller],
			templateUrl: 'app/project/views/projectResourceDirective.html'
		};
	});

	function controller($scope, $rootScope, repository, translationService, $modal, autoCompleteService, listService, ngTableParams, $localStorage, registeredFieldService) {
		$scope.rootScope = $rootScope;

		registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrderXResourceGroup)
			.then(
				function (registeredFields) {
					var isExternalUser = $localStorage.userData.isExternalUser;
					var estimatedTimeRule = _.filter(registeredFields, { DomainFieldName: 'EstimatedTime' })[0];
					var realTimeRule = _.filter(registeredFields, { DomainFieldName: 'RealTime' })[0];
					var estimatedCostRule = _.filter(registeredFields, { DomainFieldName: 'EstimatedCost' })[0];
					var realCostRule = _.filter(registeredFields, { DomainFieldName: 'RealCost' })[0];
					var noteRule = _.filter(registeredFields, { DomainFieldName: 'Note' })[0];
					$scope.resourceGroupInfo.TimeAndCostVisibility = {
						showEstimatedTime: estimatedTimeRule.Hidden ? false : isExternalUser ? !estimatedTimeRule.ExternallyHidden : true,
						showRealTime: realTimeRule.Hidden ? false : isExternalUser ? !realTimeRule.ExternallyHidden : true,
						showEstimatedCost: estimatedCostRule.Hidden ? false : isExternalUser ? !estimatedCostRule.ExternallyHidden : true,
						showRealCost: realCostRule.Hidden ? false : isExternalUser ? !realCostRule.ExternallyHidden : true,
						showNote: noteRule.Hidden ? false : isExternalUser ? !noteRule.ExternallyHidden : true,
					};

					$scope.resourceGroupInfo.initTable();
				});


		var getKpis = function () {

			var resourceGroupKpi = {
				Prefix: 'WorkOrderXResourceGroup',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: [{ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.model.Guid }],
				Properties: [{
					Property: 'EstimatedCost',
					Function: 'sum'
				}, {
					Property: 'EstimatedTime',
					Function: 'sum'
				}, {
					Property: 'RealCost',
					Function: 'sum'
				}, {
					Property: 'RealTime',
					Function: 'sum'
				}]
			};

			repository.createSingle(repository.apiData.kpi.url, resourceGroupKpi).then(function (result) {
				$scope.sumEstimatedCost = result[0].Result[0].Value;
				$scope.sumEstimatedTime = result[0].Result[1].Value;
				$scope.sumRealCost = result[0].Result[2].Value;
				$scope.sumRealTime = result[0].Result[3].Value;
			});

		};

		getKpis();

		$scope.resourceGroupInfo = new function () {
			var me = this;
			this.isLoading = true;
			this.search = {};

			this.menuOptions = function (resourceGroup) {
				var options = [];

				if ($scope.model.IsTemplate)
					options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: resourceGroup.GuidPeriodicTask }), 'zmdi-case', translationService.translate('web-open-project-template-workorder', 'Åpne arbeidsordre mal')));
				else
					options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: resourceGroup.GuidWorkOrder }), 'zmdi-case', translationService.translate('web-open-workorder', 'Åpne arbeidsordre')));
				return options;
			};

			var setColumns = function () {
				if ($scope.model.IsTemplate) {
					me.columns = [
						{ Position: 1, Title: translationService.translate('web-project-hour-resourcegroup-Caption', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
						{ Position: 2, Title: translationService.translate('web-project-hour-estimatedTime', 'Estimert tid'), Property: 'EstimatedTime', PropertyType: 'double' },
						{ Position: 3, Title: translationService.translate('web-project-hour-updatedDate', 'Oppdatert dato'), Property: 'UpdatedDate', PropertyType: 'date' },
					];
					me.sorting = { 'ResourceGroup.Caption': 'asc' };
				}
				else {

					me.columns = [
						{ Position: 1, Title: translationService.translate('web-project-hour-workOrder-Id', 'AO Id'), Property: 'WorkOrder.Id', PropertyType: 'string' },
						{ Position: 2, Title: translationService.translate('web-project-hour-resourcegroup-Caption', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
						{ Position: 3, Title: translationService.translate('web-project-hour-person-caption', 'Person'), Property: 'Person.FirstName', PropertyType: 'string' }
					];

					if (me.TimeAndCostVisibility.showEstimatedTime)
						me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-project-hour-estimatedTime', 'Estimert tid'), Property: 'EstimatedTime', PropertyType: 'double' });

					if (me.TimeAndCostVisibility.showEstimatedCost)
						me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-project-hour-estimatedCost', 'Estimert kostnad'), Property: 'EstimatedCost', PropertyType: 'double' });

					if (me.TimeAndCostVisibility.showRealTime)
						me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-project-hour-realtime', 'Reel tid'), Property: 'RealTime', PropertyType: 'double' });

					if (me.TimeAndCostVisibility.showRealCost)
						me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-project-hour-realcost', 'Reel kostnad'), Property: 'RealCost', PropertyType: 'double' });

					if (me.TimeAndCostVisibility.showNote && !me.TimeAndCostVisibility.showRealTime)
						me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-WorkOrderXResourceGroup-note', 'Kommentar'), Property: 'Note', PropertyType: 'string' });

					// Registered date as last
					me.columns.push({ Position: me.columns.length + 1, Title: translationService.translate('web-WorkOrderXResourceGroup-registeredDate', 'Registrert Dato'), Property: 'RegisteredDate', PropertyType: 'date' });
					me.sorting = { 'RegisteredDate': 'desc' };
				}
			};

			this.initTable = function () {
				if (me.table) return;
				setColumns();
				me.table = new ngTableParams({
					page: 1,
					count: 10,
					sorting: me.sorting,
					filter: { FilterOnProject: true, PropertyFilter: [] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							columns.push('Person.LastName');
							columns.push('ResourceGroup.Description');
							columns.push('ResourceGroup.Guid');

							if (!me.TimeAndCostVisibility.showEstimatedCost)
								columns.push('EstimatedCost');

							if (!me.TimeAndCostVisibility.showEstimatedTime)
								columns.push('EstimatedTime');

							if (!me.TimeAndCostVisibility.showRealCost)
								columns.push('RealCost');

							if (!me.TimeAndCostVisibility.showRealTime)
								columns.push('RealTime');

							columns.push('Note');

							var filter = angular.copy(params.filter());
							var url = '';
							var sorting = params.sorting();

							if ($scope.model.IsTemplate) {
								url = repository.apiData.periodicTaskXResourceGroup.url;
								filter.PropertyFilter.push({ Property: 'PeriodicTask.GuidProject', Operator: '=', Value: $scope.model.Guid });
							}

							else {
								url = repository.apiData.workOrderXResourceGroup.url;
								filter.PropertyFilter.push({ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.model.Guid });
							}

							if (Object.keys(sorting)[0].indexOf('.Caption') !== -1) {
								var key = Object.keys(sorting)[0];
								var order = sorting[key];
								if (key.includes('ResourceGroup'))
									key = key.replace('.Caption', '.Id');
								else if (key.includes('Person'))
									key = key.replace('.Caption', '.Firstname');
								sorting = {};
								sorting[key] = order;
							}

							repository.GetPaginated(url, params.page() - 1, params.count(), sorting, filter, null, JSON.stringify(columns)).then(
								function (result) {
									var dates = result.List.filter(function (row) { return row.RegisteredDate; }).map(function (row) {
										return new Date(row.RegisteredDate);
									});

									if (dates.length > 0) {
										var latestRegisteredDate = new Date(Math.max.apply(null, dates));
										if (latestRegisteredDate > $scope.telerikInfo.endDate)
											$scope.telerikInfo.endDate = latestRegisteredDate;
									}

									me.table.settings().total = result.TotalCount;
									me.table.settings().filterDelay = 500;

									me.isLoading = false;
									$defer.resolve(result.List);
								},
								function (error) {
									repository.growl(error, 'danger');
								});
						}
					});
			};
		}();

		$scope.hourTypeMenuOptions = function (hourType) {
			var options = [];

			if ($scope.rootScope.hasEditAccess('HourType')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-project-hourType-edit', 'Rediger timetype') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.updateHourType(hourType);
					}
				});
			}

			if ($scope.rootScope.hasDeleteAccess('HourType')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-project-hourType-delete', 'Slett timetype') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.deleteHourType(hourType);
					}
				});
			}

			return options;
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		// Watchers
		$scope.$watch('model',
			function (newValue, oldValue) {
				if (newValue === oldValue)
					return;
				$scope.canManageHourTypes = $rootScope.userInfo.guidPerson === $scope.model.GuidManagerPerson || $rootScope.userInfo.guidPerson === $scope.model.GuidOwnerPerson || $rootScope.userInfo.isSystemAdministrator;
			});

		var loadingListener = $scope.$watchGroup(['resourceGroupInfo.isLoading', 'isModelLoading'],
			function () {
				if ($scope.isModelLoading === false && $scope.resourceGroupInfo.isLoading === false) {
					$scope.telerikInfo.updateTelerikParameters();
					loadingListener(); // Clear watcher
				}
			}, true);

		$scope.$watch('currentPage', function (newVal, oldVal) {
			if (newVal === oldVal || newVal !== 'resource') return;

			if (newVal === 'resource') {
				$scope.telerikInfo.reloadReports = !$scope.telerikInfo.reloadReports;
			}
		});

		// Telerik
		$scope.telerikInfo = new function () {
			var me = this;
			this.parameters = { GuidProject: $scope.model.Guid };
			this.displayReports = false;
			this.endDate = new Date();
			this.reloadReports = false;

			this.updateTelerikParameters = function () {
				if ($scope.model.IsTemplate) return;
				var endDate = $scope.model.EndDate ? new Date($scope.model.EndDate) : null;
				var startDate = $scope.model.StartDate ? new Date($scope.model.StartDate) : new Date();
				if (endDate === null || endDate === undefined) {
					endDate = me.endDate;
				}

				if (endDate.getTime() < startDate.getTime())
					endDate = $scope.model.StartDate;

				me.parameters.StartDate = startDate;
				me.parameters.EndDate = endDate;
				me.displayReports = true;
			};
		}();
	}
})();
