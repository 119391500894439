(function () {
    angular.module('Plania').controller('CompleteWorkOrderModalController', ['$scope', '$modalInstance', 'TranslationService', 'RegisteredFieldService', 'Repository', controller]);
    // this controller relies heavily on parent workOrderController, including $scope.model
	function controller($scope, $modalInstance, translationService, registeredFieldService, repository) {
        $scope.model.EndDate = $scope.model.EndDate ? $scope.model.EndDate : new Date();
        $scope.workOrderXResourceGroup = {};
		$scope.viewMode = false;
		$scope.registeredTime = {};

		if ($scope.userInfo.guidPerson && $scope.hasReadAccess("WorkOrderXResourceGroup")) {
			var kpiPropertyTotal = {
				Prefix: 'WorkOrderXResourceGroup',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				PropertyFilter: [
					{ Property: 'GuidWorkOrder', Value: $scope.model.Guid, Operator: '=' },
					{ Property: 'GuidPerson', Value: $scope.userInfo.guidPerson, Operator: '=' }
				],
				Properties: [{
					Property: 'RealTime',
					Function: 'sum'
				}]
			};

			var kpiPropertyToday = {
				Prefix: 'WorkOrderXResourceGroup',
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				PropertyFilter: [
					{ Property: 'GuidWorkOrder', Value: $scope.model.Guid, Operator: '=' },
					{ Property: 'GuidPerson', Value: $scope.userInfo.guidPerson, Operator: '=' },
					{ Property: 'RegisteredDate', Value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(), Operator: '='}
				],
				Properties: [{
					Property: 'RealTime',
					Function: 'sum'
				}]
			};

			repository.createSingle(repository.apiData.kpi.url, kpiPropertyTotal).then(function (result) {
				$scope.registeredTime.Total = result[0].Result[0].Value;
			});

			repository.createSingle(repository.apiData.kpi.url, kpiPropertyToday).then(function (result) {
				$scope.registeredTime.Today = result[0].Result[0].Value;
			});
		}

		var getGroupMandatoryDynamicProperties = function (group) {
			var mandatoryProperties = [];
			group.Items.forEach(function (item) {
				if (item.Mandatory && !$scope.model[item.PropertyName]) {
					mandatoryProperties.push(item);
				}
				if (item.Type === 'Category') {
					item.Template.Groups.forEach(function (templateGroup) {
						var groupProps = getGroupMandatoryDynamicProperties(templateGroup);
						mandatoryProperties = mandatoryProperties.concat(groupProps);
					});
				}
			});
			return mandatoryProperties;
		};

		var getMandatoryDynamicProperties = function () {
			var mandatoryProperties = [];
			if ($scope.model.DynamicProperty) {
				$scope.model.DynamicProperty.Groups.forEach(function (group) {
					var groupProps = getGroupMandatoryDynamicProperties(group);
					mandatoryProperties = mandatoryProperties.concat(groupProps);
				});
			}
			return mandatoryProperties;
		};

		registeredFieldService.getFieldRule(repository.commonService.prefix.WorkOrderXResourceGroup, 'Note').then(function (result) {
			$scope.workOrderXResourceGroupNoteIsMandatory = result.Mandatory;
		});

		registeredFieldService.getRegisteredField(repository.commonService.prefix.WorkOrder).then(function (result) {
			var mandatoryFields = _.filter(result, function (o) {
				if (o.DomainFieldName === 'Id') //don't validate id since it will be set in API on create, updating existing should have a valid id
					return false;

				if (o.DomainFieldName === 'RealTime' ||
					o.DomainFieldName === 'RealCost' ||
					o.DomainFieldName === 'EstimatedTime' ||
					o.DomainFieldName === 'EstimatedCost') {
					if ($scope.resourceGroupInfo.table.total() > 0)
						return false;
					return o.Mandatory;
				}
				return o.Mandatory && !$scope.model[o.DomainFieldName];
			});

			$scope.mandatoryDynamicProperties = getMandatoryDynamicProperties();

			var mappedLabels = _.map(mandatoryFields, function (o) {
				return {
					FieldName: o.DomainFieldName,
					translatedValue: translationService.translate('web-workorder-' + o.DomainFieldName.replace('Guid', '')).replace(':', '')
				};
			});

			mappedLabels.push.apply(mappedLabels, _.map($scope.mandatoryDynamicProperties, function (o) {
				return {
					FieldName: o.PropertyName,
					translatedValue: o.LabelPropertyValue.replace(':', '')
				};
			}));

			$scope.mandatoryProperties = mappedLabels;
		});

		$scope.isMandatory = function (property) {
			return _.find($scope.mandatoryProperties, function (o) { return o.FieldName === property; });
		};

		$scope.isValid = function () {
			if (!$scope.model.EndDate) return false;

			var result = true;
			$scope.mandatoryProperties.forEach(function (property) {
				if (property.FieldName === 'RealTime') {
					if (!$scope.workOrderXResourceGroup.RealTime || $scope.workOrderXResourceGroup.RealTime === 0)
						result = false;
				} else {
				if(!$scope.model[property.FieldName]) result = false;
		}
	});

	if ($scope.workOrderXResourceGroupNoteIsMandatory && $scope.workOrderXResourceGroup.RealTime > 0 && !$scope.workOrderXResourceGroup.Note) {
		result = false;
	}

	return result;
};

$scope.$watch('model.DynamicProperty', function (newValue, oldValue) {
	if (newValue === oldValue) return;

	$scope.mandatoryDynamicProperties = getMandatoryDynamicProperties();
});

$scope.saveAction = function () {
	$modalInstance.close($scope.workOrderXResourceGroup);
};

$scope.cancel = function () {
	$modalInstance.dismiss('canceled');
};
	}
}) ();
