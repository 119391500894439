(function () {

	angular.module('Plania').controller('LoginController', ['$scope', 'Repository', 'MenuService', "GeneralOptionsService", '$stateParams', '$modal', '$location', 'TranslationService', '$state', '$localStorage', 'SignalR', 'config', loginController]);


	function loginController($scope, repository, menuService, generalOptionsService, $stateParams, $modal, $location, translationService, $state, $localStorage, signalR, config) {
		$scope.showFederationLogin = false;
		$scope.status = null;

		repository.getSingleWithNoParameter('/PlaniaStatus').then(function (result) {
			$scope.status = result;
			$scope.showFederationLogin = result.ShowFederationLogin;
			$scope.showSamlLogin = result.ShowSamlLogin;
			$scope.showUsernamePasswordLogin = result.ShowUsernamePasswordLogin;

			$localStorage.planiaStatus = result;
		});

		if ($location.search().error) {
			$scope.alertMessage = $location.search().error;
		}

		if ($stateParams.message) {
			$scope.alertMessage = $stateParams.message;
		}

		if ($stateParams.refreshtoken) {
			$scope.hideLoginBox = true;
			repository.authService.authenticateFederationRefresh($stateParams.refreshtoken).then(function () {
				afterLogonNavigate();
			}).catch(function (error) {
				$scope.hideLoginBox = false;
				$scope.alertMessage = "Kunne ikke logge inn, prøv igjen.";
			});
		}

		$scope.openQRCodeModal = function () {
			$modal.open({
				controller: 'QrCodeModalController',
				templateUrl: 'app/common/views/qrCodeModal.html'
			});
		};

		var sessionStorage = window.sessionStorage;
		var logoutError = sessionStorage.getItem('logoutError');

		if (logoutError) {
			$scope.alertMessage = logoutError;
			sessionStorage.removeItem('logoutError');
		}

		var afterLogonNavigate = function () {
			repository.setLocale(repository.authService.getUserData().language);
			translationService.getTranslations();
			generalOptionsService.refreshGeneralOptions();

			signalR.setQs({
				fingerprint: config.fingerprint,
				guidUser: repository.authService.getUserData().guidUser
			});
			signalR.start();

			repository.GetPaginated(repository.apiData.userAccess.url, 0, 0, {}, {}).then(
				function (response) {
					response.forEach(function (row) {
						repository.authService.updateUserAccess(row);
					});

					if ($stateParams.returnToState) {
						var returnTo = JSON.parse($stateParams.returnToState);
						$scope.navigation.go(returnTo.name, returnTo.params);
					} else {
						var userData = repository.authService.getUserData();
						menuService.getShortcuts().then(function () { //trigger fetching menus
							if (userData.GuidWebMenu && userData.MenuContext) {
								var filter = userData.MenuParameters.replace(/(\r\n|\n|\r)/gm, "");
								repository.setMenuParameters(userData.GuidWebMenu, filter, 0);
								if ($scope.$parent) {
									$scope.$parent.home = {
										state: userData.MenuContext,
										params: { menuGuid: userData.GuidWebMenu }
									};
								}
								$scope.navigation.go(userData.MenuContext, { menuGuid: userData.GuidWebMenu }, { reload: true });
							} else {
								if ($scope.$parent) {
									$scope.$parent.home = {
										state: 'building.list',
										params: { menuGuid: null }
									};
								}
								$scope.navigation.go('building.list'); //todo: create a default dashboard to show for users without startpage
							}
						}, function (error) {
							$scope.isLoginActive = false;
								if (error === translationService.translate('api-accessDomain-hasEntityAccess-hasNoAccess', 'Bruker har ikke tilgang'))
								repository.growl(translationService.translate('web-login-userXWebProfile-access-error', 'Bruker mangler rettighet til brukerprofil (UserXWebProfile), vennligst kontakt en Plania adminstrator for å fikse problemet'), 'danger');
							else
								repository.growl(translationService.translate('web-login-userXWebProfile-generic-error', 'Noe gikk galt ved henting av brukerprofil, vennligst kontakt en Plania adminstrator, for å fikse problemet'), 'danger');
						});


					}
				}, function (error) {
					$scope.isLoginActive = false;
					swal('Feil ved henting av brukerens tilganger', error, 'error');
				});
		};

		//If user is already logged in redirect to correct page
		if (repository.authService.getUserData().isAuthenticated) {
			repository.authService.refreshAuthentication().then(function (result) {
				if (result !== 'invalid')
					afterLogonNavigate();
			});
		}
		else {
			repository.authService.authenticateSSO().then(function (result) {
				if (result.success) {
					afterLogonNavigate();
				}
			});
		}


		$scope.federationLogin = function () {
			repository.authService
				.authenticateFederation(); //Federationlogin will redirect pages. Trying to do something after this will not work as the page will be reloaded into another state
		};

		$scope.samlLogin = function () {
			repository.authService.authenticateSaml(); //saml will redirect pages. code after this line will not be executed.
		};

		$scope.username = '';
		$scope.password = '';
		$scope.isLoginActive = false;

		var afterLogonRememberTwoFactor = function (twoFactor) {
			if (!twoFactor || !twoFactor.rememberMe)
				return;

			var daysToRemember = $scope.status.TwoFactorExpirationPeriodInDays;
			if (daysToRemember > 0) {
				var rememberMeInfo = {
					username: $scope.username,
					identifier: twoFactor.identifier,
					secret: twoFactor.newSecret,
					expires: moment().add(daysToRemember, 'days').format()
				};
				$localStorage.twoFactorRememberMe = rememberMeInfo;
			}
		};

		var getRememberedTwoFactorForUser = function(username) {
			var rememberMeInfo = $localStorage.twoFactorRememberMe;
			if (rememberMeInfo && rememberMeInfo.expires) {
				var expiration = moment(rememberMeInfo.expires);
				if (expiration.isBefore(moment())) {
					$localStorage.twoFactorRememberMe = null;
					rememberMeInfo = null;
				}
			}
			return rememberMeInfo && rememberMeInfo.username === username ? rememberMeInfo : null;
		};

		$scope.performLogin = function () {
			$scope.isLoginActive = true;

			if (!$scope.username) {
				repository.growl('Du må fylle ut brukernavn', 'danger');
				$scope.isLoginActive = false;
				return;
			}
			if (!$scope.password) {
				repository.growl('Du må fylle ut passord', 'danger');
				$scope.isLoginActive = false;
				return;
			}
			var logoutOtherLocation = $scope.confirmLogout === true;

			var twoFactorRemembered = getRememberedTwoFactorForUser($scope.username);
			if (!twoFactorRemembered) {
				if ($scope.twoFactor && $scope.twoFactor.rememberMe) {
					// user wants to be remembered, generate a random string and pass to the server. If successful login, we store it for further use
					$scope.twoFactor.newSecret = planiaUtils.randomString(100);
				}
			}

			repository.authService.authenticate($scope.username, $scope.password, logoutOtherLocation, $scope.twoFactor, twoFactorRemembered,
				function (result) {
					afterLogonRememberTwoFactor($scope.twoFactor);
					afterLogonNavigate();
				}, function (result) {
					if (result.status === 'error') {
						repository.growl(result.message, 'danger');
					}
					else if (result.status === 'confirm_logout') {
						$scope.displayConfirmation = true;
					}
					else if (result.status === 'two_factor_required') {
						handleTwoFactor(result);
					}
					else if (result.status === 'two_factor_failed') {
						repository.growl(result.message, 'danger');
					}
					else if (result.status === 'two_factor_cancelled') {
						repository.growl(result.message, 'danger');
						$scope.cancelTwoFactor();
					}
					$scope.isLoginActive = false;
				});
		};

		function handleTwoFactor(result) {
			// No feasible way to return result.message as json from owin, therefore key:value pairs separated by comma
			var messageParams = result.message.split(', ');
			var twoFactorParams = {};
			messageParams.forEach(function (val) {
				var sep = val.split(':');
				twoFactorParams[sep[0]] = sep[1];
			});

			$scope.twoFactor = {
				identifier: twoFactorParams.Id,
				phoneNumber: twoFactorParams.PhoneNumber,
				code: '',
				rememberMe: $scope.status && $scope.status.TwoFactorExpirationPeriodInDays && $scope.status.TwoFactorExpirationPeriodInDays > 0
			};
			$scope.showUsernamePasswordLogin = false;
			$scope.showTwoFactor = true;
		}

		$scope.submitTwoFactor = function () {
			$scope.twoFactor.error = null;
			$scope.performLogin();
		};

		$scope.cancelTwoFactor = function () {
			$scope.twoFactor = null;
			$scope.showUsernamePasswordLogin = true;
			$scope.showTwoFactor = false;
		};

		$scope.reloadPage = function () {
			$state.reload();
		};
	}
})();
