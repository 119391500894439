(function () {
	var app = angular.module('Plania');

	app.directive('plEnumDropdown', function () {
		return {
			restrict: 'E',
			scope: {
				enumType: '=enumType',
				selectedValue: '=selectedValue',
				selectedValueProperty: '=selectedValueProperty', //Value, StringValue or TranslatedValue
				limitEnumOptions: '=limitEnumOptions',
				includeUndefined: '=includeUndefined',
				multiple: '=multiple',
				onChange: '=onChange',
				ngDisabled: '=ngDisabled'
			},
			controller: ['$scope', 'EnumService', controller],
			templateUrl: 'app/common/directives/views/plEnumDropdown.html'
		};
	});

	function controller($scope, enumService) {
		// NgDisabled disables field if it is undefined.
		if (!$scope.ngDisabled) $scope.ngDisabled = false;

		if (!$scope.selectedValueProperty) {
			$scope.selectedValueProperty = 'Value';
		}

		var limitEnumOptions = function (enumOptions) {
			var options = enumOptions.slice(0);
			if (!$scope.limitEnumOptions)
				return options;

			var validOptions = [];
			if ($scope.limitEnumOptions === 'PeriodicTask.Period') {
				validOptions = ['Day', 'Week', 'Month', 'Year', 'HourOfUse'];
			}
			if ($scope.limitEnumOptions === 'PeriodicTask.deadlinePeriodUnit') { //also used by priority (priority.html)
				validOptions = ['Day', 'Week', 'Month', 'Year'];
			}
			if ($scope.limitEnumOptions === 'CleaningTask.TimePeriodUnit') {
				validOptions = ['Day', 'Week', 'Month'];
			}
			if ($scope.limitEnumOptions === 'GeneralOptions.ContractTypeRentalPeriod')
				validOptions = ['Day', 'Week', 'Month', 'Year', 'Hour', 'Once', 'Undefined', 'Udefinert'];

			if (validOptions.length > 0) {
				_.remove(options,
					function (option) {
						return !validOptions.some(function (o) { return o === option.StringValue; });
					});
				return options;
			}

			return options;
		};

		$scope.applyChange = function (newValue) {
			$scope.selectedValue = newValue;
			if ($scope.onChange && typeof $scope.onChange === 'function') {
				$scope.onChange(newValue);
			}
		};

		if ($scope.enumType) {
			enumService.GetEnumValues($scope.enumType).then(function (result) {
				$scope.enumSelectOptions = limitEnumOptions(result);

				if (!$scope.includeUndefined) {
					_.remove($scope.enumSelectOptions,
						function (option) {
							return option.StringValue === 'Undefined' || option.StringValue === 'Udefinert';
						});
				}

				if (($scope.selectedValue === '' || $scope.selectedValue === null || $scope.selectedValue === 'Undefined') && !$scope.includeUndefined && result.length > 0)
					$scope.selectedValue = result[0][$scope.selectedValueProperty];

				if ($scope.selectedValue) {
					var selectedEnumOption = _.find(result, function (enumValue) {
						return enumValue[$scope.selectedValueProperty].toString() === $scope.selectedValue.toString();
					});
					if (selectedEnumOption)
						$scope.selectedValue = selectedEnumOption[$scope.selectedValueProperty];
				}

				if ($scope.enumType === 'prefix') {
					$scope.enumSelectOptions.sort(function (a, b) {
						if (a.TranslatedValue < b.TranslatedValue) { return -1; }
						if (a.TranslatedValue > b.TranslatedValue) { return 1; }
						return 0;
					});
				}
			});
		}
	}

})();
