(function () {
	/**
		Directive to display the hierarchy of Estate, Building, Equipment/Area in a treeview. Can be used inline within any container element.
		Can be used as navigation or as a way of selecting a filter in a table.
	 */
	var app = angular.module('Plania');

	app.directive('plTreeView', function () {
		return {
			restrict: 'E',
			scope: {
				topLevelEntity: '=topLevelEntity', //The entity to show as the top level of the treeview: default Estate
				parentGuid: '=parentGuid', //The Guid to filter the top entity on
				filterEntity: '=filterEntity', //The entity to be filtered
				totalCount: '=totalCount', //property to bind totalCount to parentController if needed
				filterProperty: '=filterProperty', //property used to set filter
				usage: '=usage', //property to define how the treeview is to be used to set what clicks should do
				itemTable: '=itemTable', //The reference to the table to filter from the treeview
				selectedItem: '=selectedItem', //Item used to prefill new entity from related table
				fromState: '=navigationFromState',
				canSelectNone: '=canSelectNone' //Show button between search and items to select 
			},
			controller: ['$scope', '$rootScope', 'Repository', '$state', '$sessionStorage', 'TranslationService', controller],
			templateUrl: 'app/common/directives/views/planiaTreeViewDirective.html'
		};
	});

	function controller($scope, $rootScope, repository, $state, $sessionStorage, translationService) {
		$scope.treeModel = [];

		var url = '';
		var pageSize = 300;
		var filter = { searchString: '' };
		var columns = ['Id', 'Description'];

		var equipmentPrefix = repository.commonService.prefix.Equipment;
		var areaPrefix = repository.commonService.prefix.Area;
		var buildingPrefix = repository.commonService.prefix.Building;
		var estatePrefix = repository.commonService.prefix.Estate;

		var topLevelEntity = $scope.topLevelEntity;
		if (topLevelEntity !== 'Equipment' && repository.commonService.getFilterData().selectedBuilding.Guid !== '')
			topLevelEntity = 'Building';


		var setTopLevelEntity = function () {
			switch (topLevelEntity) {
				case 'Building':
					url = repository.apiData.building.url;
					if (!filter)
						filter = {};
					if ($scope.parentGuid)
						filter.PropertyFilter = [{ Property: 'GuidEstate', Operator: '=', Value: $scope.parentGuid }];
					pageSize = -1;
					if ($scope.filterEntity === 'Area' || $scope.filterEntity === 'CleaningCompletion')
						columns = ['Id', 'Description', 'ChildAreaCount'];
					else
						columns = ['Id', 'Description', 'ChildEquipmentCount', 'ChildAllEquipmentCount'];
					break;
				case 'Equipment':
					url = repository.apiData.equipment.url;
					filter = {
						PropertyFilter: [{
							Property: $scope.filterProperty ? $scope.filterProperty : 'GuidBuilding',
							Operator: '=',
							Value: $scope.parentGuid
						}]
					};

					if ($scope.filterProperty !== 'GuidArea') {
						filter.PropertyFilter.push({
							Operand: 'AND',
							Property: 'GuidEquipmentGroup',
							Operator: '=',
							Value: null
						});
					}
					pageSize = -1;
					columns = ['Id', 'Description', 'Building.Id', 'Building.Description', 'IsEquipmentGroup', 'ChildEquipmentCount'];
					break;
				default:
					url = repository.apiData.estate.url;
					columns = ['Id', 'Description', 'ChildBuildingCount'];
					break;
			}
		};

		setTopLevelEntity();

		var getHref = function (item) {
			switch (item.Prefix) {
				case equipmentPrefix:
					return $rootScope.navigation.href('equipment.edit', { guid: item.Guid });
				case areaPrefix:
					return $rootScope.navigation.href('area.edit', { guid: item.Guid });
				case buildingPrefix:
					return $rootScope.navigation.href('building.edit', { guid: item.Guid });
				case estatePrefix:
					return $rootScope.navigation.href('estate.edit', { guid: item.Guid });
			}
			console.warn("unknown prefix in planiaTreeViewDirective: " + item.Prefix);
			return "";
		};

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >' + translationService.translate('web-button-goTo', 'Gå til') + '</a>',
				isHref: true
			};
		};

		$scope.menuOptions = function (item) {
			var options = [];

			var url = getHref(item);
			options.push(getMenuLink(url));

			if (item.Prefix === equipmentPrefix && item.IsEquipmentGroup && repository.authService.hasCreateAccess(repository.commonService.prefix.Equipment)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href="" >' + translationService.translate('web-button-add', 'Legg til ny') + '</a>',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.addNewChild(modelValue);
					}
				});
			}

			return options;
		};

		$scope.counts = { totalCount: 0 };

		var getTreeModel = function () {
			repository.GetPaginated(url, 0, pageSize, { 'Id': 'asc' }, filter, '', JSON.stringify(columns)).then(function (result) {
				$scope.treeModel = result.List;
				$scope.totalCount = result.TotalCount;
				$scope.counts.totalCount = result.TotalCount;

				var filterData = repository.commonService.getFilterData();

				if (filterData.selectedBuilding.Guid !== '' || filterData.selectedSelection.Guid !== '') {
					if ($scope.treeModel.length === 0)
						$scope.selectedItem = null;
					else
						$scope.selectedItem = $scope.treeModel[0];
				}

				saveTreeView();
			});
		};

		var searchDelay;
		$scope.filterTopLevelEntity = function () {
			clearTimeout(searchDelay);
			searchDelay = setTimeout(function () {
				filter.searchString = $scope.searchString;
				getTreeModel();
			}, 800);
		};

		function saveTreeView() {
			if (!$sessionStorage.storedTreeView)
				$sessionStorage.storedTreeView = {};
			if (!$sessionStorage.storedTreeView[$state.current.name])
				$sessionStorage.storedTreeView[$state.current.name] = {};

			$sessionStorage.storedTreeView[$state.current.name].treeView = $scope.treeModel;
			$sessionStorage.storedTreeView[$state.current.name].treeViewTotal = $scope.counts.totalCount;
			$sessionStorage.storedTreeView[$state.current.name].selectedSelectionGuid = repository.commonService.getFilterData().selectedSelection.Guid;
			$sessionStorage.storedTreeView[$state.current.name].selectedBuildingGuid = repository.commonService.getFilterData().selectedBuilding.Guid;
		}

		function isTreeViewStored() {
			return $sessionStorage.storedTreeView && $sessionStorage.storedTreeView[$state.current.name];
		}

		var findTreeElementFromGuid = function (guid) {
			var object;

			$scope.treeModel.some(function f(a) {
				if (a.Guid === guid) {
					object = a;
					return true;
				}
				if (Array.isArray(a.children)) {
					return a.children.some(f);
				}
			});

			return object;
		};

		var updateSelectedTreeViewSection = function () {
			switch ($scope.selectedItem.Prefix) {
				case equipmentPrefix:
					if ($scope.selectedItem.GuidEquipmentGroup) {
						getChildEntities(findTreeElementFromGuid($scope.selectedItem.GuidEquipmentGroup));
					} else if ($scope.selectedItem.GuidBuilding) {
						getChildEntities(findTreeElementFromGuid($scope.selectedItem.GuidBuilding));
					}
					break;
				case buildingPrefix:
					getChildEntities(findTreeElementFromGuid($scope.selectedItem.GuidEstate));
					break;
				case estatePrefix:
					getChildEntities($scope.selectedItem);
					break;
			}
		};

		var getChildUrl = function (item) {
			switch (item.Prefix) {
				case equipmentPrefix:
				case areaPrefix:
				case buildingPrefix:
					if ($scope.filterEntity === 'Area' || $scope.filterEntity === 'CleaningCompletion')
						return repository.apiData.area.url;
					return repository.apiData.equipment.url;
				case estatePrefix:
					return repository.apiData.building.url;

			}
		};

		var getChildFilter = function (item) {
			var filter;

			switch (item.Prefix) {
				case equipmentPrefix:
					filter = {
						PropertyFilter: [
							{ Property: 'GuidEquipmentGroup', Operator: '=', Value: item.Guid }
						]
					};

					if ($scope.usage === 'filter' && $scope.filterEntity === 'Equipment') {
						filter.PropertyFilter.push({
							Property: 'IsEquipmentGroup', Operator: '=', Value: true
						});
					}

					return filter;
				case areaPrefix:
					return {};
				case buildingPrefix:
					filter = {
						PropertyFilter: [
							{ Property: 'GuidBuilding', Operator: '=', Value: item.Guid },
							{ Property: 'GuidEquipmentGroup', Operator: '=', Value: null },
						]
					};

					if ($scope.usage === 'filter' && $scope.filterEntity === 'Equipment') {
						filter.PropertyFilter.push({
							Property: 'IsEquipmentGroup', Operator: '=', Value: true
						});
					}

					return filter;
				case estatePrefix:
					return {
						PropertyFilter: [
							{ Property: 'GuidEstate', Operator: '=', Value: item.Guid }
						]
					};

			}
		};

		var getChildColumns = function (item) {
			var childColumns = ['Id', 'Description'];

			switch (item.Prefix) {
				case buildingPrefix:
				case equipmentPrefix:
					childColumns.push('Building.Id', 'Building.Description', 'IsEquipmentGroup');
					childColumns.push('ChildEquipmentCount', 'ChildEquipmentGroupCount', 'ChildAllAreaCount');
					break;
				case estatePrefix:
					if ($scope.usage === 'filter')
						childColumns.push('ChildAllEquipmentCount');
					else
						childColumns.push('ChildEquipmentCount');
					break;
			}

			return childColumns;
		};

		var getChildEntities = function (item) {
			if (!item) return;
			item.isExpanded = true;
			item.isLoading = true;

			var childColumns = getChildColumns(item);

			repository.GetPaginated(getChildUrl(item), -1, -1, { 'Id': 'asc' }, getChildFilter(item), '', JSON.stringify(childColumns)).then(function (result) {
				item.isLoading = false;
				item.children = result.List;
				saveTreeView();
				$scope.totalCount += result.List.length;
			}, function (error) {
				repository.growl(error, 'error');
				item.isLoading = false;
			});
		};

		var setTableFilter = function (filter, item) {
			if (!item) return;
			switch (item.Prefix) {
				case equipmentPrefix:
					if (item.IsEquipmentGroup)
						filter.PropertyFilter.push({ Property: 'GuidEquipmentGroup', Operator: '=', Value: item.Guid });
					else
						filter.PropertyFilter.push({ Property: 'Guid', Operator: '=', Value: item.Guid });
					break;
				case areaPrefix:
					filter.PropertyFilter.push({ Property: 'GuidArea', Operator: '=', Value: item.Guid });
					break;
				case buildingPrefix:
					filter.PropertyFilter.push({ Property: 'GuidBuilding', Operator: '=', Value: item.Guid });
					break;
				case estatePrefix:
					if ($scope.filterEntity === 'Equipment')
						filter.PropertyFilter.push({ Property: 'Building.GuidEstate', Operator: '=', Value: item.Guid });
					else
						filter.PropertyFilter.push({ Property: 'GuidEstate', Operator: '=', Value: item.Guid });
					break;
			}
		};

		var setPersistentData = function (item) {
			if (isTreeViewStored())
				$sessionStorage.storedTreeView[$state.current.name].selectedTreeViewItem = $scope.selectedItem;

			if ($scope.filterEntity === 'Equipment') {
				if ($scope.selectedItem.Prefix === estatePrefix) {
					repository.persistedData.setPersistedData('equipment.create', {});
				} else {
					repository.persistedData.setPersistedData('equipment.create', {
						GuidBuilding: $scope.selectedItem.Prefix === buildingPrefix ? $scope.selectedItem.Guid : $scope.selectedItem.GuidBuilding,
						Building: $scope.selectedItem.Prefix === buildingPrefix ? { Caption: $scope.selectedItem.Caption } : $scope.selectedItem.Building,
						GuidEquipmentGroup: $scope.selectedItem.Prefix === equipmentPrefix && $scope.selectedItem.IsEquipmentGroup ? $scope.selectedItem.Guid : null,
						EquipmentGroup: $scope.selectedItem.Prefix === equipmentPrefix && $scope.selectedItem.IsEquipmentGroup ? $scope.selectedItem : null
					});
				}
			}

		};

		if ($scope.usage === 'filter' && isTreeViewStored() &&
			(!$scope.fromState ||
				($scope.fromState === $scope.filterEntity.toLowerCase() + '.edit' ||
					$scope.fromState === $scope.filterEntity.toLowerCase() + '.create') ||
				($scope.filterEntity === 'CleaningCompletion' && $scope.fromState.toLowerCase() === $scope.filterEntity.toLowerCase() + '.list'))
		) {

			$scope.treeModel = $sessionStorage.storedTreeView[$state.current.name].treeView;
			$scope.counts.totalCount = $sessionStorage.storedTreeView[$state.current.name].treeViewTotal;
			$scope.selectedItem = $sessionStorage.storedTreeView[$state.current.name].selectedTreeViewItem;

			var selectedSelectionGuid = $sessionStorage.storedTreeView[$state.current.name].selectedSelectionGuid;
			var selectedBuildingGuid = $sessionStorage.storedTreeView[$state.current.name].selectedBuildingGuid;

			if ($scope.fromState && $scope.selectedItem) {
				updateSelectedTreeViewSection();
			}

			var filterData = repository.commonService.getFilterData();
			if (filterData.selectedBuilding.Guid !== selectedBuildingGuid || filterData.selectedSelection.Guid !== selectedSelectionGuid) {
				getTreeModel();
			}

		} else {
			getTreeModel();
			$scope.selectedItem = null;
		}

		$scope.clickAction = function (item) {
			if ($scope.usage === 'filter') {
				if ($scope.selectedItem !== item) {
					$scope.selectedItem = item;
					setPersistentData(item);
				}

				if ($scope.itemTable) {
					var filter = $scope.itemTable.filter();
					if (!filter) filter = {};
					if (!filter.PropertyFilter) {
						filter.PropertyFilter = [];
						setTableFilter(filter, $scope.selectedItem);
					} else {
						_.remove(filter.PropertyFilter, function (o) {
							return o.Property === 'Guid' || o.Property === 'GuidEstate' || o.Property === 'Building.GuidEstate' || o.Property === 'GuidBuilding' || o.Property === 'GuidEquipmentGroup' || o.Property === 'GuidArea';
						});
						setTableFilter(filter, $scope.selectedItem);
					}
				}
			}

			if (item.isExpanded) {
				item.isExpanded = false;
				saveTreeView();
				return;
			}

			if ($scope.isExpandable(item) && !item.isExpanded) {
				if (item.children) {
					item.isExpanded = true;
					saveTreeView();
				} else {
					getChildEntities(item);
				}
			}
		};

		$scope.clearSelectedItem = function () {
			$scope.selectedItem = null;
			if ($scope.usage === 'filter') {
				setPersistentData();
				// Todo - do this possibly for lists
			}
			saveTreeView();
		};

		$scope.isExpandable = function (item) {
			switch (item.Prefix) {
				case equipmentPrefix:
				case areaPrefix:
				case buildingPrefix:
					if ($scope.usage === 'filter') {
						return item.ChildEquipmentGroupCount > 0 || item.ChildAllEquipmentCount > 0 || item.ChildAreaCount > 0;
					} else {
						return item.ChildEquipmentCount > 0;
					}
					break;
				case estatePrefix:
					return item.ChildBuildingCount > 0;
			}
		};

		$scope.getChildCount = function (item) {
			switch (item.Prefix) {
				case equipmentPrefix:
					return item.ChildEquipmentCount;
				case areaPrefix:
					return item.ChildAreaCount;
				case buildingPrefix:
					if ($scope.usage === 'filter') {
						return item.ChildAllEquipmentCount;
					} else {
						return item.ChildEquipmentCount;
					}
					break;
				case estatePrefix:
					return item.ChildBuildingCount;
			}
		};

		$scope.getEntityIcon = function (item) {
			switch (item.Prefix) {
				case equipmentPrefix:
					return 'zmdi-settings c-bluegray';
				case areaPrefix:
					return 'zmdi-layers c-lightblue';
				case buildingPrefix:
					return 'zmdi-balance c-brown';
				case estatePrefix:
					return 'zmdi-city c-teal';

			}
		};

		$scope.addNewChild = function (item) {
			switch (item.Prefix) {
				case equipmentPrefix:
					repository.persistedData.setPersistedData('equipment.create', {
						GuidBuilding: item.GuidBuilding,
						Building: item.Building,
						GuidEquipmentGroup: item.Guid,
						EquipmentGroup: item
					});

					$state.go('equipment.create', { showPersistantData: true });
					break;
				case buildingPrefix:
					repository.persistedData.setPersistedData('equipment.create', {
						GuidBuilding: item.Guid,
						Building: item,
					});
					$state.go('equipment.create', { showPersistantData: true });
					break;
				case estatePrefix:
					repository.persistedData.setPersistedData('building.create', {
						GuidEstate: item.Guid,
						Estate: item,
					});
					$state.go('building.create', { showPersistantData: true });
					break;
				case areaPrefix:
					repository.persistedData.setPersistedData('area.create', {
						GuidBuilding: item.Guid,
						Building: item,
					});
					$state.go('area.create', { showPersistantData: true });
					break;
			}

		};

		$scope.$on($rootScope.events.newSelection, function () {
			// Show building as top level
			if (repository.commonService.getFilterData().selectedBuilding.Guid !== '')
				topLevelEntity = 'Building';
			else
				topLevelEntity = $scope.topLevelEntity;
			setTopLevelEntity();
			getTreeModel();
		});
	}
})();
