(function () {

	var app = angular.module('Plania');

	app.directive('projectDeviations', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				viewMode: '=viewMode',
				update: '=update',
				restrictEdit: '=restrictEdit',
				isModelLoading: '=isModelLoading'
			},
			controller: ['$scope', '$rootScope', 'Repository', 'TranslationService', '$modal', 'AutoCompleteService', 'ListService', 'ngTableParams', '$window', controller],
			templateUrl: 'app/project/views/projectDeviationsDirective.html'
		};
	});

    function controller($scope, $rootScope, repository, translationService, $modal, autoCompleteService, listService, ngTableParams, $window) {
		$scope.rootScope = $rootScope;
		$scope.deviationSetting = {};
		$scope.conditionSetting = {};

		listService.getWebListViews(null, repository.apiData.deviation.prefix, false, null, {}).then(function (result) {
			$scope.deviationSetting.deviationListOptions = result.listOptions;
			$scope.deviationSetting.deviationListSetup = result.selectedListSetup;

			$scope.deviationTable = new ngTableParams(
				{ page: 1, count: 10, sorting: { 'Id': 'desc' }, filter: { PropertyFilter: [{ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.model.Guid }] } },
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.deviationSetting.deviationListSetup.Columns.forEach(function (col) {
							columns.push(col.Property);
						});


						repository.GetPaginated(repository.apiData.deviation.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
							function (result) {
								$scope.deviationTable.settings().total = result.TotalCount;
								$scope.deviationTable.settings().filterDelay = 500;

								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		});

		$scope.$watch('deviationSetting.deviationListSetup', function (newValue, oldValue) {
			if (oldValue === newValue)
				return;

			if ($scope.deviationTable) {
				$scope.deviationTable.$params.sorting = $scope.deviationSetting.deviationListSetup.Sorting;

				if ($scope.deviationSetting.deviationListSetup.GroupBy) {
					$scope.deviationTable.settings().groupBy = function (obj) {
						if (!$scope.deviationSetting.deviationListSetup.GroupBy) {
							return false;
						}

						var attrs = $scope.deviationSetting.deviationListSetup.GroupBy.split('.');
						attrs.forEach(function (attr) {
							if (!obj) {
								obj = "";
							} else {
								obj = obj[attr];
							}
						});

						return obj;
					};
				} else {
					$scope.deviationTable.settings().groupBy = null;
				}

				$scope.deviationTable.reload();
			}
		});

		listService.getWebListViews(null, repository.apiData.condition.prefix, false, null, {}).then(function (result) {
			$scope.conditionSetting.conditionListOptions = result.listOptions;
			$scope.conditionSetting.conditionListSetup = result.selectedListSetup;

			$scope.conditionTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { 'Id': 'desc' },
					filter: {
						PropertyFilter: [{ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.model.Guid }],
						isCorrectiveAction: true
					}
				},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.conditionSetting.conditionListSetup.Columns.forEach(function (col) {
							columns.push(col.Property);
						});


						repository.GetPaginated(repository.apiData.condition.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
							function (result) {
								$scope.conditionTable.settings().total = result.TotalCount;
								$scope.conditionTable.settings().filterDelay = 500;

								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		});

		$scope.$watch('conditionSetting.conditionListSetup', function (newValue, oldValue) {
			if (oldValue === newValue)
				return;

			if ($scope.conditionTable) {
				$scope.conditionTable.$params.sorting = $scope.conditionSetting.conditionListSetup.Sorting;

				if ($scope.conditionSetting.conditionListSetup.GroupBy) {
					$scope.conditionTable.settings().groupBy = function (obj) {
						if (!$scope.conditionSetting.conditionListSetup.GroupBy) {
							return false;
						}

						var attrs = $scope.conditionSetting.conditionListSetup.GroupBy.split('.');
						attrs.forEach(function (attr) {
							if (!obj) {
								obj = "";
							} else {
								obj = obj[attr];
							}
						});

						return obj;
					};
				} else {
					$scope.conditionTable.settings().groupBy = null;
				}

				$scope.conditionTable.reload();
			}
		});

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.selectedDeviations = [];
		$scope.allDeviationsSelected = false;

		$scope.selectedConditions = [];
		$scope.allConditionsSelected = false;

		$scope.selectAllRows = function (table, selectedList, allSelectedProperty) {
			if ($scope[allSelectedProperty]) {
				table.data.forEach(function (row) {
					var index = _.findIndex(selectedList, function (o) { return o === row.Guid; });
					selectedList.splice(index, 1);
				});

				$scope[allSelectedProperty] = false;
			} else {
				table.data.forEach(function (row) {
					if (!_.contains(selectedList, row.Guid)) {
						selectedList.push(row.Guid);
					}
				});
				$scope[allSelectedProperty] = true;
			}
		};

		$scope.itemIsSelected = function (guid, selectedList) {
			return _.contains(selectedList, guid);
		};

		$scope.selectItem = function (guid, selectedList) {
			if (!_.contains(selectedList, guid))
				selectedList.push(guid);
			else {
				for (var i = 0; i < selectedList.length; i++) {
					if (selectedList[i] === guid) {
						selectedList.splice(i, 1);
						return;
					}
				}
			}
		};

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

        $scope.conditionMenuOptions = function (condition) {
			return [getMenuLink($rootScope.navigation.href('condition.edit', { guid: condition.Guid }), 'zmdi-plaster', translationService.translate('web-open-condition', 'Åpne tilstand/tiltak'))];
        };

        $scope.deviationMenuOptions = function (deviation) {
			return [getMenuLink($rootScope.navigation.href('deviation.edit', { guid: deviation.Guid }), 'zmdi-alert-octagon', translationService.translate('web-open-deviation', 'Åpne avvik'))];
        };

	}
})();
