(function () {

    angular.module('Plania').controller('ShortcutController', ['$scope', 'MenuService', '$modal', 'CommonService', controller]);

    function controller($scope, service, $modal, commonService) {
        $scope.shortcuts = [];
        service.getShortcuts().then(function (webMenus) {
            $scope.shortcuts = webMenus;
        });

        $scope.ddSelectOptions = service.getCreateObjects();
        $scope.ddSelectSelected = { text: 'Ny' };

        $scope.ddChangeFunction = function (selected) {
            $scope.ddSelectSelected = { text: 'Ny' };
            $scope.navigation.go(selected.state);
        };

        $scope.createDocument = function () {
            $modal.open({
                templateUrl: 'app/document/views/createDocumentModal.html',
                controller: 'CreateDocumentController',
                size:'lg'
            }).result.then(function () {
            }, function () {
            });
		};

		$scope.activeShortcut = null;

		$scope.ChangeView = function (shortcut) {
			if ($scope.isDisabled(shortcut)) {
                return;
			}

			$scope.activeShortcut = shortcut;

            if (shortcut.state) {
                var params = { menuGuid: shortcut.guid };
                if (shortcut.isBuildingDependent && shortcut.state === 'building.edit')
                    params.guid = commonService.getFilterData().selectedBuilding.Guid;
	            $scope.navigation.go(shortcut.state, params, { reload: true, inherit: false });
            }
            else {
                shortcut.open = !shortcut.open;
            }
        };

        $scope.isDisabled = function (shortcut) {
            if (shortcut.state) {
                if (shortcut.isBuildingDependent) {
                    return commonService.disableWebMenuNavigation();
                }
            }
            return false;
        };

        $scope.$on($scope.events.updatedAccess, function () {
            $scope.ddSelectOptions = service.getCreateObjects();
        });
        $scope.$on($scope.events.newSelection, function () {
            $scope.ddSelectOptions = service.getCreateObjects();
        });
    }
})();
