(function () {
	angular.module('Plania').factory('Repository', ['genericRepository', '$q', 'CommonService',
		'ModuleService', 'authService', 'config', 'PersistentDataService',
		'$location', '$rootScope', 'growlService', 'apiData', 'MapService', 'ValidationService', '$localStorage',

		function autoCompleteService(genericRepository, $q, commonService, moduleService, authService, config, persistedDataService, $location, $rootScope, growlsService, apiData, mapService, validationService, $localStorage) {
			var service = {};

			service.commonService = commonService;
			service.persistedData = persistedDataService;
			service.moduleService = moduleService;
			service.authService = authService;
			service.location = $location;
			service.apiData = apiData;
			service.mapService = mapService;
			service.validationService = validationService;

			service.enableWebNavigation = false;

			var getStringFromModelState = function (modelstate) {
				var propertyName = Object.getOwnPropertyNames(modelstate);
				if (propertyName)
					return modelstate[propertyName][0];
				return "";
			};

			var getStringFromErrorObject = function (error) {
				if (error && typeof error === 'object') {
					if (error.ModelState) {
						return getStringFromModelState(error.ModelState);
					}
					if (error.Data && error.Data.ModelState) {
						return getStringFromModelState(error.Data.ModelState);
					}
					if (error.Message && error.Message !== "") {
						return error.Message;
					}
					if (error.Data && typeof error.Data === 'string') {
						return error.Data;
					}
					if (error.Data && Array.isArray(error.Data)) {
						return error.Data;
					}
					if (error.Data.Message && typeof error.Data.Message === 'string')
						return error.Data.Message;
				}
				return error;
			};

			var handleLoggedInOnAnotherDevice = function (status) {
				//you have been logged in on another device or browser, this session has ended, logging out with redirect url back to where you was if you want to continue working

				//delete data saved on logged in accounts
				authService.deleteLocalData();

				//create new client id if you want to relogin
				var storage = window.localStorage;
				var d = new Date().getTime();
				var fingerprint = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
					function (c) {
						var r = (d + Math.random() * 16) % 16 | 0;
						d = Math.floor(d / 16);
						return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
					});

				storage.setItem("Fingerprint", fingerprint);
				config.fingerprint = fingerprint;

				var sessionStorage = window.sessionStorage;

				if (status === 471)
					sessionStorage.setItem('logoutError',
						'Du ble kastet ut av Plania av en administrator');
				else
					sessionStorage.setItem('logoutError',
						'Du ble logget inn i en annen browser eller på en annen maskin, vennligst logg inn igjen');

				location.reload();
			};

			var getErrorMessageFromStatus = function (status, error) {
				if (status === 471 || status === 472) {
					handleLoggedInOnAnotherDevice(status);
				}

				var message = getStringFromErrorObject(error);
				if (typeof message === 'string' && message.length > 0) {
					return message;
				}

				if (Array.isArray(message)) {
					return message;
				}

				switch (status) {
					case 401:
						return authService.getUserData().isAuthenticated ? "Mangler tilgang" : "Du må logge inn for å se denne informasjonen";
					case 404:
						return "Ingen data funnet";
					case 409:
						return "Konflikt i Api";
					default:
						return "Ukjent feil oppstod. Http status kode: " + status;
				}
			};

			var updateAccess = function (accessData) {
				authService.updateUserAccess(accessData);
			};

			service.getLocale = function () { return $localStorage.locale; };
			service.setLocale = function (newLocale) {
				if (newLocale)
					$localStorage.locale = newLocale;
				else
					$localStorage.locale = 'nb-no';
			};

			service.getUrlParameters = function (separator) {
				if (!separator)
					separator = '?';
				return separator + 'dataOwner=' + commonService.getFilterData().selectedDataOwner.Guid;
			};

			service.getFilter = function (filter) {
				var tmpFilter = _.cloneDeep(filter) || {};
				tmpFilter.GuidBuilding = '';
				tmpFilter.GuidSelection = '';
				tmpFilter.GuidDataOwner = '';

				if (tmpFilter.isBuildingDependent !== false)
					tmpFilter.isBuildingDependent = true;

				if (commonService.getFilterData().selectedBuilding.Guid && tmpFilter.isBuildingDependent && !tmpFilter.excludeBuilding) tmpFilter.GuidBuilding = commonService.getFilterData().selectedBuilding.Guid;
				if (commonService.getFilterData().selectedSelection.Guid) tmpFilter.GuidSelection = commonService.getFilterData().selectedSelection.Guid;

				if (commonService.getFilterData().selectedDataOwner.Guid) tmpFilter.GuidDataOwner = commonService.getFilterData().selectedDataOwner.Guid;

				return tmpFilter;
			};

			service.getSingle = function (serviceUrl, guid, columns, setHtmlTitle, presets) {
				var deferred = $q.defer();
				var url = serviceUrl + guid + service.getUrlParameters();
				if (columns) {
					url += '&columns=' + columns;
				}
				if (presets) {
					url += '&presets=' + presets;
				}
				genericRepository.get(url, {})
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (result.EnableWebNavigation !== undefined)
							service.enableWebNavigation = result.EnableWebNavigation;

						if (apiResponse.status === 200) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							if (setHtmlTitle && result.Data) {
								$rootScope.setHtmlTitleByModel(result.Data);
							}
							deferred.resolve(result);
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					}, function (error) {
						deferred.reject(error);
					});
				return deferred.promise;
			};

			service.getMainModel = function (serviceUrl, guid, columns, presets) {
				return service.getSingle(serviceUrl, guid, columns, true, presets);
			};

			service.getWithUrlParameter = function (serviceUrl, urlParameter, filter) {
				var deferred = $q.defer();
				var filterstring = '';
				if (filter)
					filterstring = '&filter=' + encodeURIComponent(JSON.stringify(service.getFilter(filter)));

				var url = serviceUrl + '?' + urlParameter + filterstring + service.getUrlParameters('&');

				genericRepository.get(url, {})
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (result.EnableWebNavigation !== undefined)
							service.enableWebNavigation = result.EnableWebNavigation;

						if (apiResponse.status === 200) {
							updateAccess({
								EntityName: result.EntityName,
								Access: result.Access,
								AccessOtherDataOwner: result.AccessOtherDataOwner
							});
							deferred.resolve(result);
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					}).catch(function (error) {
						deferred.reject(error);
					});
				return deferred.promise;
			};

			service.getSingleWithNoParameter = function (serviceUrl) {
				var deferred = $q.defer();
				var url = serviceUrl + service.getUrlParameters();
				genericRepository.get(url, {})
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (result.EnableWebNavigation !== undefined)
							service.enableWebNavigation = result.EnableWebNavigation;

						if (apiResponse.status === 200) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							deferred.resolve(result);
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					}).catch(function (error) {
						deferred.reject(error);
					});
				return deferred.promise;
			};

			service.filterKeyWords = [
				'@GuidLoggedInPerson',
				"@GuidLoggedInPersonDepartment",
				'@GuidLoggedInPersonResourceGroup',
				'@GuidUser',
				'@GuidUserCustomer',
				'@GuidCurrentBuilding',
				'@GuidSupplier'
			];

			service.prefillKeyWords = [
				'@LoggedInPerson',
				'@User',
				'@CurrentBuilding',
				'@UserSupplier',
				'@UserCustomer'
			];

			service.timeKeywords = [
				'@Today',
				'@Month',
				'@Year',
				'@CurrentYear',
				//'@WorkPeriod'
			];

			service.GetKeyWordFilters = function (value) {
				if (angular.isUndefined(value) || value === null) return "";

				value = value.toString().replace('@GuidUserCustomer', authService.getUserData().guidCustomer);
				value = value.toString().replace('@GuidUser', authService.getUserData().guidUser);
				value = value.toString().replace('@GuidLoggedInPersonDepartment', authService.getUserData().guidPersonDepartment);
				value = value.toString().replace('@GuidLoggedInPersonResourceGroup', authService.getUserData().guidPersonResourceGroup);
				value = value.toString().replace('@GuidLoggedInPerson', authService.getUserData().guidPerson);
				value = value.toString().replace('@Today', moment().utc().startOf('day').hours(0).toISOString());
				value = value.toString().replace('@Month', moment().utc().subtract(1, 'month').toISOString());
				value = value.toString().replace('@Year', moment().utc().subtract(1, 'year').toISOString());
				value = value.toString().replace('@CurrentYear', moment().utc().startOf('year').toISOString());
				if (commonService.getFilterData().selectedBuilding && commonService.getFilterData().selectedBuilding.Guid !== "") {
					value = value.toString().replace('@GuidCurrentBuilding', commonService.getFilterData().selectedBuilding.Guid);
				}
				if (authService.getUserData().guidSupplier) {
					value = value.toString().replace('@GuidSupplier', authService.getUserData().guidSupplier);
				} else {
					value = value.toString().replace('@GuidSupplier', '');
				}

				return value;
			};

			var uniqueString = function (serviceUrl, page, pageSize, sorting, filter, columns) {
				return serviceUrl + page + pageSize + JSON.stringify(sorting) + JSON.stringify(filter) + JSON.stringify(columns);
			};
			var lastPaginatedCall = {};
			var lastPaginatedResult = {};

			service.GetPaginated = function (serviceUrl, page, pageSize, sorting, filter, includeGuid, columns) {
				var tmpfilter = service.getFilter(filter);

				if (tmpfilter.PropertyFilter) {
					tmpfilter.PropertyFilter.forEach(function (f) {
						if (f.Value) {
							f.Value = service.GetKeyWordFilters(f.Value);
						}
					});

					_.remove(tmpfilter.PropertyFilter, function (f) { return f.Value === '@GuidCurrentBuilding'; });
				}

				if (includeGuid) {
					tmpfilter.includeGuid = includeGuid;
				}
				if (tmpfilter.searchString) {
					tmpfilter.searchString = tmpfilter.searchString.replace(/#/g, '');
				}

				var callString = uniqueString(serviceUrl, page, pageSize, sorting, tmpfilter, columns);

				var url = serviceUrl +
					'?page=' + page +
					'&pageSize=' + pageSize +
					'&sorting=' + JSON.stringify(sorting) +
					'&filter=' + encodeURIComponent(JSON.stringify(tmpfilter)) +
					'&columns=' + columns +
					service.getUrlParameters('&');

				var deferred = $q.defer();
				if (lastPaginatedCall[callString] && lastPaginatedCall[callString] > Date.now()) {
					deferred.resolve(lastPaginatedResult[callString]);
					lastPaginatedResult[callString] = null;
				} else {
					genericRepository.get(url, {}).then(function (apiResponse) {
						var result = apiResponse.data;
						if (result.EnableWebNavigation !== undefined && result.EntityName !== 'InternalWeb')
							service.enableWebNavigation = result.EnableWebNavigation;

						if (apiResponse.status === 200) {
							lastPaginatedCall[callString] = Date.now() + 1000;
							// most of the time returned data is a list of Plania entities, but sometimes it may be a plain entity without
							if (result.EntityName || result.EntityType) {
								// actual entity list is under member Data
								updateAccess({ EntityName: result.EntityName || result.EntityType, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
								lastPaginatedResult[callString] = result.Data;
								deferred.resolve(result.Data);
							}
							else {
								// actual entity list is under main object result itself
								lastPaginatedResult[callString] = result;
								deferred.resolve(result);
							}

						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					}, function (error) {
						deferred.reject(getErrorMessageFromStatus(error.status, error.data));
					});
				}
				return deferred.promise;
			};

			service.updateSingle = function (serviceUrl, model, urlParams) {
				var deferred = $q.defer();
				var id = model.Guid ? model.Guid : "";
				var url = serviceUrl + id + service.getUrlParameters();
				if (urlParams) {
					url = url + '&' + urlParams;
				}
				validationService.validateModel(model);
				genericRepository.put(url, model)
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							if (result.Data)
								deferred.resolve(result.Data);
							else {
								deferred.resolve(result);
							}
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					},
						function (error) {
							deferred.reject(getErrorMessageFromStatus(error.status, error.data));
						});
				return deferred.promise;
			};

			service.updateSingleDictionary = function (serviceUrl, model, urlParams) {
				var deferred = $q.defer();
				var id = model.Guid ? model.Guid : "";
				var url = serviceUrl + id + service.getUrlParameters();
				if (urlParams) {
					url = url + '&' + urlParams;
				}
				validationService.validateModel(model);
				genericRepository.put(url, { "": JSON.stringify(model) })
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200) {
							if (typeof result === "string") {
								deferred.resolve(result);
							} else {
								updateAccess({
									EntityName: result.EntityName,
									Access: result.Access,
									AccessOtherDataOwner: result.AccessOtherDataOwner
								});
								deferred.resolve(result.Data);
							}
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					},
						function (error) {
							deferred.reject(getErrorMessageFromStatus(error.status, error.data));
						});
				return deferred.promise;
			};

			service.runAction = function (serviceUrl, bodyParameters, urlParams, useRawBodyParams) {
				var deferred = $q.defer();
				var url = serviceUrl + service.getUrlParameters();
				if (urlParams)
					url = url + '&' + urlParams;
				genericRepository.put(url, useRawBodyParams ? bodyParameters : { "": bodyParameters })
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200) {
							if (typeof result === "string") {
								deferred.resolve(result);
							} else {
								deferred.resolve(result.Data);
							}
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					},
						function (error) {
							deferred.reject(getErrorMessageFromStatus(error.status, error.data));
						});
				return deferred.promise;
			};

			service.createSingleDictionary = function (serviceUrl, model, urlParams) {
				validationService.validateModel(model);
				var dictModel = { "": JSON.stringify(model) };
				return service.createSingle(serviceUrl, dictModel, urlParams);
			};

			service.createSingle = function (serviceUrl, model, urlParams) {
				var deferred = $q.defer();
				var url = serviceUrl + service.getUrlParameters() + '&' + urlParams;
				validationService.validateModel(model);
				genericRepository.post(url, model)
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 201) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							deferred.resolve(result.Data ? result.Data : result);
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result.Data));
						}
					},
						function (apiResponse) {
							if (!apiResponse.data) {
								return;
							}
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, apiResponse.data));
						});
				return deferred.promise;
			};

			service.createSingleWithBinary = function (serviceUrl, model, binaries) {
				var deferred = $q.defer();
				var url = serviceUrl + service.getUrlParameters();
				genericRepository.postFile(url, model, binaries)
					.then(function (apiResponse) {
						if (apiResponse.status === 200) {
							deferred.resolve(apiResponse);
						}
						else {
							deferred.reject(apiResponse.errorFiles);
						}
					}, function (error) {
						deferred.reject(getErrorMessageFromStatus(error.status, error.data.Data));
					},
						function (progress) {
							deferred.notify(progress);
						})
					.catch(function (apiResponse) {
						deferred.reject(getErrorMessageFromStatus(apiResponse.status, apiResponse.data.Data));
					});
				return deferred.promise;
			};

			service.updateSingleWithBinary = function (serviceUrl, model, binaries) {
				var deferred = $q.defer();
				var url = serviceUrl + model.Guid + service.getUrlParameters();
				genericRepository.putFile(url, model, binaries)
					.then(function (apiResponse) {
						if (apiResponse.status === 200) {
							deferred.resolve(apiResponse);
						} else {
							deferred.reject(apiResponse.errorFiles);
						}
					},
						function (error) {
							deferred.reject(getErrorMessageFromStatus(error.status, error.data.Data));
						},
						function (progress) {
							deferred.notify(progress);
						})
					.catch(function (apiResponse) {
						deferred.reject(getErrorMessageFromStatus(apiResponse.status, apiResponse.data.Data));
					});
				return deferred.promise;
			};

			service.createMultipleWithGuid = function (serviceUrl, guid, model) {
				var deferred = $q.defer();
				var url = serviceUrl + guid + service.getUrlParameters();
				genericRepository.post(url, { "": model })
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							deferred.resolve(result.Data);
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					}, function (error) {
						deferred.reject(getErrorMessageFromStatus(error.status, error.data));
					});
				return deferred.promise;
			};

			service.createMultiple = function (serviceUrl, model, urlParams) {
				var deferred = $q.defer();
				var url = serviceUrl + service.getUrlParameters() + '&' + urlParams;
				genericRepository.post(url, { "": model })
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200 || apiResponse.status === 201) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							deferred.resolve(result.Data || result);
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					}, function (error) {
						deferred.reject(getErrorMessageFromStatus(error.status, error.data));
					});
				return deferred.promise;
			};

			service.updateMultiple = function (serviceUrl, model) {
				var deferred = $q.defer();
				var url = serviceUrl + service.getUrlParameters();
				genericRepository.put(url, { "": model })
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200 || apiResponse.status === 201) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							deferred.resolve(result.Data);
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					}, function(error) {
						deferred.reject(getErrorMessageFromStatus(error.status, error.data));
					});
				return deferred.promise;
			};

			service.updateMultipleWithGuid = function (serviceUrl, guid, model, urlParams) {
				var deferred = $q.defer();
				var url = serviceUrl + guid + service.getUrlParameters();
				if (urlParams)
					url = url + '&' + urlParams;
				genericRepository.put(url, { "": model })
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200 || apiResponse.status === 201) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							deferred.resolve(result.Data);
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					}, function (error) {
						deferred.reject(getErrorMessageFromStatus(error.status, error.data));
					});
				return deferred.promise;
			};

			service.growl = function (message, type) {
				growlsService.growl(message, type, { enter: 'animated fadeInRight', exit: 'animated fadeOutRight' });
			};

			service.showError = function (error) {
				var message = getStringFromErrorObject(error);
				service.growl(message, 'danger');
			};

			service.deleteSingle = function (serviceUrl, guid, urlParameter) {
				var deferred = $q.defer();
				var url = serviceUrl + guid;
				if (urlParameter)
					url += '?' + urlParameter + service.getUrlParameters('&');
				else
					url += service.getUrlParameters();

				genericRepository.delete(url, {})
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200) {
							updateAccess({ EntityName: result.EntityName, Access: result.Access, AccessOtherDataOwner: result.AccessOtherDataOwner });
							deferred.resolve(result.Data);
						} else {
							var errorMessage = getErrorMessageFromStatus(error.status, error.data);
							swal("Error!", errorMessage, "error");
							deferred.reject(errorMessage);
						}
					}, function (error) {
						var errorMessage = getErrorMessageFromStatus(error.status, error.data);
						//If deactivateable, send handling to the specific controllers error function
						if (error.data.IsDeactivateable) {
							deferred.reject(error.data);
						} else {
							//Else show errorMessage as normal
							swal("Error!", errorMessage, "error");
						}
					});
				return deferred.promise;
			};


			// Guid - Used when updating one object, model shuld be a serialized dictionary with Property as key.
			// When Guid is missing, then model should be a list of serialized dictionaries where each dictionary should contain a Guid for the object to be changed.
			service.patch = function (serviceUrl, guid, model) {
				var deferred = $q.defer();

				var url = serviceUrl;
				if (guid)
					url += guid;
				url += service.getUrlParameters();

				genericRepository.patch(url, { '': model })
					.then(function (apiResponse) {
						var result = apiResponse.data;
						if (apiResponse.status === 200) {
							if (result.Data)
								deferred.resolve(result.Data);
							else {
								deferred.resolve(result);
							}
						} else {
							deferred.reject(getErrorMessageFromStatus(apiResponse.status, result));
						}
					},
						function (error) {
							deferred.reject(getErrorMessageFromStatus(error.status, error.data));
						});
				return deferred.promise;
			};


			service.apiUrl = {
				base: config.baseUrl,
				api: config.baseUrlApi
			};



			//web menu

			service.setMenuParameters = function (guidWebMenu, parameters, isBuildingDependent) {
				var webMenus = angular.fromJson($localStorage.webMenus) || {};
				if (!parameters)
					return;
				var paramObj = JSON.parse(parameters);
				paramObj.isBuildingDependent = isBuildingDependent;
				webMenus[guidWebMenu] = JSON.stringify(paramObj);
				$localStorage.webMenus = webMenus;
			};

			service.getMenuParameters = function (guidWebMenu) {
				if (!guidWebMenu)
					return {};
				if (guidWebMenu === authService.getUserData().GuidWebMenu)
					return JSON.parse(authService.getUserData().MenuParameters);
				var webMenus = angular.fromJson($localStorage.webMenus);
				if (!webMenus) {
					service.getShortcuts();
					return {};
				}
				var params = webMenus[guidWebMenu];


				if (params === undefined) {
					service.getShortcuts();
					return {};
				}

				return JSON.parse(params);
			};
			var userMenus = [];

			service.getShortcuts = function() {
				var defer = $q.defer();

				service.GetPaginated(service.apiData.userXWebProfile.url,
					0,
					100,
					{ Index_position: "asc" },
					{ LoggedInUser: true }).then(
					function(result) {
						while (userMenus.length > 0) userMenus.pop();
						if (result.List.length > 0) {
							result.List.forEach(function(userWebProfile) {

								var children = [];
								if (userWebProfile.WebProfile.WebMenus)
									userWebProfile.WebProfile.WebMenus.forEach(function(webMenu) {
										if (webMenu) {
											var filter = {};
											if (webMenu.Reference) {
												filter = webMenu.Reference.replace(/(\r\n|\n|\r)/gm, "");
											}
											children.push({
												guid: webMenu.Guid,
												text: webMenu.Name,
												state: webMenu.Context,
												isBuildingDependent: webMenu.IsBuildingDependent,
												params: filter
											});
											service.setMenuParameters(webMenu.Guid,
												filter,
												webMenu.IsBuildingDependent);
										}
									});
								userMenus.push(
									{
										guid: userWebProfile.WebProfile.Guid,
										text: userWebProfile.WebProfile.Description,
										open: userWebProfile.WebProfile.IsExpanded,
										children: children
									});
							});
						}
						defer.resolve(userMenus);
					},
					function(error) {
						defer.reject(error);
					});

				return defer.promise;
			};

			//end web menu



			return service;
		}
	]);
})();
