(function () {

	var app = angular.module('Plania');

	app.directive('operationalMessage', function () {
		return {
			restrict: 'E',
			scope: {
				isCollapsed: '=isCollapsed',
				reload: '=reload',
				operationalMessages: '=operationalMessages',
				disableActions: '=disableActions',
				parentEntity: '=parentEntity',
				guid: '=guid',
				restrictEdit: '=restrictEdit',
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', '$rootScope', 'TranslationService', '$localStorage', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/operationalMessage/views/operationalMessageDirective.html'
		};
	});

	function controller($scope, $modal, ngTableParams, repository, $rootScope, translationService, $localStorage) {
		$scope.messagesIsLoading = false;
		$scope.search = {};


		$scope.hasEditAccess = $rootScope.hasEditAccess('OperationalMessage');

		var getPropertyFilter = function () {

			var propertyFilter = [];
			var dateFilter;
			switch ($localStorage.selectedOperationalMessageFilterType) {
				case 'active':
					dateFilter = { Operand: 'and', Property: 'DueDate', operator: '>=', Value: moment().startOf('day').toISOString() };
					break;
				case 'history':
					dateFilter = { Operand: 'and', Property: 'DueDate', operator: '<', Value: moment().toISOString() };
					break;
				case 'all':
					break;
			}

			propertyFilter.push({ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid });
			if (dateFilter) {
				propertyFilter.push(dateFilter);
			}

			if ($scope.operationalMessageTable.filter().showBuildingMessages && $scope.parentEntity === 'Estate') {
				propertyFilter.push({ operand: 'or', Property: 'Building.GuidEstate', operator: '=', value: $scope.guid });
				if (dateFilter) {
					propertyFilter.push(dateFilter);
				}
			}
			if ($scope.operationalMessageTable.filter().showEquipmentMessages) {
				if ($scope.parentEntity === 'Estate')
					propertyFilter.push({ operand: 'or', Property: 'Equipment.Building.GuidEstate', operator: '=', value: $scope.guid });
				if ($scope.parentEntity === 'Building')
					propertyFilter.push({ operand: 'or', Property: 'Equipment.GuidBuilding', operator: '=', value: $scope.guid });
				if (dateFilter) {
					propertyFilter.push(dateFilter);
				}
			}
			$localStorage.selectedOperationalMessageShowEquipmentMessages = $scope.operationalMessageTable.filter().showEquipmentMessages;
			$localStorage.selectedOperationalMessageShowBuildingMessages = $scope.operationalMessageTable.filter().showBuildingMessages;

			return propertyFilter;
		};

		$scope.$watch('reload', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			if ($scope.reload && $scope.reload === true) {

				$scope.operationalMessageTable.reload();

				$scope.reload = false;
			}
		});

		var columns = [
			'Message', 'DueDate',
			'StartDate', 'Person.FirstName', 'Person.LastName',
			'Equipment.Id', 'Equipment.Description', 'Equipment.Building.Id', 'Equipment.Building.Description', 'Equipment.Building.Estate.Id', 'Equipment.Building.Estate.Description',
			'Building.Id', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description',
			'Estate.Id', 'Estate.Description'
		];

		$scope.operationalMessageTable = new ngTableParams({
			page: 1,
			count: 5,
			sorting: { 'DueDate': 'desc' },
			filter: {
				showEquipmentMessages: $localStorage.selectedOperationalMessageShowEquipmentMessages || false,
				showBuildingMessages: $localStorage.selectedOperationalMessageShowBuildingMessages || true
			}
		}, {
				total: 0,
				counts: [5, 10, 20],
				filterDelay: 50,
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					var propertyFilter = getPropertyFilter();
					if (propertyFilter.length === 0) return;
					var filter = angular.copy(params.filter());
					filter.PropertyFilter = propertyFilter;
					$scope.messagesIsLoading = true;


					repository.GetPaginated(repository.apiData.operationalMessage.url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns))
						.then(function (result) {
							$scope.operationalMessageTable.settings().total = result.TotalCount;
							$scope.operationalMessageTable.settings().filterDelay = 500;

							if (!$scope.operationalMessages)
								$scope.operationalMessages = [];

							if ($scope.operationalMessages) {
								while ($scope.operationalMessages.length > 0)
									$scope.operationalMessages.pop();
							}

							result.List.forEach(function (message) {
								$scope.operationalMessages.push(message);
							});

							$defer.resolve(result.List);
							$scope.messagesIsLoading = false;
						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		var filterTypes = [
			{ value: 'active', name: translationService.translate('web-operationalMessage-filterType-active', 'Aktive') },
			{ value: 'history', name: translationService.translate('web-operationalMessage-filterType-history', 'Historiske') },
			{ value: 'all', name: translationService.translate('web-operationalMessage-filterType-all', 'Alle') }
		];

		$scope.setFilter = function (filterType) {
			$scope.selectedFilterName = _.find(filterTypes, { value: filterType }).name;
			$localStorage.selectedOperationalMessageFilterType = filterType;
			$scope.operationalMessageTable.reload();
			$scope.showFilterDropdown = false;
		};

		if ($localStorage.selectedOperationalMessageFilterType) {
			$scope.selectedFilterName = _.find(filterTypes, { value: $localStorage.selectedOperationalMessageFilterType }).name;
			$scope.setFilter($localStorage.selectedOperationalMessageFilterType);
		} else {
			$scope.selectedFilterName = _.find(filterTypes, { value: 'active' }).name;
			$scope.setFilter(_.find(filterTypes, { value: 'active' }).value);
		}

		$scope.create = function () {
			$modal.open({
				templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
				controller: 'OperationalMessageModalController',
				resolve: {
					params: function () {
						return {
							parentGuid: $scope.guid,
							parentEntity: $scope.parentEntity
						};
					}
				}
			}).result.then(function () {
				$scope.operationalMessageTable.reload();
			}, function () {
				$scope.operationalMessageTable.reload();
				//cancel
			});
		};

		$scope.update = function (message) {
			if (!$scope.hasEditAccess) return;

			$modal.open({
				templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
				controller: 'OperationalMessageModalController',
				resolve: {
					params: function () {
						return {
							guid: message.Guid
						};
					}
				}
			}).result.then(function () {
				$scope.operationalMessageTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.remove = function (message) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-operationalMessage-message', 'Driftsmeldingen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-operationalMessage-button-confirm', 'Ja, fjern driftsmeldingen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.operationalMessage.url, message.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-operationalMessage-success', 'Driftsmeldingen ble fjernet!'), result, "success");
						$scope.operationalMessageTable.reload();
					}, function (error) {
						swal('Error', error, "error");
					});
			});
		};

		$scope.getEntityIcon = function (entityType) {
			switch (entityType) {
			case 'Equipment':
				return 'zmdi-settings c-bluegray';
			case 'Building':
				return 'zmdi-balance c-brown';
			case 'Estate':
				return 'zmdi-city c-teal';

			}
		};

		$scope.showWarning = function (dueDate) {
			return moment().isAfter(dueDate);
		};

		$scope.$on($rootScope.events.newSelection, function () {
			$scope.operationalMessageTable.reload();
		});
	}
})();
