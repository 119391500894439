(function () {
	angular.module('Plania').controller('CleaningTaskWizardModalController', ['$scope', '$modalInstance', 'modalParams', 'Repository', 'ngTableParams', 'TranslationService', 'ListService', '$localStorage', 'EnumService', controller]);

	function controller($scope, $modalInstance, modalParams, repository, ngTableParams, translationService, listService, $localStorage, enumService) {
		$scope.modalParams = modalParams;
		$scope.model = {};
		$scope.showRecurrenceRule = true;
		$scope.useAreaClassification = false;

		if (modalParams.cleaningTask) {
			$scope.isSelectable = true;
			$scope.useAreaClassification = $localStorage.generalOptions && $localStorage.generalOptions.UseAreaClassification;
			$scope.model.CleaningType = modalParams.cleaningTask.CleaningType;

			$scope.model.RecurrenceRule = angular.copy(modalParams.cleaningTask.RecurrenceRule);
			$scope.model.AttributeValues = angular.copy(modalParams.cleaningTask.AttributeValues);

			$scope.model.AttributeValues.forEach(function (attribute) {
				attribute.TextValue = attribute.NamedSelectionDefaultOption;
			});
		}

		//Wizard Setup
		var updateTabs = function () {
			$scope.showRecurrenceRule = $scope.model.CleaningType === 'PeriodicCleaning' || $scope.model.CleaningType === 'StandardCleaning' || ($scope.model.CleaningType === 'Undefined' && $scope.model.RecurrenceRule && $scope.model.RecurrenceRule.ByDay);
			if ($scope.showRecurrenceRule) {
				$scope.tabs = [
					{ heading: modalParams.cleaningTask ? translationService.translate('web-cleaningtaskwizard-selectarea', 'Velg Rom') : translationService.translate('web-cleaningtaskwizard-selectCleaningTask', 'Velg Renholdsoppgave'), step: 0 },
					{ heading: translationService.translate('web-cleaningtaskwizard-recurrencerule', 'Gjentakelsesregel'), step: 1 },
					{ heading: translationService.translate('web-cleaningtaskwizard-extra', 'Ekstrafelter'), step: 2 },
				];

				$scope.steps = ['one', 'two', 'three'];
			}
			else {
				$scope.tabs = [
					{ heading: modalParams.cleaningTask ? translationService.translate('web-cleaningtaskwizard-selectarea', 'Velg Rom') : translationService.translate('web-cleaningtaskwizard-selectCleaningTask', 'Velg Renholdsoppgave'), step: 0 },
					{ heading: translationService.translate('web-cleaningtaskwizard-extra', 'Ekstrafelter'), step: 1 },
				];

				$scope.steps = ['one', 'two'];
			}
		};
		updateTabs();

		$scope.step = 0;

		$scope.isCurrentStep = function (step) {
			return $scope.step === step;
		};

		$scope.setCurrentStep = function (step) {
			$('.tab-content').css('overflow', 'hidden');
			setTimeout(function () {
				$('.tab-content').css('overflow', 'visible');
			}, 500);
			$scope.step = step;
		};

		$scope.getCurrentStep = function () {
			return $scope.steps[$scope.step];
		};

		$scope.isLastStep = function () {
			return $scope.step === ($scope.steps.length - 1);
		};

		$scope.getNextLabel = function () {
			return $scope.isLastStep() ? translationService.translate('web-button-save', 'Lagre') : translationService.translate('web-button-next', 'Neste');
		};

		$scope.handlePrevious = function () {
			$scope.setCurrentStep($scope.step - ($scope.step === 0 ? 0 : 1));
		};

		$scope.handleNext = function () {
			if ($scope.isLastStep()) {
				$scope.save();
			} else {
				$scope.setCurrentStep($scope.step += 1);
			}
		};
		//End Wizard setup

		//List handling
		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.itemTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: modalParams.sorting,
			filter: modalParams.filter
		},
			{
				total: 0,
				counts: [10, 50, 100],
				paginationMaxBlocks: 8,
				getData: function ($defer, params) {
					var filter = angular.copy(params.filter());
					var columns = null;
					if (modalParams.columns) {
						columns = _.map(modalParams.columns, 'Property');
					}
					columns.push('CleaningType');

					if (modalParams.area) {
						columns.push('Recurrence');
					}

					if (modalParams.cleaningTask) {
						if (!filter.PropertyFilter)
							filter.PropertyFilter = [];
						if (filter.mainFunction !== undefined && filter.mainFunction !== "")
							filter.PropertyFilter.push({ Property: 'MainFunction', Operator: "contains", Value: filter.mainFunction });

						if (filter.partFunction !== undefined && filter.partFunction !== "")
							filter.PropertyFilter.push({ Property: 'PartFunction', Operator: "contains", Value: filter.partFunction });

						if (filter.roomName !== undefined && filter.roomName !== "")
							filter.PropertyFilter.push({ Property: 'RoomName', Operator: "contains", Value: filter.roomName });

						if (filter.roomSpecification !== undefined && filter.roomSpecification !== "")
							filter.PropertyFilter.push({ Property: 'RoomSpecification', Operator: "contains", Value: filter.roomSpecification });
						filter.GuidCleaningTask = modalParams.cleaningTask.Guid;

						if (modalParams.useCaption) {
							columns.push("Building.Description");
							columns.push("Description");
						}
					}

					repository.GetPaginated(modalParams.url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.selectedItems = [];

		$scope.save = function () {
			if (modalParams.useObjects) {
				$modalInstance.close($scope.selectedItems);
			} else {

				if ($scope.model.RecurrenceRule && $scope.model.RecurrenceRule.Unit) {
					enumService.setTimeUnitOnRecurrence($scope.model.RecurrenceRule);
				}

				if ($scope.model.AttributeValues) {
					$scope.model.AttributeValues.forEach(function (attribute) {
						if (attribute.ValueType === 'Date' && attribute.DateValue)
							attribute.DateValue = attribute.DateValue.toISOString();
					});
				}
				var resultObject;

				if (modalParams.area) {
					resultObject = {
						cleaningTask: $scope.model.cleaningTask,
						recurrenceRule: $scope.model.RecurrenceRule,
						attributeValues: $scope.model.AttributeValues
					};
				} else {
					resultObject = {
						selectedGuids: $scope.selectedItems,
						recurrenceRule: $scope.model.RecurrenceRule,
						attributeValues: $scope.model.AttributeValues
					};
				}
				$modalInstance.close(resultObject);
			}
		};

		$scope.addSelectedItem = function (object) {
			if (modalParams.area) {
				$scope.model.cleaningTask = object;
				$scope.model.CleaningType = object.CleaningType;
				$scope.model.RecurrenceRule = object.RecurrenceRule;
				$scope.model.AttributeValues = object.AttributeValues;
				$scope.model.RecurrenceRule.StartDate = new Date();

				$scope.model.AttributeValues.forEach(function (attribute) {
					attribute.TextValue = attribute.NamedSelectionDefaultOption;
				});

				updateTabs();
				$scope.handleNext();
			} else {
				if (!_.contains($scope.selectedItems, object.Guid))
					$scope.selectedItems.push(object.Guid);
				else {
					for (var i = 0; i < $scope.selectedItems.length; i++) {
						if ($scope.selectedItems[i] === object.Guid) {
							$scope.selectedItems.splice(i, 1);
							return;
						}
					}
				}
			}
		};

		$scope.isItemSelected = function (object) {
			return _.contains($scope.selectedItems, object.Guid);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		//End list handling
	}

})();
