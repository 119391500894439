(function () {
	angular.module('Plania').controller('CreateProjectController', ['$scope', 'Repository', 'RegisteredFieldService', 'TranslationService', 'ngTableParams', 'ListService', controller]);

	function controller($scope, repository, registeredFieldService, translationService, ngTableParams, ListService) {
		$scope.model = {
			isTemplate: false
		};

		/*Create steps*/

		$scope.steps = [			
			{ stepNumber: 1, name: 'startPage', hidden: true, },
			{ stepNumber: 2, name: 'chooseTemplate', hidden: false, title: translationService.translate('web-project-section-title-templates', 'Prosjektmal')},
			{ stepNumber: 3, name: 'basicInfo', hidden: false, title: translationService.translate('web-project-section-title-basicInformation', 'Basisinformasjon') },
			{ stepNumber: 4, name: 'connections', hidden: false, title: translationService.translate('web-project-section-title-connections', 'Tilknytninger') },
			{ stepNumber: 5, name: 'responsibles', hidden: false, title: translationService.translate('web-project-section-title-responsibles', 'Ansvarsfordeling') }
		];

		$scope.currentStep = $scope.steps[0].name;

		$scope.getCurrentStepNumber = function () {
			return _.find($scope.steps, { name: $scope.currentStep }).stepNumber;
		};
	

		$scope.setCurrentStep = function (step) {
			$scope.currentStep = step;
		};

		$scope.isStepDisabled = function (step) {
			if (_.find($scope.steps, { name: $scope.currentStep }).stepNumber <= step.stepNumber) {
				return true;
			}

			return false;
		};
		$scope.isLastStep = function () {
			return $scope.currentStep === $scope.steps[$scope.steps.length - 1].name;
		};

		registeredFieldService.getRegisteredField(repository.commonService.prefix.Project).then(function (result) {
			$scope.fieldRules = result;
		});

		var userData = repository.authService.getUserData();

		if (userData.guidPerson) {
			$scope.model.Person1 = { Caption: userData.personFirstName + ' ' + userData.personLastName };
			$scope.model.GuidManagerPerson = userData.guidPerson;
			$scope.model.Person2 = { Caption: userData.personFirstName + ' ' + userData.personLastName };
			$scope.model.GuidOwnerPerson = userData.guidPerson;
		}

		$scope.activateAutocomplete = true;

		$scope.missingMandatoryProperties = [];

		$scope.hasError = function (field) {
			if ($scope.missingMandatoryProperties.includes(field) && !$scope.model[field])
				return true;
			else if ($scope.missingMandatoryProperties.includes(field) && $scope.model[field]) {
				_.remove($scope.missingMandatoryProperties, function (property) { return property === field; });
				return false;
			}

			return false;
		};

		var basicInformationFields = ['Description', 'StartDate', 'EndDate', 'Text'];
		var connectionsFields = ['GuidEstate', 'GuidBuilding', 'GuidArea', 'GuidCostCenter', 'GuidProjectCategory'];
		var responsiblesField = [];

		var sectionIsValid = function (fields) {
			$scope.missingMandatoryProperties = [];
			fields.forEach(function (field) {
				if (_.find($scope.fieldRules, { DomainFieldName: field, Mandatory: true }) && !$scope.model[field]) {
					$scope.missingMandatoryProperties.push(field);
				}
			});

			if ($scope.missingMandatoryProperties.length > 0) {
				return false;
			} else {
				return true;
			}
		};

		$scope.isEndDateValid = function () {
			if ($scope.model.EndDate && $scope.model.EndDate < $scope.model.StartDate)
				return false;
			return true;
		};

		$scope.handleNextStep = function () {
			switch ($scope.currentStep) {
				case 'startPage':
					if ($scope.creationMethod === 'template') {

						$scope.steps = [							
							{ stepNumber: 1, name: 'startPage', hidden: true, },
							{ stepNumber: 2, name: 'chooseTemplate', hidden: false, title: translationService.translate('web-project-section-title-templates', 'Prosjektmal') },
							{ stepNumber: 3, name: 'basicInfo', hidden: false, title: translationService.translate('web-project-section-title-basicInformation', 'Basisinformasjon') },
							{ stepNumber: 4, name: 'connections', hidden: false, title: translationService.translate('web-project-section-title-connections', 'Tilknytninger') },
							{ stepNumber: 5, name: 'responsibles', hidden: false, title: translationService.translate('web-project-section-title-responsibles', 'Ansvarsfordeling') }
						];

						$scope.currentStep = 'chooseTemplate';
					}

					else {
						$scope.steps = [							
							{ stepNumber: 1, name: 'startPage', hidden: true, },	
							{ stepNumber: 2, name: 'basicInfo', hidden: false, title: translationService.translate('web-project-section-title-basicInformation', 'Basisinformasjon') },
							{ stepNumber: 3, name: 'connections', hidden: false, title: translationService.translate('web-project-section-title-connections', 'Tilknytninger') },
							{ stepNumber: 4, name: 'responsibles', hidden: false, title: translationService.translate('web-project-section-title-responsibles', 'Ansvarsfordeling') }
						];

						$scope.currentStep = 'basicInfo';
					}
						
					break;
				case 'chooseTemplate':
					$scope.currentStep = 'basicInfo';
					break;
				case 'basicInfo':
					if (sectionIsValid(basicInformationFields) && $scope.isEndDateValid()) {
						$scope.currentStep = 'connections';
					}
					break;
				case 'connections':
					if (sectionIsValid(connectionsFields)) {
						$scope.currentStep = 'responsibles';
					}
					break;
				case 'responsibles':
					if (sectionIsValid(responsiblesField)) {
						$scope.create();
					}
					break;
			}
		};

		$scope.handlePreviousStep = function () {
			for (var i = 1; i < $scope.steps.length; i++) {
				var step = $scope.steps[i];
				if (step.name === $scope.currentStep) {
					if ($scope.creationMethod !== 'template' && $scope.currentStep === 'basicInfo')
						$scope.currentStep = 'startPage';
					else
						$scope.currentStep = $scope.steps[i - 1].name;
					return;
				}
			}
		};

		$scope.setCreationMethod = function (choice) {
			$scope.creationMethod = choice;
			$scope.handleNextStep();
		};
		$scope.creationMethod = '';

		$scope.create = function () {
			if (!$scope.hasCreateAccess('Project')) return;
			if ($scope.isSaving) return;
			$scope.isSaving = true;

			if ($.trim($scope.model.Id) === '')
				$scope.model.Id = null;

			repository.createSingleDictionary(repository.apiData.project.url, $scope.model).then(function (response) {
				$scope.isSaving = false;
				$scope.model.Guid = response.Guid;
				$scope.model.Id = response.Id;
				repository.growl('Lagret prosjet', 'success');
				$scope.goBack('project.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
			}, function (error) {
				$scope.isSaving = false;
				swal('Feil ved lagring', error, 'error');
			});
		};

		$scope.onProjectTemplateSelect = function(project) {
			if (!project) {
				$scope.model.GuidProjectTemplate = "";
				return;
			}
			$scope.model.GuidProjectTemplate = project.Guid;
			$scope.handleNextStep();
		};


		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.model.Building = "";
				$scope.model.GuidArea = "";
				$scope.model.Area = "";
			}

			$scope.model.GuidEstate = estate.Guid;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidArea = "";
				$scope.model.Area = "";
			}

			$scope.model.GuidBuilding = building.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== building.GuidEstate) {
				$scope.model.GuidEstate = building.GuidEstate;
				$scope.model.Estate = { Caption: building.Estate.Id + " - " + building.Estate.Description };
			}

			if (building.CostCenter) {
				$scope.model.CostCenter = { Caption: building.CostCenter.Caption };
				$scope.model.GuidCostCenter = building.GuidCostCenter;
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.model.GuidArea = "";
				return;
			}
			$scope.model.GuidArea = area.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "" || $scope.model.GuidBuilding !== area.GuidBuilding) {
				$scope.model.GuidBuilding = area.GuidBuilding;
				$scope.model.Building = { Caption: area.Building.Caption };
			}

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== area.Building.GuidEstate) {
				$scope.model.GuidEstate = area.Building.Estate.Guid;
				$scope.model.Estate = { Caption: area.Building.Estate.Caption };
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "estate":
					return filter;
				case "building":
					if ($scope.model.GuidEstate) {
						var estateFieldRule = _.filter($scope.fieldRules, function (o) { return o.FieldName === "GuidEstate"; });
						if (estateFieldRule.length === 1 && estateFieldRule[0].Hidden)
							return filter;
						filter.PropertyFilter = [{ Property: 'GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "area":
					filter = { GuidBuilding: $scope.model.GuidBuilding };
					if ($scope.model.GuidEstate) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "person":
					return filter;
				case "supplier":
					filter.PropertyFilter = [{ Property: 'IsDeactivated', Operator: '=', Value: 'false' }];
					return filter;
				case "project":
					filter.PropertyFilter = [{ Property: 'IsTemplate', Operator: '=', Value: 'true' }];
					return filter;
			}
			return filter;
		};

		$scope.onProjectCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidProjectCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidProjectCategory = category.Guid;
		};

		$scope.selectProjectTemplate = function (project) {
			if (!project) {
				$scope.model.GuidProjectTemplate = "";
				return;
			}

			if (project.Guid === $scope.model.GuidProjectTemplate) {
				$scope.model.GuidProjectTemplate = null;
				$scope.selectedTemplateCaption = null;
				return;
			}

			$scope.model.GuidProjectTemplate = project.Guid;
			$scope.selectedTemplateCaption = project.Caption;
		};

		$scope.columns = [
			{ Position: 1, Title: translationService.translate('web-project-id', 'Id'), Property: 'Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-project-description', 'Beskrivelse'), Property: 'Description' },
		];

		$scope.getPropertyValue = function (entity, column) {
			return ListService.GetPropertyValue(entity, column);
		};

		// Project template table
		$scope.items = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: { IsTemplate: true }
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				filterDelay: 10,
				getData: function ($defer, params) {
					if (!$scope.hasReadAccess('Project')) return;
					var columns = [];
					$scope.columns.forEach(function (col) {
						columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.project.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							params.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

	}
})();
