(function () {

	angular.module('Plania').controller('WebProfileController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'TranslationService', listController]);

	function listController($scope, repository, $stateParams, ngTableParams, $modal, translationService) {
		$scope.update = $scope.navigation.current.name === 'webProfile.edit';
		$scope.isMenuCollapsed = true;
		$scope.isUserCollapsed = true;
		$scope.model = {};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.WebProfile, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebProfile, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.WebProfile);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebProfile);
			}
		};

		updateAccess();

		$scope.menus = [];
		var getMenus = function () {
			var columns = ['WebMenu.Name', 'WebMenu.Description'];

			repository.GetPaginated(repository.apiData.webProfileWebMenu.url, 0, 1000, { Index_position: 'asc' }, { PropertyFilter: [{ Property: "GuidWebProfile", Operator: "=", Value: $stateParams.guid }] }, null, JSON.stringify(columns))
				.then(function (menuResult) {
					if (menuResult) {
						$scope.menus = menuResult.List;
					}
				},
				function (error) {
					repository.growl(error, 'danger');
				});
		};

		if ($scope.update) {
			repository.getSingle(repository.apiData.webProfile.url, $stateParams.guid).then(
				function (result) {
					$scope.model = result.Data;

					getMenus();
					updateAccess();
				},
				function (error) {
					repository.growl(error, 'danger');
				});

			$scope.userTable = new ngTableParams({
				page: 1,
				count: 25,
				sorting: { 'User.RealName': 'asc' },
				filter: { PropertyFilter: [{ Property: 'GuidWebProfile', operator: '=', value: $stateParams.guid }, { Property: 'User.GuidUserGroup', operator: '=', value: "" }] }
			},
				{
					total: 0,
					filterDelay: 10,
					counts: [],
					getData: function ($defer, params) {
						repository.GetPaginated(repository.apiData.userXWebProfile.url, params.page() - 1, params.count(), params.sorting(), params.filter())
							.then(function (result) {
								$scope.userTable.settings().total = result.TotalCount;
								$scope.userTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		}

		$scope.updateWebProfile = function (destination) {
			var success = function (result) {
				repository.growl($scope.update ? 'Web profil har blitt lagret' : 'Web profil har blitt opprettet', 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (result) {
				repository.growl(result, 'danger');
			};

			//todo hack, should implement updatemultiple on planiadomain in order to not send to many requests to update entities
			var index = 0;
			$scope.menus.forEach(function (menu) {
				menu.IndexPosition = index++;
				repository.updateSingleDictionary(repository.apiData.webProfileWebMenu.url, menu);
			});


			if ($scope.update)
				repository.updateSingleDictionary(repository.apiData.webProfile.url, $scope.model).then(success).catch(error);
			else
				repository.createSingleDictionary(repository.apiData.webProfile.url, $scope.model).then(success).catch(error);
		};

		$scope.addMenu = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Legg til web meny',
							url: repository.apiData.webMenu.url,
							sorting: { 'Name': 'asc'},
							columns: [
								{ Title: translationService.translate('web-webMenu-name'), Property: 'Name', PropertyType: 'string' },
								{ Title: translationService.translate('web-webMenu-description'), Property: 'Description', PropertyType: 'string' }
							],
							useObjects: false
						};
					}
				}
			}).result.then(function (guids) {
				var model = [];
				var index = $scope.menus.length;
				guids.forEach(function (guid) {
					model.push({ GuidWebMenu: guid, GuidWebProfile: $scope.model.Guid, IndexPosition: index++ });
				});

				repository.createMultipleWithGuid(repository.apiData.webProfileWebMenu.url, $scope.model.Guid, JSON.stringify(model)).then(function (result) {
					getMenus();
				});
			}, function () {
			});
		};

		$scope.removeMenu = function (webProfileWebMenu) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-webprofile-webmenu-message', 'Menyen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-webprofile-webmenu-button-confirm', 'Ja, fjern menyen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.webProfileWebMenu.url, webProfileWebMenu.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-webprofile-webmenu-success', 'Menyen ble fjernet!'), result, "success");
						repository.growl('Menyen har blitt fjernet', 'success');
						getMenus();
					});
			});
		};

		$scope.removeUser = function (user) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-webprofile-user-message', 'Brukeren vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-webprofile-user-button-confirm', 'Ja, fjern brukeren!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
					repository.deleteSingle(repository.apiData.userXWebProfile.url, user.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-webprofile-user-success', 'Brukeren ble fjernet!'), result, "success");
						repository.growl('Brukeren har blitt fjernet', 'success');
						$scope.userTable.reload();
					});
			});
		};

		$scope.addUser = function () {
			var alreadySavedGuids = _.map($scope.userTable.data, 'GuidUser');

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						var propertyFilter = [
							{ Property: 'GuidUserGroup', Operator: '=', Value: '' },
							{ Property: 'IsUserGroup', Operator: '=', Value: 1, allowToggle: true, filterValue: 'Brukergruppe', isDisabled: true }];
						return {
							title: 'Velg bruker',
							columns: [
								{ Title: translationService.translate('web-user-username'), Property: 'Username' },
								{ Title: translationService.translate('web-user-realName'), Property: 'RealName' },
							],
							useObjects: false,
							url: repository.apiData.user.url,
							sorting: { Username: 'asc' },
							filter: { PropertyFilter: propertyFilter },
							alreadySavedGuids: alreadySavedGuids
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var toSave = _.difference(selectedEntities, alreadySavedGuids);
				var userXWebProfiles = _.map(toSave, function (entity) {
					return JSON.stringify({ GuidUser: entity, GuidWebProfile: $scope.model.Guid, IndexPosition: 999 });
				});
				repository.createMultiple(repository.apiData.userXWebProfile.url, userXWebProfiles).then(function (result) {
					$scope.userTable.reload();
				}, function (error) {
					repository.growl(error, 'danger');
				});
			});
		};

		$scope.removeWebProfile = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-webprofile-message', 'MenyProfilen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-webprofile-button-confirm', 'Ja, fjern Menyprofilen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.webProfile.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-webprofile-success', 'MenyProfilen ble fjernet!'), result, "success");
						repository.growl(translationService.translate('Web-profile-deleted-message', 'Web profilen har blitt slettet.'), 'success');
						$scope.goBack('webProfile.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
