(function (window, document) {
    'use strict';

    // attach planiaUtils as a property of window
    var planiaUtils = window.planiaUtils || (window.planiaUtils = {});

    function copyProperties(srcObj, destObj) {
        for (var key in destObj) {
            if (destObj.hasOwnProperty(key) && srcObj.hasOwnProperty(key)) {
                destObj[key] = srcObj[key];
            }
        }
    }

    //https://stackoverflow.com/a/7557433
    function isElementInViewport(el) {
	    // Special bonus for those using jQuery
	    if (typeof jQuery === "function" && el instanceof jQuery) {
		    el = el[0];
	    }

	    var rect = el.getBoundingClientRect();

	    return (
		    rect.top >= 0 &&
			    rect.left >= 0 &&
			    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
			    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
	    );
	}

    function randomString(length) {
	    var result = '';
	    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	    var charactersLength = characters.length;
	    for (var i = 0; i < length; i++) {
		    result += characters.charAt(Math.floor(Math.random() * charactersLength));
	    }
	    return result;
    }

    var contextIds = {
	    housing: 'List-Housing',
		housingCustomer: 'List-HousingCustomer',
		housingContract: 'List-HousingContract',
		reinvoicingContract: 'List-ReinvoicingContract',
		userGroup: 'List-UserGroup',
		periodicTaskTemplate: 'List-PeriodicTaskTemplate',
	    projectTemplate: 'List-ProjectTemplate',
	    anonymizationHousingCustomer: 'Anonymization-HousingCustomer'
    };

    // publish external API by extending planiaUtils
    function publishExternalAPI(planiaUtils) {
        angular.extend(planiaUtils, {
            'copyProperties': copyProperties,
			'isElementInViewport': isElementInViewport,
			'randomString': randomString,
			'contextIds': contextIds
        });
    }

    publishExternalAPI(planiaUtils);

})(window, document);
