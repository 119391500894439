(function () {
	angular.module('Plania').controller('BuildingController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', '$localStorage', 'ListService', '$sce', controller]);

	function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, $localStorage, listService, $sce) {
		$scope.isUpdate = $scope.navigation.current.name === 'building.edit';
		$scope.activateAutocomplete = false;
		$scope.isDocumentsCollapsed = true;
		$scope.isPersonCollapsed = true;
		$scope.isDynamicPropertiesCollapsed = false;
		$scope.showImage = false;
		$scope.reloadDocumentTable = false;
		$scope.reloadDeviations = false;
		$scope.reloadOperationalMessages = false;
		$scope.persons = [];
		$scope.barcode = {};
		$scope.model = { Guid: $stateParams.guid };
		$scope.viewMode = $scope.isUpdate ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.Building, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Building, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.Building);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.Building);
			}
		};

		$scope.personColumns = [
			{ Position: 1, Title: translationService.translate('web-person-employeenr'), Property: 'Person.EmployeeNr', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-person-firstname'), Property: 'Person.FirstName', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-person-lastname'), Property: 'Person.LastName', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-person-position'), Property: 'Person.Position', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-resourcegroup-id'), Property: 'Person.ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
			{ Position: 6, Title: translationService.translate('web-person-telephoneWork'), Property: 'Person.TelephoneWork', PropertyType: 'string' },
			{ Position: 7, Title: translationService.translate('web-person-cellphone'), Property: 'Person.Cellphone', PropertyType: 'string' },
			{ Position: 8, Title: translationService.translate('web-person-email'), Property: 'Person.Email', PropertyType: 'string' },
			{ Position: 9, Title: translationService.translate('web-personrole-id'), Property: 'PersonRole.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidPersonRole', AutocompleteService: 'personRole' }
		];

		$scope.workOrderColumns = [
			{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-workorder-equipment'), Property: 'Equipment.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuildingCategory', AutocompleteService: 'buildingCategory' },
			{ Position: 4, Title: translationService.translate('web-workOrder-resourceGroup'), Property: 'ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
			{ Position: 5, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
			{ Position: 6, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' }
		];


		$scope.supplierColumns = [
			{ Position: 1, Title: translationService.translate('web-supplier-id'), Property: 'Supplier.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-supplier-description'), Property: 'Supplier.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-supplier-address1'), Property: 'Supplier.Address1', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-supplier-postalcode'), Property: 'Supplier.PostalCode', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-supplier-postalarea'), Property: 'Supplier.PostalArea', PropertyType: 'string' }
		];

		$scope.contractColumns = [
			'Id',
			'Description',
			'Customer.Id',
			'Customer.Description',
			'StartDate',
			'EndDate',
			'Status'
		];

		$scope.areaColumns = [
			{ Position: 1, Title: translationService.translate('web-area-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-area-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-area-areaCategory'), Property: 'AreaCategory.Description', PropertyType: 'string' }
		];

		updateAccess();

		var getCardTables = function () {

			$scope.personTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'Person.FirstName': 'asc' },
				filter: { PropertyFilter: [{ Property: "GuidBuilding", Operator: "=", Value: $scope.model.Guid }] }
			},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function ($defer, params) {
						$scope.loadingPersons = true;
						var columns = [];
						$scope.personColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						columns.push('Person.Guid');

						repository.GetPaginated(repository.apiData.buildingPerson.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.loadingPersons = false;
								$scope.personTable.settings().total = result.TotalCount;
								$scope.personTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								$scope.loadingPersons = false;
								repository.growl(error, 'danger');
							});
					}
				});

				$scope.createWorkOrder = function () {

					repository.persistedData.setPersistedData('workOrder.create', {
						model: {GuidEstate: $scope.model.GuidEstate, GuidBuilding: $scope.model.GuidBuilding},
						guidEstate: $scope.model.Estate ? $scope.model.Estate.Guid : null,
						estate: $scope.model.Estate ? $scope.model.Estate.Caption : null,
						guidBuilding: $scope.model.Guid,
						building: $scope.model.Caption
					});
					$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
				};

			$scope.supplierTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { 'Supplier.Id': 'asc' },
					filter: { PropertyFilter: [{ Property: "GuidBuilding", Operator: "=", value: $scope.model.Guid }] }
				},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = [];
						$scope.supplierColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						columns.push('Supplier.Guid');

						repository.GetPaginated(repository.apiData.buildingSupplier.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.supplierTable.settings().total = result.TotalCount;
								$scope.supplierTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});

			$scope.contractTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { 'Id': 'desc' },
					filter: { PropertyFilter: [{ Property: "ContractItems.GuidBuilding", Operator: "=", Value: $scope.model.Guid }, { Property: 'ContractItems.IsCurrentVersion', Operator: '=', Value: 'true' }] }
				},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = [];
						$scope.contractColumns.forEach(function (col) {
							columns.push(col);
						});

						repository.GetPaginated(repository.apiData.contract.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.contractTable.settings().total = result.TotalCount;
								$scope.contractTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		};


		var setProperties = function () {
			var building = $scope.model;


			if (building.BuildingCategory) {
				$scope.category = $scope.getEntityCaption(building.BuildingCategory);
			}

			if (building.CostCenter) $scope.costCenter = building.CostCenter.Caption;
			if (building.Estate) $scope.estate = $scope.getEntityCaption(building.Estate);
		};

		if ($scope.isUpdate) {

			var columns = ["BuildingCategory.Description", "CostCenter.Description", "CostCenter.Id", "Estate.Id", "Estate.Description", 'Estate.Guid', 'Region.Id', 'Person.Guid', 'Person.FirstName', 'Person.LastName'];

			repository.getMainModel(repository.apiData.building.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.reload = true;
					$scope.model = response.Data;

					updateAccess();
					$scope.reloadDocumentTable = true;
					$scope.reloadDeviations = true;
					$scope.reloadOperationalMessages = true;
					setProperties();
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});

			getCardTables();

		} else {

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'Estate':
								$scope.estate = prefill.Value.Label;
								$scope.model.GuidEstate = prefill.Value.Guid;
								break;
							case 'BuildingCategory':
								$scope.category = prefill.Value.Label;
								$scope.model.GuidBuildingCategory = prefill.Value.Guid;
								break;
							case 'CostCenter':
								$scope.costCenter = prefill.Value.Label;
								$scope.model.GuidCostCenter = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			if ($stateParams.guidEntityToCopy) {
				repository.getSingle(repository.apiData.building.url, $stateParams.guidEntityToCopy).then(function (result) {
					delete result.Data.Guid;

					$scope.model = result.Data;
					$scope.model.Id += '-N';
					$scope.reload = true;

					setProperties();
				});
			}

			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('building.create');

				if (persistentData) {
					$scope.model.GuidEstate = persistentData.GuidEstate;
					$scope.estate = persistentData.Estate;
				}
			}

			$scope.activateAutocomplete = true;
		}

		$scope.onFileSelect = function (images) {
			var files = [];
			handleFileUpload(images[0]);
			//Read Base64 string and add to file object
			function handleFileUpload(file) {
				var reader = new FileReader();
				reader.onload = function (e) {
					var newFile = {
						ImageSrc: e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length),
						Name: file.name,
						GuidBuilding: $scope.model.Guid
					};
					files.push(newFile);
					onLoadEndHandler();
				};
				reader.readAsDataURL(file);
			}

			function onLoadEndHandler(image) {
				if ($scope.model.GuidBuildingImage) {
					repository.deleteSingle(repository.apiData.image.url, $scope.model.GuidBuildingImage).then(function (result) {
						console.log(result);
					});
				}

				repository.createMultiple(repository.apiData.image.url, files).then(function (result) {
					$scope.model.GuidBuildingImage = result[0].Guid;

				}, function (error) {
					repository.growl(error);
				});
			}
		};

		$scope.updateBuilding = function (destination) {

			var success = function (result) {
				repository.growl($scope.isUpdate ? "Bygg har blitt oppdatert." : 'Bygg har blitt opprettet', 'success');
				if (repository.commonService.getFilterData().selectedSelection.Guid) {
					repository.growl('Bygget har blitt lagt til gjeldende utvalg.', 'success');
				}
				var buildingGuid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, buildingGuid);
				$scope.goBack(destination, { guid: buildingGuid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				if ($scope.barcode.Id) {
					repository.createSingle(repository.apiData.barcode.url, $scope.barcode)
						.then(function (result) {

						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}

				repository.updateSingleDictionary(repository.apiData.building.url, $scope.model).then(success).catch(error);
			} else {
				$scope.model.BuildingXBuildingSelection = [
					{ GuidBuildingSelection: repository.commonService.getFilterData().selectedSelection.Guid }
				];
				repository.createSingleDictionary(repository.apiData.building.url, $scope.model).then(success, error);
			}
		};

		$scope.cancel = function () {
			$scope.goBack('building.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.onBuildingCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidBuildingCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidBuildingCategory = category.Guid;
		};

		$scope.addPerson = function () {

			$modal.open({
				templateUrl: 'app/buildingPerson/views/createBuildingPerson.html',
				controller: 'BuildingPersonController',
				resolve: {
					params: function () {
						return {
							GuidBuilding: $scope.model.Guid,
							selectedEntityGuids: _.map($scope.personTable.data, function (row) {
								return { GuidPerson: row.GuidPerson, GuidBuilding: row.GuidBuilding, GuidPersonRole: row.GuidPersonRole };
							}),
							buildingCaption: $scope.model.Id + ' - ' + $scope.model.Description,
                            isModal: true
						};
					}
				}
			}).result.then(function (selectedPersons) {
				$scope.personTable.reload();
				$scope.isPersonCollapsed = false;
			},
				function () {
					//cancel
					$scope.isPersonCollapsed = false;
				});
		};

		$scope.addSupplier = function () {

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Velg leverandører',
							columns: [
								{ Title: translationService.translate('web-supplier-id'), Property: 'Id' },
								{ Title: translationService.translate('web-supplier-description'), Property: 'Description' }
							],
							useObjects: false,
							url: repository.apiData.supplier.url,
							sorting: { Id: 'asc' },
							filter: {},
							alreadySavedGuids: function () {
								var selectedGuids = [];
								$scope.supplierTable.data.forEach(function (entity) {
									selectedGuids.push(entity.GuidSupplier);
								});
								return selectedGuids;
							}()
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var buildingXSupplier = [];
				selectedEntities.forEach(function (entity) {
					buildingXSupplier.push({ GuidBuilding: $scope.model.Guid, GuidSupplier: entity });
				});
				repository.createMultipleWithGuid(repository.apiData.buildingSupplier.url, $scope.model.Guid, JSON.stringify(buildingXSupplier)).then(function (result) {
					repository.growl('Leverandør(er) har blitt latt til bygg', 'success');
					$scope.supplierTable.reload();
				});
			});
		};

		$scope.getPropertyValue = function (building, column) {
			return listService.GetPropertyValue(building, column);
		};

		$scope.removePerson = function (buildingXPerson) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-building-person-message', "Personen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-building-person-button-confirm', 'Ja, fjern personen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.buildingPerson.url, buildingXPerson.Guid)
					.then(function (result) {
						swal('Suksess!', translationService.translate('web-swal-building-person-success', 'Personen ble fjernet!'), "success");
						repository.growl('Personen har blitt fjernet', 'success');
						$scope.personTable.reload();
					});
			});
		};

		$scope.removeSupplier = function (supplier) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-building-supplier-message', "Leverandøren vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-building-supplier-button-confirm', 'Ja, fjern leverandøren'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.buildingSupplier.url, supplier.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-building-supplier-success', 'Leverandøren ble fjernet!'), result, "success");
						repository.growl('Personen har blitt fjernet', 'success');
						$scope.supplierTable.reload();
					});
			});
		};

		var getAttachedEntityMessage = function () {
			var message = "Bygg har tilknyttede ";
			if ($scope.documents > 0 && $scope.personTable.total() > 0)
				return message + "dokumenter og driftsansvarlig";
			if ($scope.documents > 0)
				return message + "dokumenter";
			if ($scope.personTable.total() > 0)
				return message + "driftsansvarlig";
			return "";
		};

		$scope.removeBuilding = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-building-message', "Bygningen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-building-button-confirm', 'Ja, fjern bygningen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.building.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-building-success', 'Bygningen ble fjernet!'), result, "success");
						repository.growl('Bygningen har blitt fjernet', 'success');
						$scope.goBack('building.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
					swal({
						title: "Kunne ikke slette bygget!",
						text: error.Message + "<br/><br/> Vil du deaktivere bygget?",
						type: "error",
						html: true,
						showCancelButton: true,
						confirmButtonText: "Deaktiver",
						cancelButtonText: "Avbryt",
						closeOnConfirm: true,
						closeOnCancel: true
					},
						function(isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.Id = '#' + $scope.model.Id;
								$scope.model.IsDeactivated = true;
								$scope.updateBuilding('building.list');
							}
						}
					);
				});
			});
		};

		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.GuidPost = null;
				$scope.model.PostalArea = '';
				$scope.model.County = '';
				$scope.model.Municipality = '';
			} else {
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.GuidPost = postalInfo.Guid;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.model.County = postalInfo.County;
				$scope.model.Municipality = postalInfo.MunicipalityName;
				$scope.postalInfo = postalInfo;
			}
		};
		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.trustAsHtml = function (text) {
			return $sce.trustAsHtml(text);
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "person":
					filter.PropertyFilter = [{ Property: 'IsOperationsManager', Operator: "=", Value: true }];
					return filter;
			}
		};
	}

})();
