(function () {

	angular.module('Plania').controller('ConditionController', ['$scope', 'Repository', '$stateParams', '$modalInstance', '$localStorage', 'TranslationService', '$modal', '$rootScope', 'ngTableParams', 'ListService', controller]);

	function controller($scope, repository, $stateParams, $modalInstance, $localStorage, translationService, $modal, $rootScope, ngTableParams, listService) {
		var isModal = $stateParams.isModal;
		$scope.isEdit = $stateParams.guid ? true : false;
		$scope.useNewCorrectiveAction = $localStorage.generalOptions.UseNewCorrectiveAction;
		$scope.model = {};

		$scope.restrictEdit = $scope.isEdit ? !$scope.hasEditAccess('Condition') : !$scope.hasCreateAccess('Condition');

		if (!$scope.isEdit) {
			$scope.steps = [
				{ stepNumber: 1, name: 'chooseConditionType', title: translationService.translate('web-condition-section-title-type', 'Velg type') },
				{ stepNumber: 2, name: 'basicInfo', title: translationService.translate('web-condition-section-title-basicInformation', 'Basisinformasjon') },
			];
			$scope.currentStep = $scope.steps[0].name;
			$scope.getCurrentStepNumber = function () {
				return _.find($scope.steps, { name: $scope.currentStep }).stepNumber;
			};
			$scope.isStepDisabled = function (step) {
				if (_.find($scope.steps, { name: $scope.currentStep }).stepNumber <= step.stepNumber) {
					return true;
				}
				return false;
			};
			$scope.isLastStep = function () {
				return $scope.currentStep === $scope.steps[$scope.steps.length - 1].name;
			};

			$scope.setConditionType = function (conditionType) {
				$scope.model.Type = conditionType;
				$scope.currentStep = 'basicInfo';

				if ($scope.model.Type === 'CurrentConditionInfo')
					$scope.model.ConditionDate = new Date().toISOString();
			};
		}

		$scope.modelIsLoaded = false;
		if ($stateParams.guid) $scope.model.Guid = $stateParams.guid;

		$scope.viewMode = (isModal || !$scope.isEdit) ? false : true;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.active = { Condition: true, CorrectiveAction: false, Document: false };

		$scope.canCloseCorrectiveAction = function (condition, guidWorkOrder) {
			if (isModal) {
				return $stateParams.canCloseCorrectiveAction(condition);
			} else {
				return !$scope.model.EndDate &&
					$scope.model.GuidWorkOrder &&
					$scope.model.GuidWorkOrder === guidWorkOrder &&
					$scope.model.Type === 'CorrectiveAction';
			}
		};

		$scope.closeCorrectiveAction = function (condition) {
			if (isModal) {
				$stateParams.closeCorrectiveAction(condition).then(function (result) {
					if (isModal) {
						$modalInstance.close('ok');
					}
				});
			} else {
				swal({
					title: translationService.translate('web-condition-closeCorrectiveAction-swalTitle', 'Bekreft lukking av tiltak'),
					text: translationService.translate('web-condition-closeCorrectiveAction-swalMessage', 'Er du sikker på at du vil lukke tiltaket?'),
					type: "info",
					showCancelButton: true,
					confirmButtonColor: "#2196f3",
					confirmButtonText: translationService.translate('web-condition-closeCorrectiveAction-swalConfirm', 'Ja, Lukk tiltaket!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					$scope.model.RequestAction = "Close";
					repository.updateSingleDictionary(repository.apiData.condition.url, $scope.model).then(function (result) {
						swal(translationService.translate('web-condition-closeCorrectiveAction-swalSuccess', 'Tiltaket ble lukket!'), result.Id, "success");
						$scope.goBack('condition.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						swal(translationService.translate('web-error', "Feil"), error, "error");
					});
					$scope.model.RequestAction = null;
				});
			}
		};

		$scope.year = {};
		$scope.reloadDocumentTable = false;
		$scope.activateAutocomplete = false;
		$scope.reload = false;
		$scope.isDocumentsCollapsed = true;
		$scope.defaultDocumentCategory = { Guid: repository.commonService.getFilterData().selectedDataOwner.GuidWoXEqDocumentCategory, Name: repository.commonService.getFilterData().selectedDataOwner.WoXEqDocumentCategory };
		$scope.dropdown = {
			ConditionType: { Description: "Ikke valgt", Guid: null },
			Consequence: { Id: "Ikke valgt", Guid: null },
			ConsequenceType: { Id: "Ikke valgt", Guid: null },
			ConditionTypes: [{ Description: "Ikke valgt", Guid: null }],
			Consequences: [{ Id: "Ikke valgt", Guid: null }],
			ConsequenceTypes: [{ Id: "Ikke valgt", Guid: null }],
			EstimateDateYear: null
		};
		$scope.year.range = [];

		$scope.$watch('dropdown.ConditionType.Guid', function (newValue, oldValue) {
			if (newValue === oldValue)
				return;
			if (!newValue) {
				$scope.model.ConditionDate = '';
			}
			if (newValue && !$scope.model.ConditionDate) {
				$scope.model.ConditionDate = moment().toDate();
			}
		});

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "estate":
                    return {};
				case "building":
					if ($scope.model.GuidEstate) {
                        return { PropertyFilter: [{ Property: 'GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }] };
					}
					return {};
				case "area":
					if ($scope.model.GuidBuilding) {
                        return { PropertyFilter: [{ Property: 'GuidBuilding', Operator: '=', Value: $scope.model.GuidBuilding }] };
					} else if ($scope.model.GuidEstate) {
                        return { PropertyFilter: [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }] };
					}
					return {};
				case "equipment":
					if ($scope.model.GuidBuilding) {
                        return { GuidBuilding: $scope.model.GuidBuilding };
					} else if ($scope.model.GuidEstate) {
                        return { PropertyFilter: [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }] };
					}
					return {};
                case "priority":
					return { PropertyFilter: [{ Property: 'EntityType', Operator: '=', Value: 'Condition' }] };
				case "deviation":
					return {};
				default:
                    return {};
			}
		};

		var startYear = $localStorage.generalOptions.YearRangeStart;
		var year = new Date().getFullYear();
		if (startYear === 0) startYear = year - 10;
		var endYear = $localStorage.generalOptions.YearRangeEnd;
		if (endYear === 0) endYear = year + 10;

		for (var i = startYear; i <= endYear; i++) {
			$scope.year.range.push(i);
		}

		$scope.setCorrectiveActionType = function () {
			$scope.model.Type = 'CorrectiveAction';
			$scope.selectTab('CorrectiveAction');
			$scope.activeTabIndex = 1;
		};

		$scope.cancelCorrectiveAction = function() {
			$scope.model.IsCancelled = true;
			$scope.saveAction('condition.edit');
		};

		$scope.selectTab = function (action) {
			for (var key in $scope.active) {
				if ($scope.active.hasOwnProperty(key))
					$scope.active[key] = false;
			}
			$scope.active[action] = true;
		};

		var getDropdownData = function () {
			var conditionTypeColumns = ['Description'];
			repository.GetPaginated(repository.apiData.conditionType.url, 0, 20, { 'Description': 'asc' }, {}, null, JSON.stringify(conditionTypeColumns))
				.then(function (result) {
					$scope.dropdown.ConditionTypes = [{ Description: "Ikke valgt", Guid: null }].concat(result.List);

					if ($scope.model.GuidConditionType) {
						var conditionType = _.filter($scope.dropdown.ConditionTypes, { Guid: $scope.model.GuidConditionType });
						if (conditionType && conditionType[0])
							$scope.dropdown.ConditionType = conditionType[0];
					}
				});

			repository.GetPaginated(repository.apiData.consequence.url, 0, 20, { 'Id': 'asc' }, {})
				.then(function (result) {
					$scope.dropdown.Consequences = [{ Id: "Ikke valgt", Guid: null }].concat(result.List);
					if ($scope.model.GuidConsequence) {
						var consequence = _.filter($scope.dropdown.Consequences, { Guid: $scope.model.GuidConsequence });
						if (consequence && consequence[0])
							$scope.dropdown.Consequence = consequence[0];
					}
				});

			var consequenceTypeColumns = ['Id'];
			repository.GetPaginated(repository.apiData.consequenceType.url, 0, 20, { 'Id': 'asc' }, {}, null, JSON.stringify(consequenceTypeColumns))
				.then(function (result) {
					$scope.dropdown.ConsequenceTypes = [{ Id: "Ikke valgt", Guid: null }].concat(result.List);
					if ($scope.model.GuidConsequenceType) {
						var consequenceType = _.filter($scope.dropdown.ConsequenceTypes, { Guid: $scope.model.GuidConsequenceType });
						if (consequenceType && consequenceType[0])
							$scope.dropdown.ConsequenceType = consequenceType[0];
					}
				});
		};

		var refreshDropdownDataBinding = function () {
			if ($scope.dropdown.ConditionTypes && $scope.model.GuidConditionType) {
				var conditionType = _.filter($scope.dropdown.ConditionTypes, { Guid: $scope.model.GuidConditionType });
				if (conditionType && conditionType[0])
					$scope.dropdown.ConditionType = conditionType[0];
			}

			if ($scope.dropdown.Consequences && $scope.model.GuidConsequence) {
				var consequence = _.filter($scope.dropdown.Consequences, { Guid: $scope.model.GuidConsequence });
				if (consequence && consequence[0])
					$scope.dropdown.Consequence = consequence[0];
			}

			if ($scope.dropdown.ConsequenceTypes && $scope.model.GuidConsequenceType) {
				var consequenceType = _.filter($scope.dropdown.ConsequenceTypes, { Guid: $scope.model.GuidConsequenceType });
				if (consequenceType && consequenceType[0])
					$scope.dropdown.ConsequenceType = consequenceType[0];
			}

			if ($scope.model.EstimateDate) {
				$scope.dropdown.EstimateDateYear = new Date($scope.model.EstimateDate).getFullYear();
			}
		};

		getDropdownData();

		$scope.versionTableColumns = [
			{ Position: 1, Title: translationService.translate('web-condition-versionTable-version', 'Nr'), Property: 'Version', PropertyType: 'number' },
			{ Position: 2, Title: translationService.translate('web-condition-versionTable-conditionType', 'Tilstand'), Property: 'ConditionType.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-condition-versionTable-year', 'Tiltaksår'), Property: 'Year', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-condition-versionTable-estimate', 'Overslag'), Property: 'Estimate', PropertyType: 'double' },
			{ Position: 5, Title: translationService.translate('web-condition-versionTable-confirmed', 'Bekreftet'), Property: 'ConfirmedDate', PropertyType: 'date' },
			{ Position: 6, Title: translationService.translate('web-condition-versionTable-status', 'Status'), Property: 'Status', PropertyType: 'enum.DeviationStatus' },
		];
		
		$scope.isVersionTableLoading = false;

		$scope.confirmVersion = function () {
			if (!$scope.useNewCorrectiveAction) return;

			$modal.open({
				templateUrl: 'app/condition/views/conditionConfirmVersionModal.html',
				controller: 'ConditionConfirmVersionModalController',
				size: 'md',
				resolve: {
					modalParams: function () {
						return {
							guid: $scope.model.Guid,
							model: $scope.model,
							formatFunction: formatModelBeforeSave
						};
					}
				}
			}).result.then(function () {
				$scope.goBack('condition.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
			});
		};

		$scope.goToVersion = function (guid) {
			if (!guid || guid === $scope.model.Guid) return;
			window.open($rootScope.navigation.href('condition.edit', { guid: guid }), "_blank");
		};


		var getVersionTable = function () {
			if ($scope.versionTable || !$scope.useNewCorrectiveAction) return;
			$scope.versionTable = new ngTableParams({
				page: 1,
				count: 5,
				sorting: { 'Version': 'desc' },
				filter: { GetAllVersions: true, PropertyFilter: [{ Property: 'GuidMasterRecord', Operator: '=', Value: $scope.model.GuidMasterRecord }] },
			}, {
				total: 0,
				filterDelay: 10,
				counts: [5, 10, 20],
				getData: function ($defer, params) {
					var columns = [];
					$scope.versionTableColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					columns.push("VersionComment");
					columns.push("UserConfirmedBy.RealName");
					columns.push("IsCurrentVersion");
					columns.push("ConditionDate");

					$scope.isVersionTableLoading = true;
					repository.GetPaginated(repository.apiData.condition.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
						$scope.isVersionTableLoading = false;
						$scope.versionTable.settings().total = result.TotalCount;
						$scope.versionTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) { repository.growl(error, 'danger'); $scope.isVersionTableLoading = false; });
				}
			});
		};

		if ($scope.isEdit) {
			var columns = [
				'Estate.Id', 'Estate.Description',
				'Building.Id', 'Building.Description',
				'Area.Id', 'Area.Description',
				'Equipment.Id', 'Equipment.Description', 'Equipment.ConditionContextDescription',
				'ConditionType.Id', 'ConditionType.Description',
				'Consequence.Id', 'ConsequenceType.Id',
				'ConstructionType.Id', 'Priority.Description',
				'UserCreatedBy.RealName', 'UserUpdatedBy.RealName',
				'WorkOrder1.Id', 'WorkOrder1.Description', 'WorkOrder1.DueDate', 'WorkOrder1.GuidProject', 'WorkOrder1.EndDate', 'WorkOrder1.Status', 'WorkOrder1.GuidPeriodicTask',
				'WorkOrder1.Project.Id', 'WorkOrder1.Project.Description',
				'WorkOrder2.Id', 'WorkOrder2.Description', 'WorkOrder2.DueDate', 'WorkOrder2.GuidProject', 'WorkOrder2.EndDate', 'WorkOrder2.Status', 'WorkOrder2.GuidPeriodicTask',
				'WorkOrder2.Project.Id', 'WorkOrder2.Project.Description',
			];

			repository.getMainModel(repository.apiData.condition.url, $scope.model.Guid, JSON.stringify(columns)).then(function (result) {
				$scope.model = result.Data;
				$scope.fieldRules = result.FieldRules;
				$scope.selectTab($scope.model.Type === 'CorrectiveAction' ? 'CorrectiveAction' : 'Condition');
				$scope.reloadDocumentTable = true;
				$scope.reload = true;

				if ($scope.model.Estate)
					$scope.estate = $scope.getEntityCaption($scope.model.Estate);
				if ($scope.model.Area)
					$scope.area = $scope.getEntityCaption($scope.model.Area);
				if ($scope.model.Building)
					$scope.building = $scope.getEntityCaption($scope.model.Building);
				if ($scope.model.Equipment)
					$scope.equipment = $scope.getEntityCaption($scope.model.Equipment);
				if ($scope.model.ConditionType)
					$scope.conditionType = $scope.getEntityCaption($scope.model.ConditionType);

				refreshDropdownDataBinding();
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				$scope.modelIsLoaded = true;
				if (!$scope.restrictEdit) $scope.restrictEdit = !$scope.model.IsCurrentVersion;
				if (!isModal) {
					if ($scope.model.Type === 'CurrentConditionInfo') {
						$scope.versionTableColumns = _.filter($scope.versionTableColumns, function (col) {
							return col.Property !== "Year" && col.Property !== "Estimate";
						});
					}

					getVersionTable();
				}
			},
			function (error) {
				repository.growl(error, 'danger');
			});
		} else {
			if (!isModal) {
				var selectedBuilding = repository.commonService.getFilterData().selectedBuilding;
				if (selectedBuilding.Guid) {
					$scope.model.GuidBuilding = selectedBuilding.Guid;
					$scope.building = selectedBuilding.Name;

					if (selectedBuilding.Estate) {
						$scope.model.GuidEstate = selectedBuilding.Estate.Guid;
						$scope.estate = $scope.getEntityCaption(selectedBuilding.Estate);
					}
				}
			}

			$scope.activateAutocomplete = true;
			repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix.Condition)
				.then(function (result) {
					$scope.fieldRules = result;
					$scope.reload = true;
				});
			$scope.modelIsLoaded = true;
		}

		$scope.save = function () {
			$modalInstance.close();
		};

		// Any preparations to be done on the model should be done here.
		var formatModelBeforeSave = function (model) {
			if (model.ConditionDate) model.ConditionDate = new Date(model.ConditionDate).toISOString();
			if (model.DeadlineDate) model.DeadlineDate = new Date(model.DeadlineDate).toISOString();
			model.GuidConditionType = $scope.dropdown.ConditionType.Guid;
			model.GuidConsequence = $scope.dropdown.Consequence.Guid;
			model.GuidConsequenceType = $scope.dropdown.ConsequenceType.Guid;

			if (model.GuidConditionType && model.Type === 'Undefined') {
				model.Type = 'Condition';
			}
		};

		$scope.saveAction = function (destination) {
			formatModelBeforeSave($scope.model);

			if ($scope.isEdit) {
				repository.updateSingleDictionary(repository.apiData.condition.url, $scope.model).then(function (result) {
					repository.growl('Tiltak oppdatert', 'success');
					swal.close();
					if (isModal) {
						$modalInstance.close('ok');
					}
					else {
						repository.commonService.setLastRegisterGuid(destination, $scope.model.Guid);
						$scope.goBack(destination, { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
					}
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
			} else {
				repository.createSingleDictionary(repository.apiData.condition.url, $scope.model)
					.then(function (result) {
						repository.growl("Tiltak har blitt lagt til", 'success');

						if (destination === 'exit') {
							$scope.goBack('condition.list', { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
						} else if (destination === 'close') {
							$modalInstance.close('ok');
						} else {
							$scope.model = result;
							if (isModal) {
								$scope.isEdit = true;
								$scope.reloadDocumentTable = true;
							} else {
								$scope.goBack('condition.edit', { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
							}
						}
					}).catch(function (error) {
						repository.growl(error, 'danger');
					});
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-condition-currentConditionInfo-message', 'Tilstandsinformasjon vil bli fjernet'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-condition-currentConditionInfo-button-confirm', 'Ja, fjern tilstandsinformasjon'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.condition.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-condition-currentConditionInfo-success', 'Tilstandsinformasjon ble fjernet!'), result, "success");
					$scope.goBack('condition.list', { menuGuid: $scope.navigation.params.menuGuid });
				});
			});
		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.building = "";
				$scope.model.GuidEquipment = "";
				$scope.equipment = "";
				$scope.model.GuidArea = "";
				$scope.area = "";
			}

			$scope.model.GuidEstate = estate.Guid;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipment = "";
				$scope.equipment = "";
				$scope.model.GuidArea = "";
				$scope.area = "";
			}

			$scope.model.GuidBuilding = building.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = building.GuidEstate;
				$scope.estate = $scope.getEntityCaption(building.Estate);
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.model.GuidArea = "";
				return;
			}

			$scope.model.GuidArea = area.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = area.GuidBuilding;
				$scope.building = $scope.getEntityCaption(area.Building);
			}

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = area.Building.Estate.Guid;
				$scope.estate = $scope.getEntityCaption(area.Building.Estate);
			}
		};

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.model.GuidEquipment = "";
				return;
			}

			$scope.model.GuidEquipment = equipment.Guid;
			$scope.model.Equipment = equipment;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "") {
				$scope.model.GuidEstate = equipment.Building.Estate.Guid;
				$scope.estate = $scope.getEntityCaption(equipment.Building.Estate);
			}

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				$scope.building = $scope.getEntityCaption(equipment.Building);
			}

			if (equipment.GuidArea) {
				$scope.model.GuidArea = equipment.GuidArea;
				$scope.area = $scope.getEntityCaption(equipment.Area);
			}
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.calculateEstimate = function () {
			if (!$scope.useNewCorrectiveAction) return;
			// Math.Round((Quantity * UnitPrice) / 100) * 100   from api.
			$scope.model.Estimate = Math.round(($scope.model.Quantity * $scope.model.UnitPrice) / 100) * 100;
			if (isNaN($scope.model.Estimate))
				$scope.model.Estimate = 0;
		};

		$scope.onEstimateDateYearDropdownChange = function () {
			if ($scope.dropdown.EstimateDateYear)
				$scope.model.EstimateDate = new Date($scope.dropdown.EstimateDateYear.toString()).toISOString();
			else
				$scope.model.EstimateDate = null;
		};

		$scope.onGetValidityRules = function (originalValidityRules) {
			var validityRules = _.clone(originalValidityRules);// work on a copy, not to alter the original rules
			if (validityRules.EntityType === 'Condition' && validityRules.DomainFieldName === "ActionComment")
				validityRules.Mandatory |= $scope.model.Type === 'CorrectiveAction';
			return validityRules;
		};

		$scope.getPropertyValue = function (building, column) {
			return listService.GetPropertyValue(building, column);
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			if (icon)
				htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.originContextMenu = function () {
			var options = [];

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.GuidInspectionWorkOrder && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: $scope.model.GuidInspectionWorkOrder }), translationService.translate('web-condition-originContextMenu-viewInspectionWorkOrder', 'Gå til arbeidsordre')));

			if ($scope.hasReadAccess('Project') && $scope.model.GuidInspectionWorkOrder && $scope.model.WorkOrder1 && $scope.model.WorkOrder1.GuidProject && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('project.edit', { guid: $scope.model.WorkOrder1.GuidProject }), translationService.translate('web-condition-originContextMenu-viewInspectionProject', 'Gå til prosjekt')));

			if ($scope.hasReadAccess('PeriodicTask') && $scope.model.GuidInspectionWorkOrder && $scope.model.WorkOrder1 && $scope.model.WorkOrder1.GuidPeriodicTask && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: $scope.model.WorkOrder1.GuidPeriodicTask }), translationService.translate('web-condition-originContextMenu-viewInspectionPeriodicTask', 'Gå til periodisk rutine')));

			if (options.length === 0) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#">' + translationService.translate('web-condition-originContextMenu-empty', 'Ingen aksjoner tilgjengelig') + '</a>',
					enabled: false
				});
			}

			return options;
		};

		$scope.followUpContextMenu = function () {
			var options = [];

			if ($scope.hasEditAccess('Condition') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.WorkOrder2 && !$scope.model.WorkOrder2.EndDate && $scope.canCloseCorrectiveAction($scope.model, $scope.model.GuidCorrectiveWorkOrder)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-closeCorrectiveActionFromCorrective', 'Lukk tiltak via') + ' ' + $scope.model.WorkOrder2.Caption + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.closeCorrectiveAction();
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && !$scope.model.GuidCorrectiveWorkOrder && $scope.model.GuidInspectionWorkOrder && $scope.model.WorkOrder1 && !$scope.model.WorkOrder1.EndDate && $scope.canCloseCorrectiveAction($scope.model, $scope.model.GuidInspectionWorkOrder)) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-closeCorrectiveActionFromInspection', 'Lukk tiltak via') + ' ' + $scope.model.WorkOrder1.Caption + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.closeCorrectiveAction();
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && $scope.model.IsCurrentVersion && $scope.useNewCorrectiveAction) {
				var text = translationService.translate('web-condition-followUpContextMenu-confirmVersion', 'Bekreft versjon');
				if ($scope.model.Type === 'CurrentConditionInfo')
					test = translationService.translate('web-condition-currentConditionInfo-followUpContextMenu-confirmVersion', 'Bekreft versjon');

				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.confirmVersion();
					}
				});
			}

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.GuidCorrectiveWorkOrder && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: $scope.model.GuidCorrectiveWorkOrder }), translationService.translate('web-condition-followUpContextMenu-viewCorrectiveWorkOrder', 'Gå til arbeidsordre')));

			if ($scope.hasReadAccess('Project') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.WorkOrder2 && $scope.model.WorkOrder2.GuidProject && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('project.edit', { guid: $scope.model.WorkOrder2.GuidProject }), translationService.translate('web-condition-followUpContextMenu-viewCorrectiveProject', 'Gå til prosjekt')));

			if ($scope.hasReadAccess('PeriodicTask') && $scope.model.GuidCorrectiveWorkOrder && $scope.model.WorkOrder2 && $scope.model.WorkOrder2.GuidPeriodicTask && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: $scope.model.WorkOrder2.GuidPeriodicTask }), translationService.translate('web-condition-followUpContextMenu-viewCorrectivePeriodicTask', 'Gå til periodisk rutine')));

			if ($scope.hasEditAccess('Condition') && $scope.model.Status === 'InWorks' && $scope.model.Type !== "CurrentConditionInfo" && $scope.model.GuidCorrectiveWorkOrder && !$scope.model.WorkOrder2.EndDate) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-removeFromWorkOrder', 'Koble fra arbeidsordre') + '</a >',
					click: function () {
						swal({
							title: translationService.translate('web-condition-followUpContextMenu-removeFromWorkOrder-swalTitle', 'Bekreft frakobling av tiltak'),
							text: translationService.translate('web-condition-followUpContextMenu-removeFromWorkOrder-swalMessage', 'Er du sikker på at du vil koble dette tiltaket ifra arbeidsordren?'),
							type: "info",
							showCancelButton: true,
							confirmButtonColor: "#2196f3",
							confirmButtonText: translationService.translate('web-condition-followUpContextMenu-removeFromWorkOrder-swalConfirm', 'Ja, koble fra!'),
							cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
							closeOnConfirm: false,
							showLoaderOnConfirm: true
						}, function () {
							window.onkeydown = null;
							window.onfocus = null;
							$scope.model.GuidCorrectiveWorkOrder = null;
							$scope.saveAction('condition.edit');
						});
					}
				});
			}

			if ($scope.hasCreateAccess('WorkOrder') && $scope.model.Status !== 'Closed' && $scope.model.Type !== "CurrentConditionInfo" && !$scope.model.GuidCorrectiveWorkOrder) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-createWorkOrder', 'Opprett ny arbeidsordre for gjennomføring av tiltak') + '</a >',
					click: function () {
						var workOrder = {
							guidSelections: [$scope.model.Guid],
							fromCondition: true,
							fromDeviation: false,
							defaultDescription: translationService.translate('correctiveAction-new-workOrder-defaultDescription', 'Gjennomføring av tiltak')
						};

						if ($scope.model.GuidEstate) {
							workOrder.guidEstate = $scope.model.GuidEstate;
							workOrder.estate = $scope.model.Estate.Caption;
						}

						if ($scope.model.GuidBuilding) {
							workOrder.guidBuilding = $scope.model.GuidBuilding;
							workOrder.building = $scope.model.Building.Caption;
						}

						if ($scope.model.GuidEquipment) {
							workOrder.guidEquipment = $scope.model.GuidEquipment;
							workOrder.equipment = $scope.model.Equipment.Caption;
						}

						repository.persistedData.setPersistedData('workOrder.create', workOrder);
						$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
					}
				});
			}

			if ($scope.hasReadAccess('WorkOrder') && $scope.model.Status !== 'Closed' && $scope.model.Type !== "CurrentConditionInfo" && $scope.hasEditAccess('Condition') && !$scope.model.GuidCorrectiveWorkOrder) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-addToExistingWo', 'Koble til eksisterende arbeidsordre') + '</a >',
					click: function () {
						$modal.open({
							templateUrl: 'app/common/views/singleSelectModal.html',
							controller: 'SingleSelectModalController',
							size: 'lg',
							resolve: {
								modalParams: function () {
									return {
										title: 'Velg arbeidsordre tiltaket skal kobles til',
										url: repository.apiData.workOrder.url,
										sorting: { 'Id': 'desc' },
										filter: { PropertyFilter: [{ Property: 'EndDate', Operator: '=', Value: null }] },
										columns: [
											{ title: translationService.translate('web-workOrder-Id', 'Ao Nr'), property: 'Id' },
											{ title: translationService.translate('web-workOrder-Description', 'Beskrivelse'), property: 'Description', PropertyType: 'String' },
											{ title: translationService.translate('web-workOrder-Building-Id', 'Bygg Id'), property: 'Building.Id' },
											{ title: translationService.translate('web-workOrder-Building-Description', 'Byggbeskrivelse'), property: 'Building.Description', PropertyType: 'String' },
										]
									};
								}
							}
						}).result.then(function (selectedEntity) {
							$scope.model.GuidCorrectiveWorkOrder = selectedEntity.Guid;
							$scope.saveAction('condition.edit');
						});
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && $scope.model.Status === 'New' && $scope.model.Type === 'CorrectiveAction' && !$scope.model.IsCancelled) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-currentConditionInfo-followUpContextMenu-cancelCorrectiveAction', 'Kanseller') + '</a >',
					click: function () {
						$scope.cancelCorrectiveAction();
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && $scope.model.Type === 'CurrentConditionInfo' && $scope.model.EndDate && $scope.model.IsCurrentVersion) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-currentConditionInfo-followUpContextMenu-reOpenCurrentCondition', 'Reaktiver') + '</a >',
					click: function () {
						$scope.model.EndDate = null;
						$scope.saveAction('condition.edit');
					}
				});
			}

			if ($scope.hasEditAccess('Condition') && $scope.model.Type === 'CurrentConditionInfo' && !$scope.model.EndDate && $scope.model.IsCurrentVersion) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-condition-followUpContextMenu-deactivateCurrentCondition', 'Deaktiver') + '</a >',
					click: function () {
						$scope.model.EndDate = new Date().toISOString();
						$scope.saveAction('condition.edit');
					}
				});
			}


			if (options.length === 0) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#">' + translationService.translate('web-condition-followUpContextMenu-empty', 'Ingen aksjoner tilgjengelig') + '</a>',
					enabled: false
				});
			}
			return options;
		};
	}
})();
