(function () {
	angular.module('Plania').controller('IntegrationDataController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'authService', '$window', 'config', controller]);

	function controller($scope, repository, $stateParams, translationService, authService, $window, config) {

		$scope.model = {};

		if ($stateParams.guid) {
			repository.getSingle(repository.apiData.integrationData.url, $stateParams.guid).then(function (response) {
				$scope.model = response.Data;
				if ($scope.model && $scope.model.TextData) {
					$scope.textDataJsonParsed = JSON.parse($scope.model.TextData);
				}
			},
				function (error) {
					repository.growl(error, 'danger');
				}
			);
		}

		$scope.downloadFile = function() {
			if (!$scope.model || !$scope.model.Guid) {
				repository.growl(translationService.translate('web-integrationdata-download-missingGuid',
						'Kunne ikke laste ned integrasjonsdata'),
					'danger');
				return;
			}
			var dataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
			$window.open(config.baseUrlApi +
				repository.apiData.integrationData.url +
				'downloadFile/' +
				$scope.model.Guid +
				'?dataOwner=' +
				dataOwner +
				'&access_token=' +
				authService.getPlaniaToken().access,
				'_self');
		};
	}
})();
