(function () {
    angular.module('Plania').controller('GeneralOptionsController', ['$scope', '$window', 'Repository', '$localStorage', 'Constants', 'TranslationService', 'ColumnFormatService', '$modal', controller]);

    function controller($scope, $window, repository, $localStorage, constants, translationService, columnFormatService, $modal) {
		$scope.model = { UserAuthentication: 0 };
		$scope.times = {};
		$scope.activateAutocomplete = false;

		repository.getSingleWithNoParameter(repository.apiData.generalOptions.url).then(function (result) {
			$scope.model = result.Data;

			$scope.selectedInspectedEntityLocation = $scope.enumInspectedEntityLocation[$scope.model.InspectedEntityLocation];
			$scope.selectedLogSecurityEventLevel = _.find($scope.enumLogSeverity, { value: $scope.model.LogSecurityEventLevel });
			$scope.selectedLogSecurityDbLevel = _.find($scope.enumLogSeverity, { value: $scope.model.LogSecurityDbLevel });
			$scope.selectedMailServerConnectionType = _.find($scope.enumMailServerConnectionType, { value: $scope.model.MailServerConnectionType });
			$scope.selectedTwoFactorAuthenticationOption = _.find($scope.twoFactorAuthenticationOption, { value: $scope.model.TwoFactorAuthenticationOption });

			if ($scope.model.MapSettings)
				$scope.mapSettings = JSON.parse($scope.model.MapSettings);

			if (!$scope.mapSettings) {
				$scope.mapSettings = { Options: {} };
				setDefaultEntityInfo();
			}

			if (!$scope.mapSettings.entityInfo) {
				setDefaultEntityInfo();
			}

			if ($scope.mapSettings.entityInfo && !$scope.mapSettings.entityInfo.request) {
				$scope.mapSettings.entityInfo.request = {
					entity: 'Request',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'zmdi-email'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8,
				};
			}
			if ($scope.mapSettings.entityInfo && !$scope.mapSettings.entityInfo.workOrder) {
				$scope.mapSettings.entityInfo.workOrder = {
					entity: 'WorkOrder',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'zmdi-case'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8
				};
			}
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		});

		$scope.poEntityTypes = [
			{ text: translationService.translate('web-enum-prefix-article'), value: repository.commonService.prefix.Article },
			{ text: translationService.translate('web-enum-prefix-component'), value: repository.commonService.prefix.Component },
			{ text: translationService.translate('web-enum-prefix-sparePart'), value: repository.commonService.prefix.SparePart },
			{ text: translationService.translate('web-enum-prefix-equipment'), value: repository.commonService.prefix.Equipment }
        ];

		$scope.save = function () {
			delete $scope.BarcodeEquipmentFormatError;
			delete $scope.BarcodeComponentFormatError;
			delete $scope.BarcodeBuildingFormatError;
			delete $scope.BarcodeAreaFormatError;
			$scope.model.MapSettings = JSON.stringify($scope.mapSettings);
			$localStorage.generalOptions = $scope.model;
			repository.updateSingle(repository.apiData.generalOptions.url, $scope.model).then(function (result) {
				repository.growl('Klientprofilen har blitt oppdatert', 'success');
			}, function (error) {
				if (Array.isArray(error)) {
					error.forEach(function (err) {
						if (err === 'BarcodeEquipmentFormat') {
							$scope.BarcodeEquipmentFormatError = translationService.translate(
								'web-generalOptions-error-BarcodeAreaFormat',
								'QR kode format for anlegg er ikke unikt, vennligst gjør den unik eller fjern formattet for å kunne lagre kundeprofilen');
						}
						if (err === 'BarcodeComponentFormat') {
							$scope.BarcodeComponentFormatError = translationService.translate(
								'web-generalOptions-error-BarcodeComponentFormat',
								'QR kode format for komponent er ikke unikt, vennligst gjør den unik eller fjern formattet for å kunne lagre kundeprofilen');
						}
						if (err === 'BarcodeBuildingFormat') {
							$scope.BarcodeBuildingFormatError = translationService.translate(
								'web-generalOptions-error-BarcodeBuildingFormat',
								'QR kode format for bygg er ikke unikt, vennligst gjør den unik eller fjern formattet for å kunne lagre kundeprofilen');
						}
						if (err === 'BarcodeAreaFormat') {
							$scope.BarcodeAreaFormatError = translationService.translate(
								'web-generalOptions-error-BarcodeAreaFormat',
								'QR kode format for areal er ikke unikt, vennligst gjør den unik eller fjern formattet for å kunne lagre kundeprofilen');
						}
					});
					repository.growl(
						'Feil i generering av QR-koder, fiks feilene før du kan lagre endringer på kundeprofilen', 'danger');
				}
				if (typeof (error) === 'string') {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'error');
				}
			});
		};

		//MAP_SETTINGS

		$scope.setMapData = function (item) {
			$scope.mapSettings.TileLayer = item.mapData.TileLayer;
			$scope.mapSettings.TileLayerOptions = item.mapData.TileLayerOptions;
			$scope.mapSettings.CrsCode = item.mapData.CrsCode;
			$scope.mapSettings.Proj4Def = item.mapData.Proj4Def;
			$scope.mapSettings.Options.Origin = item.mapData.Options.Origin;
			$scope.mapSettings.Options.Resolution = item.mapData.Options.Resolution;
			$scope.mapSettings.Options.Resolution = item.mapData.Options.Resolution;
		};

		$scope.generateGisEntities = function () {
            repository.createMultiple(repository.apiData.gisManagement.url + 'gisManagement', {}).then(function (result) {
                swal(translationService.translate('web-swal-generaloptions-generateGisEntities-success', 'Genering av kartpunkt er startet. Dette kan ta litt tid.'), "", "success");
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.updateCleaningEstimate = function () {
			repository.updateSingle(repository.apiData.cleaningStatus.url, { Guid: 'UpdateEstimatedTime' }).then(
				function (result) {
					repository.growl(result, 'success');
				}).catch(function (error) {
					repository.growl(error, 'danger');
				});
		};

		//$scope.getKpiMapData = function () {
		//	var estateKpi = {
		//		Prefix: 'Estate',
		//		PropertyFilter: [],
		//		Properties: [{
		//			Property: 'Guid',
		//			Function: 'count',
		//		}]
		//	};

		//	repository.createSingle(repository.apiData.kpi.url, kpi).then(function (result) {

		//	});
		//};

		$scope.verifyUniqueBarcode = function(typeFormat) {
			var payload = { type: typeFormat, Guid: $scope.model.Guid };

			switch (typeFormat) {
			case repository.commonService.prefix.Building:
				payload.format = $scope.model.BarcodeBuildingFormat ? $scope.model.BarcodeBuildingFormat : null;
				break;
			case repository.commonService.prefix.Equipment:
				payload.format = $scope.model.BarcodeEquipmentFormat ? $scope.model.BarcodeEquipmentFormat : null;
				break;
			case repository.commonService.prefix.Area:
				payload.format = $scope.model.BarcodeAreaFormat ? $scope.model.BarcodeAreaFormat : null;
				break;
			case repository.commonService.prefix.Component:
				payload.format = $scope.model.BarcodeComponentFormat ? $scope.model.BarcodeComponentFormat : null;
				break;

			default:
				console.log("Not Implemented");
			}

			if (payload.format) {
				repository.updateSingle(repository.apiData.generalOptions.url + "VerifyUniqueBarcode/", payload).then(
					function(result) {
						if (result.IsSuccess) {
							repository.growl("Formatet er unikt", "success");
						} else {
							$modal.open({
								templateUrl: 'app/generalOptions/views/showDuplicateQrCodeModal.html',
								controller: function($scope, params) {
									$scope.listOfDuplicates = params.failList;
								},
								resolve: {
									params: function() {
										return {
											failList: result.FailList
										};
									}
								}
							});
						}
					},
					function(error) {
						repository.growl(error, 'danger');
					});
			}
        };

        $scope.setPlaniaWebUrl = function () {
            $scope.model.WebBaseUrl = $window.location.origin + "/";
        };

		$scope.availableColors = ['blue', 'red', 'green', 'orange', 'cyan', 'pink', 'black'];

		var setDefaultEntityInfo = function () {
			$scope.mapSettings.entityInfo = {
				estate: {
					entity: 'Estate',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'zmdi-city'
					},
					iconAnchor: [10, 33],
					popupAnchor: [0, -19],
					weight: 8,
				},
				building: {
					entity: 'Building',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'zmdi-home'
					},
					iconAnchor: [10, 33],
					popupAnchor: [0, -19],
					weight: 8,
				},
				equipment: {
					entity: 'Equipment',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'zmdi-settings'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8,
				},
				request: {
					entity: 'Request',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'zmdi-email'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8,
				},
				workOrder: {
					entity: 'WorkOrder',
					displayType: 'marker',
					marker: {
						shape: 'circle',
						color: 'blue',
						warningColor: 'red',
						icon: 'zmdi-case'
					},
					iconAnchor: [10, 24],
					popupAnchor: [0, -10],
					weight: 8
				}
			};
        };

		$scope.availableMaps = constants.availableMaps;

		$scope.enumInspectedEntityLocation =
			[
                { value: constants.inspectedEntityLocation.anywhere, text: translationService.translate('web-enum-inspectedentity-any','Hvor som helst') },
                { value: constants.inspectedEntityLocation.estate, text: translationService.translate('web-enum-inspectedentity-property','Eiendom') },
                { value: constants.inspectedEntityLocation.building, text: translationService.translate('web-enum-inspectedentity-building','Bygg') }
			];

		$scope.enumLogSeverity = [
            { value: -1, text: translationService.translate('web-enum-logseverity-none','Ingen logging') },
            { value: 0, text: translationService.translate('web-enum-logseverity-info','Info (alle requests, avslått og vellyket)') },
            { value: 1, text: translationService.translate('web-enum-logseverity-warning','Advarsel (alle avslåtte forespørsler)') },
            { value: 2, text: translationService.translate('web-enum-logseverity-error','Feil (forsøk på innlogging etc)') },
		];

		$scope.enumMailServerConnectionType = [
            { value: 0, text: translationService.translate('web-enum-mailserverconnection-standard','Standard') },
            { value: 1, text: translationService.translate('web-enum-mailserverconnection-tls','TLS') },
            { value: 2, text: translationService.translate('web-enum-mailserverconnection-ssl','SSL') }
		];

		$scope.twoFactorAuthenticationOption = [
			{ value: 0, text: translationService.translate('web-enum-twofactorauthenticationoption-disabled', 'Avslått') },
			{ value: 1, text: translationService.translate('web-enum-twofactorauthenticationoption-activeperuser', 'Aktiv per bruker') },
			{ value: 2, text: translationService.translate('web-enum-twofactorauthenticationoption-activeforall', 'Aktiv for alle') }
		];

		$scope.canChangeTwoFactorAuthenticationOption = function() {
			return $scope.model.SmsConfiguration;
		};

		$scope.addNewBarcodeFormatColumn = function (modelProperty, entityName) {
			columnFormatService.chooseColumn($scope.model, modelProperty, entityName);
		};

		$scope.navigateTo = function (url, guid) {
			$scope.navigation.go(url, { guid: guid });
		};
	}
})();
