(function () {

    var app = angular.module('Plania');

    app.directive('imageGallery', function () {
        return {
            restrict: 'E',
            scope: {
                reload: '=reload',
                edit: '=edit', // access to edit
                delete: '=delete', // access to delete
                images: '=images',
                inline: '=inline',
                columnSize: '=columnSize',
                guidBuilding: '=guidBuilding',
                guidComponent: '=guidComponent',
                guidEquipment: '=guidEquipment',
                guidEstate: '=guidEstate',
                guidPeriodicTask: '=guidPeriodicTask',
                guidRequest: '=guidRequest',
                guidWorkOrder: '=guidWorkOrder',
                guidCondition: '=guidCondition',
                guidDeviation: '=guidDeviation',
            },
            controller: ['$scope', '$modal', 'ngTableParams', 'config', 'Repository', '$filter', '$window', 'TranslationService', controller],
            link: function (scope, element, attrs) {
            },
            templateUrl: 'app/common/directives/views/imageGallery.html'
        };
    });

    function controller($scope, $modal, ngTableParams, config, repository, $filter, $window, translationService) {
        $scope.documentsIsLoading = true;

        $scope.imageApiUrl = config.baseUrlApi + repository.apiData.image.url;

        $scope.photoColumnSize = $scope.columnSize ? $scope.columnSize : 3;
        $scope.photoColumn = 'col-md-' + $scope.photoColumnSize;

        $scope.photoOptions = [
            { value: 2, column: 6 },
            { value: 3, column: 4 },
            { value: 4, column: 3 },
            { value: 6, column: 2 }
        ];

        //Change grid
        $scope.photoGrid = function (size) {
            $scope.photoColumn = 'col-md-' + size;
            $scope.photoColumnSize = size;
        };

        $scope.reloadImages = function () {
            repository.getPaginated().then(function () {

            });
        };

        var resolveParams = function () {
            return {
                guidBuilding: $scope.guidBuilding,
                guidEquipment: $scope.guidEquipment,
                guidEstate: $scope.guidEstate,
                guidPeriodicTask: $scope.guidPeriodicTask,
                guidRequest: $scope.guidRequest,
                guidWorkOrder: $scope.guidWorkOrder,
                guidDeviation: $scope.guidDeviation,
                guidCondition: $scope.guidCondition,
                defaultCategory: $scope.defaultCategory
            };
        };

        $scope.addNewImage = function () {
            $modal.open({
                templateUrl: 'app/document/views/createImageModal.html',
                controller: 'CreateDocumentXEntityController',
                size: 'lg',
                resolve: {
                    params: resolveParams
                }
            }).result.then(function (images) {
                if (!images) return;

                images.forEach(function (image) {
                    $scope.images.push(image);
                });
            }, function (msg) {

            });
        };

        $scope.editImage = function (image, $index) {
            $modal.open({
                templateUrl: 'app/document/views/editImageModal.html',
                controller: 'EditImageController',
                size: 'lg',
                resolve: {
                    params: function () {
                        return {
                            image: image,
                            deleteAccess: $scope.delete
                        };
                    }
                }
            }).result.then(function (result) {
                if (result === 'Deleted') {
                    $scope.images.splice($index, 1);
                }
                else {
                    planiaUtils.copyProperties(result, $scope.images[$index]);
                }
            });
        };
    }
})();
