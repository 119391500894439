(function () {

	angular.module('Plania').controller('EditComponentAreaController', ['$scope', 'Repository', 'params', '$modalInstance', controller]);

	function controller($scope, repository, params, $modalInstance) {
		$scope.model = params.componentArea;

		$scope.saveAction = function () {
			if ($scope.model.Quantity === null || $scope.model.Quantity === undefined){
				swal('Valideringsfeil', 'Antall må være et nummer', 'error');
				return;
			}

			repository.updateSingleDictionary(repository.apiData.componentArea.url, $scope.model).then(function (result) {
				repository.growl('Komponent på rom er oppdatert', 'success');
				$modalInstance.close('ok');
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
