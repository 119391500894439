(function () {
	'use strict';

	angular.module('Plania').directive('plExcelImport', [excelImport]);

	function excelImport() {
		return {
			restrict: 'E',
			templateUrl: 'app/common/directives/views/planiaExcelImport.html',
			replace: true,
			controller: ['$scope', 'Repository', function (scope, repository) {
				scope.excel = {
					show: false,
					selectedHeaderIndex: 1,
					selectedDataIndex: 2
				};

				scope.read = function (workbook) {
					scope.excel.sheetNames = workbook.SheetNames;
					scope.excel.sheets = workbook.Sheets;
					scope.excel.show = true;
					scope.$apply();
				};

				scope.stateHeader = "Velg fil";

				var populateData = function (sheet) {
					if (sheet === null || sheet['!ref'] === null) return;

					var range = XLSX.utils.decode_range(sheet["!ref"]);

					var numberOfColumns = range.e.c > range.s.c ? range.e.c : range.s.c;

					scope.excel.headers = [];
					scope.excel.headers[0] = "";
					for (var i = 0; i <= numberOfColumns; i++) {
						scope.excel.headers[i + 1] = XLSX.utils.encode_col(i);
					}

					var numberOfRows = range.e.r > range.s.r ? range.e.r : range.s.r;
					var data = [];
					for (var j = 0; j <= numberOfRows; j++) {
						var row = [];
						row[0] = { v: j + 1, t: "rowNum", headerKey: "rownumber" };
						var encodedRow = XLSX.utils.encode_row(j);
						for (var k = 0; k <= numberOfColumns; k++) {
							var encodedCol = XLSX.utils.encode_col(k);
							var property = encodedCol + encodedRow;
							row[k + 1] = sheet[property] ? sheet[property] : { v: "", t: "s" };
							row[k + 1].headerKey = encodedCol;
						}
						data.push(row);
					}
					scope.excel.table = data;
					scope.stateHeader = "Klargjør fil for opplasting til Plania";
				};

				scope.excel.organizeColumns = function () {
					var headerColumns = scope.excel.table[scope.excel.selectedHeaderIndex - 1];
					headerColumns.forEach(function (header, index) {
						var existing = _.filter(scope.availablePrefixProperties, header.v);
						if (existing[0])
							header.Property = existing[0];
					});

					scope.excel.planiaHeaders = headerColumns.slice(1);
					setAvailablePrefixProperties();

					var planiaData = scope.excel.table.slice(scope.excel.selectedDataIndex - 1)
						.map(function (val) { return val.slice(1); });
					scope.excel.planiaData = planiaData;
					scope.stateHeader = "Verifiser kolonne mapping og valider";
					scope.excel.validation = undefined;
				};

				scope.excel.validate = function () {

					var planiaMapping = {};
					scope.excel.planiaHeaders.forEach(function (header) {
						if (header.Property)
							planiaMapping[header.headerKey] = header.Property;
					});


					var planiaObjects = [];
					scope.excel.planiaData.forEach(function (row) {
						var planiaObject = {};
						row.forEach(function (column) {
							if (planiaMapping[column.headerKey])
								planiaObject[planiaMapping[column.headerKey]] = column.v;
						});
						planiaObjects.push(JSON.stringify(planiaObject));
					});

					scope.excel.validation = { headers: [] };

					repository.createMultiple(repository.apiData.massUpdate.url,
						planiaObjects,
						'Prefix=' + scope.selectedPrefix + '&validate=true').then(
						function (result) {
							if (result && result.length > 0) {
								scope.excel.validation.result = result;
								result[0].Properties.forEach(function (prop) {
									scope.excel.validation.headers.push(prop.Property);
								});
								scope.excel.validation.numberOfValidRows = 0;
								scope.excel.validation.numberOfInvalidRows = 0;
								result.forEach(function (res) {
									if (res.Success)
										scope.excel.validation.numberOfValidRows++;
									else
										scope.excel.validation.numberOfInvalidRows++;
								});
								scope.stateHeader = "Resultat av validering";
							}
						}).catch(function (error) {

						});


					scope.stateHeader = "valider";
				};

				scope.excel.import = function () {
					var planiaObjects = [];
					scope.excel.validation.result.forEach(function (row) {
						if (row.Success) {
							var planiaObject = {};
							row.Properties.forEach(function (prop) {
								if (prop.Success) {
									planiaObject[prop.Property] = prop.Value;
								}
							});
							planiaObjects.push(JSON.stringify(planiaObject));
						}
					});

					repository.createMultiple(repository.apiData.massUpdate.url,
						planiaObjects,
						'Prefix=' + scope.selectedPrefix + '&validate=false').then(
						function (result) {
							if (result && result.length > 0) {
								scope.excel.validation.result = result;
								result[0].Properties.forEach(function (prop) {
									scope.excel.validation.headers.push(prop.Property);
								});
								scope.excel.validation.numberOfValidRows = 0;
								scope.excel.validation.numberOfInvalidRows = 0;
								result.forEach(function (res) {
									if (res.Success)
										scope.excel.validation.numberOfValidRows++;
									else
										scope.excel.validation.numberOfInvalidRows++;
								});
								scope.stateHeader = "Import resultat";
								scope.excel.validation.imported = true;
							}
						});
				};

				scope.$watch('excel.selectedSheet', function (newValue, oldValue) {
					if (newValue === oldValue)
						return;
					//todo find out why it gets wrong value on second select (correct on first, then it seems like index is moved and wrong item will be sent as newValue)
					scope.excel.selectedSheet = newValue;

					populateData(scope.excel.sheets[newValue]);

				});

				scope.getMappedHeader = function (header) {
					if (!header.Property)
						return header.v;

					return header.Property + " (" + header.v + ")";
				};

				scope.setMapping = function (header, property) {
					header.Property = property;
				};


				var selectedPrefixProperties = [];
				scope.availablePrefixProperties = [];

				var setAvailablePrefixProperties = function () {
					scope.availablePrefixProperties = [];

					selectedPrefixProperties.forEach(function (prop) {
						scope.availablePrefixProperties.push(prop);
					});
				};

				var getWebMenuFilters = function () {
					repository.getSingle(repository.apiData.webMenuFilter.url, scope.selectedPrefix).then(function (response) {
						selectedPrefixProperties = response.Properties;
					});
				};


				scope.prefixes = repository.commonService.prefix;
				scope.selectedPrefix = scope.prefixes.Building;

				scope.$watch('selectedPrefix', function (newValue, oldValue) {
					if (newValue === oldValue)
						return;
					getWebMenuFilters();
				});



				getWebMenuFilters();

			}],
			link: function (scope, element, attrs) {

				//Todo rewrite reader, should not be necessary to extend reader
				var fileInputElement = element.find('#fileInput');
				function handleSelect() {
					var file = fileInputElement[0].files[0];
					if (!file) return;
					var reader = new FileReader();
					var data = null;
					reader.onload = function(e) {
						if (!e) {
							data = reader.content;
						} else {
							data = e.target.result;
						}

						try {
							var workbook = XLS.read(data, { type: 'binary' });
							if (typeof scope.read === "function") {
								scope.read(workbook);
							}
						} catch (error) {
							if (typeof scope.onError === "function") {
								scope.onError(error);
							}
						}

						fileInputElement.val('');
					};
					var exReader = new FileReader();
					//extend FileReader
					if (!FileReader.prototype.readAsBinaryString) {
						FileReader.prototype.readAsBinaryString = function (fileData) {
							var binary = "";
							var pt = this;

							exReader.onload = function (e) {
								var bytes = new Uint8Array(exReader.result);
								var length = bytes.byteLength;
								for (var i = 0; i < length; i++) {
									binary += String.fromCharCode(bytes[i]);
								}
								//pt.result  - readonly so assign binary
								pt.content = binary;
								$(pt).trigger('onload');
							};
							exReader.readAsArrayBuffer(fileData);
						};
					}
					reader.readAsBinaryString(file);
				}
				fileInputElement.on('change', handleSelect);
			}
		};


	}
})();
