(function () {
    angular.module('Plania').directive('planiaMap', [
        '$animate', function ($animate) {
            return {
                restrict: 'E',
                scope: {
                    entity: '=',
                    entityModel: '=',
                    height: '=',
                    hideEdit: '='
                },
                controller: ['$scope', 'Repository', 'leafletData', '$localStorage', '$modal', 'TranslationService', 'Constants', '$rootScope', planiaMapController],
                link: function (scope, element, attrs) {
                },
                templateUrl: 'app/common/directives/views/planiaMapDirective.html'
            };
        }
    ]);

    function planiaMapController($scope, repository, leafletData, $localStorage, $modal, translationService, constants, $rootScope) {
        $scope.showMap = false;
        $scope.mapData = {
            Norge: {
                lat: 63.43,
                lng: 10.40,
                zoom: 3
            },
            defaults: {
                scrollWheelZoom: false,
                zoom: 1
            }
		};
        $scope.rootScope = $rootScope;

        window.plania = { GoogleApiKey: $localStorage.generalOptions.GoogleApiKey };
        var geoSearchProvider = new L.GeoSearch.Provider.Google();
        var entityInfos = {};
		$scope.mapData.markers = {};
		$scope.mapData.paths = {};


        $scope.emptyMarkers = function () {
            return _.isEmpty($scope.mapData.markers);
        };

        $scope.shouldShowMap = function () {
            return (!_.isEmpty($scope.entityModel.GisEntity) || !_.isEmpty($scope.gisEntity) || $scope.showMap) && $rootScope.hasReadAccess('GisEntity');
        };

        $scope.setNewCoordinatesFromInput = function () {
            updateMarker();
            setMapZoom();
            repository.updateSingleDictionary(repository.apiData.gisEntity.url, $scope.gisEntity).then(function () {
                repository.growl('Koordinater oppdatert', 'info');
            });
        };

        $scope.$on('leafletDirectiveMarker.dragend', function (event, target) {
            $scope.gisEntity.Coordinates[0].lat = target.model.lat; //seems to be set to original values when draggable changes
            $scope.gisEntity.Coordinates[0].lng = target.model.lng;
            updateMarker();
            repository.updateSingleDictionary(repository.apiData.gisEntity.url, $scope.gisEntity).then(function () {
                repository.growl('Koordinater oppdatert', 'info');
            });
        });

        $scope.$watch('updateMode', function (newValue, oldValue) {
            if (newValue === oldValue) return;
            updateMarker();
            $scope.mapData.markers[$scope.gisEntity.Guid.replace(/-/g, '')].draggable = newValue;
        });

        var updateMarker = function () {
            var marker = $scope.mapData.markers[$scope.gisEntity.Guid.replace(/-/g, '')];
            if (marker) {
                marker.lat = $scope.gisEntity.Coordinates[0].lat;
                marker.lng = $scope.gisEntity.Coordinates[0].lng;
            }
        };

        $scope.toggleUpdateMode = function () {
            $scope.updateMode = !$scope.updateMode;
        };

        var getMarker = function (guidGisEntity) {
            repository.getSingle(repository.apiData.gisEntity.url, guidGisEntity).then(function (result) {
                $scope.gisEntity = result.Data;
                var entityInfo = entityInfos[$scope.entity];
                if (entityInfo.displayType === 'marker') {
                    var marker = {
                        icon: entityInfo.marker.icon,
                        markerColor: entityInfo.marker.color,
                        shape: entityInfo.marker.shape,
                        prefix: 'zmdi',
                        type: 'extraMarker'
                    };
                    $scope.mapData.markers[$scope.gisEntity.Guid.replace(/-/g, '')] = {
                        icon: marker,
                        weight: entityInfo.weight,
                        lat: $scope.gisEntity.Coordinates[0].lat,
                        lng: $scope.gisEntity.Coordinates[0].lng,
                        focus: true,
                        draggable: $scope.updateMode,
                    };
                    setMapZoom();
				}

				if ($localStorage.generalOptions.DrawPathsOnMap) {
					if ($scope.gisEntity.Coordinates.length > 1) {
						$scope.mapData.paths[$scope.gisEntity.Guid.replace(/-/g, '')] = {
							latlngs: $scope.gisEntity.Coordinates,
							opacity: 0.8,
							weight: 6,
							color: '#2196f3'
						};
					}
				}
            });
        };

        var setMapZoom = function () {
            leafletData.getMap("editMap").then(function (map) {
                var zoom;
                if ($scope.mapData.defaults.tileLayerOptions)
                    zoom = $scope.mapData.defaults.tileLayerOptions.maxZoom - 3;
                if (zoom) {
                    map.setView(new L.latLng($scope.gisEntity.Coordinates[0].lat, $scope.gisEntity.Coordinates[0].lng), zoom);
                } else {
                    map.setView(new L.latLng($scope.gisEntity.Coordinates[0].lat, $scope.gisEntity.Coordinates[0].lng));
                }

                map.invalidateSize();
            });
        };

        $scope.$watch('entityModel', function (newValue, oldValue) {
            if (!newValue || !newValue.GisEntity || !newValue.GisEntity[0]) return;
            getMarker(newValue.GisEntity[0].Guid);

        });

        if ($localStorage.generalOptions.MapSettings) {
            var settings = JSON.parse($localStorage.generalOptions.MapSettings);
            if (!settings.TileLayer && !settings.TileLayerOptions && !settings.GoogleApiKey) {
            	// use default setting if nothing else is specified
                //todo: only override mapdata not settings (entityinfo etc)
            	//settings = constants.availableMaps[0].mapData;
                settings.TileLayer = constants.availableMaps[0].mapData.TileLayer;
                settings.TileLayerOptions = constants.availableMaps[0].mapData.TileLayerOptions;
            }
            var options = {};
            if (settings.Options) {
                if (settings.Options.Origin)
                    options.origin = JSON.parse(settings.Options.Origin);
                if (settings.Options.Resolution)
                    options.resolutions = JSON.parse(settings.Options.Resolution);
            }

            $scope.mapData.defaults = {
                tileLayer: settings.TileLayer,
                tileLayerOptions: settings.TileLayerOptions ? JSON.parse(settings.TileLayerOptions) : null,
            };
            if (settings.CrsCode && settings.Proj4Def && options)
                $scope.mapData.defaults.crs = new L.Proj.CRS(settings.CrsCode, settings.Proj4Def, options);

            entityInfos = settings.entityInfo;
        }

        if ($scope.mapData.defaults) {
            $scope.mapData.defaults.scrollWheelZoom = false;
        } else {
            $scope.mapData.defaults = { scrollWheelZoom: false };
        }

        $scope.addNewMarker = function () {
            var address = "      ";
            if ($scope.entityModel.Address && $scope.entityModel.PostalCode && $scope.entityModel.PostalArea) {
                address = $scope.entityModel.Address +
                    ' ' +
                    $scope.entityModel.PostalCode +
                    ' ' +
                    $scope.entityModel.PostalArea;
            }
            var markerData;

            geoSearchProvider.GetLocations(address, function (data) {
                if (data.length > 0) {
                    var newGisEntity = {
                        Coordinates: [{
                            lat: data[0].Y,
                            lng: data[0].X
                        }]
                    };

                    createGisEntity(newGisEntity);
                } else {
                    var modalInstance = $modal.open({
                        controller: 'CoordinateSelectionController',
                        templateUrl: 'app/common/directives/views/planiaMapCoordinateSelectionModal.html',
                        resolve: {
                            entity: function () {
                                return $scope.entity;
                            }
                        }
                    });

                    modalInstance.result.then(function (response) {
                        if (response.useMapClick) {
                            $scope.showMap = true;
                            $scope.mapClickable = true;
                            $scope.$on('leafletDirectiveMap.click', function (event, args) {
                                if (!$scope.mapClickable) return;

                                var newGisEntity = {
                                    Coordinates: [{
                                        lat: args.leafletEvent.latlng.lat,
                                        lng: args.leafletEvent.latlng.lng
                                    }]
                                };

                                createGisEntity(newGisEntity);
                                $scope.mapClickable = false;
                            });
                        } else if (response.lat && response.lng) {
                            var newGisEntity = {
                                Coordinates: [{
                                    lat: response.lat,
                                    lng: response.lng
                                }]
                            };

                            createGisEntity(newGisEntity);
                        } else if (response.address) {
                            geoSearchProvider.GetLocations(response.address, function (data) {
                                if (data.length > 0) {
                                    var newGisEntity = {
                                        Coordinates: [{
                                            lat: data[0].Y,
                                            lng: data[0].X
                                        }]
                                    };

                                    createGisEntity(newGisEntity);
                                } else {
                                    console.log('No result with given address: ', response.address);
                                }
                            });
                        } else {
                            return;
                        }
                    });
                }
            });
        };

        var createGisEntity = function (newGisEntity) {
            if ($scope.entity === 'estate') newGisEntity.GuidEstate = $scope.entityModel.Guid;
            if ($scope.entity === 'building') newGisEntity.GuidBuilding = $scope.entityModel.Guid;
            if ($scope.entity === 'equipment') newGisEntity.GuidEquipment = $scope.entityModel.Guid;
            if ($scope.entity === 'request') newGisEntity.GuidRequest = $scope.entityModel.Guid;

            repository.createSingleDictionary(repository.apiData.gisEntity.url, newGisEntity).then(function (result) {
                $scope.showMap = true;
                getMarker(result.Guid);
            }, function (error) {
                repository.growl(error, 'danger');
            });
        };

        $scope.deleteGisEntity = function () {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-gisentity-message', "Kartpunktet vil bli permanent fjernet!"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-gisentity-button-confirm', 'Ja, fjern kartpunktet'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

                repository.deleteSingle(repository.apiData.gisEntity.url, $scope.gisEntity.Guid).then(function (result) {
                    repository.growl(result, 'success');
                    $scope.entityModel.GisEntity = [];
                    $scope.gisEntity = {};
                    $scope.showMap = false;
                    $scope.mapData.markers = {};
                    swal(translationService.translate('web-swal-gisentity-success', 'Kartpunktet ble slettet!'), result, "success");
                });
            });
        };
    }

    angular.module('Plania').controller('CoordinateSelectionController', ['$scope', '$modalInstance', 'entity', function ($scope, $modalInstance, entity) {
        $scope.entity = entity;
        $scope.model = {};
        var selectedTab = '';

        $scope.select = function (selected) {
            selectedTab = selected;
        };

        $scope.ok = function () {
            var response = {};

            if (selectedTab === 'MapClick') {
                response.useMapClick = true;
            } else if (selectedTab === 'Coordinates') {
                response.lat = $scope.model.lat;
                response.lng = $scope.model.lng;
            } else if (selectedTab === 'Address') {
                response.address = $scope.model.address;
            }
            $modalInstance.close(response);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('canceled');
        };
    }]);
})();

