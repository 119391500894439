(function () {
	angular.module('Plania').controller('SparePartObjectWithdrawalModalController', ['$scope', 'params','Repository', '$modalInstance', '$localStorage', controller]);

	function controller($scope, params, repository, $modalInstance, $localStorage) {
		$scope.model = {
			GuidSparePart: params.GuidSparePart,
			WithdrawalDate: new Date().toISOString()
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}
			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidEquipment = "";
				$scope.model.Equipment = null;
			}
			$scope.model.GuidBuilding = building.Guid;
		};

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.model.GuidEquipment = "";
				return;
			}
			$scope.model.GuidEquipment = equipment.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				$scope.model.Building = equipment.Building;
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "building":
					return filter;
				case "equipment":
					filter = { GuidBuilding: $scope.model.GuidBuilding};
					return filter;
			}
		};

		$scope.save = function () {
			$modalInstance.close($scope.model);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};
	}
})();
