(function () {
	angular.module('Plania').factory('RegisteredFieldService', ['TranslationService', 'Repository', '$q', regisredFieldService]);

	function regisredFieldService(translationService, repository, $q) {
		var service = {};

		var registeredFields = {};

		service.getRegisteredField = function(prefix, forceApiLoad) {
			var deferred = $q.defer();

			if (registeredFields[prefix] && !forceApiLoad) {
				deferred.resolve(registeredFields[prefix]);
			} else {
				repository.getSingle(repository.apiData.registeredField.url, prefix).then(
					function (result) {
						registeredFields[prefix] = result;
						deferred.resolve(registeredFields[prefix]);
					},
					function(error) {
						deferred.reject('Kunne ikke finne definering av felter for: ' + prefix);
					});
			}

			return deferred.promise;
		};

		service.getFieldRule = function(prefix, column, forceApiLoad) {
			var deferred = $q.defer();

			service.getRegisteredField(prefix, forceApiLoad).then(function(result) {
				var fieldrule = _.filter(result, { DomainFieldName: column })[0];
				if (fieldrule) {
					deferred.resolve(fieldrule);
				} else {
					deferred.reject('Kunne ikke finne fieldrule: ' + prefix + '.' + column);
				}
			});

			return deferred.promise;
		};

		service.getFieldRuleByEntityAndColumn = function (entityAndColumn) {
			var deferred = $q.defer();

			if (!entityAndColumn) return deferred.reject('Ugyldig input');

			var splitByDot = entityAndColumn.split('.');
			if (splitByDot.length !== 2) {
				return deferred.reject('Kunne ikke finne fieldrule: ' + entityAndColumn);
			}
			this.getFieldRule(splitByDot[0], splitByDot[1]).then(function(fieldrule) {
				return deferred.resolve(fieldrule);
			}, function(error) {
				return deferred.reject(error);
			});

			return deferred.promise;
		};

		//todo we're rellying on registeredfield to be populated through the other methods before this is run, we need this to be synch since it's used from code which doesn't handle promises
		service.getLocalRegisteredField = function(prefix, column) {
			if (!registeredFields && registeredFields[prefix])
				return;
			return _.filter(registeredFields[prefix], { DomainFieldName: column })[0];

		};

		return service;
	}
})();
