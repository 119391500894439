(function () {
	angular.module('Plania').controller('ImportController', ['$scope', 'Repository', 'TranslationService', controller]);

	function controller($scope, repository, translationService) {
		$scope.csv = {
			content: null,
			header: true,
			headers: [],
			separator: ';',
			result: null
		};
		$scope.fieldErrors = [];

		$scope.clearResult = function () {
			$scope.csv = {
				content: null,
				header: true,
				headers: [],
				separator: ';',
				result: null
			};
		};

		var headerMapping = [];

		$scope.importData = function () {
			var model = [];
			$scope.csv.result.forEach(function (row) {
				var modelItem = {};
				for (var key in row) {
					if (key === '$$hashKey')
						continue;

					modelItem[$scope.getMappedHeader(key, true)] = row[key];
				}
				model.push(JSON.stringify(modelItem));
			});

			repository.createMultiple(repository.apiData.massUpdate.url, model, 'Prefix=' + $scope.selectedPrefix).then(function (response) {
				$scope.fieldErrors = [];
				if (response && response[0] && response[0].Properties) {
					var failedFields = response[0].Properties.filter(function (prop) { return !prop.Success; });
					if (failedFields) {
						failedFields.forEach(function (field) {
							$scope.fieldErrors.push(field.Property);
						});
					}

					if (response[0].Success)
						repository.growl(translationService.translate('web-import-success', 'Data har blitt importert til Plania'), 'success');
				}

			}, function (error) {
				$scope.fieldErrors = [];
				repository.growl(error, 'danger');
			});
		};
		var selectedPrefixProperties = [];
		$scope.availablePrefixProperties = [];

		var setAvailablePrefixProperties = function () {
			$scope.availablePrefixProperties = [];

			selectedPrefixProperties.forEach(function (prop) {
				var existing = _.filter(headerMapping, { to: prop });
				if (!existing[0]) {
					if ($scope.csv.headers.indexOf(prop) === -1)
						$scope.availablePrefixProperties.push(prop);
					else {
						var mappedHeader = $scope.getMappedHeader(prop);
						if (mappedHeader !== prop)
							$scope.availablePrefixProperties.push(prop);
					}
				}
			});
		};

		$scope.setMapping = function (header, property) {
			var map = _.filter(headerMapping, { from: header });
			if (map[0]) {
				map[0].to = property;
			} else {
				headerMapping.push({ from: header, to: property });
			}
			setAvailablePrefixProperties();
		};

		$scope.getMappingProperty = function (property, item) {
			var map = _.filter(headerMapping, { from: property });
			if (map[0]) {
				return item[map[0].from];
			}

			return item[property];
		};

		$scope.getMappedHeader = function (header, getTrueToMapping) {
			var map = _.filter(headerMapping, { from: header });
			if (map[0]) {
				if (map[0].from !== map[0].to && !getTrueToMapping)
					return map[0].to + " (" + header + ")";
				return map[0].to;
			}
			return header;
		};

		$scope.prefixes = repository.commonService.prefix;
		$scope.selectedPrefix = $scope.prefixes.Building;

		$scope.$watch('csv.headers', function (newValue, oldValue) {
			if (newValue === oldValue)
				return;

			headerMapping = [];
			newValue.forEach(function (header) {
				headerMapping.push({ from: header, to: header });
			});
			setAvailablePrefixProperties();
		}, true);

		$scope.$watch('selectedPrefix', function (newValue, oldValue) {
			if (newValue === oldValue)
				return;
			getWebMenuFilters();
		});

		var getWebMenuFilters = function () {
			repository.getSingle(repository.apiData.webMenuFilter.url, $scope.selectedPrefix).then(function (response) {
				selectedPrefixProperties = response.Properties;
				setAvailablePrefixProperties();
			});
		};

		getWebMenuFilters();

	}
})();
