(function () {
	angular.module('Plania').controller('PurchaseOrderItemModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', 'EnumService', 'ngTableParams', 'ListService', '$localStorage', '$state', '$rootScope', 'SignalR', controller]);

	function controller($scope, $modalInstance, params, repository, translationService, enumService, ngTableParams, ListService, $localStorage, $state, $rootScope, signalR) {
		$scope.isUpdate = !!params.Guid;
		$scope.rootScope = $rootScope;
		$scope.model = {};
		$scope.modelDto = {};
		$scope.activateAutocomplete = false;
		$scope.lockCostCenter = $localStorage.generalOptions.GetPoItemAccountingFromWo;
		$scope.restrictEdit = false;
		$scope.restrictEditMessage = '';

		$scope.isWorkOrderClosedViaProject = false;

		$scope.isFromProjectTemplate = params.isFromProjectTemplate; // Used to hide certain fields

		var originalModel = {};
		$scope.prefix = repository.commonService.prefix.PurchaseOrderItem;

		$scope.getPropertyValue = function (entity, column) {
			return ListService.GetPropertyValue(entity, column);
		};

		if (params.purchaseOrderItem && params.purchaseOrderItem.GuidSupplier && params.purchaseOrderItem.GuidPurchaseOrder) {
			$scope.lockSupplier = true;
		}

		$scope.validCharacters = '^[^<>"%{}|^~`]+$';

		$scope.generalOptions = $localStorage.generalOptions;

		//todo when equipment and sparepart is added on purchaseorderitem for web this should be changed to set SelectedLInkedEntity to value in generaloptions.
		if ($scope.generalOptions.DefaultPoEntityType === repository.commonService.prefix.Component) {
			if ($scope.hasReadAccess('Component'))
				$scope.info = { selectedLinkedEntity: 'Component' };
			else if ($scope.hasReadAccess('Article'))
				$scope.info = { selectedLinkedEntity: 'Article' };
		} else {
			if ($scope.hasReadAccess('Article'))
				$scope.info = { selectedLinkedEntity: 'Article' };
			else if ($scope.hasReadAccess('Component'))
				$scope.info = { selectedLinkedEntity: 'Component' };
		}

        //Wizard Setup
        if ($scope.isUpdate) {
            $scope.tabs = [
                { heading: translationService.translate('web-purchaseOrderItemWizard-purchaseOrderItemProduct-purchaseInfo', 'KonteringsObjekt'), step: 0 }
            ];
        } else {
            $scope.tabs = [
                { heading: translationService.translate('web-purchaseOrderItemWizard-purchaseOrderItemProduct', 'Velg produkt'), step: 0 },
                { heading: translationService.translate('web-purchaseOrderItemWizard-purchaseOrderItem', 'Bestilling'), step: 1 },
                { heading: translationService.translate('web-purchaseOrderItemWizard-accounting', 'Kontering'), step: 2 },
                { heading: translationService.translate('web-purchaseOrderItemWizard-confirmation', 'Bekreftelse'), step: 3 },
            ];
        }
		$scope.units = enumService.unit;

		$scope.getUnitValue = function (value) {
			var unit = _.find($scope.units, function (o) { return o.value === value; });

			if (unit)
				return unit.translatedValue;
			else
				return value;
		};

		$scope.step = 0;

		$scope.isCurrentStep = function (step) {
			return $scope.step === step;
		};

		$scope.setCurrentStep = function (step) {
			if ($scope.isValid(step)) {
				$('.tab-content').css('overflow', 'hidden');
				setTimeout(function () {
					$('.tab-content').css('overflow', 'visible');
				}, 500);
				$scope.step = step;
			}
		};

		$scope.isLastStep = function () {
			return $scope.step === ($scope.tabs.length - 1);
		};

		$scope.getNextLabel = function () {
			return $scope.isLastStep() ? translationService.translate('web-button-confirm', 'Bekreft') : translationService.translate('web-button-next', 'Neste');
		};

		$scope.handlePrevious = function () {
			$scope.setCurrentStep($scope.step - ($scope.step === 0 ? 0 : 1));
		};

		$scope.handleNext = function () {
			if ($scope.isLastStep()) {
				$scope.update(true);
			} else {
				$scope.setCurrentStep($scope.step += 1);
			}
		};

		$scope.isValid = function (step) {
			if ($scope.isUpdate) return true;

			var valid = true;

			switch (step) {
				case 0:
					break;
				case 1:
				case 2:
				case 3:
					if (!$scope.model.GuidComponent && !$scope.model.GuidArticle)
						valid = false;
					break;
			}

			return valid;
        };


		//End Wizard setup
		var accountingColumns = [
			{ Property: 'Department.Id', hidden: true },
			{ Property: 'Department.Description', hidden: true },
			{ Property: 'CostCenter.Id', hidden: true },
			{ Property: 'CostCenter.Description', hidden: true },
			{ Property: 'Account.Id', hidden: true },
			{ Property: 'Account.Description', hidden: true },
			{ Property: 'Accounting0.Id', hidden: true },
			{ Property: 'Accounting0.Description', hidden: true },
			{ Property: 'Accounting1.Id', hidden: true },
			{ Property: 'Accounting1.Description', hidden: true },
			{ Property: 'Accounting2.Id', hidden: true },
			{ Property: 'Accounting2.Description', hidden: true },
			{ Property: 'Accounting3.Id', hidden: true },
			{ Property: 'Accounting3.Description', hidden: true },
			{ Property: 'Accounting4.Id', hidden: true },
			{ Property: 'Accounting4.Description', hidden: true },
			{ Property: 'DataOwner.Dimension1', hidden: true },
			{ Property: 'DataOwner.Dimension2', hidden: true },
			{ Property: 'DataOwner.Dimension3', hidden: true },
			{ Property: 'DataOwner.Dimension4', hidden: true },
			{ Property: 'DataOwner.Dimension5', hidden: true },
			{ Property: 'DataOwner.DepartmentCost', hidden: true },
			{ Property: 'DataOwner.LastDimensionCost', hidden: true }
		];

		$scope.isAccountingMandatory = function (accountingColumn) {
			if (!$scope.modelDto.dataOwner)
				return false;
			if (accountingColumn === 'GuidDepartment')
				return $scope.modelDto.dataOwner.DepartmentCost;
			var lastDimensionCost = $scope.modelDto.dataOwner.LastDimensionCost;
			if (angular.isDefined(lastDimensionCost) && accountingColumn.startsWith('GuidAccounting'))
				return lastDimensionCost >= parseInt(accountingColumn.slice(-1));

			return false;
		};

		$scope.onGetValidityRules = function (originalValidityRules) {
			if (!$scope.isAccountingMandatory(originalValidityRules.DomainFieldName))
				return originalValidityRules;

			var validityRules = _.clone(originalValidityRules);// work on a copy, not to alter the original rules
			validityRules.Mandatory = true;
			return validityRules;
		};

		$scope.articleColumns = accountingColumns.slice(0).concat([
			{ Position: 1, Title: translationService.translate('web-article-id'), Property: 'Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-article-description'), Property: 'Description' },
			{ Position: 3, Title: translationService.translate('web-article-unitPrice'), Property: 'UnitPrice' },
			{ Property: 'Unit', hidden: true }
		]);
		$scope.componentColumns = accountingColumns.slice(0).concat([
			{ Position: 1, Title: translationService.translate('web-component-id'), Property: 'Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-component-description'), Property: 'Description' },
			{ Property: 'Unit', hidden: true }
		]);

		$scope.$watch('info.selectedLinkedEntity', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (newValue !== 'Article') {
				$scope.model.GuidArticle = null;
			}

			if (newValue !== 'Component') {
				$scope.model.GuidComponent = null;
			}

			if (newValue) {
				$scope.items.reload();
			}
		});

		$scope.items = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: {}
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {

					if (!$scope.info || !$scope.info.selectedLinkedEntity) {
						return;
					}
					var filter = $scope.autoCompleteFilter($scope.info.selectedLinkedEntity) || {};
					if (params.filter().searchString)
						filter.searchString = params.filter().searchString;
					$scope.columns = [];
					var url = '';
					var columnProperties = [];

					switch ($scope.info.selectedLinkedEntity) {
						case 'Article':
							url = repository.apiData.article.url;
							$scope.articleColumns.forEach(function (column) {
								if (!column.hidden)
									$scope.columns.push(column);
								columnProperties.push(column.Property);
							});
							break;
						case 'Component':
							url = repository.apiData.component.url;
							$scope.componentColumns.forEach(function (column) {
								if (!column.hidden)
									$scope.columns.push(column);
								columnProperties.push(column.Property);
							});
							break;
					}

					repository.GetPaginated(url, params.page() - 1, params.count(), params.sorting(), filter, null, JSON.stringify(columnProperties)).then(
						function (result) {
							params.total(result.TotalCount);
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		var setAccountingCaption = function (entity) {
			if (entity.Account && $scope.model.GuidAccount === entity.GuidAccount)
				$scope.modelDto.accountCaption = entity.Account.Caption;

			if (entity.CostCenter && $scope.model.GuidCostCenter === entity.GuidCostCenter && !$scope.lockCostCenter)
				$scope.modelDto.costCenterCaption = entity.CostCenter.Caption;

			if (entity.Department && $scope.model.GuidDepartment === entity.GuidDepartment)
				$scope.modelDto.departmentCaption = entity.Department.Caption;

			if (entity.Accounting0 && $scope.model.GuidAccounting0 === entity.GuidAccounting0)
				$scope.modelDto.accounting0Caption = entity.Accounting0.Caption;

			if (entity.Accounting1 && $scope.model.GuidAccounting1 === entity.GuidAccounting1)
				$scope.modelDto.accounting1Caption = entity.Accounting1.Caption;

			if (entity.Accounting2 && $scope.model.GuidAccounting2 === entity.GuidAccounting2)
				$scope.modelDto.accounting2Caption = entity.Accounting2.Caption;

			if (entity.Accounting3 && $scope.model.GuidAccounting3 === entity.GuidAccounting3)
				$scope.modelDto.accounting3Caption = entity.Accounting3.Caption;

			if (entity.Accounting4 && $scope.model.GuidAccounting4 === entity.GuidAccounting4)
				$scope.modelDto.accounting4Caption = entity.Accounting4.Caption;
		};



		$scope.selectEntity = function (entity) {
			if (entity.GuidCostCenter && !$scope.lockCostCenter)
				$scope.model.GuidCostCenter = entity.GuidCostCenter;
			if (!$scope.model.GuidDepartment)
				$scope.model.GuidDepartment = entity.GuidDepartment;
			if (!$scope.model.GuidAccount)
				$scope.model.GuidAccount = entity.GuidAccount;
			if (!$scope.model.GuidAccounting0)
				$scope.model.GuidAccounting0 = entity.GuidAccounting0;
			if (!$scope.model.GuidAccounting1)
				$scope.model.GuidAccounting1 = entity.GuidAccounting1;
			if (!$scope.model.GuidAccounting2)
				$scope.model.GuidAccounting2 = entity.GuidAccounting2;
			if (!$scope.model.GuidAccounting3)
				$scope.model.GuidAccounting3 = entity.GuidAccounting3;
			if (!$scope.model.GuidAccounting4)
				$scope.model.GuidAccounting4 = entity.GuidAccounting4;

			$scope.entity = entity;

			if ($scope.info.selectedLinkedEntity === 'Article') {
				$scope.model.GuidArticle = entity.Guid;
				$scope.model.Id = entity.Id;
				$scope.model.Description = entity.Description;
				$scope.model.UnitPrice = entity.UnitPrice;
				$scope.model.Unit = entity.Unit;
			}

			if ($scope.info.selectedLinkedEntity === 'Component') {
				$scope.model.GuidComponent = entity.Guid;
				$scope.model.Id = entity.Id;
				$scope.model.Description = entity.Description;
				var unit = _.find($scope.units, { translatedValue: entity.Unit });
				$scope.model.Unit = unit ? unit.value : null;

				if (entity.ComponentXSupplier && entity.ComponentXSupplier.length === 1) {
					$scope.model.UnitPrice = entity.ComponentXSupplier[0].Price;
				}
			}

			$scope.modelDto.dataOwner = entity.DataOwner;
			setAccountingCaption(entity);
			//set modeldto on accounting information and dataowner
			$scope.setCurrentStep(1);
		};


		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName.toLowerCase()) {
				case "component":
					if (params.purchaseOrderItem.GuidSupplier) {
						filter.PropertyFilter = [
							{
								Property: 'ComponentXSupplier.GuidSupplier',
								Value: params.purchaseOrderItem.GuidSupplier,
								Operator: '='
							}
						];
					}
					return filter;
				case "accounting0":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 0, Operator: '=' }];
					return filter;
				case "accounting1":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 1, Operator: '=' }];
					return filter;
				case "accounting2":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 2, Operator: '=' }];
					return filter;
				case "accounting3":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 3, Operator: '=' }];
					return filter;
				case "accounting4":
					filter.PropertyFilter = [{ Property: 'DimensionNumber', Value: 4, Operator: '=' }];
					return filter;
				case "equipment":
					if ($scope.model.GuidBuilding)
						filter.PropertyFilter = [{ Property: 'GuidBuilding', Value: $scope.model.GuidBuilding, Operator: '=' }];
					return filter;
				case "supplier":
					filter.PropertyFilter = [
						{ Property: 'IsDeactivated', Operator: '=', Value: 'false' },
						{ Property: 'HasNotRequisition', Operator: '=', Value: 'false' }];
					return filter;
			}
		};

		$scope.$watch('model.OrderedQuantity', function (newValue, oldValue) {
			$scope.model.OrderedAmount = (newValue && $scope.model.UnitPrice) ? (newValue * $scope.model.UnitPrice) : 0;


			if ($scope.model.OrderedAmount >= 0)            {
                if ($scope.model.OrderedAmount > $scope.model.EstimatedAmount) {
					$scope.model.EstimatedAmount = $scope.model.OrderedAmount;
				}
            }
            else
				$scope.model.EstimatedAmount = $scope.model.OrderedAmount;
		}, true);

		$scope.$watch('model.UnitPrice', function (newValue, oldValue) {
			$scope.model.OrderedAmount = (newValue && $scope.model.OrderedQuantity) ? (newValue * $scope.model.OrderedQuantity) : 0;
			if ($scope.model.OrderedAmount >= 0)            {
                if ($scope.model.OrderedAmount > $scope.model.EstimatedAmount) {
					$scope.model.EstimatedAmount = $scope.model.OrderedAmount;
				}
            }
            else
				$scope.model.EstimatedAmount = $scope.model.OrderedAmount;
		}, true);

		var columns = [
			'Building.Id', 'Building.Description',
			'Supplier.Id', 'Supplier.Description',
			'Account.Id', 'Account.Decription',
			'Accounting0.Id', 'Accounting0.Description',
			'Accounting1.Id', 'Accounting1.Description',
			'Accounting2.Id', 'Accounting2.Description',
			'Accounting3.Id', 'Accounting3.Description',
			'Accounting4.Id', 'Accounting4.Description',
			'Article.Id', 'Article.Description',
			'Equipment.Id', 'Equipment.Description',
			'Component.Id', 'Component.Description',
			'SparePart.Id', 'SparePart.Description',
			'CostCenter.Id', 'CostCenter.Description',
			'Department.Id', 'Department.Description',
			'PurchaseOrder.GuidSupplier',
			'PurchaseOrder.Status',
			'PurchaseOrder.Id',
			'PurchaseOrder.Description',
			'PurchaseOrder.OrderingDate',
			'WorkOrder.Id',
			'WorkOrder.Description',
			'WorkOrder.Project.IsCompleted',
			'WorkOrder.Stage',
			'WorkOrder.EndDate',
			'DataOwner.Dimension1',
			'DataOwner.Dimension2', 'DataOwner.Dimension3',
			'DataOwner.Dimension4', 'DataOwner.Dimension5',
			'DataOwner.DepartmentCost', 'DataOwner.LastDimensionCost'
		];

		var getEntity = function () {
			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)
			return repository.getSingle(repository.apiData.purchaseOrderItem.url, ($scope.isUpdate ? params.Guid : "00000000-0000-0000-0000-000000000000"), JSON.stringify(columns));
		};

		//TODO: Change to use normal repository functions
		getEntity().then(
			function (response) {
				// for the moment only use the response to get proper FieldRules, but still use the client entity rather than the returned one, since some of its fields have be prefilled on client side
				// TODO: find a way, either to post client entity (with its prefilled fields) to the API, or to move the whole prefill logic to the API (preferred),
				//       to be able to benefit from the sometimes complex dynamic prefill from the API, which would be difficult to maintain consistently on all clients as the product evolves
				var purchaseOrderItem = $scope.isUpdate ? response.Data : params.purchaseOrderItem;

				$scope.model = purchaseOrderItem;
				$scope.modelDto.dataOwner = response.Data.DataOwner;

				var unit = _.find($scope.units, { translatedValue: $scope.model.Unit });
				if (unit)
					$scope.model.Unit = unit.value;
				setAccountingCaption($scope.model);
				if (!$scope.model.OrderedQuantity)
					$scope.model.OrderedQuantity = 1;
				$scope.fieldRules = response.FieldRules;
				$scope.reload = true;

				if ($scope.isUpdate && $scope.model.Status !== "Proposal") {
					$scope.tabs = [
						{ heading: translationService.translate('web-purchaseOrderItemWizard-purchaseOrderItemProduct-purchaseInfo', 'KonteringsObjekt'), step: 0 },
						{ heading: translationService.translate('web-purchaseOrderItemWizard-purchaseOrderItem-costTabel', 'Kostnader'), step: 1 },
					];
				}

				$scope.isWorkOrderClosedViaProject = $scope.model.Status === 'Proposal' && $scope.model.WorkOrder && $scope.model.WorkOrder.Project && $scope.model.WorkOrder.Project.IsCompleted;
				setTimeout(function () {
					$scope.activateAutocomplete = true;
					// Copy the model in order to check for changes when user tries to navigate away
					originalModel = angular.copy($scope.model);
				}, 250);

				if ($scope.isUpdate && !$scope.restrictEdit) {
					if ($scope.model.WorkOrder && $scope.model.WorkOrder.Stage === 'History') {
						$scope.restrictEdit = true;
						$scope.restrictEditMessage = translationService.translate('web-purchaseOrderItem-restrictEditMessage-woIsHistory', 'Redigering og sletting er ikke tilgjengelig når arbeidsordren som denne konteringsobjektet er koblet til er hisorisk.');
					} else if ($scope.model.WorkOrder && $scope.model.WorkOrder.EndDate) {
						$scope.restrictEdit = true;
						$scope.restrictEditMessage = translationService.translate('web-purchaseOrderItem-restrictEditMessage-woHasEndDate', 'Redigering og sletting er ikke tilgjengelig når arbeidsordren som denne konteringsobjektet er koblet til er fullført.');
					} else if ($scope.model.WorkOrder && $scope.model.WorkOrder.Project && $scope.model.WorkOrder.Stage === 'Project' && $scope.model.WorkOrder.Project.IsCompleted) {
						$scope.restrictEdit = true;
						$scope.restrictEditMessage = translationService.translate('web-purchaseOrderItem-restrictEditMessage-projectIsCompleted', 'Redigering og sletting er ikke tilgjengelig når prosjektet via tilknyttet arbeidsordre er fullført.');
					} else if ($scope.model.IsInvoiced) {
						$scope.restrictEdit = true;
						$scope.restrictEditMessage = translationService.translate('web-purchaseOrderItem-restrictEditMessage-isInvoiced', 'Redigering og sletting er ikke tilgjengelig fordi denne ressursen er fakturert.');
					}
				}

			}, function (error) {
				repository.growl(error, 'danger');
			}
		);

		// properties to parent entities may contain incomplete (ex. when fetched using columns), outdated or unreliable data, that may cause errors when deserialized on server side
		// -> unless explicitly required (seldom), reset them to be sure they are correctly reloaded if needed
		$scope.parentProperties = [
			"Account",
			"Accounting0",
			"Accounting1",
			"Accounting2",
			"Accounting3",
			"Accounting4",
			"Article",
			"Building",
			"Component",
			"CostCenter",
			"DataOwner",
			"Department",
			"Equipment",
			"PeriodicTask",
			"PurchaseOrder",
			"SparePart",
			"Supplier",
			"User",
			"User1",
			"WorkOrder",
		];

		// get a copy of the original entity whose parent entities are reset (to be passed as payload for update function)
		$scope.getPayloadEntity = function () {
			var payloadEntity = angular.copy($scope.model);
			$scope.parentProperties.forEach(function (field) {
				delete payloadEntity[field];
			});
			return payloadEntity;
		};

		// on returning from update, refresh the model with any up-to-date parent property sent by the API
		// NB: the API may not send all properties currently loaded in the model (if the API did not need them, they were not loaded, just the GUID is valid)
		$scope.refreshModel = function (result) {
			// instead of copying every single property to the model, while skipping parent properties that are set in the model but not in the result,
			// it is more convenient to first copy from the model to the result every parent property set in the model but missing in the result, then copy the whole result object back to the model
			$scope.parentProperties.forEach(function (field) {
				if (!result[field] && $scope.model[field])
					result[field] = angular.copy($scope.model[field]);
			});
			$scope.model = result;
		};

		$scope.close = function () {
			$modalInstance.close({ estimatedCost: $scope.model.UnitPrice * $scope.model.OrderedQuantity });
		};

		$scope.update = function (closeOnSave) {
			var success = function (result) {
				$scope.refreshModel(result);
				originalModel = angular.copy($scope.model);
				repository.growl($scope.isUpdate ? translationService.translate('update-purchaseOrderItem-success', 'Bestilling objekt har blitt oppdatert.') : translationService.translate('create-purchaseOrderItem-success', 'Bestilling objekt har blitt opprettet.'), 'success');
				if (closeOnSave)
					$scope.close();
			};

			var error = function (error) {
				if (typeof (error) === "string")
					repository.growl(error, 'danger');
				else
					repository.growl(error.Data.Message, 'danger');
			};
			if ($scope.isUpdate)
				repository.updateSingleDictionary(repository.apiData.purchaseOrderItem.url, $scope.getPayloadEntity()).then(success, error);
			else
				repository.createSingleDictionary(repository.apiData.purchaseOrderItem.url, $scope.model).then(success, error);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.onComponentSelect = function (component) {
			if (component.Guid !== $scope.model.Component.Guid) {
				$scope.model.Id = component.Id;
				$scope.model.Description = component.Description;
				$scope.model.GuidComponent = component.Guid;
			}
		};

		$scope.onArticleSelect = function (article) {
			if (article.Guid !== $scope.model.Article.Guid) {
				$scope.model.Id = article.Id;
				$scope.model.Description = article.Description;
				$scope.model.GuidArticle = article.Guid;
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-purchaseOrderItem-message', "Bestilling objektet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-purchaseOrderItem-button-confirm', 'Ja, fjern bestilling objekt'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.purchaseOrderItem.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-purchaseOrderItem-success', 'Bestilling objekt ble fjernet!'), result, "success");
						$scope.goBack('purchaseOrderItem.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.isFieldEnabled = function (fieldName) {
			if ($scope.restrictEdit)
				return false;
			var isConfirmed = $scope.model.Status && $scope.model.Status !== 'Proposal';

			if ($scope.isWorkOrderClosedViaProject && fieldName !== 'EstimateAmount') {
				return false;
			}

			switch (fieldName) {
				case 'OrderedQuantity':
				case 'UnitPrice':
					return !isConfirmed || $localStorage.generalOptions.CanEditPoItemAfterOrder;
				case 'GuidSupplier':
					if (!$scope.model.PurchaseOrder)
						return !isConfirmed;
					return !isConfirmed && $scope.model.PurchaseOrder && !$scope.model.PurchaseOrder.GuidSupplier;
				case 'BudgetDate':
					return !isConfirmed;
				case 'EstimateAmount':
					return true;
				default:
					return !isConfirmed;
			}
		};

		$scope.enableSaveButton = function () {
			var isConfirmed = $scope.model.Status && $scope.model.Status !== 'Proposal';
			return (!isConfirmed || $localStorage.generalOptions.CanEditPoItemAfterOrder) ||
				(!isConfirmed && $scope.model.PurchaseOrder && !$scope.model.PurchaseOrder.GuidSupplier);
		};

		$scope.unitSetterGetter = function (val) {
			return arguments.length ? $scope.model.Unit = val : $scope.getUnitValue($scope.model.Unit);
		};


		var forceNavigation = false;
		if ($scope.isUpdate) {
			// Prevent modal from closing when there is a change
			$scope.$on('modal.closing', function (event, reason, closed) {
				if (reason === undefined && JSON.stringify(originalModel) !== JSON.stringify($scope.model) && !forceNavigation)
					event.preventDefault();
			});


			$scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
				if (JSON.stringify(originalModel) !== JSON.stringify($scope.model) && !forceNavigation) {
					// Stop navigation, does not stop url
					event.preventDefault();

					swal({
						title: translationService.translate('web-swal-purchaseOrderItem-notsaved', 'Ikke alt er lagret'),
						text: translationService.translate('web-swal-purchaseOrderItem-navigation-message', 'Du vil miste forandringene som er gjort hvis du navigerer vekk'),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-purchaseOrderItem-navigation-button-confirm', 'Ja, naviger vekk'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					}, function (choice) {
						window.onkeydown = null;
						window.onfocus = null;

						if (!choice)
							forceNavigation = false;
						else {
							forceNavigation = true;
							$state.go(toState, toParams);
						}
					});
				}
			});
		}

		if ($scope.isUpdate) {
			$scope.costInfo = new function () {
				var me = this;
				this.apiData = repository.apiData.cost;
				this.jsPrefix = _.camelCase(this.apiData.prefix);

				this.canCreate = function () {
					return !$scope.restrictEdit && $scope.rootScope.hasCreateAccess(this.apiData.prefix) && $scope.model.Status !== "Proposal";
				};

				this.initTable = function () {
					if (me.table)
						return;
					me.table = new ngTableParams({
						page: 1,
						count: 10,
						sorting: { 'UpdatedDate': 'desc' },
						filter: { PropertyFilter: [{ Property: 'GuidPurchaseOrderItem', Operator: '=', Value: params.Guid }] }
					},
						{
							total: 0,
							filterDelay: 10,
							counts: [10, 20, 50],
							getData: function ($defer, params) {
								var columns = [];
								me.listSetup.Columns.forEach(function (col) {
									columns.push(col.Property);
								});
								repository.GetPaginated(me.apiData.url,
									params.page() - 1,
									params.count(),
									params.sorting(),
									params.filter(),
									null,
									JSON.stringify(columns)).then(
										function (result) {
											me.table.settings().total = result.TotalCount;
											me.table.settings().filterDelay = 500;
											$defer.resolve(result.List);
										},
										function (error) {
											repository.growl(error, 'danger');
										});
							}
						});
				};

				this.create = function () {
					if (!me.canCreate())
						return;

					var persistedData = {};
					persistedData.GuidPurchaseOrderItem = $scope.model.Guid;
					repository.persistedData.setPersistedData(me.jsPrefix + '.create', persistedData);
					$scope.$parent.navigation.go(me.jsPrefix + '.create', { showPersistantData: true }, { reload: true });
				};

				this.update = function (item, event) {
					$scope.$root.navigation.go('cost.edit', { guid: item.Guid });
				};
				ListService.getWebListViews(null, this.apiData.prefix, false, null, {}).then(function (result) {
					me.listOptions = result.listOptions;
					me.listSetup = result.selectedListSetup;

					$scope.costInfo.initTable();
				});
			}();
		}

		if ($scope.isUpdate) {
			signalR.on('UpdatedPurchaseOrderItemSums' + params.Guid, function (economyColumns) {
				repository.getSingle(repository.apiData.purchaseOrderItem.url, params.Guid).then(
					function (response) {
						JSON.parse(economyColumns).forEach(function (field) {
							$scope.model[field] = response.Data[field];
						});
					},
					function (error) {
						repository.growl(error, 'danger');
					});
			});

			$scope.$on('$destroy', function () {
				signalR.off('UpdatedPurchaseOrderItemSums' + params.Guid);
			});
		}
	}
})();
