(function () {
	angular.module('Plania').controller('DataOwnerController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService) {
		$scope.isUpdate = $scope.navigation.current.name === 'dataOwner.edit';
		$scope.languages = [];
		$scope.model = {};
		$scope.restrictEdit = false;
		$scope.countersIsCollapsed = true;
		$scope.activateAutocomplete = false;

		$scope.intervalSelectOptions = [
			{ text: 'Dag', value: 0 },
			{ text: 'Uke', value: 1 },
			{ text: 'Måned', value: 2 },
			{ text: 'År', value: 3 },
			{ text: 'Timer', value: 7 },
			{ text: 'Gang', value: 8 }
		];

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = false;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.DataOwner, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.DataOwner, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.DataOwner);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.DataOwner);
			}
		};

		updateAccess();

		var columns = ['DocumentType.Id', 'DocumentType1.Id', 'DocumentCategory.Description', 'DocumentCategory1.Description'];

		var getSingle = function () {
			repository.getSingle(repository.apiData.dataOwner.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					var dataOwner = response.Data;

					$scope.model = dataOwner;

					updateAccess();

					if (dataOwner.DocumentType) {
						$scope.defaultDocumentType = $scope.getEntityCaption(dataOwner.DocumentType);
					}
					if (dataOwner.DocumentCategory) {
						$scope.emailDocumentCategory = $scope.getEntityCaption(dataOwner.DocumentCategory);
					}
					if (dataOwner.DocumentType1) {
						$scope.defaultEmailDocumentType = $scope.getEntityCaption(dataOwner.DocumentType1);
					}
					if (dataOwner.DocumentCategory1) {
						$scope.defaultWoXEqDocumentCategory = $scope.getEntityCaption(dataOwner.DocumentCategory1);
					}

					repository.GetPaginated(repository.apiData.language.url, 0, 100, {}, {}).then(
						function (result) {
							$scope.languages = [];
							result.List.forEach(function (row) {
								$scope.languages.push(row);
							});
							$scope.model.GuidLanguage = $scope.model.GuidLanguage ? $scope.model.GuidLanguage : $scope.languages[0].Guid;
						}
					);
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

				},
				function (error) {
					if (typeof (error) === "string") {
						repository.growl(error, 'danger');
					} else {
						repository.growl(error.Data.Message, 'danger');
					}
				});
		};

		if ($scope.isUpdate) {
			getSingle();
		} else {

			repository.GetPaginated(repository.apiData.language.url, 0, 100, {}, {}).then(
				function (result) {
					result.List.forEach(function (row) {
						$scope.languages.push(row);
					});
					var language = _.filter($scope.languages, { Country: 'Norway' })[0] || $scope.languages[0];
					$scope.model.GuidLanguage = language.Guid;
				}
			);
			$scope.activateAutocomplete = true;

		}

		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.GuidPost = null;
				$scope.model.PostalArea = '';
				$scope.model.County = '';
				$scope.model.Municipality = '';
			} else {
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.GuidPost = postalInfo.Guid;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.model.County = postalInfo.County;
				$scope.model.Municipality = postalInfo.MunicipalityName;
				$scope.postalInfo = postalInfo;
			}
		};

		$scope.updateDataOwner = function (destination) {
			$scope.model.ActivityPeriodDate = $scope.model.ActivityPeriodDate ? new Date($scope.model.ActivityPeriodDate).toISOString() : null;
			$scope.model.EnergyPeriodDate = $scope.model.EnergyPeriodDate ? new Date($scope.model.EnergyPeriodDate).toISOString() : null;
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-dataowner-success', 'Kienten har blitt oppdatert.') : translationService.translate('create-dataowner-success', 'Klienten har blitt registrert.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (result) {
				repository.growl(result, 'danger');
			};

			if ($scope.isUpdate)
				repository.updateSingleDictionary(repository.apiData.dataOwner.url, $scope.model).then(success).catch(error);
			else
				repository.createSingleDictionary(repository.apiData.dataOwner.url, $scope.model).then(success).catch(error);
		};

		$scope.deleteDataOwner = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-dataowner-message', 'Klienten vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-dataowner-button-confirm', 'Ja, fjern klienten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.dataOwner.url, $scope.model.Guid)
					.then(function (result) {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dataowner-success', 'Klienten ble fjernet!'), result, "success");
						repository.growl(translationService.translate('delete-dataowner-success-message', ' Klienten ble fjernet fra systemet'), 'success');
						$scope.goBack('dataOwner.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};
	}
})();
