(function () {
	angular.module('Plania').controller('ChangePasswordModalController', ['$scope', '$modalInstance', 'params', controller]);

	function controller($scope, $modalInstance, params) {
        $scope.viewModel = {};
        $scope.user = params.user;

        $scope.save = function () {
			$modalInstance.close($scope.viewModel.NewPassword);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };
	}
})();
