(function () {
	angular.module('Plania').controller('CreateProjectTemplateController', ['$scope', 'Repository', 'RegisteredFieldService', 'ngTableParams', 'TranslationService', 'ListService', controller]);

	function controller($scope, repository, registeredFieldService, ngTableParams, translationService, ListService) {
		$scope.model = {
			isTemplate: true
		};
		$scope.isSaving = false;

		registeredFieldService.getRegisteredField(repository.commonService.prefix.Project).then(function (result) {
			$scope.fieldRules = result;
		});

		$scope.activateAutocomplete = true;

		$scope.missingMandatoryProperties = [];

		$scope.hasError = function (field) {
			if ($scope.missingMandatoryProperties.includes(field) && !$scope.model[field])
				return true;
			else if ($scope.missingMandatoryProperties.includes(field) && $scope.model[field]) {
				_.remove($scope.missingMandatoryProperties, function (property) { return property === field; });
				return false;
			}

			return false;
		};

		var sectionIsValid = function (fields) {
			$scope.missingMandatoryProperties = [];
			fields.forEach(function (field) {
				if (_.find($scope.fieldRules, { DomainFieldName: field, Mandatory: true }) && !$scope.model[field]) {
					$scope.missingMandatoryProperties.push(field);
				}
			});

			if ($scope.missingMandatoryProperties.length > 0) {
				return false;
			} else {
				return true;
			}
		};

		$scope.update = function (destination) {
			if (!$scope.hasCreateAccess('Project')) return;
			if ($scope.isSaving || !sectionIsValid(['Id', 'Description', 'Text'])) return;
			$scope.isSaving = true;

			var success = function (result) {
				$scope.isSaving = false;
				repository.growl("Prosjektmal har blitt laget", 'success');
				if (destination) {
					repository.commonService.setLastRegisterGuid(destination, $scope.model.Guid);
					$scope.goBack(destination,
						{ guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				$scope.isSaving = false;
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Message, 'danger');
				}
			};

			repository.createSingleDictionary(repository.apiData.project.endpoint.createTemplate, $scope.model).then(success).catch(error);
		};

		$scope.onProjectTemplateSelect = function (project) {
			if (!project) {
				$scope.model.GuidProjectTemplate = "";
				return;
			}
			$scope.model.GuidProjectTemplate = project.Guid;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidArea = "";
				$scope.model.Area = "";
			}

			$scope.model.GuidBuilding = building.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== building.GuidEstate) {
				$scope.model.GuidEstate = building.GuidEstate;
				$scope.model.Estate = { Caption: building.Estate.Id + " - " + building.Estate.Description };
			}

			if (building.CostCenter) {
				$scope.model.CostCenter = { Caption: building.CostCenter.Caption };
				$scope.model.GuidCostCenter = building.GuidCostCenter;
			}
		};

		// START Select template table
		$scope.columns = [
			{ Position: 1, Title: translationService.translate('web-project-id', 'Id'), Property: 'Id', sortable: true },
			{ Position: 2, Title: translationService.translate('web-project-description', 'Beskrivelse'), Property: 'Description' },
		];

		$scope.getPropertyValue = function (entity, column) {
			return ListService.GetPropertyValue(entity, column);
		};

		$scope.selectEntity = function (project) {
			if (!project) {
				$scope.model.GuidProjectTemplate = "";
				return;
			}

			if (project.Guid === $scope.model.GuidProjectTemplate) {
				$scope.model.GuidProjectTemplate = null;
				$scope.selectedTemplateCaption = null;
				return;
			}

			$scope.model.GuidProjectTemplate = project.Guid;
			$scope.selectedTemplateCaption = project.Caption;
		};

		// Project template table
		$scope.items = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: { IsTemplate: true }
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				filterDelay: 10,
				getData: function ($defer, params) {
					if (!$scope.hasReadAccess('Project')) return;

					var columns = [];
					$scope.columns.forEach(function (col) {
						if(col.Property)
							columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.project.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(
						function (result) {
							params.total(result.TotalCount);
							params.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}, function (error) {
							repository.growl(error, 'danger');
						});
				}
			});
		// END Select template table
	}
})();
