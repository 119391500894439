(function () {
	angular.module('Plania').controller('CleaningTaskController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'EnumService', 'PersistentDataService', '$rootScope', controller]);

	function controller($scope, repository, $stateParams, translationService, enumService, persistentDataService, $rootScope) {
		$scope.model = { Guid: $stateParams.guid };
		$scope.user = repository.authService.getUserData();
		$scope.reloadTables = false;
		$scope.isUpdate = $scope.navigation.current.name === 'cleaningTask.edit';

		var hash = window.location.hash;
		if (hash)
			$('#cleaningTaskTabs a[href="' + hash + '"]').tab('show');
		else
			$('#cleaningTaskTabs a[href="#info"]').tab('show');

		$scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
			var hash = window.location.hash;
			if (hash)
				$('#cleaningTaskTabs a[href="' + hash + '"]').tab('show');
			else
				$('#cleaningTaskTabs a[href="#info"]').tab('show');
		});

		var updateAccess = function () {
			var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
			$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.CleaningTask, checkOtherDO);
			$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.CleaningTask, checkOtherDO);
		};

		updateAccess();
		
		repository.getSingle(repository.apiData.cleaningTask.url, $stateParams.guid).then(
			function (response) {
				$scope.reload = true;
				$scope.model = response.Data;
				updateAccess();

				if ($scope.model.CleaningType === 'Undefined')
					handleUndefinedCleaningTask();
			}, function (error) {
				repository.growl(error, 'danger');
			}
		);

		function handleUndefinedCleaningTask() {
			if (!$scope.user.guidPerson) return;
			repository.getSingle(repository.apiData.person.url, $scope.user.guidPerson).then(function (result) {
				var isCleaningLeader = !result.Data.DoesCleaningTasks && $rootScope.hasEditAccess(repository.apiData.cleaningTask.prefix);
				if (!isCleaningLeader) return;

				swal({
					title: translationService.translate('web-swal-cleaningTask-undefinedTaskTitle', 'Renholdstype er ikke definert'),
					text: translationService.translate('web-swal-cleaningTask-undefinedTaskMessage', "Ønsker du å opprette renholdstype for oppgaven?"),
					type: "info",
					showCancelButton: true,
					confirmButtonColor: "#2196f3",
					confirmButtonText: translationService.translate('web-swal-cleaningTask-undefinedTask-confirm', 'Ja, ta meg til oppsett'),
					cancelButtonText: translationService.translate('web-swal-cleaningTask-undefinedTask-askMeLater', 'Minn meg på det neste gang'),
					closeOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					var data = {
						guid: $scope.model.Guid,
						id: $scope.model.Id,
						explanatoryText: $scope.model.ExplanatoryText,
						recurrence: $scope.model.Recurrence,
						recurrenceRule: $scope.model.RecurrenceRule,
						estimatedTime: $scope.model.EstimatedTime,
						estimatedCost: $scope.model.EstimatedCost,
						isTemplate: $scope.model.IsTemplate
					};
					persistentDataService.setPersistedData('cleaningTask.create', data);
					$scope.navigation.go('cleaningTask.create', { showPersistantData: true });
				});
			});
		}

		$scope.update = function (destination) {
			var toSave = angular.copy($scope.model);

			if (toSave.RecurrenceRule && toSave.RecurrenceRule.StartDate)
				toSave.RecurrenceRule.StartDate = new Date(toSave.RecurrenceRule.StartDate).toISOString();

			if (toSave.RecurrenceRule && toSave.RecurrenceRule.Unit) {
				enumService.setTimeUnitOnRecurrence(toSave.RecurrenceRule);
			}

			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-cleaningTask-success', 'Renholdsoppgaven har blitt oppdatert.') : translationService.translate('create-cleaningTask-success', 'Renholdsoppgaven har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.cleaningTask.url, toSave).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.cleaningTask.url, toSave).then(success, error);
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaningTask-message', "Renholdsoppgaven vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaningTask-button-confirm', 'Ja, fjern Renholdsoppgaven'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.cleaningTask.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-cleaningTask-success', 'Renholdsoppgaven ble fjernet!'), result, "success");
						$scope.goBack('cleaningTask.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
			$scope.reloadTables = !$scope.reloadTables;
		});
	}
})();
