(function () {
	angular.module('Plania').directive('plListWidget', ['TranslationService', 'ListService', '$timeout', '$modal', function (translationService, listService, $timeout, $modal) {
		return {
			restrict: 'A',
			require: '^plDashboardGrid',
			scope: {
				widget: '=widget',
				edit: '=edit',
				saveFunction: '=saveFunction',
				selectedDrawings: '=selectedDrawings'
			},
			link: function (scope, element, attrs, gridCtrl) {
				scope.$parent.attachWidget(element, attrs);

				scope.removeWidget = function () {
					swal({
						title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
						text: translationService.translate('web-swal-dashboard-list-message', "Listen vil bli permanent fjernet!"),
						type: "warning",
						showCancelButton: true,
						confirmButtonColor: "#f44336",
						confirmButtonText: translationService.translate('web-swal-dashboard-list-confirm', 'Ja, fjern listen'),
						cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
						closeOnConfirm: false
					}, function () {
						window.onkeydown = null;
						window.onfocus = null;
						swal(translationService.translate('web-swal-dashboard-list-success', 'Listen ble fjernet!'), '', "success");
						scope.$parent.removeWidget(element, attrs);
					});
				};

				//Event for resizing
				$('.grid-stack').on('resizestop', function (event, ui) {
					if ($(event.target).find('.list-widget').length > 0) {
						$timeout(function () {
							scope.setHeight();
						}, 350);
					}
				});

				scope.setHeight = function () {
					var bodyHeight = element.height() - (element.find('.card-header').height() + 40);
					element.find('.lv-body').height(bodyHeight);
				};

				scope.setHeight();
			},
			templateUrl: 'app/dashboard/directives/widgets/listWidget.html',
			controller: ['$scope', 'TranslationService', 'Repository', 'NgTableParams', '$modal', '$rootScope', 'states', '$interval', 'DashboardSharedDataService', function ($scope, translationService, repository, ngTableParams, modal, $rootScope, states, $interval, sharedDataService) {
				$scope.model = $scope.widget;
				$scope.search = {};
				$scope.nowDate = new Date();
				$scope.showFilter = false;
				$scope.listviewSearchStat = false;
				$scope.hideSearch = function () {
					$scope.search = {};
					$scope.listviewSearchStat = false;
				};

				$scope.count = 50;
				$scope.totalCount = 0;
				if (typeof ($scope.model.WidgetData) === "string") {
					$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
				}

				$scope.guidDrawings = sharedDataService.getDrawingGuids();

				var searchTimeOutPromise;

				$scope.searchChanged = function () {
					$timeout.cancel(searchTimeOutPromise);
					searchTimeOutPromise = $timeout(function () {
						getItems();
					}, 500);
				};

				var refreshInterval;
				var isFirstDataFetch = true;
				var firstListItem = {};
				var itemShadowList = [];

				var getItems = function () {
					if (!$scope.model.WidgetData.DataType) return;
					var url = repository.apiData[$scope.model.WidgetData.DataType].url;

					var filter = {
						PropertyFilter: [{ Property: 'GuidArea', Operator: '<>', Value: '' }]
					};

					if ($scope.search.searchString)
						filter.searchString = $scope.search.searchString;

					var columns = ['Id', 'Description', 'CreationDate', 'Area.Id', 'Area.Description'];

					if ($scope.model.WidgetData.DataType === 'areaXCleaningTask') {
						columns.push('CleaningTask.Id', 'CleaningTask.CleaningType', 'Area.Building.Id', 'Area.Building.Description');
						filter.PropertyFilter.push(
							{ Operand: 'and', Property: 'CleaningTask.CleaningType', Operator: '<>', Value: -1 },
							{ Operand: 'and', Property: 'CleaningTask.CleaningType', Operator: '<>', Value: 0 },
							{ Operand: 'and', Property: 'CleaningTask.CleaningType', Operator: '<>', Value: 2 },
							{ Operand: 'and', Property: 'GuidCleaner', Operator: '=', Value: '' },
							{ Operand: 'and', Property: 'GuidCleaningTeam', Operator: '=', Value: '' }
						);
					} else if ($scope.model.WidgetData.DataType === 'request') {
						columns.push('Building.Id', 'Building.Description');
						filter.PropertyFilter.push({ Operand: 'and', Property: 'Status', Operator: '=', Value: '0' });
					} else if ($scope.model.WidgetData.DataType === 'operationalMessage') {
						filter = { PropertyFilter: [{ Property: 'DueDate', operator: '>=', Value: moment().startOf('day').toISOString() }] };
						columns = [
							'Estate.Id', 'Estate.Description',
							'Building.Id', 'Building.Description',
							'Equipment.Id', 'Equipment.Description',
							'StartDate', 'DueDate', 'Person.FirstName', 'Person.LastName', 'Message'
						];

						$scope.canCreateNew = $scope.$root.hasCreateAccess('OperationalMessage');
					}

					if ($scope.guidDrawings.length > 0)
						filter.PropertyFilter.push({ Operand: 'and', Property: 'Area.GuidDrawing', Operator: 'in', Value: $scope.guidDrawings.join(',') });

					repository.GetPaginated(url, 0, $scope.count, { 'CreationDate': 'desc' }, filter, '', JSON.stringify(columns)).then(function (result) {
						if (result.List.length === 0) return;
						$scope.items = result.List;
						if (isFirstDataFetch) {
							firstListItemDate = $scope.items[0].CreationDate;
							isFirstDataFetch = false;
						}

						$scope.items.forEach(function (item) {
							if (_.contains(itemShadowList, item.Guid)) return;

							if (moment(item.CreationDate).isAfter(moment(firstListItemDate))) {
								itemShadowList.push(item.Guid);
							}
							//Format start and duedate to date instead of string in order to get colour on operationalmessage (svv specific)
							if (item.StartDate) {
								item.StartDate = new Date(item.StartDate);
							}
							if (item.DueDate) {
								item.DueDate = new Date(item.DueDate);
							}
						});

						firstListItemDate = $scope.items[0].CreationDate;

						$scope.totalCount = result.TotalCount;
					}, function (error) {
						repository.growl(error, 'danger');
					});
				};

				getItems();

				if ($scope.model.WidgetData.AutoRefresh && $scope.model.WidgetData.RefreshInterval) {
					setRefreshInterval();
				}

				$scope.isNewAndUnread = function (item) {
					return _.contains(itemShadowList, item.Guid);
				};

				$scope.$watch('guidDrawings', function (newValue, oldValue) {
					if (newValue === oldValue) return;
					getItems();
				}, true);

				$scope.$watch('model.WidgetData', function (newValue, oldValue) {
					if (newValue === oldValue) return;

					if (typeof ($scope.model.WidgetData) === 'string') {
						$scope.model.WidgetData = JSON.parse($scope.model.WidgetData);
					}

					if ($scope.model.WidgetData.AutoRefresh && $scope.model.WidgetData.RefreshInterval) {
						setRefreshInterval();
					} else {
						if (refreshInterval) {
							cancelInterval(refreshInterval);
						}
					}

					getItems();
				}, true);

				function setRefreshInterval() {
					if (refreshInterval) {
						cancelInterval(refreshInterval);
					}

					refreshInterval = $interval(function () {
						getItems();
					}, $scope.model.WidgetData.RefreshInterval * 1000);

					$scope.$on('$destroy', function () {
						cancelInterval(refreshInterval);
					});
				}

				function cancelInterval(interval) {
					$interval.cancel(interval);
					interval = undefined;
				}

				$scope.addMoreData = function () {
					$scope.count += 50;
					getItems();
				};

				$rootScope.$on($rootScope.events.newSelection, function () {
					getItems();
				});

				$scope.activateItem = function (item) {
					removeItemFromShadowList(item);

					$scope.$root.$broadcast('dashboard.activateItem', item);
				};

				$scope.addNew = function () {
					if ($scope.model.WidgetData.DataType === 'operationalMessage') {
						$modal.open({
							templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
							controller: 'OperationalMessageModalController',
							resolve: {
								params: function () {
									return {
									};
								}
							}
						}).result.then(function () {
							getItems();
						}, function () {
							//cancel
						});
					}
				};

				$scope.editItem = function (item) {
					removeItemFromShadowList(item);

					if ($scope.model.WidgetData.DataType === 'request') {
						$rootScope.navigation.go('request.edit', { guid: item.Guid });
					} else if ($scope.model.WidgetData.DataType === 'areaXCleaningTask') {
						modal.open({
							templateUrl: 'app/area/views/EditAreaXCleaningTaskSimpleModal.html',
							controller: 'AreaXCleaningTaskController',
							resolve: {
								modalParams: function () {
									return { Guid: item.Guid };
								}
							}
						}).result.then(function (result) {
							repository.growl(result, 'info');
							getItems();
						});
					} else if ($scope.model.WidgetData.DataType === 'operationalMessage') {
						modal.open({
							templateUrl: 'app/operationalMessage/views/operationalMessageModal.html',
							controller: 'OperationalMessageModalController',
							resolve: {
								params: function () {
									return { guid: item.Guid };
								}
							}
						}).result.then(function (result) {
							repository.growl(result, 'info');
							getItems();
						});
					}
				};

				function removeItemFromShadowList(item) {
					_.remove(itemShadowList, function (o) {
						return o === item.Guid;
					});
				}

				$scope.editWidget = function () {
					modal.open({
						controller: 'AddWidgetModalController',
						templateUrl: 'app/dashboard/addWidgetModal.html',
						resolve: {
							widget: function () {
								return $scope.model;
							}
						}
					}).result.then(function (widgetModel) {
						$scope.saveFunction(false);
					}, function () {
						//Dismissed
					});
				};
			}],
		};
	}]);
})();
