(function () {

	var app = angular.module('Plania');

	app.directive('deviationDirective', function () {
		return {
			restrict: 'E',
			scope: {
				isCollapsed: '=isCollapsed',
				reload: '=reload',
				deviations: '=deviations',
				disableActions: '=disableActions',
				estate: '=estate',
				building: '=building',
				area: '=area',
				equipment: '=equipment',
				parentEntity: '=parentEntity',
				guid: '=guid',
                restrictEdit: '=restrictEdit',
                showConnection: '@',
				useList: '=useList', //Set to true if using directive in small width container
				inline: '=inline' //Set to true if used inline in modal
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', '$rootScope', 'TranslationService', '$localStorage', 'Constants', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/deviation/views/deviationDirective.html'
		};
	});

    function controller($scope, $modal, ngTableParams, repository, $rootScope, translationService, $localStorage, Constants) {
		$scope.deviationsIsLoading = true;
		$scope.search = {};

		$scope.hasEditAccess = $rootScope.hasEditAccess('Deviation');

		var deviationIsClosed = function (deviation) {
			return deviation && deviation.Status === 'Closed';
		};

		$scope.canDeleteDeviation = function (deviation) {
			return !deviationIsClosed(deviation) &&
				deviation.GuidCorrectiveWorkOrder &&
				deviation.GuidCorrectiveWorkOrder === $scope.guid;
		};

		$scope.canCloseDeviation = function (deviation) {
			if (deviationIsClosed(deviation)) return false;

			if ($localStorage.generalOptions.CloseDeviationFromWoOnly)
				return $scope.parentEntity === 'WorkOrder' && deviation.GuidWorkOrder === $scope.guid;
			else
				return $scope.parentEntity === 'WorkOrder' ? deviation.GuidWorkOrder === $scope.guid : true;
		};

		$scope.$watch('reload', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			if ($scope.reload && $scope.reload === true) {

				if ($scope.parentEntity === 'WorkOrder') {
					$scope.deviationTable.filter().GuidWorkOrder = $scope.guid;
				} else {
					$scope.deviationTable.filter().PropertyFilter =
						[{ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid }];

					if ($scope.parentEntity === 'Area')
						$scope.deviationTable.filter().PropertyFilter.push({ Property: 'GuidEquipment', Operator: '=', value: null });
					if ($scope.parentEntity === 'Building')
						$scope.deviationTable.filter().PropertyFilter.push({ Property: 'GuidArea', Operator: '=', value: null });
					if ($scope.parentEntity === 'Estate')
						$scope.deviationTable.filter().PropertyFilter.push({ Property: 'GuidBuilding', Operator: '=', value: null });
				}
				$scope.deviationTable.reload();

				$scope.reload = false;
			}
		});

		$scope.columns = [
			{ Position: 1, Title: translationService.translate('web-estate-id'), Property: 'Estate.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-estate-description'), Property: 'Estate.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-area-id'), Property: 'Area.Id', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-area-description'), Property: 'Area.Description', PropertyType: 'string' },
			{ Position: 7, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'string' },
			{ Position: 8, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'string' },
			{ Position: 9, Title: translationService.translate('web-deviationType-id'), Property: 'DeviationType.Id', PropertyType: 'string' },
			{ Position: 10, Title: translationService.translate('web-deviationType-description'), Property: 'DeviationType.Description', PropertyType: 'string' },
			{ Position: 11, Title: translationService.translate('web-deviation-actioncomment', 'Kommentar'), Property: 'ActionComment' },
			{ Position: 12, Title: translationService.translate('web-deviation-deadlinedate', 'Fristdato'), Property: 'DeadlineDate', PropertyType: 'date' },
			{ Position: 13, Title: translationService.translate('web-deviation-enddate', 'Lukket dato'), Property: 'EndDate', PropertyType: 'date' },
			{ Position: 14, Title: translationService.translate('web-deviation-status', 'Status'), Property: 'Status', PropertyType: 'string' },
		];

		//Returns the entity connected to the the deviation that is the lowest in the hierarchy
		$scope.getLowestEntityCaption = function (deviation) {
			if (deviation.Equipment) return $rootScope.getEntityCaption(deviation.Equipment);
			if (deviation.Area) return $rootScope.getEntityCaption(deviation.Area);
			if (deviation.Building) return $rootScope.getEntityCaption(deviation.Building);
			if (deviation.Estate) return $rootScope.getEntityCaption(deviation.Estate);
		};

		$scope.getEntityCaption = function (object) {
			return $rootScope.getEntityCaption(object);
		};

		$scope.getEntityIcon = function (deviation) {
			if (deviation.Equipment) return 'zmdi-settings c-bluegray';
			if (deviation.Area) return 'zmdi-layers c-lightblue';
			if (deviation.Building) return 'zmdi-balance c-brown';
			if (deviation.Estate) return 'zmdi-city c-teal';
		};

		$scope.getStatusColor = function (status) {
			switch (status) {
				case 'New':
					return 'info';
				case 'InWorks':
					return 'warning';
				case 'Closed':
					return 'success';
				case 'Canceled':
					return 'danger';
				default:
					return;
			}
		};

		$scope.deviationIsOverDeadline = function (deadlineDate) {
			if (moment(deadlineDate).isBefore(moment())) return true;
			return false;
		};

		$scope.deviationTable = new ngTableParams({
			page: 1,
			count: 5,
			sorting: { 'Status': 'asc', 'DeadlineDate': 'asc' },
		}, {
			total: 0,
			counts: [5, 10, 20],
			filterDelay: 50,
			paginationMaxBlocks: $scope.useList ? 6 : 8,
			getData: function ($defer, params) {
				if (!$scope.deviationTable.filter().PropertyFilter && !$scope.deviationTable.filter().GuidWorkOrder) return;

				$scope.deviationsIsLoading = true;

				var columns = [];
				$scope.columns.forEach(function (col) {
					columns.push(col.Property);
				});

				columns.push('Id');

				repository.GetPaginated(repository.apiData.deviation.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns))
					.then(function (result) {
						$scope.deviationTable.settings().total = result.TotalCount;
						$scope.deviationTable.settings().filterDelay = 500;

						if (!$scope.deviations)
							$scope.deviations = [];

						if ($scope.deviations) {
							while ($scope.deviations.length > 0)
								$scope.deviations.pop();
						}

						result.List.forEach(function (deviation) {
							$scope.deviations.push(deviation);
						});

						$defer.resolve(result.List);
						$scope.deviationsIsLoading = false;
					}, function (error) {
						repository.growl(error, 'danger');
					});
			}
		});

		var filterTypes = [
			{ value: 'all', name: translationService.translate('web-deviation-filterType-all', 'Alle') },
			{ value: 'open', name: translationService.translate('web-deviation-filterType-open', 'Åpne') },
			{ value: 'closed', name: translationService.translate('web-deviation-filterType-closed', 'Lukkede') }
		];

		$scope.setFilter = function (filterType) {
			$scope.selectedFilterName = _.find(filterTypes, { value: filterType }).name;
			$localStorage.selectedDeviationFilterType = filterType;

			var existingStatusFilterIndex = _.findIndex($scope.deviationTable.filter().PropertyFilter, function (o) { return o.Property === 'Status'; });

			if (existingStatusFilterIndex > -1) {
				$scope.deviationTable.filter().PropertyFilter.splice(existingStatusFilterIndex, 1);
			}

			switch (filterType) {
				case 'all':
					delete $scope.deviationTable.filter().active;
					break;
				case 'open':
					$scope.deviationTable.filter().active = true;
					break;
				case 'closed':
					delete $scope.deviationTable.filter().active;
					var propertyFilter = $scope.deviationTable.filter().PropertyFilter;
					if (propertyFilter)
						propertyFilter.push({ Property: 'Status', Operator: '=', Value: '3' });
					else
						$scope.deviationTable.filter().PropertyFilter = [{ Property: 'Status', Operator: '=', Value: '3' }];
					break;
			}
		};

		if ($localStorage.selectedDeviationFilterType) {
			$scope.selectedFilterName = _.find(filterTypes, { value: $localStorage.selectedDeviationFilterType }).name;
			$scope.setFilter($localStorage.selectedDeviationFilterType);
		} else {
			$scope.selectedFilterName = _.find(filterTypes, { value: 'all' }).name;
		}

        $scope.create = function () {
			$modal.open({
				templateUrl: 'app/deviation/views/editDeviationModal.html',
				controller: 'DeviationController',
				resolve: {
					$stateParams: function () {
						var params = {
							guid: '',
							isModal: true,
							parentEntity: $scope.parentEntity,
							disableEntityInput: $scope.parentEntity && $scope.parentEntity !== 'WorkOrder'
						};

						if ($scope.building) params.building = { Caption: $scope.getEntityCaption($scope.building), guid: $scope.building.Guid };
						if ($scope.estate) params.estate = { Caption: $scope.getEntityCaption($scope.estate), guid: $scope.estate.Guid };
						if ($scope.equipment) params.equipment = { Caption: $scope.getEntityCaption($scope.equipment), guid: $scope.equipment.Guid };
						if ($scope.area) params.area = { Caption: $scope.getEntityCaption($scope.area), guid: $scope.area.Guid };
						if ($scope.parentEntity === 'WorkOrder') params.GuidInspectionWorkOrder = $scope.guid;
						return params;
					}
				}
			}).result.then(function () {
				$scope.deviationTable.reload();
			}, function () {
				$scope.deviationTable.reload();
				//cancel
			});
		};

		$scope.update = function (deviation) {
			if (!$scope.hasEditAccess) return;

			$modal.open({
				templateUrl: 'app/deviation/views/editDeviationModal.html',
				controller: 'DeviationController',
				resolve: {
					$stateParams: function () {
						return {
							guid: deviation.Guid,
							isModal: true,
							parentEntity: $scope.parentEntity,
						};
					}
				}
			}).result.then(function () {
				$scope.deviationTable.reload();
			}, function () {
				//cancel
			});
		};

		$scope.closeDeviation = function (deviation) {
			swal({
				title: translationService.translate('web-swal-close-Deviation', 'Bekreft lukking av avvik'),
				text: translationService.translate('web-swal-close-Deviation-message', 'Er avviket klar til å bli lukket?'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#2196f3",
				confirmButtonText: translationService.translate('web-swal-deviation-button-confirm', 'Ja, Lukk avviket!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				deviation.RequestAction = "Close";
				repository.updateSingleDictionary(repository.apiData.deviation.url, deviation).then(function (result) {
					swal(translationService.translate('web-swal-deviation-success', 'Avviket ble lukket!'), '', "success");
					deviation.Status = 'Closed';
					$scope.deviationTable.reload();
				});
				deviation.RequestAction = null;
			});
        };

        $scope.createWorkOrder = function (deviation) {

            var building = '';
            var estate = '';
            var equipment = '';

            var selectedList = [];
            selectedList.push(deviation.Guid);
            var errorMessage1 = '';
            var errorMessage2 = '';

            if (deviation.GuidCorrectiveWorkOrder) {
                swal('Feilmelding', 'Du kan ikke generere arbeidsordre på et avvik som allerede har en arbeidsordre generert. ' + errorMessage2, 'error');
                return;
            }

            if (deviation.GuidEstate) {
                estate = deviation.Estate.Id + " - " + deviation.Estate.Description;
            }

            if (deviation.GuidBuilding) {
                building = deviation.Building.Id + " - " + deviation.Building.Description;
            }

            if (deviation.GuidEquipment) {
                equipment = deviation.Equipment.Id + " - " + deviation.Equipment.Description;
            }

            repository.persistedData.setPersistedData('workOrder.create', {
                guidEstate: deviation.GuidEstate,
                estate: estate,
                guidBuilding: deviation.GuidBuilding,
                building: building,
                guidEquipment: deviation.GuidEquipment,
                equipment: equipment,
                guidSelections: selectedList,
                fromDeviation: true,
                fromCondition: false,
                defaultDescription: translationService.translate('deviation-new-workOrder-defaultDescription', 'Utbedring av avvik')
            });
            $rootScope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
        };

        $scope.createWorkOrder = function (deviation) {

            var building = '';
            var estate = '';
            var equipment = '';

            var selectedList = [];
            selectedList.push(deviation.Guid);
            var errorMessage1 = '';
            var errorMessage2 = '';

            if (deviation.GuidCorrectiveWorkOrder) {
                swal('Feilmelding', 'Du kan ikke generere arbeidsordre på et avvik som allerede har en arbeidsordre generert. ' + errorMessage2, 'error');
                return;
            }

            if (deviation.GuidEstate) {
                estate = deviation.Estate.Id + " - " + deviation.Estate.Description;
            }

            if (deviation.GuidBuilding) {
                building = deviation.Building.Id + " - " + deviation.Building.Description;
            }

            if (deviation.GuidEquipment) {
                equipment = deviation.Equipment.Id + " - " + deviation.Equipment.Description;
            }

            repository.persistedData.setPersistedData('workOrder.create', {
                guidEstate: deviation.GuidEstate,
                estate: estate,
                guidBuilding: deviation.GuidBuilding,
                building: building,
                guidEquipment: deviation.GuidEquipment,
                equipment: equipment,
                guidSelections: selectedList,
                fromDeviation: true,
                fromCondition: false,
                defaultDescription: translationService.translate('deviation-new-workOrder-defaultDescription', 'Utbedring av avvik')
            });
            $rootScope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
        };

		$scope.remove = function (deviation) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-workOrder-deviation-message', 'Avviket vil bli fjernet fra arbeidsordren!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-workOrder-deviation-button-confirm', 'Ja, fjern avviket!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				deviation.RequestAction = "ResetCorrectiveWorkorder";
				repository.updateSingleDictionary(repository.apiData.deviation.url, deviation)
					.then(function (result) {
						swal(translationService.translate('web-swal-workOrder-deviation-success', 'Avviket ble fjernet fra arbeidsordre!'), "Id -" + result.Caption, "success");
						$scope.deviationTable.reload();
					}, function (error) {
						swal('Error', error, "error");
					});
			});
        };


        $scope.connectDeviation = function () {
            var columns = [
                { Position: 1, Title: translationService.translate('web-deviation-id'), Property: 'Id', PropertyType: 'string' },
                { Position: 2, Title: translationService.translate('web-deviation-deviationtype'), Property: 'DeviationType.Id', PropertyType: 'string' },
                { Position: 3, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'string' },
                { Position: 4, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'string' },
                { Position: 5, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
                { Position: 6, Title: translationService.translate('web-building-description'), Property: 'Building.Description', PropertyType: 'string' },
                { Position: 7, Title: translationService.translate('web-estate-id'), Property: 'Estate.Id', PropertyType: 'string' },
                { Position: 8, Title: translationService.translate('web-estate-description'), Property: 'Estate.Description', PropertyType: 'string' },
                { Position: 9, Title: translationService.translate('web-deviation-actioncomment'), Property: 'ActionComment', PropertyType: 'string' },
                { Property: 'Remarks', Hidden: true },
                { Property: 'StartDate', Hidden: true },
                { Property: 'DeadlineDate', Hidden: true },
                { Property: 'ReportedEndDate', Hidden: true },
                { Property: 'EndDate', Hidden: true },
                { Property: 'Status', Hidden: true },
                { Property: 'StatusComment', Hidden: true },
            ];

            var propertyFilter = [
                { Property: "GuidCorrectiveWorkOrder", Operator: '=', Value: null },
                { Property: "GuidEquipment", Operator: '<>', Value: null },
            ];

            // Estate
            if ($localStorage.generalOptions.InspectedEntityLocation === Constants.inspectedEntityLocation.estate) {
                propertyFilter.push({ Property: "GuidEstate", Operator: '=', Value: $scope.estate.Guid, allowToggle: true, isDisabled: false, filterValue: $scope.estate.Caption });
                propertyFilter.push({ Property: "GuidBuilding", Operator: '=', Value: $scope.building.Guid, allowToggle: true, isDisabled: true, filterValue: $scope.building.Caption });
            }
            // Building
            else if ($localStorage.generalOptions.InspectedEntityLocation === Constants.inspectedEntityLocation.building) {
                propertyFilter.push({ Property: "GuidBuilding", Operator: '=', Value: $scope.building.Guid, allowToggle: true, isDisabled: false, filterValue: $scope.building.Caption });
                propertyFilter.push({ Property: "GuidEstate", Operator: '=', Value: $scope.estate.Guid, allowToggle: true, isDisabled: true, filterValue: $scope.estate.Caption });
            } else {
                propertyFilter.push({ Property: "GuidBuilding", Operator: '=', Value: $scope.building.Guid, allowToggle: true, isDisabled: true, filterValue: $scope.building.Caption });
                propertyFilter.push({ Property: "GuidEstate", Operator: '=', Value: $scope.estate.Guid, allowToggle: true, isDisabled: true, filterValue: $scope.estate.Caption });
            }

            $modal.open({
                templateUrl: 'app/common/views/multipleSelectModal.html',
                controller: 'MultipleSelectModalController',
                size: 'lg',
                resolve: {
                    modalParams: function () {
                        return {
                            title: translationService.translate('web-deviation-choose' , 'Velg avvik'),
                            columns: columns,
                            useObjects: true,
                            url: repository.apiData.deviation.url,
                            sorting: { Id: 'asc' },
                            filter: { PropertyFilter: propertyFilter }
                        };
                    }
                }
            }).result.then(function (deviations) {
                if (deviations.length > 0) {
                    deviationList = [];

                    deviations.forEach(function (deviation) {
                        deviation.GuidCorrectiveWorkOrder = $scope.guid;
                        deviationList.push(JSON.stringify(deviation));
                    });

                    repository.updateMultiple(repository.apiData.deviation.url, deviationList).then(function () {
                        repository.growl(translationService.translate('deviationList-addMultiple-success', 'Avvikene har blitt lagt til'), "success");
                        $scope.deviationTable.reload();
                    }, function (error) {
                        repository.growl(error, 'danger');
                        $scope.deviationTable.reload();
                    });
                }
            });
        };

		$scope.$on($rootScope.events.newSelection, function () {
			$scope.deviationTable.reload();
		});
	}
})();
