(function () {
	angular.module('Plania').controller('MaintenancePlanController', ['$scope', 'Repository', 'ListService', '$filter', controller]);

	function controller($scope, repository, listService, $filter) {
		$scope.showTaskHeader = false;
		$scope.sortingProperty = 'StartDate';
		$scope.sortingDirection = 'desc';
		$scope.sorting = {};
		$scope.viewMode = 'days';
		$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
		//Contain the filterValues
		$scope.filterValues = {};
		$scope.autocompleteModel = {};
		$scope.filters = { ActivityStatus: 'Active', PropertyFilter: [] };
		$scope.loading = false;

		$scope.count = 100;
		$scope.totalCount = 0;

		$scope.sortingProperties = [
			{ value: 'Description', label: 'Beskrivelse', type: 'string' },
			{ value: 'StartDate', label: 'Startdato', type: 'date' },
			{ value: 'EstimatedCost', label: 'Estimert kostnad', type: 'string' },
			{ value: 'EstimatedTime', label: 'Estimert tid', type: 'string' },
			{ value: 'Building.Id', label: 'Bygning ID', typee: 'string' },
			{ value: 'Building.Description', label: 'Bygningsbeskrivelse', type: 'string' },
			{ value: 'Equipment.Id', label: 'Anlegg ID', type: 'string' },
			{ value: 'Equipment.Description', label: 'Anleggsbeskrivelse', type: 'string' },
			{ value: 'Area.Id', label: 'Areal ID', type: 'string' },
			{ value: 'ResourceGroup.Id', label: 'Ressurs' },
			{ value: 'Type', label: 'Arbeidsordretype' },
			{ value: 'ActivityCategory.Description', label: 'Kategori' },
			{ value: 'Supplier.Description', label: 'Leverandør' },
			{ value: 'Person.LastName', label: 'Ansvarlig person(Etternavn)' },
		];

		$scope.workOrderTypes = [
			{ title: 'KO', value: 0 },
			{ title: 'PR', value: 1 },
			{ title: 'FV', value: 2 },
			{ title: 'ME', value: 3 },
			{ title: 'Test', value: 4 },
			{ title: 'Kontroll', value: 5 },
			{ title: 'AV', value: 6 },
			{ title: 'Alarm', value: 7 },
			{ title: 'VL', value: 8 },
			{ title: 'TT', value: 9 },
		];

		$scope.setSorting = function (item) {
			if ($scope.sortingProperty === item.value) {
				$scope.sortingDirection = $scope.sortingDirection === 'desc' ? 'asc' : 'desc';
				$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
			} else {
				$scope.sorting = {};
				$scope.sortingProperty = item.value;
				$scope.sortingDirection = 'desc';
				$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
			}

			$scope.getData();
		};

		var currentPropertyKey = '';

		var findProperty = function (o) {
			return o.Property === currentPropertyKey;
		};

		var findArrayIndex = function (array, property) {
			var propForTest = property ? property : currentPropertyKey;
			for (var i = 0; i < array.length; i++) {
				if (array[i].Property === propForTest) {
					return i;
				}
			}
		};

		var findPropertyIndex = function (element, index, array) {
			return element.Property === currentPropertyKey;
		};

		$scope.$watch('filterValues', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			for (var key in newValue) {
				currentPropertyKey = key;
				var property = _.find($scope.filters.PropertyFilter, findProperty);

				if (property && (!$scope.filterValues[key] || $scope.filterValues[key] === "") && $scope.filterValues[key] !== 0) {
					var index = findArrayIndex($scope.filters.PropertyFilter);
					$scope.filters.PropertyFilter.splice(index, 1);
				}

				if (property)
					property.Value = $scope.filterValues[key];
				else
					$scope.filters.PropertyFilter.push({ Property: key, Operator: '=', Value: $scope.filterValues[key] });
			}

			$scope.getData();
		}, true);

		$scope.$watch('filters.showWorkordersWithoutStartDate', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			$scope.getData();
		});



		$scope.getData = function () {
			if ($scope.viewMode === "months") {
				$scope.filters.NumberOfYearsToCalculate = 3;
			}

			$scope.loading = true;

			repository.GetPaginated(repository.apiData.maintenance.url, 0, $scope.count, $scope.sorting, $scope.filters, null).then(function (result) {
				var data = [];
				result.List.forEach(function (item) {
					var selectedSorting = _.find($scope.sortingProperties, function (o) { return o.value === $scope.sortingProperty; });
					$scope.loading = false;
					var object = {
						name: item.Title,
						filterValue: item.Subtitle,
						filterLabel: selectedSorting.label,
						//Object containing the values for the detailed view of the row
						detailProperties: [],
						dataObj: item,
						values: []
					};

					if (item.Building) object.detailProperties.push({ name: 'Bygg', value: item.Building });
					if (item.Equipment) object.detailProperties.push({ name: 'Anlegg', value: item.Equipment });
					if (item.Area) object.detailProperties.push({ name: 'Areal', value: item.Area });
					if (item.ResourceGroup) object.detailProperties.push({ name: 'Ressurs', value: item.ResourceGroup });
					if (item.WorkOrderType) object.detailProperties.push({ name: 'Type', value: item.WorkOrderType.Description });
					if (item.ActivityCategory) object.detailProperties.push({ name: 'Kategori', value: item.ActivityCategory });
					if (item.Supplier) object.detailProperties.push({ name: 'Leverandør', value: item.Supplier });
					if (item.StartDate) object.detailProperties.push({ name: 'Start dato', value: $filter('date')(item.StartDate, 'dd.MM.yyyy') });
					if (item.EstimatedCost) object.detailProperties.push({ name: 'Est. kost', value: item.EstimatedCost });
					if (item.EstimatedTime) object.detailProperties.push({ name: 'Est. tid', value: item.EstimatedTime });

					item.MaintenanceValues.forEach(function (value) {
						var className = value.IsCompleted ? "ganttGreen" : value.IsPastDueDate ? "ganttRed" : "";
						if (value.GuidPeriodicTask) className = "ganttGray transparent-bar";
						object.values.push({
							from: "/Date(" + new Date(value.StartDate).getTime() + ")/",
							to: "/Date(" + new Date(value.EndDate).getTime() + ")/",
							//label: item.Title,
							customClass: className,
							dataObj: value
						});
					});


					data.push(object);
				});

				$scope.tasks = data;

				$scope.totalCount = result.TotalCount;
			}, function (error) {
				$scope.loading = false;
				repository.growl(error, 'danger');
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			$scope.getData();
		});

		$scope.addMoreData = function () {
			if ($scope.loading) return;
			$scope.count += 100;
			$scope.getData();
		};

		$scope.lessData = function () {
			if ($scope.loading) return;
			$scope.count -= 100;
			$scope.getData();
		};

		$scope.getData();

	}
})();
