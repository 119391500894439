(function () {
	angular.module('Plania').controller('ControlListSelectionController', ['$scope', 'Repository', 'ngTableParams', 'TranslationService', '$modal', controller]);

	function controller($scope, repository, ngTableParams, translationService, $modal) {
		var guidArea = null;

		$scope.init = function (params) {
			guidArea = params.guidArea;
			getData();
		};

		var columns = [
			'Status', 'WorkOrder.Id', 'WorkOrder.Description', 'WorkOrder.EndDate',
			'Area.Id', 'Area.Description', 'Area.Building.Id', 'Area.Building.Description', 'Area.Building.Guid',
			'Area.Building.Estate.Id', 'Area.Building.Estate.Description', 'Area.Building.Estate.Guid',
			'ControlList.Id', 'ControlList.Description', 'ControlList.Name', 'ControlList.Guid',
			'ControlList.Color', 'ControlList.Icon', 'ClosedDate', 'User.RealName'
		];

		var getData = function () {
			$scope.controlListTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: {'WorkOrder.Id':'desc'},
				filter: {
					PropertyFilter: [
						{ Property: 'WorkOrder.StartDate', Operator: '<=', Value: moment().endOf('day').toISOString() },
						{ Operand: 'OR', Property: 'WorkOrder.StartDate', Operator: '=', Value: '' },
						{ Property: 'GuidArea', Operator: '=', Value: guidArea },
						{ Property: 'GuidWorkOrder', Operator: '<>', Value: null },
						{ Property: 'WorkOrder.EndDate', Operator: '=', Value: '' }
					]
				}
			}, {
					total: 0,
					counts: [10, 20],
					getData: function ($defer, params) {
						repository.GetPaginated(repository.apiData.controlListXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter(), '', JSON.stringify(columns)).then(
							function (result) {
								params.total(result.TotalCount);
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		};

		$scope.openControlList = function (controlListXEntity) {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'lg',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							controlList: controlListXEntity.ControlList,
							controlListXEntity: controlListXEntity,
							workOrder: controlListXEntity.WorkOrder,
							workOrderCaption: controlListXEntity.WorkOrder.Caption
						};
					}
				}
			}).result.then(function (result) {
				$scope.controlListTable.reload();
			}, function () {
				$scope.controlListTable.reload();
			});
		};
	}
})();
