(function () {

	var app = angular.module('Plania');

	app.directive('userxwebprofile', function () {
		return {
			restrict: 'E',
			scope: {
				user: '=user',
				reload: '=reload',
				guid: '=guid',
				restrictEdit: '=restrictEdit'
			},
			controller: ['$scope', '$modal', '$q', 'Repository', 'TranslationService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/userXWebProfile/views/userXWebProfileDirective.html'
		};
	});

	function controller($scope, $modal, $q, repository, translationService) {
		$scope.webProfiles = [];

		var getWebProfiles = function () {
			repository.GetPaginated(repository.apiData.userXWebProfile.url, 0, 1000, { IndexPosition: 'asc' }, { PropertyFilter: [{ Property: 'GuidUser', operator: '=', value: $scope.user.Guid }] })
				.then(function (profileResult) {
						if (profileResult) {
							$scope.webProfiles = profileResult.List;
						}
					},
					function (error) {
						repository.growl(error, 'danger');
					});
		};

		getWebProfiles();

		$scope.$watch('user.Guid', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			getWebProfiles();
		});

		var isOrderedByIndexPosition = function(webProfiles) {
			var index = 0;
			for (var i = 0; i < webProfiles.length; i++) {
				if (webProfiles[i].IndexPosition < index) {
					return false;
				}
				index = webProfiles[i].IndexPosition;
			}
			return true;
		};

		var reorderAndSave = function (webProfiles) {
			var index = 0;
			var promises = [];
			webProfiles.forEach(function(webProfile) {
				webProfile.IndexPosition = index++;
				// TODO should implement updatemultiple on planiadomain in order to not send to many requests to update entities
				promises.push(repository.updateSingleDictionary(repository.apiData.userXWebProfile.url, webProfile));
			});
			$q.all(promises).then(function(result) {
				repository.growl('Webprofiler har blitt lagret', 'success');
			});
		};

		$scope.$watchCollection('webProfiles', function(newValue, oldValue) {
			if (newValue === oldValue) return;
			if (isOrderedByIndexPosition($scope.webProfiles)) return;
			reorderAndSave($scope.webProfiles);
		});

		var getHighestIndexPosition = function () {
			var maxWebProfile = _.max($scope.webProfiles, function (webProfile) {
				return webProfile.IndexPosition;
			});
			return maxWebProfile ? maxWebProfile.IndexPosition : 0;
		};

		$scope.addWebProfile = function () {
			var alreadySavedGuids = _.map($scope.webProfiles, 'GuidWebProfile');

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Velg webprofil',
							columns: [
								{ Title: translationService.translate('web-webProfile-id'), Property: 'Id' },
								{ Title: translationService.translate('web-webProfile-description'), Property: 'Description' },
							],
							useObjects: false,
							url: repository.apiData.webProfile.url,
							sorting: { Id: 'asc' },
							filter: {},
							alreadySavedGuids: alreadySavedGuids
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var toSave = _.difference(selectedEntities, alreadySavedGuids);
				var i = getHighestIndexPosition();
				var userXWebProfiles = _.map(toSave, function (entity) {
					return JSON.stringify({ GuidUser: $scope.user.Guid, GuidWebProfile: entity, IndexPosition: ++i });
				});
				repository.createMultiple(repository.apiData.userXWebProfile.url, userXWebProfiles).then(function (result) {
					repository.growl('Webprofiler har blitt lagt til', 'success');
					getWebProfiles();
				}, function(error) {
					repository.growl(error, 'danger');
				});
			});
		};

		$scope.removeWebProfile = function (profile) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-user-webProfile-message', 'profilen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-user-webprofile-button-confirm', 'Ja, fjern profilen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.userXWebProfile.url, profile.Guid)
					.then(function (result) {
						window.onkeydown = null;
						window.onfocus = null;
						repository.growl(translationService.translate('user-profile-removed-message', 'Profilen har blitt fjernet'), 'success');
						_.remove($scope.webProfiles, function (webProfile) {
							return webProfile.Guid === profile.Guid;
						});
						swal.close();
					});
			});
		};
	}
})();
