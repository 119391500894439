(function () {
	angular.module('Plania').controller('UserGroupController', ['$scope', 'TranslationService', 'Repository', '$modal', '$stateParams', 'ngTableParams', 'ListService', '$localStorage', controller]);

	function controller($scope, translationService, repository, $modal, $stateParams, ngTableParams, listService, $localStorage) {
		$scope.update = $scope.navigation.current.name === 'usergroup.edit';
		$scope.model = { Guid: $stateParams.guid };
		$scope.activateAutocomplete = $scope.update ? false : true;
		$scope.webProfiles = [];

		$scope.getPropertyValue = function (row, column) {
			return listService.GetPropertyValue(row, column);
		};

		$scope.userColumns = [
			{ Position: 1, Title: translationService.translate('web-userGroup-RealName', "Navn"), Property: 'RealName', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-userGroup-UserName', "Brukernavn"), Property: 'Username', PropertyType: 'string' },
		];

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.User, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.User, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.User);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.User);
			}
		};

		updateAccess();
		setTwoFactorStatus();

		/*user*/
		$scope.AddUsers = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Velg brukere',
							columns: [
								{ Title: translationService.translate('web-user-username'), Property: 'Username' },
								{ Title: translationService.translate('web-user-realName'), Property: 'RealName' },
							],
							useObjects: false,
							url: repository.apiData.user.url,
							sorting: { Username: 'asc' },
							filter: {},
							alreadySavedGuids: _.map($scope.userTable.data, 'Guid')
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var editetRows = [];
				selectedEntities.forEach(function (guid) {
					editetRows.push(JSON.stringify({ Guid: guid, GuidUserGroup: $scope.model.Guid }));
				});

				repository.createMultiple(repository.apiData.massUpdate.url, editetRows, 'Prefix=' + repository.apiData.user.prefix).then(function (response) {
					$scope.userTable.reload();
				});

			});
		};


		$scope.removeUser = function (user) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-usergroup-user-message', 'Brukeren vil bli fjernet fra gruppen!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-usergroup-user-button-confirm', 'Ja, fjern brukeren!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.createMultiple(repository.apiData.massUpdate.url, [JSON.stringify({ Guid: user.Guid, GuidUserGroup: null })], 'Prefix=User')
					.then(function (result) {
						repository.growl(translationService.translate('web-swal-usergroup-user-success', 'Brukeren ble fjernet!', result), 'success');
						$scope.userTable.reload();
						swal.close();
					});
			});
		};

		/*end user*/

		$scope.userTable = new ngTableParams(
			{
				page: 1,
				count: 10,
				sorting: { 'RealName': 'asc' },
				filter: { PropertyFilter: [{ Property: "GuidUserGroup", Operator: "=", value: $scope.model.Guid }, { Property: "IsUserGroup", Operator: "=", value: 0 }] }
			},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					var columns = [];
					$scope.userColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.user.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
						.then(function (result) {
							$scope.userTable.settings().total = result.TotalCount;
							$scope.userTable.settings().filterDelay = 500;
							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});

		$scope.userProfileColumns = [];
		$scope.userProfileColumns.addColumn = function (translationId, defaultText, property, propertyType) {
			this.push({ Position: this.length, Title: translationService.translate(translationId, defaultText), Property: property, PropertyType: propertyType });
			return this;
		};

		$scope.userProfileColumns
			.addColumn('user-clientTable-dataOwnerName', "Klient", 'LinkedDataOwner.Description', 'string')
			.addColumn('user-clientTable-userProfileName', "Profil", 'EntityPermissionProfile.Id', 'string')
			.addColumn('user-clientTable-userProfileDescription', "Profilbeskrivelse", 'EntityPermissionProfile.Description', 'string')
			.addColumn('user-clientTable-isDefaultDataOwner', "Standard Klient", 'IsDefaultDataOwner', 'string');

		var getCardTables = function () {
			$scope.dataOwnerTable = new ngTableParams(
				{ page: 1, count: 10, filter: { PropertyFilter: [{ Property: 'GuidUser', Operator: '=', Value: $scope.model.Guid }] } },
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.userProfileColumns.forEach(function (col) {
							columns.push(col.Property);
						});
						repository.GetPaginated(repository.apiData.userProfile.url, params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(),
							null,
							JSON.stringify(columns)
						).then(
							function (result) {
								$scope.dataOwnerTable.settings().total = result.TotalCount;
								$scope.dataOwnerTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		};

		$scope.access = {
			Desktop: false,
			Web: false,
			Mobile: false,
			ReadOnlyWeb: false
		};

		var setUserAccess = function (access) {
			if (access.indexOf("Desktop") >= 0)
				$scope.access.Desktop = true;
			if (access.indexOf("Web") >= 0)
				$scope.access.Web = true;
			if (access.indexOf("Mobil") >= 0)
				$scope.access.Mobile = true;
			if (access.indexOf("ReadOnlyWeb") >= 0)
				$scope.access.ReadOnlyWeb = true;
		};

		var GetUserAccess = function () {
			var access = 0;
			if ($scope.access.Desktop)
				access = access | 1 << 0;
			if ($scope.access.Web)
				access = access | 1 << 1;
			if ($scope.access.Mobile)
				access = access | 1 << 2;
			if ($scope.access.ReadOnlyWeb)
				access = access | 1 << 3;

			return access;
		};

		function setTwoFactorStatus() {
			$scope.twoFactor = {};
			if ($localStorage.generalOptions.TwoFactorAuthenticationOption <= 0) {
				$scope.twoFactor.isEnabled = false;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-usergroup-twofactor-disabled', 'Tofaktor er avskrudd fra system');
			} else if ($localStorage.generalOptions.TwoFactorAuthenticationOption === 2) {
				$scope.twoFactor.isEnabled = true;
				$scope.twoFactor.canChange = false;
				$scope.twoFactor.statusText = translationService.translate('web-usergroup-twofactor-enabled', 'Tofaktor er påskrudd fra system');
			} else {
				$scope.twoFactor.canChange = true;
			}
		}

		repository.GetPaginated(repository.apiData.webMenu.url, 0, 1000, { Name: "asc" }, {}, '', JSON.stringify(['Name']))
			.then(function (result) {
                var availableMenus = [];
                availableMenus.push({ text: 'Ingen', guid: null });
				if (result.List.length > 0) {
					result.List.forEach(function (webMenu) {

						if (!webMenu.IsBuildingDependent) {
							availableMenus.push({
								text: webMenu.Name,
								guid: webMenu.Guid
							});
						}
                    });
                }
				$scope.availableStartPages = availableMenus;
				if ($scope.model.GuidWebmenu)
					$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.GuidWebMenu })[0] || availableMenus[0];
			});


		if ($scope.update) {
			repository.getSingle(repository.apiData.user.url, $stateParams.guid).then(function (result) {
				$scope.model = result.Data;
				$scope.reload = true;
				updateAccess();
				setUserAccess($scope.model.AccessType);

				if ($scope.availableStartPages)
					$scope.selectedStartMenu = _.filter($scope.availableStartPages, { guid: $scope.model.GuidWebMenu })[0] || $scope.availableStartPages[0];

				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}, function (error) {
				repository.growl(error, 'danger');
			});
			getCardTables();
		} else {
			$scope.model.IsUserGroup = true;
		}

		$scope.GuidChangeLanguage = function (selectedLanguage) {
			$scope.model.GuidLanguage = selectedLanguage.Guid;
		};

		$scope.changeStartPage = function (selected) {
			$scope.model.GuidWebMenu = selected.guid;
		};

		$scope.removeDataOwner = function (dataOwner) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-usergroup-dataOwner-message', 'Klienten vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-usergroup-dataOwner-button-confirm', 'Ja, fjern klienten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.userProfile.url, dataOwner.Guid)
					.then(function (result) {
						repository.growl(translationService.translate('usergroup-removeDataOwner-Success', 'Klient ble fjernet fra brukergruppen.'), 'success');
						$scope.dataOwnerTable.reload();
						swal.close();
					});
			});
		};

		$scope.deleteUserGroup = function (destination, userGroup) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-usergroup-message', 'Brukergruppen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-usergroup-button-confirm', 'Ja, fjern brukergruppen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.user.url, userGroup.Guid)
					.then(function (result) {
						repository.growl(translationService.translate('delete-usergroup-success-message', 'brukergruppen ble slettet fra systemet'), 'success');
						swal.close();
						$scope.goBack(destination, { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.updateUserGroup = function (destination) {
			$scope.model.AccessType = GetUserAccess();
			console.log($scope.model.AccessType);
			var success = function (result) {
				repository.growl($scope.update ? 'Brukergruppe har blitt oppdatert' : translationService.translate('create-userGroup-success', 'Brukergruppen har blitt registrert.'), 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				repository.growl(error, 'danger');
			};

			if ($scope.update)
				repository.updateSingleDictionary(repository.apiData.user.url, $scope.model).then(success).catch(error);
			else
				repository.createSingleDictionary(repository.apiData.user.url, $scope.model).then(success).catch(error);
		};

		$scope.addDataOwner = function () {
			$modal.open({
				templateUrl: 'app/user/views/editUserDataOwner.html',
				controller: 'CreateUserDataOwnerController',
				resolve: {
					params: function () {
						return {
							guidUser: $scope.model.Guid,
							isFirst: $scope.dataOwnerTable.data.length < 1
						};
					}
				}
			}).result.then(function () {
				$scope.dataOwnerTable.reload();
			});
		};

		$scope.updateDataOwner = function (dataOwner) {
			$modal.open({
				templateUrl: 'app/user/views/editUserDataOwner.html',
				controller: 'EditUserDataOwnerController',
				resolve: {
					params: function () {
						return {
							guidUserProfile: dataOwner.Guid
						};
					}
				}
			}).result.then(function () {
				$scope.dataOwnerTable.reload();
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
