(function () {
	angular.module('Plania').controller('RuleModalController', ['$scope', '$modalInstance', 'Repository', 'params', 'TranslationService', controller]);

	function controller($scope, $modalInstance, repository, params, translationService) {
		$scope.activateAutocomplete = false;

		$scope.availableActions = [
			{ Value: 'Show', icon: 'eye' },
			{ Value: 'Hide', icon: 'eye-off' }
		];


		if (repository.moduleService.hasModule(repository.moduleService.moduleFlags.Deviation)) {
			$scope.availableActions.push({ Value: 'Deviation', icon: 'alert-octagon' });
		}

		if (repository.moduleService.hasModule(repository.moduleService.moduleFlags.Activity)) {
			$scope.availableActions.push({ Value: 'Request', icon: 'email' });
		}

		$scope.operators = ['=', '<>', '>', '<', '>=', '<=']; //'in', 'contains', 'startsWith'
		$scope.parameterColumns = [];

		$scope.controlListItems = params.controlListItems;

		$scope.groupedControlListItems = [];

		var currentSection = null;
		params.controlListItems.forEach(function (controlListItem) {
			if (controlListItem.Type === 'SectionHeader') {
				currentSection = controlListItem.Name;
			} else if (controlListItem.Type !== 'Info') {
				if (currentSection) controlListItem.Section = currentSection;
				$scope.groupedControlListItems.push(controlListItem);
			}
		});

		$scope.selectedFormItem = params.selectedFormItem;

		var parseConditions = function (condition) {
			if (condition && condition.Filter) {
				_.each(condition.Filter,
					function (filter) {
						filter.GuidControlListItem = filter.GuidControlListItem.toLowerCase();
					});
			}
			return condition;
		};

		if (params.rule) {
			$scope.rule = angular.copy(params.rule);
			$scope.rule.Conditions = parseConditions($scope.rule.Conditions);
			$scope.selectedAction = $scope.rule.Action;
			removeAutocompleteLabelValues();
		} else {
			$scope.rule = {
				GuidControlListItem: $scope.selectedFormItem ? $scope.selectedFormItem.Guid : null,
				Action: null,
				Conditions: {
					Filter: [{
						Operator: '='
					}]
				},
				IsMandatory: false,
				Parameters: []
			};
		}

		setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

		function removeAutocompleteLabelValues() {
			$scope.rule.Parameters.forEach(function (parameter) {
				if (parameter.IsExpandable) {
					delete parameter.Value.Label;
				}

				// Data is set from searching in autocomplete. This can contain a lot of data. 
				if (parameter.data)
					delete parameter.data;
			});
		}

		$scope.selectAction = function (action) {
			if (action.Value === $scope.selectedAction) return;

			$scope.selectedAction = action.Value;

			switch ($scope.selectedAction) {
				case 'Request':
					$scope.parameterColumns = requestColumns;
					break;
				case 'Deviation':
					$scope.parameterColumns = deviationColumns;
					break;
			}


			$scope.rule = {
				GuidControlListItem: $scope.selectedFormItem ? $scope.selectedFormItem.Guid : null,
				Action: action.Value,
				Conditions: {
					Filter: [{
						GuidControlListItem: ($scope.selectedAction === 'Deviation' || $scope.selectedAction === 'Request') && $scope.selectedFormItem ? $scope.selectedFormItem.Guid : null,
						Operator: '='
					}]
				},
				IsMandatory:false,
				Parameters: []
			};
		};

		//Get deviation columns for parameter prefill
		var locale = translationService.getLocale();
		var deviationColumns, requestColumns;
		repository.getWithUrlParameter(repository.apiData.webColumns.url, 'domainModel=Deviation_RuleSelection' + '&locale=' + locale).then(function (result) {
			deviationColumns = result;
			if ($scope.rule.Action === 'Deviation')
				$scope.parameterColumns = deviationColumns;
		});

		repository.getWithUrlParameter(repository.apiData.webColumns.url, 'domainModel=Request_RuleSelection' + '&locale=' + locale).then(function (result) {
			requestColumns = result;
			if ($scope.rule.Action === 'Request')
				$scope.parameterColumns = requestColumns;
		});

		$scope.changedParameter = function (parameter) {
			parameter.Value = null;
			var parameterColumn = _.find($scope.parameterColumns, function (o) { return o.Property === parameter.Property; });
			parameter.PropertyType = parameterColumn.PropertyType;
			parameter.IsExpandable = parameterColumn.IsExpandable;
			if (parameter.PropertyType === 'bool') {
				parameter.Value = true;
            }
            if (parameterColumn.GuidProperty)
                parameter.GuidProperty = parameterColumn.GuidProperty;
		};

		$scope.changedAutoCompleteValue = function (value) {
			if (!value)
				value = {};
		};

		$scope.getTypeOf = function (value) {
			if (value === null) {
				value = {};
			}

			return typeof (value);
		};

		$scope.getItemFromGuid = function (guid) {
			if (!guid) return;
			var item = _.find($scope.controlListItems, { Guid: guid.toLowerCase() });
			return item;
		};

		$scope.isGuid = function (stringToTest) {
			if (!stringToTest) return false;

			var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
			return regexGuid.test(stringToTest);
		};

		$scope.ok = function () {
			removeAutocompleteLabelValues();
			$modalInstance.close($scope.rule);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
        };
	}
})();
