(function () {
	angular.module('Plania').controller('EntityTaskController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);
	function controller($scope, repository, $stateParams, translationService) {
		$scope.model = { Guid: $stateParams.guid };

		$scope.apiRequestLogColumns = [
			{ Position: 1, Title: translationService.translate('web-apiRequestLog-creationDate', 'Opprettet dato'), Property: 'CreationDate', PropertyType: 'date' },
			{ Position: 2, Title: translationService.translate('web-apiRequestLog-user-realName', 'Opprettet av'), Property: 'User.RealName', PropertyType: 'string' },
		];

		var columns = [
			"EntityLink.Id", "EntityLink.Description"
		];

		repository.getMainModel(repository.apiData.entityTask.url, $stateParams.guid, JSON.stringify(columns)).then(function (response) {
			$scope.model = response.Data;
		},
		function (error) {
			repository.growl(error, 'danger');
		});


	}
})();
