/*
 * Provides a way to edit image description,
 * If the image is deleted, all references (xrefs) are deleted with it on the API side
 */
(function () {
    angular
        .module('Plania')
        .controller('EditImageController', ['$scope', 'Repository', 'params', 'config', '$modalInstance', 'TranslationService', controller]);

    function controller($scope, repository, params, config, $modalInstance, translationService) {
        $scope.model = angular.copy(params.image);
        $scope.imageApiUrl = config.baseUrlApi + repository.apiData.image.url;
        $scope.deleteAccess = params.deleteAccess;

        $scope.editImage = function () {
            repository.updateSingle(repository.apiData.image.url, $scope.model).then(
                function () {
                    repository.growl('Bilde ble lagret', 'success');
                    $modalInstance.close($scope.model);
                },
                function (error) {
                    repository.growl('Kunne ikke lagre bilde - ' + error.message, 'danger');
                }
            );
        };

        $scope.deleteImage = function () {
            swal({
                title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
                text: translationService.translate('web-swal-image-message', "Bildet vil bli permanent fjernet!"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f44336",
                confirmButtonText: translationService.translate('web-swal-image-button-confirm', 'Ja, fjern bildet'),
                cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
            }, function () {
                window.onkeydown = null;
                window.onfocus = null;

                repository.deleteSingle(repository.apiData.image.url, $scope.model.Guid).then(function (result) {
                    swal(translationService.translate('web-swal-image-success', 'Bildet ble fjernet!'), result, "success");
                    $modalInstance.close('Deleted');

                }, function (error) {
                    repository.growl('Kunne ikke slette bilde - ' + error.message, 'danger');
                });
            });
        };

    }
})();
