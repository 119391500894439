(function () {
	angular.module('Plania').controller('CreateWorkOrderXSparePartController', ['$scope', 'ngTableParams', '$modalInstance', 'params', 'Repository', 'ListService', 'TranslationService', controller]);

	function controller($scope, ngTableParams, $modalInstance, params, repository, listService, translationService) {
		$scope.model = { Quantity: 0 };
		$scope.isUpdate = params.isUpdate;
		$scope.isWithdrawal = params.withdraw;
		$scope.restrictEdit = false;
		$scope.restrictEditMessage = '';
		$scope.isLoaded = false;
		
		$scope.tabs = [
			{ heading: translationService.translate('web-sparepart-chooseSparePart', "Velg reservedel"), step: 1 },
			{ heading: translationService.translate('web-sparepart-setNumber', "Sett antall"), step: 2 }
		];
		
		//Used on sparePartWithdraw
		$scope.modelProperties = { quantityToWithdraw: 0 };

		$scope.title = $scope.isUpdate ? translationService.translate('web-sparepart-edit-title', "Rediger reservedel") : translationService.translate('web-sparepart-new-title', "Legg til ny reservedel");

		$scope.step = $scope.isUpdate ? 2 : 1;

		$scope.setCurrentStep = function (step) {
			$('.tab-content').css('overflow', 'hidden');
			setTimeout(function () {
				$('.tab-content').css('overflow', 'visible');
			}, 500);
			$scope.step = step;
		};

		var sparePartColumns = [
			{ Title: translationService.translate('web-component-id', 'Id'), Property: 'Component.Id' },
			{ Title: translationService.translate('web-component-description', 'Beskrivelse'), Property: 'Component.Description' },
			{ Title: translationService.translate('web-componentXSupplier-partnumber', 'Delenummer'), Property: 'ComponentXSupplier.PartNumber' },
			{ Title: translationService.translate('web-componentXSupplier-Manufacturer', 'Fabrikat'), Property: 'ComponentXSupplier.Manufacturer' },
			{ Title: translationService.translate('web-componentXSupplier-Supplier', 'Leverandør'), Property: 'ComponentXSupplier.Supplier.Description' },
			{ Property: 'ComponentXSupplier.Price', hidden: true },
			{ Property: 'Inventory', hidden: true },
			{ Property: 'Component.Unit', hidden: true },
			{ Property: 'Component.GuidImage', hidden: true }
		];

		$scope.columns = _.filter(sparePartColumns, function (c) { return !c.hidden; });

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		if ($scope.isUpdate) {
			var columns = [
				'SparePart.ComponentXSupplier.PartNumber', 'SparePart.Inventory', 'SparePart.Component.Unit',
				'SparePart.Component.GuidImage', 'SparePart.Component.Id', 'SparePart.Component.Description',
				'WorkOrder.EndDate', 'WorkOrder.Stage', 'WorkOrder.Project.IsCompleted'
			];

			repository.getSingle(repository.apiData.workOrderSparePart.url, params.guid, JSON.stringify(columns))
				.then(
					function (result) {
						$scope.model = result.Data;
						$scope.model.Inventory = result.Data.SparePart.Inventory;
						if (result.Data.SparePart.Component) {
							$scope.model.Unit = result.Data.SparePart.Component.Unit;
							$scope.model.GuidImage = result.Data.SparePart.Component.GuidImage;
						}
						if ($scope.modelProperties) {
							if (params.withdraw) {
								$scope.modelProperties.quantityToWithdraw = $scope.model.Quantity - $scope.model.QuantityWithdrawn;
								$scope.maxValue = $scope.model.Inventory;
							}
							else {
								$scope.modelProperties.quantityToWithdraw = $scope.model.QuantityWithdrawn;
								$scope.maxValue = $scope.model.QuantityWithdrawn;
							}
						}
						$scope.isLoaded = true;
						if ($scope.isUpdate && !$scope.restrictEdit) {
							if ($scope.model.WorkOrder && $scope.model.WorkOrder.Stage === 'History') {
								$scope.restrictEdit = true;
								$scope.restrictEditMessage = translationService.translate('web-workOrderXSparePart-restrictEditMessage-woIsHistory', 'Redigering og sletting er ikke tilgjengelig når arbeidsordren som denne reservedelen er koblet til er hisorisk.');
							} else if ($scope.model.WorkOrder && $scope.model.WorkOrder.EndDate) {
								$scope.restrictEdit = true;
								$scope.restrictEditMessage = translationService.translate('web-workOrderXSparePart-restrictEditMessage-woHasEndDate', 'Redigering og sletting er ikke tilgjengelig når arbeidsordren som denne reservedelen er koblet til er fullført.');
							} else if ($scope.model.WorkOrder && $scope.model.WorkOrder.Project && $scope.model.WorkOrder.Stage === 'Project' && $scope.model.WorkOrder.Project.IsCompleted) {
								$scope.restrictEdit = true;
								$scope.restrictEditMessage = translationService.translate('web-workOrderXSparePart-restrictEditMessage-projectIsCompleted', 'Redigering og sletting er ikke tilgjengelig når prosjektet via tilknyttet arbeidsordre er fullført.');
							} else if ($scope.model.IsInvoiced) {
								$scope.restrictEdit = true;
								$scope.restrictEditMessage = translationService.translate('web-workOrderXSparePart-restrictEditMessage-isInvoiced', 'Redigering og sletting er ikke tilgjengelig fordi denne reservedelen er fakturert.');
							}
						}
					});
		} else {
			$scope.isLoaded = true;
			$scope.sparePartTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: {},
				filter: {}
			},
				{
					total: 0,
					counts: [10, 50, 100],
					paginationMaxBlocks: 8,
					getData: function ($defer, params) {
						var columns = _.map(sparePartColumns, 'Property');


						repository.GetPaginated(repository.apiData.sparePart.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(),
							null,
							JSON.stringify(columns))
							.then(
								function (result) {
									params.total(result.TotalCount);
									$defer.resolve(result.List);
								},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});
		}

		$scope.chooseSparePart = function (item) {
			if ($scope.itemExcluded(item.Guid)) {
				return;
			}

			$scope.model = {
				GuidSparePart: item.Guid,
				Id: item.Component.Id,
				Description: item.Component.Description,
				Inventory: item.Inventory, //transient property for ui
				Unit: item.Component.Unit, //transient property for ui
				GuidImage: item.Component.GuidImage,
				Quantity: 0
			};
			if (item.ComponentXSupplier) {
				$scope.model.PartNumber = item.ComponentXSupplier.PartNumber;
				$scope.model.Price = item.ComponentXSupplier.Price;
			}
			$scope.setCurrentStep(2);
		};

		$scope.itemExcluded = function (guid) {
			return _.contains(params.excludeGuids, guid);
		};

		$scope.save = function () {
			delete $scope.model.Inventory;
			delete $scope.model.Unit;
			delete $scope.model.GuidUmage;

			if ($scope.isWithdrawal !== undefined) {
				if ($scope.isWithdrawal) {
					$scope.model.QuantityWithdrawn = $scope.model.QuantityWithdrawn + $scope.modelProperties.quantityToWithdraw;
				}
				else {
					$scope.model.QuantityWithdrawn = $scope.model.QuantityWithdrawn - $scope.modelProperties.quantityToWithdraw;
				}
			}
			

			$modalInstance.close($scope.model);
		};

		$scope.cancel = function () {
			$modalInstance.close();
		};
	}

})();
