(function () {
	angular.module('Plania').controller('EmailTemplateController', ['$scope', 'Repository', '$stateParams', 'TranslationService', '$modal', controller]);

	function controller($scope, repository, $stateParams, translationService, $modal) {
		$scope.isUpdate = $scope.navigation.current.name === 'emailTemplate.edit';
		$scope.model = {};
		$scope.modelDto = {};
		$scope.activateAutocomplete = false;

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictSave = !repository.authService.hasEditAccess(repository.commonService.prefix.EmailTemplate, checkOtherDo);
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.EmailTemplate, checkOtherDo);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.EmailTemplate, checkOtherDo);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.EmailTemplate);
			}
		};

		updateAccess();

		var encodeHtml = function (html) {
			var tmpElement = document.createElement('div');
			return $(tmpElement).text(html).html();
		};

		var decodeHtml = function (html) {
			var tmpElement = document.createElement('textarea');
			tmpElement.innerHTML = html;
			return tmpElement.value;
		};

		$scope.entityTypes = [
			{ prefix: 'WorkOrder', translation: translationService.translate('web-enum-prefix-workorder') },
			{ prefix: 'PurchaseOrder', translation: translationService.translate('web-enum-prefix-purchaseOrder') },
			{ prefix: 'Request', translation: translationService.translate('web-enum-prefix-Request') },
			{ prefix: 'Project', translation: translationService.translate('web-enum-prefix-Project') }
		];

		var columns = [

		];

		//GET DATA
		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.emailTemplate.url, $stateParams.guid, JSON.stringify(columns)).then(function (response) {
				$scope.model = response.Data;
				$scope.model.TemplateBody = decodeHtml($scope.model.TemplateBody);

				updateAccess();

				$scope.reload = true;
				setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		} else {
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		}

		$scope.update = function (destination) {
			var updateModel = angular.copy($scope.model);
			updateModel.TemplateBody = encodeHtml(updateModel.TemplateBody);

			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-emailTemplate-success', 'Epost-malen har blitt oppdatert.') : translationService.translate('create-emailTemplate-success', 'Epost-malen har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.emailTemplate.url, updateModel).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.emailTemplate.url, updateModel).then(success, error);
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-emailTemplate-message', "Epost-malen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-emailTemplate-button-confirm', 'Ja, fjern Epost-malen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.emailTemplate.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-emailTemplate-success', 'Epost-malen ble fjernet!'), result, "success");
						$scope.goBack('emailTemplate.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.preview = function () {
			var previewModal = $modal.open({
				templateUrl: 'app/emailTemplate/views/emailTemplatePreviewModal.html',
				controller: 'EmailTemplatePreviewModalController',
				size: 'lg',
				resolve: {
					params: function() {
						return {
							prefix: _.find($scope.entityTypes, { prefix: $scope.model.EntityType }).prefix,
							body: $scope.model.TemplateBody,
						};
					}
				}
			});
		};

		$scope.tinymceOptions = {
			language: 'nb_NO',
			branding: false,
			plugins: [
				'advlist autolink lists link image charmap print preview hr anchor pagebreak',
				'searchreplace visualblocks visualchars code codesample lineheight',
				'insertdatetime media nonbreaking save table contextmenu directionality',
				'emoticons template paste textcolor colorpicker textpattern imagetools toc help autoresize'
			],
			toolbar: 'undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright lineheightselect | bullist numlist outdent indent | addProperty addUserProperty addLinkToCurrentObject',
			image_advtab: true,
			relative_urls: false,
			convert_urls: false,
			remove_script_host: false,
			paste_data_images: true,
			autoresize_min_height: 400,
			autoresize_max_height: 600,
			forced_root_block: false,
			lineheight_formats: "14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt",
			templates: [
				{ title: 'Test template 1', content: 'Test 1' },
				{ title: 'Test template 2', content: 'Test 2' }
			],
			inline_styles: true,
			font_formats: 'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Calibri=calibri;Comic Sans MS=comic sans ms,sans-serif;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;',
			setup: function (editor) {

				function getEntityTypePrefix() {
					var entityType = _.find($scope.entityTypes, { prefix: $scope.model.EntityType });
					if (!entityType) return null;
					return entityType.prefix;
				}
				
				editor.addButton('addProperty', {
					icon: 'plus',
					tooltip: 'Sett inn et databasefelt',
					onclick: function () {

						var prefix = getEntityTypePrefix();
						if (!prefix) {
							swal('Ingen entitet valgt', 'Velg en entitet for å kunne legge til en kolonne i malen', 'warning');
							return;
						}

						var modalInstance = $modal.open({
							templateUrl: 'app/common/views/addNewListColumnModal.html',
							controller: 'AddNewListColumnController',
							resolve: {
								params: function () {
									return {
										prefix: prefix,
										existingColumns: []
									};
								}
							}
						});

						modalInstance.result.then(function (selectedColumns) {
							selectedColumns.forEach(function (column) {
								editor.insertContent('{{' + column.Property + '}} ');
							});
						});
					}
				});

				editor.addButton('addUserProperty', {
					icon: 'user',
					tooltip: 'Sett inn felt fra innlogget bruker',
					onclick: function () {
						var modalInstance = $modal.open({
							templateUrl: 'app/common/modal/views/selectUserInfoPropertyModal.html',
							controller: 'SelectUserInfoPropertyModal'
						});

						modalInstance.result.then(function (selectedProperties) {
							selectedProperties.forEach(function (property) {
								editor.insertContent('{{' + property + '}} ');
							});
						});
					}
				});

				editor.addButton('addLinkToCurrentObject',
					{
						icon: 'newtab',
						tooltip: 'Sett inn link til objektet',
						onclick: function () {
							var prefix = getEntityTypePrefix();
							if (!prefix) {
								swal('Ingen entitet valgt', 'Velg en entitet for å kunne legge til link i malen', 'warning');
								return;
							}
							var url = '{{#web_base_url}}/{{Prefix}}/edit/{{Guid}}';
							editor.insertContent('<a target="_blank" href="' + url + '">' + url + ' </a> ');
						}
					});

				//editor.addButton('addLogo', {
				//    icon: 'image',
				//    tooltip: 'Sett inn min Logo',
				//    onclick: function () {
				//        var img = $('<img id="dynamic" >');
				//        img.attr('src', $rootScope.customerLogoSrc);

				//        var imgBase64 = getBase64Image(img[0]);
				//        img.attr('src', imgBase64);

				//        editor.insertContent(img[0].outerHTML);
				//    }
				//});
			}
		};
	}
})();
