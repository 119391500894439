(function () {
	angular.module('Plania').controller('AddExistingVideoController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', 'config', '$filter', controller]);

	function controller($scope, $modalInstance, parentParams, repository, ngTableParams, config, $filter) {

	    $scope.activateAutocomplete = false;

		$scope.documentsTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { id: 'asc' },
			filter: { isBuildingDependent: false }
		},
		{
			total: 0,
			counts: [10, 25, 50],
			paginationMaxBlocks: 5,
			getData: function ($defer, params) {
				repository.GetPaginated(repository.apiData.videoXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter()).then(
					function (result) {
						params.total(result.TotalCount);
						var sortedResult = params.sorting() ? $filter('orderBy')(result.List, params.orderBy()) : result.List;
						$defer.resolve(sortedResult);
						setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

					},
					function (error) {
					    repository.growl(error, 'danger');
					});
			}
		});
		var selectedDocuments = [];

		$scope.saveDocument = function () {
		    var documentXEntities = [];
		    selectedDocuments.forEach(function (guid) {
		        documentXEntities.push({
		            Guid: guid,
		            GuidCleaningTask: parentParams.guidCleaningTask,
		            Entity: -1
		        });
		    });
		    repository.createMultiple(repository.apiData.videoXEntity.url, documentXEntities).then(function (result) {
		        repository.growl("Dokumentene har blitt lagt til", 'success');
		        $modalInstance.close('OK');
		    });
		};

		$scope.addDocument = function (guid) {
		    if (!_.contains(selectedDocuments, guid))
		        selectedDocuments.push(guid);
		    else {
		        for (var i = 0; i < selectedDocuments.length; i++) {
		            var p = selectedDocuments[i];
		            if (p === guid) {
		                selectedDocuments.splice(i, 1);
		                return;
		            }
		        }
		    }
		};

		$scope.isDocumentSelected = function (guid) {
		    return _.contains(selectedDocuments, guid);
		};

		$scope.cancel = function () {
		    $modalInstance.dismiss('canceled');
		};


		$scope.openDocument = function (document) {
		    repository.createSingle(repository.apiData.documentData.url, { GuidDocument: document.Guid }).then(function (result) {
		        var dataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
		        window.open(config.baseUrlApi + repository.apiData.documentData.url + result + '?dataOwner=' + dataOwner, '_blank');
		    });
		};
	}
})();
