angular.module('Plania').directive('gantt', ['TranslationService', function (translationService) {
    return {
        restrict: 'E',
        scope: {
            tasks: '=tasks',
            viewMode: '=viewMode',
            filters: '=filters',
            count: '=count'
        },
        controller: ['$scope', 'Repository', controller],
        templateUrl: '/app/maintenancePlan/gantt.html'
    };

    function controller(scope, repository) {
        var monthArray = ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"];

        scope.$watchGroup(['tasks', 'viewMode'], function (newValues, oldValues, scope) {
            if (newValues === oldValues || !newValues) return;
            generateGantt();
        });

        var getMinDate = function () {
            if (scope.tasks.length < 1) return moment().add(-1, 'days');

            var minDate = moment();

            scope.tasks.forEach(function (task) {
                task.values.forEach(function (value) {
                    if (moment(value.from).isBefore(minDate)) {
                        minDate = moment(value.from);
                    }
                });
            });

            return minDate.add(-1, 'days');
        };

        var getMaxDate = function () {
            if (scope.tasks.length < 1) return moment().add(1, 'days');

            var maxDate = moment();

            scope.tasks.forEach(function (task) {
                task.values.forEach(function (value) {
                    if (moment(value.to).isAfter(maxDate)) {
                        maxDate = moment(value.to);
                    }
                });
            });

            return maxDate.add(1, 'days');
        };

        var generateGantt = function () {
            var interval = 1;

            switch (scope.viewMode) {
                case 'months':
                    interval = 3;
                    break;
                case 'weeks':
                    interval = 2;
                    break;
                case 'days':
                    interval = 1;
                    break;
            }



            var kpi = {
                Prefix: 'WorkOrder',
                FromDate: getMinDate().toISOString(),
                ToDate: getMaxDate().toISOString(),
                DateProperty: 'StartDate',
                IncludePeriodicTask: true,
                Interval: interval,
                PropertyFilter: scope.filters || [],
                Properties: [{
                    Property: 'EstimatedTime',
                    Function: 'sum',
                }, {
                    Property: 'EstimatedCost',
                    Function: 'sum',
                }]
            };


            var stageFilter = _.find(scope.filters, function (o) { return o.Property === 'Stage'; });
            if (!stageFilter)
                kpi.PropertyFilter.push({ Property: 'Stage', Operator: '=', Value: '1' });

            if (repository.commonService.getFilterData().selectedBuilding.Guid)
                kpi.GuidBuilding = repository.commonService.getFilterData().selectedBuilding.Guid;
            if (repository.commonService.getFilterData().selectedSelection.Guid)
                kpi.GuidSelection = repository.commonService.getFilterData().selectedSelection.Guid;

            var columnData = [];

            repository.createSingle(repository.apiData.kpi.url, kpi).then(function (result) {
                columnData = result;

                $(".gantt").gantt({
                    source: scope.tasks,
                    columnData: columnData,
                    scale: scope.viewMode,
                    scrollToToday: true,
                    maxScale: "months",
                    minScale: "days",
                    itemsPerPage: scope.count,
                    months: monthArray,
                    dow: ["S", "M", "T", "O", "T", "F", "L"],
                    waitText: "",
                    useCookie: true,
                    onItemClick: function (data) {
                        var state = data.GuidWorkOrder ? 'workOrder.edit' : 'periodicTask.edit';
                        var guid = data.GuidWorkOrder ? data.GuidWorkOrder : data.GuidPeriodicTask;
                        scope.$root.navigation.go(state, { guid: guid, menuGuid: scope.$root.navigation.params.menuGuid });
                    },
                    onAddClick: function (dt, rowId) {

                    },
                    onRender: function (settings) {
                        $(".gantt-task-controls, .gantt-task-footer").css('display', 'block');
                        $(".gantt-task-controls").height($(".spacer").height());

                        switch (settings.scale) {
                            case "days":
                                $(".row .date").height($(".dataPanel").height() - 24);
                                $('.dataPanel').css("background-position", "24px 25px");
                                break;
                            case "weeks":
                                $(".row .day").height($(".dataPanel").height() - 48);
                                $('.dataPanel').css("background-position", "24px 25px");
                                break;
                            case "months":
                                $(".row.year:not(.data-row)").height($(".dataPanel").height());
                                $('.dataPanel').css("background-position", "24px 1px");
                                break;
                        }
                    }
                });
            }, function (error) {

            });
        };
    }
}]);
