(function () {
	angular.module('Plania').controller('EquipmentComponentController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', '$filter', controller]);

	function controller($scope, $modalInstance, parentParams, repository, ngTableParams, $filter) {
	    var oldCount = 0;

	    if (parentParams.AddComponents) {
	        var countsArray = [];
	        $scope.componentsTable = new ngTableParams({
	            page: 1,
	            count: 10,
	            sorting: { id: 'asc' },
	            filter: { }
	        },
	        {
	            total: 0,
	            counts: countsArray,
	            getData: function($defer, params) {
	                $scope.isLoading = true;
	                repository.GetPaginated(repository.apiData.component.url, params.page() - 1, params.count(), params.sorting(), params.filter(), '', JSON.stringify(['Id', 'Description'])).then(
	                    function(result) {
	                        if (result.totalCount > 10) {
	                            countsArray.push(10);
	                            countsArray.push(20);
	                            countsArray.push(50);
	                        }
	                        $scope.isLoading = false;
	                        params.total(result.TotalCount);
	                        var sortedResult = params.sorting() ? $filter('orderBy')(result.List, params.orderBy()) : result.List;
	                        $defer.resolve(sortedResult);
	                    },
	                    function(error) {
	                        repository.growl(error, 'danger');
	                    });
	            }
	        });

	        var selectedComponents = [];

	        $scope.addComponent = function (guid) {
	            if (!_.contains(selectedComponents, guid))
	                selectedComponents.push(guid);
	            else {
	                for (var i = 0; i < selectedComponents.length; i++) {
	                    var p = selectedComponents[i];
	                    if (p === guid) {
	                        selectedComponents.splice(i, 1);
	                        return;
	                    }
	                }
	            }
	        };

	        $scope.isComponentSelected = function (guid) {
	            return _.contains(selectedComponents, guid);
	        };

	    } else {
	        oldCount = parentParams.component.Count;
	        $scope.model = parentParams.component;
	    }

	    $scope.saveComponents = function () {
	        if (parentParams.AddComponents) {
	            if (selectedComponents.length === 0) {
	                $modalInstance.close('OK');
	                return;
	            }

	            var postData = {
	                GuidEquipment: parentParams.guidEquipment,
	                GuidComponents: selectedComponents,
	            };

	            repository.createSingle(repository.apiData.componentEquipment.url, postData).then(function (result) {
	                repository.growl('Komponentene har blitt lagt til anlegg', 'success');
	                $modalInstance.close('OK');
	            });
	        } else {
	            repository.updateSingle(repository.apiData.componentEquipment.url, $scope.model).then(function (result) {
	                repository.growl('Antall komponenter har blitt lagret', 'success');
	                $modalInstance.close('OK');
	            });
	        }
	    };

	    $scope.cancel = function () {
	        if (!parentParams.AddComponents)
	            parentParams.component.Count = oldCount;
	        $modalInstance.dismiss('canceled');
	    };
	}
})();
