(function () {

	var app = angular.module('Plania');

	app.directive('qrCodeList', function () {
		return {
			restrict: 'E',
			scope: {
				isCollapsed: '=isCollapsed',
				reload: '=reload',
				qrCodes: '=qrCodes',
				disableActions: '=disableActions',
				parentEntity: '=parentEntity',
				guid: '=guid',
				hidePrint: '=hidePrint',
				guidSparePart: '=guidSparePart',
				restrictEdit: '=restrictEdit'
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', '$rootScope', 'TranslationService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/common/directives/views/qrCodeListDirective.html'
		};
	});

	function controller($scope, $modal, ngTableParams, repository, $rootScope, translationService) {
		$scope.isLoading = false;
		$scope.search = {};
		$scope.reloads = {
			reloadQrCode: false
		};

		$scope.hasReadAccess = $rootScope.hasReadAccess('Barcode');

		var columns = ['Id', 'IsDefault', 'UpdatedDate', 'CreationDate'];

		var getQrCodes = function () {
			if (!$scope.guid) return;
			repository.GetPaginated(repository.apiData.barcode.prefix, 0, 50, { 'IsDefault': 'desc', 'CreationDate': 'desc' }, { PropertyFilter: [{ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid }] }, '', JSON.stringify(columns)).then(function (response) {
				$scope.qrCodes = response.List;
				$scope.totalCount = response.TotalCount;
				$scope.reloads.reloadQrCode = true;

				if ($scope.guidSparePart) {
					$scope.printGuids = [$scope.guidSparePart];
				} else {
					$scope.printGuids = [$scope.guid];
				}
			});
		};

		$scope.$watch('reload', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			if ($scope.reload && $scope.reload === true) {
				getQrCodes();
				$scope.reload = false;
			}
		});

		getQrCodes();

		$scope.create = function () {
			var qrCode = {};
			qrCode['Guid' + $scope.parentEntity] = $scope.guid;

			$modal.open({
				templateUrl: 'app/common/modal/views/editQrCodeModal.html',
				controller: 'EditQrCodeModalController',
				resolve: {
					params: function () {
						return {
							isUpdate: false,
							qrCode: qrCode,
							domainName: $scope.parentEntity,
							guidSparePart: $scope.guidSparePart,
							hidePrint:$scope.hidePrint
						};
					}
				}
			}).result.then(function (response) {
				getQrCodes();
			}, function () {
			});
		};

		$scope.generateBarcode = function () {
			var qrCode = {};

			qrCode['Guid' + $scope.parentEntity] = $scope.guid;

			repository.createSingleDictionary(repository.apiData.barcode.url, qrCode).then(function (result) {
				getQrCodes();
				repository.growl('Qr-kode generert', 'success');
			}, function (error) {
				if (error === translationService.translate('web-barcode-not-unique', 'QR-koden må være unik')) {
					repository.growl(translationService.translate('web-barcode-alreadyExists', 'Det finnes allerede en generert qr-kode med samme id, ta kontakt med systemadministrator for å endre format til å sikre unik'), 'danger');
				} else {
					repository.growl(error, 'danger');
				}
			});
		};

		$scope.update = function (qrCode) {
			$modal.open({
				templateUrl: 'app/common/modal/views/editQrCodeModal.html',
				controller: 'EditQrCodeModalController',
				resolve: {
					params: function () {
						return {
							isUpdate: true,
							qrCode: qrCode,
							domainName: $scope.parentEntity,
							guidSparePart: $scope.guidSparePart,
							hidePrint:$scope.hidePrint
						};
					}
				}
			}).result.then(function (response) {
				getQrCodes();
			}, function () {
			});
		};

		$scope.remove = function (item) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-barcode-message', 'QR-koden vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-barcode-button-confirm', 'Ja, fjern qr-koden!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.barcode.url, item.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-barcode-success', 'QR-koden ble fjernet!'), result, "success");
						getQrCodes();
					}, function (error) {
						swal('Error', error, "error");
					});
			});
		};

		$scope.$on($rootScope.events.newSelection, function () {
			getQrCodes();
		});
	}
})();
