(function () {
	angular.module('Plania').controller('MassEditModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', 'EnumService',controller]);

	function controller($scope, $modalInstance, params, repository, translationService, enumService) {
		var entityPrefix = params.prefix;
		$scope.datePickers = {};
		$scope.showPeriodicCleaningRecurrence = params.showPeriodicCleaningRecurrence;

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix[entityPrefix]).then(function (result) {
			$scope.fieldRules = result;
			$scope.columns = [];

			params.columns.slice().forEach(function (column) {
				var columnFieldRule = _.find($scope.fieldRules, function (o) { return o.FieldName === column.Property; });

				if (column.Property === 'UpdatedDate' || column.Property === 'CreationDate') return;
				if (columnFieldRule && (columnFieldRule.Unique || columnFieldRule.NotEditableInListView)) return;

				if (column.PropertyType === 'date') {
					$scope.datePickers[column.Title] = false;
				}

				if (column.Property.indexOf('CleaningQuality.') >= 0) {
					column.PropertyType = 'autocomplete';
					column.AutocompleteProperty = 'GuidCleaningQuality';
					column.AutocompleteService = 'cleaningQuality';
				}

				if ((entityPrefix === repository.apiData.area.prefix) && (column.Property.indexOf('ResourceGroup.') >= 0)) {
					column.PropertyType = 'autocomplete';
					column.AutocompleteProperty = 'GuidCleaningTeam';
					column.AutocompleteService = 'resourceGroup';
				}

				if (column.PropertyType === 'recurrence') {
					column.RecurrenceRule = {};
					if ($scope.showPeriodicCleaningRecurrence) {
						column.RecurrenceRule = {
							StartDate: new Date(),
							Unit: 'Once',
							PeriodNumber: 1,
							PeriodUnit: 'Day'
						};
					} else {
						column.RecurrenceRule = {
							StartDate: new Date(),
							Unit: 'Day'
						};
					}
					$scope.model = column;
				}

				$scope.columns.push(column);
			});
		});

		$scope.selectedRows = params.selectedRows;
		$scope.selectedColumns = [{}];

		$scope.getSelectedRowCaption = function (result) {
			var object = _.find(params.selectedRowsObjects, { Guid: result.Guid });
			if (object.Caption) return object.Caption;

			if (object.Area.Caption) return object.Area.Caption;
			if (object.Building.Caption) return object.Building.Caption;
		};

		$scope.save = function () {
			//areaxcleaningtaskcon
			var editedRows = [];

			$scope.selectedRows.forEach(function (rowGuid) {
				var editedRow = { Guid: rowGuid };
				$scope.selectedColumns.forEach(function (column) {
					if (!column.model) {
						return;
					}

					if (column.model.PropertyType === 'recurrence') {
						if (column.model.RecurrenceRule) {
							enumService.setTimeUnitOnRecurrence(column.model.RecurrenceRule);
							editedRow[column.model.Property] = JSON.stringify(column.model.RecurrenceRule);
						}
					}
					else if (column.model.NewValue || column.model.NewValue === 0) {
						if (column.model.PropertyType === 'string' || column.model.PropertyType === 'number') {
							editedRow[column.model.Property] = column.model.NewValue;
						} else if (column.model.PropertyType === 'autocomplete') {
							editedRow[column.model.AutocompleteProperty] = column.model.newPropertyValue;
						} else if (column.model.PropertyType === 'date') {
							editedRow[column.model.Property] = new Date(column.model.NewValue).toISOString();
						}
					}
				});
				editedRows.push(JSON.stringify(editedRow));
			});

			repository.createMultiple(repository.apiData.massUpdate.url, editedRows, 'Prefix=' + params.prefix).then(function (response) {
				$scope.updateResult = response;

				if (response.length === 0) {
					$modalInstance.close();
				}
			});
		};

		$scope.columnWasSelected = function (column) {
			var index = $scope.columns.indexOf(column);
			$scope.columns.splice(index, 1);
			if ($scope.columns.length > 0) {
				$scope.selectedColumns.push({});
			}
		};

		$scope.removeSelectedColumn = function (column, index) {
			$scope.selectedColumns.splice(index, 1);

			if ($scope.columns.length === 0) {
				$scope.selectedColumns.push({});
			}

			$scope.columns.push(column);
		};

		$scope.cancel = function () {
			if ($scope.updateResult) {
				$modalInstance.close();
			} else {
				$modalInstance.dismiss('canceled');
			}
		};

		var weekdaysToNumber = {
			'Monday': 0,
			'Tuesday': 1,
			'Wednesday': 2,
			'Thursday': 3,
			'Friday': 4,
			'Saturday': 5,
			'Sunday': 6
		};

		var numberToWeekdays = {
			0: 'Monday',
			1: 'Tuesday',
			2: 'Wednesday',
			3: 'Thursday',
			4: 'Friday',
			5: 'Saturday',
			6: 'Sunday'
		};

		$scope.months = [
			{ name: 'Januar', id: 1 },
			{ name: 'Februar', id: 2 },
			{ name: 'Mars', id: 3 },
			{ name: 'April', id: 4 },
			{ name: 'Mai', id: 5 },
			{ name: 'Juni', id: 6 },
			{ name: 'Juli', id: 7 },
			{ name: 'August', id: 8 },
			{ name: 'September', id: 9 },
			{ name: 'Oktober', id: 10 },
			{ name: 'November', id: 11 },
			{ name: 'Desember', id: 12 },
		];

		function convertWeekdayArray(array) {
			var i = 0;
			if (typeof (array[0]) === 'string') {
				for (i; i < array.length; i++) {
					array[i] = weekdaysToNumber[array[i]];
				}
			} else if (typeof (array[0]) === 'number') {
				for (i; i < array.length; i++) {
					array[i] = numberToWeekdays[array[i]];
				}
			}
		}

		function sortNumberArray(a, b) {
			return a - b;
		}

		$scope.toggleWeekDaySelection = function (weekday) {
			var column = _.find($scope.selectedColumns, function (o) { return o.model.PropertyType === 'recurrence'; });

			var array = column.model.RecurrenceRule.ByDay;
			var idx = array.indexOf(weekday);

			if (idx > -1) {
				array.splice(idx, 1);
			} else {
				array.push(weekday);
				convertWeekdayArray(array);
				array.sort(sortNumberArray);
				convertWeekdayArray(array);
			}

			column.model.RecurrenceRule.ByDay = array;
		};

		$scope.onDailySelect = function (value) {
			var column = _.find($scope.selectedColumns, function (o) { return o.model.PropertyType === 'recurrence'; });
			if (value === 1) {
				column.model.RecurrenceRule.Interval = 1;
				column.model.RecurrenceRule.ByDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
			} else {
				column.model.RecurrenceRule.ByDay = [];
			}
		};

		$scope.onUnitSelect = function () {
			var column = _.find($scope.selectedColumns, function (o) { return o.model.PropertyType === 'recurrence'; });
			if (column.model.RecurrenceRule.Unit === 'Day') {
				$scope.onDailySelect($scope.selectedDaily, column);
			}
			column.model.RecurrenceRule.ByMonthDay = [];
			column.model.RecurrenceRule.ByMonth = [];
			column.model.RecurrenceRule.ByDay = [];
		};
	}
})();
