(function () {
	angular.module('Plania').controller('AddExistingDocumentController', ['$scope', '$modalInstance', 'params', 'Repository', 'ngTableParams', 'config', '$filter', 'TranslationService', controller]);

	function controller($scope, $modalInstance, parentParams, repository, ngTableParams, config, $filter, translationService) {

		$scope.activateAutocomplete = false;

		$scope.columns = [
			{ Position: 1, Title: translationService.translate('web-document-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-document-description'), Property: 'Description', PropertyType: 'string' },
		];

		$scope.documentsTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { id: 'asc' },
			filter: { isBuildingDependent: false }
		},
			{
				total: 0,
				counts: [10, 25, 50],
				paginationMaxBlocks: 5,
				getData: function ($defer, params) {
					repository.GetPaginated(repository.apiData.document.url, params.page() - 1, params.count(), params.sorting(), params.filter()).then(
						function (result) {
							params.total(result.TotalCount);
							var sortedResult = params.sorting() ? $filter('orderBy')(result.List, params.orderBy()) : result.List;
							$defer.resolve(sortedResult);
							setTimeout(function () { $scope.activateAutocomplete = true; }, 250);

						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});
		$scope.documentUrl = config.baseUrlApi + 'documentData/';
		$scope.selectedDocuments = [];

		$scope.saveDocument = function () {
			if ($scope.selectedDocuments.length === 0) {
				swal(
					'Ingen valg',
					translationService.translate('web-addExistingDocument-saveDocument-emptySelection', 'Ingen dokumenter valgt. Velg en eller flere rader fra listen for å koble til ekisterende dokument.'),
					'error');
				return;
			}

			var documentXEntities = [];
			$scope.selectedDocuments.forEach(function (guid) {
				documentXEntities.push(JSON.stringify({
					GuidDocument: guid,
					GuidEquipment: parentParams.guidEquipment,
					GuidArea: parentParams.guidArea,
					GuidWorkOrder: parentParams.guidWorkOrder,
					GuidBuilding: parentParams.guidBuilding,
					GuidEstate: parentParams.guidEstate,
					GuidCleaningTask: parentParams.guidCleaningTask,
					GuidCustomer: parentParams.guidCustomer,
					GuidRequest: parentParams.guidRequest,
					GuidPeriodicTask: parentParams.guidPeriodicTask,
					GuidPurchaseOrder: parentParams.guidPurchaseOrder,
					GuidCondition: parentParams.guidCondition,
					GuidDeviation: parentParams.guidDeviation,
					GuidComponent: parentParams.guidComponent,
                    GuidSupplier: parentParams.guidSupplier,
                    GuidProject: parentParams.guidProject,
                    GuidCost: parentParams.guidCost,
                    GuidPaymentOrder: parentParams.guidPaymentOrder,
                    GuidContract: parentParams.guidContract,
					Entity: -1
				}));
			});
			repository.createMultiple(repository.apiData.documentXEntity.url, documentXEntities).then(function (result) {
				repository.growl("Dokumentene har blitt lagt til", 'success');
				$modalInstance.close('OK');
			}).catch(function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.addDocument = function (guid) {
			if (!_.contains($scope.selectedDocuments, guid))
				$scope.selectedDocuments.push(guid);
			else {
				for (var i = 0; i < $scope.selectedDocuments.length; i++) {
					var p = $scope.selectedDocuments[i];
					if (p === guid) {
						$scope.selectedDocuments.splice(i, 1);
						return;
					}
				}
			}
		};

		$scope.isDocumentSelected = function (guid) {
			return _.contains($scope.selectedDocuments, guid);
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('canceled');
		};

		$scope.openDocument = function (document) {
			repository.createSingle(repository.apiData.documentData.url, { GuidDocument: document.Guid }).then(function (result) {
				var dataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;
				window.open(config.baseUrlApi + repository.apiData.documentData.url + result + '?dataOwner=' + dataOwner, '_blank');
			});
		};
	}
})();
