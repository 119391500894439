angular.module('Plania')
	// =========================================================================
	// CALENDAR WIDGET
	// =========================================================================

	.directive('calendarWidget', function ($compile, Repository, $timeout, TranslationService, $rootScope) {
		return {
			restrict: 'A',
			scope: {
				select: '&',
				control: '=',
				edit: '=',
				eventSource: '=',
				viewType: '=',
				editFunction: '=',
				removeFunction: '='
			},
			link: function (scope, element) {
				scope.internalControl = scope.control || {};
				var toolbarHeight = 75;
				var calendarHeight = 360;
				scope.showActive = true;

				//Create and add Action button with dropdown in Calendar header.
				scope.actionLinks = '<ul class="actions actions-alt" id="fc-widget-actions" ng-hide="edit">' +
					'<li class="dropdown" dropdown>' +
					'<a href="" dropdown-toggle><i class="zmdi zmdi-more-vert"></i></a>' +
					'<ul class="dropdown-menu dropdown-menu-right">' +
					'<li>' +
					'<a href="" ng-click="viewType = \'month\'">' + TranslationService.translate('web-calendar-monthview', 'M&aring;nedsvisning') + '</a>' +
					'</li>' +
					'<li>' +
					'<a href="" ng-click="viewType = \'basicWeek\'">' + TranslationService.translate('web-calendar-weekview', 'Ukesvisning') + '</a>' +
					'</li>' +
					'<li>' +
					'<a href="" ng-click="viewType = \'basicDay\'">' + TranslationService.translate('web-calendar-dayview', 'Dagsvisning') + '</a>' +
					'</li>' +
					'<li>' +
					'<a href="" ng-click="showActive = !showActive">' + TranslationService.translate('web-calendar-showhistoric', 'Vis/skjul historisk data') + '</a>' +
					'</li>' +
					'</ul>' +
					'</li>' +
					'</ul>';

				var setHeight = function (height) {
					calendarHeight = height;
					element.fullCalendar('option', 'height', calendarHeight);
					element.fullCalendar('option', 'contentHeight', calendarHeight - toolbarHeight);
				};

				var date = new Date();
				var d = date.getDate();
				var m = date.getMonth();
				var y = date.getFullYear();

				var columns = [
					'StartDate',
					'DueDate',
					'EndDate',
					'Estate.Id',
					'Estate.Description',
					'Building.Id',
					'Building.Description',
					'Equipment.Id',
					'Equipment.Description',
					'Description',
					'Id'
				];

				var setPreviousColour = function (startDate) {
					element.find('.fc-prev-button').css('color', '#fff');

					var workOrderFilter = {
						showActive: true,
						PropertyFilter: [
							{
								Operand: 'AND',
								Property: 'GUID_RESPONSIBLE_PERSON',
								Operator: '=',
								Value: $rootScope.userInfo.guidPerson
							},
							{ Operand: 'AND', Property: 'StartDate', Operator: '<=', Value: startDate.toISOString() },
							{ Operand: 'AND', Property: 'EndDate', Operator: '=', Value: '' },
							{
								Operand: 'OR',
								Property: 'RESOURCE_GROUP.People.GUID',
								Operator: '=',
								Value: $rootScope.userInfo.guidPerson
							},
							{ Operand: 'AND', Property: 'StartDate', Operator: '<=', Value: startDate.toISOString() },
							{ Operand: 'AND', Property: 'EndDate', Operator: '=', Value: '' },
							{
								Operand: 'OR',
								Property: 'WORK_ORDER_X_RESOURCE_GROUP.GUID_RESOURCE_GROUP',
								Operator: '=',
								Value: $rootScope.userInfo.guidPersonResourceGroup
							},
							{ Operand: 'AND', Property: 'StartDate', Operator: '<=', Value: startDate.toISOString() },
							{ Operand: 'AND', Property: 'EndDate', Operator: '=', Value: '' },
							{
								Operand: 'OR',
								Property: 'WORK_ORDER_X_RESOURCE_GROUP.GUID_PERSON',
								Operator: '=',
								Value: $rootScope.userInfo.guidPerson
							},
							{ Operand: 'AND', Property: 'StartDate', Operator: '<=', Value: startDate.toISOString() },
							{ Operand: 'AND', Property: 'EndDate', Operator: '=', Value: '' }
						],
					};

					Repository.GetPaginated(Repository.apiData.workOrder.url,
						0,
						100,
						{ StartDate: 'desc' },
						workOrderFilter,
						null,
						JSON.stringify(columns))
						.then(function (result) {
							if (result.List.length > 0)
								element.find('.fc-prev-button').css('color', '#f44336');
						});


				};
				var language = TranslationService.getLocale();
				if (!language || language === 'nb-no')
					language = 'nb';
				element.fullCalendar({
					theme: true,
					defaultView: scope.viewType,
					eventLimit: true,
					header: {
						right: '',
						center: 'prev, title, next',
						left: ''
					},
					defaultDate: new Date(),
					editable: false,
					lang: language,
					viewRender: function (view, element) {
						setHeight(calendarHeight);
					},
					events: function (start, end, timezone, callback) {
						var startDate = new Date(start);
						var endDate = new Date(end);

						startDate.setMonth(startDate.getMonth() - 1);

						var workOrderFilter = {
							showActive: scope.showActive,
							PropertyFilter: [
								{ Operand: 'AND', Property: 'GUID_RESPONSIBLE_PERSON', Operator: '=', Value: $rootScope.userInfo.guidPerson },
								{ Operand: 'AND', Property: 'StartDate', Operator: '>=', Value: startDate.toISOString() },
								{ Operand: 'AND', Property: 'StartDate', Operator: '<=', Value: endDate.toISOString() },
								{ Operand: 'OR', Property: 'RESOURCE_GROUP.People.GUID', Operator: '=', Value: $rootScope.userInfo.guidPerson },
								{ Operand: 'AND', Property: 'StartDate', Operator: '>=', Value: startDate.toISOString() },
								{ Operand: 'AND', Property: 'StartDate', Operator: '<=', Value: endDate.toISOString() },
								{ Operand: 'OR', Property: 'WORK_ORDER_X_RESOURCE_GROUP.GUID_RESOURCE_GROUP', Operator: '=', Value: $rootScope.userInfo.guidPersonResourceGroup },
								{ Operand: 'AND', Property: 'StartDate', Operator: '>=', Value: startDate.toISOString() },
								{ Operand: 'AND', Property: 'StartDate', Operator: '<=', Value: endDate.toISOString() },
								{ Operand: 'OR', Property: 'WORK_ORDER_X_RESOURCE_GROUP.GUID_PERSON', Operator: '=', Value: $rootScope.userInfo.guidPerson },
								{ Operand: 'AND', Property: 'StartDate', Operator: '>=', Value: startDate.toISOString() },
								{ Operand: 'AND', Property: 'StartDate', Operator: '<=', Value: endDate.toISOString() }
							],
						};

						Repository.GetPaginated(Repository.apiData.workOrder.url, 0, 100, { StartDate: 'desc' }, workOrderFilter, null, JSON.stringify(columns)).then(function (result) {
							var events = [];

							result.List.forEach(function (item) {
								var eventColor = 'bgm-blue';
								if (item.EndDate) {
									eventColor = 'bgm-green';
								}
								if (!item.EndDate && new Date(item.StartDate) < new Date(y, m, d)) {
									eventColor = 'bgm-red';
								}
								if (item.StartDate) {
									var title = '';
									var titleLabel = '';
									if (item.Building) {
										title = item.Building.Id + ' - ' + item.Description;
										titleLabel = TranslationService.translate('web-calendar-building', 'Bygg:');
									}
									else if (item.Estate) {
										title = item.Estate.Id + ' - ' + item.Description;
										titleLabel = TranslationService.translate('web-calendar-estate', 'Eiendom:');
									}
									else {
										title = item.Description;
										titleLabel = TranslationService.translate('web-calendar-workOrder', 'AO:');
									}


									events.push({
										id: item.Guid,
										title: title,
										titleLabel: titleLabel,
										start: new Date(item.StartDate),
										allDay: true,
										className: eventColor,
										data: item
									});
								}
							});
							callback(events);
						});

						setPreviousColour(new Date(start));
					},
					eventRender: function (event, element) {
						var content = '<div>' + event.data.Description + '</div>' +
							'<div class="m-t-10">' + event.titleLabel + ' ' + event.title + '</div>';

						if (event.data.Equipment) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-equipment', 'Anlegg:') + ' ' + event.data.Equipment.Id + ' - ' + event.data.Equipment.Description + '</div>';
						}

						if (event.data.EstimatedTime) {
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-estimatedTime', 'Estimert tid:') + ' ' + event.data.EstimatedTime + '</div>';
						}

						if (event.data.DueDate) {
							var date = new Date(event.data.DueDate);
							var formattedDate = ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
							content = content + '<div class="m-t-10">' + TranslationService.translate('web-calendar-calculatedEndDate', 'Antatt sluttdato:') + ' ' + formattedDate + '</div>';
						}

						$(element).popover({
							placement: 'top',
							trigger: 'mouseenter',
							container: 'body',
							html: true,
							title: '<div>' + TranslationService.translate('web-calendar-id', 'Id:') + ' ' + event.data.Id + '</div>',
							content: content
						});
					},
					eventMouseover: function (event, jsEvent, view) {
						$(this).popover('show');
					},
					eventMouseout: function (event, jsEvent, view) {
						$(this).popover('hide');
					},
					eventClick: function (event, jsEvent, view) {
						$(this).popover('hide');
						scope.$root.navigation.go('workOrder.edit', { guid: event.data.Guid });
					}
				});

				scope.internalControl.resizeCalendar = function (height) {
					setHeight(height);
				};

				scope.$watch('viewType', function (newValue, oldValue) {
					if (newValue === oldValue) return;
					element.fullCalendar('changeView', scope.viewType);
				});

				scope.$watch('showActive', function (newValue, oldValue) {
					if (newValue === oldValue) return;
					element.fullCalendar('refetchEvents');
				});

				$rootScope.$on($rootScope.events.newSelection, function () {
					element.fullCalendar('refetchEvents');
				});

				//Add action links in calendar header
				element.find('.fc-toolbar').append($compile(scope.actionLinks)(scope));
			}
		};
	})

	//Change Calendar Views
	.directive('calendarView', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				element.on('click', function () {
					$('#calendar-widget').fullCalendar('changeView', attrs.calendarView);
				});
			}
		};
	});
