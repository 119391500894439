(function () {
	angular.module('Plania').controller('EquipmentController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'RegisteredFieldService', 'authService', 'TranslationService', 'ListService', '$localStorage', '$rootScope', '$timeout', controller]);

	function controller($scope, repository, $stateParams, ngTableParams, $modal, registeredFieldService, authService, translationService, listService, $localStorage, $rootScope, $timeout) {
		$scope.update = $scope.navigation.current.name === 'equipment.edit';
		$scope.activateAutocomplete = false;
		$scope.components = [];
		$scope.model = { Guid: $stateParams.guid };
		$scope.isDocumentsCollapsed = true;
		$scope.isTextCollapsed = true;
		$scope.isDynamicPropertiesCollapsed = false;
		$scope.isComponentCollapsed = true;
		$scope.reloadDocumentTable = false;
		$scope.reloadOperationalMessages = false;
		$scope.documents = [];
		$scope.viewMode = $scope.update ? true : false;
		$scope.showEntityComment = false;
		$scope.entityComments = [];
		$scope.reloads = {
			deviations: false,
			controlLists: false
        };
        $scope.entityCommentSearch = { searchString: '', focus: false };

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		$scope.toggleEntityComment = function () {
			$scope.showEntityComment = !$scope.showEntityComment;
		};

		$scope.allEntityCommentsRead = function (entityComments) {
			if (!entityComments || entityComments.length === 0) return true;
			return _.every(entityComments, function (comment) {
				return comment.ReadDate || comment.GuidUserCreatedBy === $scope.userInfo.guidUser;
			});
		};

		var updateUserAccess = function () {
			if ($scope.update) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.Equipment, checkOtherDo);
				$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.Equipment, checkOtherDo);
			} else {
				$scope.restrictEdit = !authService.hasCreateAccess(repository.commonService.prefix.Equipment);
			}
		};

		$scope.componentColumns = [
			{ Position: 1, Title: translationService.translate('web-component-id'), Property: 'Component.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-component-description'), Property: 'Component.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-componentCategory-description'), Property: 'Component.ComponentCategory.Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-componentxequipment-quantity', 'Antall'), Property: 'Quantity', PropertyType: 'string' },
		];


		$scope.workOrderColumns = [
			{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 1, Title: translationService.translate('web-workorder-type'), Property: 'Type', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-equipment-resourcegroup-description', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
			{ Position: 6, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
			{ Position: 7, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' },
		];

		$scope.periodicTaskColumns = [
			{ Position: 1, Title: translationService.translate('web-periodicTask-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidBuilding', AutocompleteService: 'building' },
			{ Position: 5, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
			{ Position: 5, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidEquipment', AutocompleteService: 'equipment' },
			{ Position: 7, Title: translationService.translate('web-periodicTask-description'), Property: 'Description', PropertyType: 'string' },
		];

		$scope.conditionColumns = [
			{ Position: 1, Title: translationService.translate('web-condition-Description'), Property: 'Condition1.Description', PropertyType: 'string' },
			{ Position: 1, Title: translationService.translate('web-condition-conditionType'), Property: 'ConditionType.Description', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-condition-Workorder-Id', "Arbeidsordre Id"), Property: 'WorkOrder.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-condition-Workorder-Description', "Arbeidsordre"), Property: 'WorkOrder.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-condition-CreatedBy', "Opprettet av"), Property: 'UserCreatedBy.RealName', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-condition-CreationDate', "Opprettet"), Property: 'CreationDate', PropertyType: 'date' },
		];

		$scope.equipmentHourColumns = [
			{ Position: 1, Title: translationService.translate('web-EquipmentOperatingHours-Value', 'Verdi'), Property: 'Value', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-EquipmentOperatingHours-EquipmentOperatingHourType-id', 'Type'), Property: 'EquipmentOperatingHourType.Id', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-EquipmentOperatingHours-ReadDate', 'Registrert dato'), Property: 'ReadDate', PropertyType: 'date' },
			{ Position: 4, Title: translationService.translate('web-EquipmentOperatingHours-CreationDate', 'Opprettet'), Property: 'CreationDate', PropertyType: 'date' },
			{ Position: 5, Title: translationService.translate('web-EquipmentOperatingHours-CreatedBy', 'Opprettet av'), Property: 'User1.RealName', PropertyType: 'string' },
		];

		registeredFieldService.getFieldRule(repository.commonService.prefix.equipmentOperatingHours, 'GuidEquipmentOperatingHourType').then(function (result) {
			if (result.Hidden || (authService.getUserData().isExternalUser && result.ExternallyHidden)) {
				$scope.equipmentHourColumns = [
					{ Position: 1, Title: translationService.translate('web-EquipmentOperatingHours-Value', 'Verdi'), Property: 'Value', PropertyType: 'string' },
					{ Position: 2, Title: translationService.translate('web-EquipmentOperatingHours-ReadDate', 'Registrert dato'), Property: 'ReadDate', PropertyType: 'date' },
					{ Position: 3, Title: translationService.translate('web-EquipmentOperatingHours-CreationDate', 'Opprettet'), Property: 'CreationDate', PropertyType: 'date' },
					{ Position: 4, Title: translationService.translate('web-EquipmentOperatingHours-CreatedBy', 'Opprettet av'), Property: 'User1.RealName', PropertyType: 'string' },
				];
			}
		});

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		$scope.componentMenuOptions = function (componentXEquipment) {
			var options = [];

			if ($scope.hasReadAccess('Component')) {
				options.push(getMenuLink($rootScope.navigation.href('component.edit', { guid: componentXEquipment.GuidComponent }), 'zmdi-arrow-right zmdi-hc-fw zmdi-hc-lg', translationService.translate('web-button-goTo', 'Gå til')));
			}

			if ($scope.hasEditAccess('ComponentXEquipment')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-button-edit', 'Rediger') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.editComponent(componentXEquipment);
					}
				});
			}

			if ($scope.hasDeleteAccess('ComponentXEquipment')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-button-delete', 'Slett') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.removeComponent(componentXEquipment);
					}
				});
			}

			return options;
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.selectedOperatingHourTypeFilter = { Guid: undefined, Id: 'Alle' };

		$scope.setOperatingHourTypeFilter = function (type) {
			$scope.selectedOperatingHourTypeFilter = type;
			$scope.EquipmentOperatingHoursTable.reload();
		};

		var GetOperatingHourTypeFilters = function () {
			repository.GetPaginated(repository.apiData.equipmentOperatingHourType.url,
				0,
				100,
				{ id: 'asc' },
				{
					PropertyFilter: [
						{ Property: 'EquipmentOperatingHours.GuidEquipment', Operator: '=', Value: $scope.model.Guid }
					]
				},
				"",
				JSON.stringify(['Id'])).then(function (result) {
					$scope.equipmentOperatingHourTypes = result.List;
					$scope.equipmentOperatingHourTypes.unshift({ Guid: null, Id: 'Uten type' });
					$scope.equipmentOperatingHourTypes.unshift({ Guid: undefined, Id: 'Alle' });
				});
		};

		var getCardTables = function () {
			$scope.componentTable = new ngTableParams(
				{
					page: 1,
					count: 5,
					sorting: { 'Component.Id': 'asc' },
					filter: { GuidEquipment: $scope.model.Guid }
				},
				{
					total: 0,
					filterDelay: 10,
					counts: [5, 10, 20],
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						if (!params.filter().GuidEquipment)
							return;

						var columns = [];
						$scope.componentColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						columns.push('Note');
						columns.push('Component.Guid');
						columns.push('Component.GuidImage');

						repository.GetPaginated(repository.apiData.componentEquipment.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns))
							.then(function (result) {
								$scope.components = result.List;
								$scope.componentTable.settings().total = result.TotalCount;
								$scope.componentTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});

			$scope.conditionTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { CreationDate: 'desc' },
					filter: { PropertyFilter: [{ Property: "GuidEquipment", Operator: "=", value: $scope.model.Guid }, { Property: "GuidConditionType", Operator: "<>", value: null }] }
				},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = [];
						$scope.conditionColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						columns.push('WorkOrder.Guid');

						repository.GetPaginated(repository.apiData.condition.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.conditionTable.settings().total = result.TotalCount;
								$scope.conditionTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});

			GetOperatingHourTypeFilters();

			$scope.EquipmentOperatingHoursTable = new ngTableParams(
				{
					page: 1,
					count: 10,
					sorting: { ReadDate: 'desc' },
					filter: {}
				},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = [];
						$scope.equipmentHourColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						var filter = params.filter();
						filter.PropertyFilter = [{ Property: "GuidEquipment", Operator: "=", value: $scope.model.Guid }];
						if ($scope.selectedOperatingHourTypeFilter.Guid !== undefined) {
							filter.PropertyFilter.push({
								Property: "GuidEquipmentOperatingHourType",
								Operator: "=",
								value: $scope.selectedOperatingHourTypeFilter.Guid
							});
						}

						repository.GetPaginated(repository.apiData.equipmentOperatingHours.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(),
							"",
							JSON.stringify(columns))
							.then(function (result) {
								$scope.EquipmentOperatingHoursTable.settings().total = result.TotalCount;
								$scope.EquipmentOperatingHoursTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});

			var ptFilter = {
				PropertyFilter: [{ Property: 'GuidEquipment', Operator: '=', Value: $scope.model.Guid }]
			};

			$scope.periodicTaskTable = new ngTableParams({ page: 1, count: 20, sorting: { 'Id': 'desc' }, filter: ptFilter },
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {
						var columns = [];
						$scope.periodicTaskColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.periodicTask.url, params.page() - 1, params.count(), params.sorting(), params.filter(), '', JSON.stringify(columns))
							.then(function (result) {
								$scope.periodicTaskTable.settings().total = result.TotalCount;
								$scope.periodicTaskTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});
		};


		var columns = [
			'Building.Id', 'Building.Description', 'Building.Guid',
			'Building.Estate.Id', 'Building.Estate.Description', 'Building.Estate.Guid',
			'CostCenter.Id', 'CostCenter.Description',
			'EquipmentCategory.Description',
			'Supplier.Description',
			'Area.Id', 'Area.Description', 'Area.Guid',
			'Account.Description',
			'Person.Guid', 'Person.FirstName', 'Person.LastName'
		];
		if ($scope.update) {
			repository.getMainModel(repository.apiData.equipment.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					var eq = response.Data;

					$scope.model = eq;

					updateUserAccess();
					$scope.reloads.deviations = true;
					$scope.reloads.controlLists = true;
					$scope.reloadDocumentTable = true;
					$scope.reloadOperationalMessages = true;

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});

			getCardTables();
		} else {
			var selectedBuilding = repository.commonService.getFilterData().selectedBuilding;
			if (selectedBuilding.Guid) {
				$scope.model.GuidBuilding = selectedBuilding.Guid;
				$scope.model.Building = { Caption: selectedBuilding.Name };
			}

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'Building':
								$scope.model.Building = { Caption: prefill.Value.Label };
								$scope.model.GuidBuilding = prefill.Value.Guid;
								break;
							case 'EquipmentCategory':
								$scope.model.EquipmentCategory = { Id: prefill.Value.Label, Caption: prefill.Value.Label };
								$scope.model.GuidEquipmentCategory = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			if ($stateParams.guidEntityToCopy) {
				repository.getSingle(repository.apiData.equipment.url, $stateParams.guidEntityToCopy).then(function (result) {
					delete result.Data.Guid;

					$scope.model = result.Data;
					$scope.model.Id += '-N';

					$scope.activateAutocomplete = false;
					$timeout(function () { $scope.activateAutocomplete = true; }, 100);
				});
			}

			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('equipment.create');

				if (persistentData) {
					$scope.model.GuidBuilding = persistentData.GuidBuilding;
					$scope.model.Building = typeof (persistentData.Building) === 'string' ? { Caption: persistentData.Building } : persistentData.Building;

                    // Just in case the guidArea and area with lowercase is used elsewhere
                    $scope.model.GuidArea = persistentData.guidArea ? persistentData.guidArea : persistentData.GuidArea;
                    var persistentArea = persistentData.area ? persistentData.area : persistentData.Area;
                    $scope.model.Area = typeof (persistentArea) === 'string' ? { Caption: persistentArea } : persistentArea;

					$scope.model.GuidEquipmentGroup = persistentData.GuidEquipmentGroup;
					$scope.model.EquipmentGroup = typeof (persistentData.EquipmentGroup) === 'string' ? { Caption: persistentData.EquipmentGroup } : persistentData.EquipmentGroup;
				}
			}

			$scope.activateAutocomplete = true;
		}

		$scope.sendApprovalRequest = function () {
			swal({
				title: translationService.translate('web-swal-equipment-approval-title',
					'Vil du sende anlegget til godkjenning?'),
				text: translationService.translate('web-swal-equipment-approval-message',
					'Anlegget vil bli markert "avventer godkjenning"'),
				type: "info",
				showCancelButton: true,
				confirmButtonColor: "#337ab7",
				confirmButtonText: translationService.translate('web-swal-equipment-button-confirm-approval',
					'Ja, send til godkjenning!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			},
				function () {
					window.onkeydown = null;
					window.onfocus = null;

					var postModel = angular.copy($scope.model);
					postModel.RequestAction = 'SetPendingApproval';
					var success = function (result) {
						repository.growl(translationService.translate('web-equipment-approval-sent-confirmation', 'Anlegg har blitt sendt til godkjenning!'), 'success');
						$scope.model.DataAcquisitionStatus = result.DataAcquisitionStatus;
					};
					var updateError = function (result) {
						repository.growl(result, 'danger');
					};
					repository.updateSingleDictionary(repository.apiData.equipment.url, postModel)
						.then(success, updateError);
				});
		};

		$scope.updateEquipment = function (destination) {
			var success = function (result) {
				repository.growl($scope.update ?
					translationService.translate('update-equipment-success', 'Anlegg har blitt oppdatert.') :
					translationService.translate('create-equipment-success', 'Anlegg har blitt opprettet.'), 'success');

				var equipmentGuid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, equipmentGuid);
				if (destination === 'equipment.create') {
					repository.persistedData.setPersistedData(destination, { guidBuilding: $scope.model.GuidBuilding, building: $scope.model.Building });
					$scope.navigation.go(destination, { showPersistantData: true, menuGuid: $scope.navigation.params.menuGuid }, { reload: true });
				} else {
					repository.persistedData.clearPersistedData();
					$scope.goBack(destination, { guid: equipmentGuid, menuGuid: $scope.navigation.params.menuGuid });
				}
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.update) {
				repository.updateSingleDictionary(repository.apiData.equipment.url, $scope.model).then(success).catch(error);
			} else
				repository.createSingleDictionary(repository.apiData.equipment.url, $scope.model).then(success).catch(error);
		};


		$scope.onEquipmentCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidEquipmentCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidEquipmentCategory = category.Guid;
		};

		$scope.onEquipmentSelect = function (equipment) {
			if (!equipment) {
				$scope.model.GuidEquipment = "";
				return;
			}
			$scope.model.GuidEquipmentGroup = equipment.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "") {
				$scope.model.GuidBuilding = equipment.GuidBuilding;
				$scope.model.Building = equipment.Building;
			}

			if (equipment.GuidArea) {
				$scope.model.GuidArea = equipment.GuidArea;
				$scope.model.Area = equipment.Area;
			}
		};

		$scope.autoCompleteFilter = function (filterName) {
			switch (filterName) {
				case "equipment":
					var filter = {
						GuidBuilding: $scope.model.GuidBuilding,
						RestrictHierarchy: true,
						PropertyFilter: [{ Property: 'IS_EQUIPMENT_GROUP', Operator: '=', Value: '1' }]
					};

					if ($scope.update) {
						filter.PropertyFilter.push({ Property: 'GUID', Operator: '<>', Value: $scope.model.Guid });
					}

					return filter;
				case "area":
					if ($localStorage.generalOptions.EqMustBeLocatedInOwnBld)
						return { PropertyFilter: [{ Property: 'GUID_BUILDING', 'operator': '=', Value: $scope.model.GuidBuilding }] };
                    return {};
                case "person":
                    return { PropertyFilter: [{ Property: 'IsOperationsManager', Value: true, Operator: '=' }] };
			}
			return null;
		};

		$scope.cancel = function () {
			repository.commonService.setLastRegisterGuid('equipment.list', $scope.model.Guid);
			$scope.goBack('equipment.list', { menuGuid: $scope.navigation.params.menuGuid });
		};

		$scope.removeEquipment = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-equipment-message', 'Anlegget vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-equipment-button-confirm', 'Ja, slett anlegget!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.equipment.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-equipment-success', 'Anlegget ble fjernet!'), result, "success");
						repository.growl(result, 'success');
						$scope.goBack('equipment.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.editComponent = function (component) {
			$modal.open({
				templateUrl: 'app/equipment/views/editComponentEquipment.html',
				controller: 'EquipmentComponentController',
				resolve: {
					params: function () { return { component: component, AddComponents: false }; }
				}
			}).result.then(function () {
				$scope.componentTable.reload();
				$scope.isComponentCollapsed = false;
			},
				function () {
					//cancel
					$scope.isComponentCollapsed = false;
				});
		};

		$scope.addOperatingHours = function () {
			$modal.open({
				templateUrl: 'app/equipment/views/equipmentOperatingHoursModal.html',
				controller: 'EquipmentOperatingHoursController',
				size: 'sm',
				resolve: {
					params: function () { return { GuidEquipment: $scope.model.Guid, ReadDate: new Date() }; }
				}
			}).result.then(function () {
				$scope.EquipmentOperatingHoursTable.reload();
				GetOperatingHourTypeFilters();

			},
				function () {
					//cancel
				});
		};

		$scope.editEquipmentOperatingHour = function (equipmentOperatingHour) {
			$modal.open({
				templateUrl: 'app/equipment/views/equipmentOperatingHoursModal.html',
				controller: 'EquipmentOperatingHoursController',
				size: 'sm',
				resolve: {
					params: function () { return equipmentOperatingHour; }
				}
			}).result.then(function () {
				$scope.EquipmentOperatingHoursTable.reload();
				GetOperatingHourTypeFilters();

			},
				function () {
					//cancel
				});
		};

		$scope.removeEquipmentOperatingHour = function (equipmentOperatingHour) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-equipment-equipmentOperatingHour-message',
					'Driftstimene vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-equipment-equipmentOperatingHour-button-confirm',
					'Ja, fjern driftstimene!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			},
				function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.equipmentOperatingHours.url, equipmentOperatingHour.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-equipment-equipmentOperatingHour-success',
								'Driftstimene ble fjernet!'),
								result,
								"success");
							$scope.EquipmentOperatingHoursTable.reload();
							GetOperatingHourTypeFilters();

						});
				});
		};

		$scope.addComponents = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Legg til komponent',
							columns: [
								{ Title: 'Id', Property: 'Id' },
								{ Title: 'Beskrivelse', Property: 'Description' }
							],
							url: repository.apiData.component.url,
							sorting: { Id: 'asc' },
							filter: {}
						};
					}
				}
			}).result.then(function (selectedComponents) {
				var postData = {
					GuidEquipment: $scope.model.Guid,
					GuidComponents: selectedComponents,
				};

				repository.createSingle(repository.apiData.componentEquipment.url, postData).then(function (result) {
					repository.growl('Komponentene har blitt lagt til anlegg', 'success');
					$scope.componentTable.reload();
				});

			}, function () {
				//cancel
			});
		};

		$scope.removeComponent = function (component) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-equipment-component-message', 'Komponenten vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-equipment-component-button-confirm', 'Ja, fjern komponenten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.componentEquipment.url, component.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-equipment-component-success', 'Komponenten ble fjernet!'), result, "success");
						$scope.componentTable.reload();
					});
			});
		};

		$scope.createPeriodicTask = function () {
			repository.persistedData.setPersistedData('periodicTask.create',
				{
					guidEstate: $scope.model.Building ? $scope.model.Building.Estate.Guid : null,
					estate: $scope.model.Building ? $scope.model.Building.Estate.Caption : '',
					guidBuilding: $scope.model.GuidBuilding,
					building: $scope.model.Building ? $scope.model.Building.Caption : '',
					guidEquipment: $scope.model.Guid,
					equipment: $scope.model.Id + ' - ' + $scope.model.Description,
					guidArea: $scope.model.GuidArea,
					area: $scope.model.Area ? $scope.model.Area.Caption : '',
					fromEquipment: true
				});
			$scope.navigation.go('periodicTask.create', { showPersistantData: true }, { reload: true });
		};

		$scope.createWorkOrder = function () {
			repository.persistedData.setPersistedData('workOrder.create',
				{
					guidEstate: $scope.model.Building ? $scope.model.Building.Estate.Guid : null,
					estate: $scope.model.Building ? $scope.model.Building.Estate.Caption : '',
					guidBuilding: $scope.model.GuidBuilding,
					guidEquipment: $scope.model.Guid,
					building: $scope.model.Building ? $scope.model.Building.Caption : '',
					guidArea: $scope.model.GuidArea,
					area: $scope.model.Area ? $scope.model.Area.Caption : '',
					equipment: $scope.model.Id + ' - ' + $scope.model.Description,
					fromEquipment: true
				});
			$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};

		$scope.equipmentHoursMenuOptions = function (equipmentHours) {
			var options = [];

			if ($scope.hasEditAccess('EquipmentOperatingHours')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-button-edit', 'Rediger') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.editEquipmentOperatingHour(equipmentHours);
					}
				});
			}

			if ($scope.hasDeleteAccess('EquipmentOperatingHours')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-button-delete', 'Slett') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.removeEquipmentOperatingHour(equipmentHours);
					}
				});
			}

			return options;
		};

		$scope.$on($scope.events.newSelection, function () {
			updateUserAccess();
		});
	}
})();
