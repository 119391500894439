(function () {

    angular.module('Plania').controller('SelectedDataOwnerController', ['$scope', '$rootScope', 'Repository', function controller($scope, $rootScope, repository) {
        $scope.count = 10;
        $scope.totalCount = 0;
        $scope.filter = {
            includeAllDataOwners: "false"
        };

        var columns = ['Id', 'Description','Dimension1','DocumentType.Id','DocumentCategory1.Description'];

        $scope.getDataOwners = function () {
            repository.GetPaginated(repository.apiData.dataOwner.url, 0, $scope.count, { id: "asc" }, $scope.filter, repository.commonService.getFilterData().selectedDataOwner.Guid, JSON.stringify(columns)).then(
                function (result) {
                    $scope.dataOwners = result.List;
                    $scope.totalCount = result.TotalCount;

                    if (repository.commonService.getFilterData().selectedDataOwner.Guid !== '' && !$scope.filter.Dropdown) {
                        $scope.selectedDataOwner = _.filter($scope.dataOwners, { Guid: repository.commonService.getFilterData().selectedDataOwner.Guid })[0];
                        repository.commonService.setDataOwner($scope.selectedDataOwner);
                        $rootScope.$broadcast($rootScope.events.updateMenu, true);
                    }
                },
                function (error) {
                    repository.growl(error, 'danger');
                });
        };

        $scope.getDataOwners();
        $scope.selectedDataOwner = repository.commonService.getFilterData().selectedDataOwner;

        $scope.addMoreData = function () {
            $scope.count += 100;
            $scope.getDataOwners();
        };

        $scope.changeDataOwner = function (owner) {
            if (owner.Guid === $scope.selectedDataOwner.Guid) {
                return;
            }
            repository.commonService.setDataOwner(owner);
            repository.commonService.setEmptyBuilding();
            if (repository.authService.hasReadAccess(repository.commonService.prefix.BuildingSelection))
                repository.commonService.setEmptySelection();

            $rootScope.$broadcast($rootScope.events.updateMenu, true);
            $rootScope.$broadcast($rootScope.events.newSelection, true);
        };

        $scope.$on($scope.events.newSelection, function () {
            $scope.selectedDataOwner = repository.commonService.getFilterData().selectedDataOwner;
            $scope.getDataOwners();
        });
    }
    ]);
})();
