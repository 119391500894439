(function () {
    angular.module('Plania').controller('ViewPdfDocumentController', ['$scope', 'Repository', 'config', controller]);

    function controller($scope, repository, config) {
        $scope.isLoaded = false;

        var menuParams = {};
        if ($scope.navigation.params.menuGuid)
            menuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);

        var guidDocument = menuParams.GuidDocument;

        repository.createSingle(repository.apiData.documentData.url, { GuidDocument: guidDocument })
            .then(function(result) {
                var dataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid;

                $scope.documentUrl = config.baseUrlApi + repository.apiData.documentData.url + result + '?dataOwner=' + dataOwner;
                $scope.isLoaded = true;
            });
    }

})();
