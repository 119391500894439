(function () {
	angular.module('Plania').controller('ControlListController', ['$scope', 'Repository', '$stateParams', '$modal', 'ngTableParams', 'TranslationService', '$q', 'ListService', 'ScrollService', '$rootScope', 'SignalR', controller]);

	function controller($scope, repository, $stateParams, $modal, ngTableParams, translationService, $q, listService, scrollService, $rootScope, signalR) {
		$scope.isUpdate = $scope.navigation.current.name === 'controlList.edit';
		$scope.model = {};
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.model.ControlListItems = [];
		$scope.activateAutocomplete = false;
		$scope.columns = []; // for conditions
		$scope.datePickers = {};

		$scope.open = function ($event, calendar) {
			$event.preventDefault();
			$event.stopPropagation();

			$scope.datePickers[calendar] = !$scope.datePickers[calendar];
		};

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var copyOfSelectedItem = {};

		//Uncomment to simulate not having module
		//$scope.hasModule = function () { return false; };

		var updateAccess = function () {
			$scope.restrictCreatePeriodicTask = $rootScope.hasCreateAccess('ControlListXEntity');
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.ControlList, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ControlList, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.ControlList);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.ControlList);
			}
		};

		updateAccess();

		//This list is also hard-coded in ControlListPrint.cs because of color format issues, so any changes here should be reflected there.
		$scope.availableColors = ['#2196F3', '#673AB7', '#009688', '#4caf50', '#ff9800', '#00bcd4', '#9E9E9E', '#607D8B', '#000000', '#f44336'];

		var defaultRadioOptions = [
			{ Value: translationService.translate('web-controllist-answer-Yes', 'Ja') },
			{ Value: translationService.translate('web-controllist-answer-No', 'Nei') },
			{ Value: translationService.translate('web-controllist-answer-NotApplicable', 'Ikke aktuelt') }
		];

		var defaultChecklistOptions = [
			{ Value: translationService.translate('web-controllist-answer-Ok', 'Ok') },
			{ Value: translationService.translate('web-controllist-answer-NotOk', 'Ikke Ok') },
			{ Value: translationService.translate('web-controllist-answer-NotApplicable', 'Ikke aktuelt') }
		];

		$scope.fieldTypeItems = [
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-RadioButton', 'Radioknapper'), Type: 'RadioButton', Data: { Options: defaultRadioOptions }, icon: 'dot-circle', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Combo', 'Nedtrekksfelt'), Type: 'Combo', Data: { Options: defaultRadioOptions }, icon: 'caret-down-circle', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Text', 'Kort svar'), Type: 'Text', icon: 'text-format', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-TextArea', 'Utfyllende svar'), Type: 'TextArea', icon: 'text-format', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Numeric', 'Nummer'), Type: 'Numeric', icon: 'collection-item-1', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-CheckBox', 'Sjekkboks'), Type: 'CheckBox', icon: 'check-square', disabled: !$scope.hasModule('ControlList') },
			{ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Date', 'Dato'), Type: 'Date', icon: 'calendar', disabled: !$scope.hasModule('ControlList') }
		];

		if ($scope.hasModule('Deviation')) {
			$scope.fieldTypeItems.unshift({ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Checklist', 'Sjekklistepunkt'), Type: 'Checklist', icon: 'format-list-bulleted', disabled: false, IsMandatory: !$scope.hasModule('ControlList'), Data: { Options: defaultChecklistOptions } });
		}

		$scope.fieldTypeItems.unshift({ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-Info', 'Beskrivelse'), Type: 'Info', icon: 'info', Data: { Description: 'Informasjonstekst' }, disabled: !$scope.hasModule('ControlList') });
		$scope.fieldTypeItems.unshift({ Guid: null, Name: translationService.translate('web-enum-controllistitemtype-SectionHeader', 'Seksjonstittel'), Type: 'SectionHeader', icon: 'font', disabled: !$scope.hasModule('ControlList') });

		$scope.formItems = [];

		$scope.selectedFormItem = null;

		$scope.setSelectedFormItem = function (item) {
			saveCurrentItem();
			$scope.selectedFormItem = item;
			$scope.reloadFormElements = true;
			copyOfSelectedItem = angular.copy($scope.selectedFormItem);
		};

		$scope.setSelectedFormItemFromGuid = function (guidControlListItem) {
			saveCurrentItem();
			$scope.selectedFormItem = _.find($scope.model.ControlListItems, function (o) {
				return o.Guid.toLowerCase() === guidControlListItem.toLowerCase();
			});
			$scope.scrollToItem($scope.selectedFormItem);
			copyOfSelectedItem = angular.copy($scope.selectedFormItem);
		};

		$scope.isRelatedToSelectedItem = function (item) {
			if (!$scope.selectedFormItem || item.Guid === $scope.selectedFormItem.Guid) return false;

			var itemRules = _.filter($scope.model.ControlListRules, { GuidControlListItem: item.Guid });
			var selectedItemRules = _.filter($scope.model.ControlListRules, { GuidControlListItem: $scope.selectedFormItem.Guid });

			if (!selectedItemRules && !itemRules) return false;

			var result = false;

			itemRules.forEach(function (rule) {
				if (_.filter(rule.Conditions.Filter, { GuidControlListItem: $scope.selectedFormItem.Guid }).length > 0) result = true;
			});

			selectedItemRules.forEach(function (rule) {
				if (_.filter(rule.Conditions.Filter, { GuidControlListItem: item.Guid }).length > 0) result = true;
			});

			return result;
		};

		var newVersionSwal = false;

		var showNewVersionGenerationWarning = function () {
			if ($scope.model.RequireNewVersion) {
				swal({
					title: translationService.translate('web-swal-controlList-newVersion-header',
						'Genererer ny revisjon'),
					text: translationService.translate('web-swal-controlList-newVersion-message',
						"Vennligst vent med flere oppdateringer frem til denne advarselen forsvinner!"),
					type: "warning",
					buttons: false,
				});
				newVersionSwal = true;
			}
		};

		var updateModelWithCurrentVersion = function (updateVersionNumber, result) {

			if (updateVersionNumber && result === undefined) {
				result = { ControlList: angular.copy($scope.model) };
				result.ControlList.Version += 1;
			}

			if (result && result.ControlList && result.ControlList.Version !== $scope.model.Version) {
				if ($scope.model.IsCurrentVersion && result.ControlList.IsCurrentVersion) {
					$scope.model.Version = result.ControlList.Version;
					$scope.model.IsChangesSlow = false;
					$scope.model.RequireNewVersion = false;
				}
			}

			if (newVersionSwal) {
				setTimeout(function () {
					swal.close();
				}, 2000);
				newVersionSwal = false;
			}
		};

		var saveCurrentItem = function (thenFunction) {
			if ($scope.selectedFormItem && !angular.equals($scope.selectedFormItem, copyOfSelectedItem)) {
				var objectToSave = angular.copy($scope.selectedFormItem);
				if (objectToSave.Data) objectToSave.Data = JSON.stringify(objectToSave.Data);

				if (objectToSave.ControlListLogItem && objectToSave.ControlListLogItem.Id) {
					objectToSave.ControlListLogItem.Id = objectToSave.ControlListLogItem.Id.trim();
				}
				showNewVersionGenerationWarning();

				repository.updateSingleDictionary(repository.apiData.controlListItem.url, objectToSave).then(function (result) {

					//Saving ControlListItem can create a new version of the controlList if it's in use. if we're on current version and a new version is created on server side update version
					updateModelWithCurrentVersion(true, result);
					if (thenFunction)
						thenFunction();
				}).catch(function (error) {
					updateModelWithCurrentVersion(false);
					repository.growl(error, 'danger');
				});
			}
			else if (thenFunction)
				thenFunction();
		};

		$scope.getControlListItem = function (guidControlListItem) {
			if (!guidControlListItem) return;

			var controlListItem = _.find($scope.model.ControlListItems, function (o) {
				if (!o.Guid) return false;
				return o.Guid.toLowerCase() === guidControlListItem.toLowerCase();
			});

			if (!controlListItem) return;

			return controlListItem;
		};

		$scope.newControlListLogItemHandler = {
			// can: use default permission test,
			templateUrl: 'app/controlList/views/editControlListLogItemModal.html',
			// controller: use default modal controller,
			// closeModal: use default behaviour,
			// cancelModal: use default behaviour,
		};

		$scope.showAffectedField = function (action) {
			if (action === 'Show' || action === 'Hide')
				return true;
			else
				return false;
		};

		$scope.treeOptions = {
			dropped: function (event) {
				if (event.dest.nodesScope.$parent.nodropEnabled) return;

				if (event.source.cloneModel && !event.source.cloneModel.Guid) {
					$scope.setSelectedFormItem(event.source.cloneModel);
					var postData = angular.copy(event.source.cloneModel);

					postData.IndexPosition = event.dest.index;
					postData.GuidControlList = $scope.model.Guid;
					delete postData.icon;
					delete postData.Guid;

					if (postData.Data) postData.Data = JSON.stringify(postData.Data);
					createControlListItem(postData).then(function (response) {
						if (response.Data && typeof (response.Data) === "string") response.Data = JSON.parse(response.Data);
						$scope.model.ControlListItems[event.dest.index] = response;
						$scope.selectedFormItem = $scope.model.ControlListItems[event.dest.index];
						repository.growl('Kontrollpunktet ble lagt til', 'success');
						updateIndexPositions(event.dest.index, $scope.model.ControlListItems.length - 1);
					}, function (error) {
					});
				} else {
					var draggedDown = event.source.index > event.dest.index;
					var startIndex = draggedDown ? event.dest.index : event.source.index;
					var endIndex = draggedDown ? event.source.index : event.dest.index;

					setTimeout(function () {
						updateIndexPositions(startIndex, endIndex);
					}, 0);
				}
			}
		};

		var createControlListItem = function (postData) {
			var deferred = $q.defer();
			showNewVersionGenerationWarning();
			repository.createSingleDictionary(repository.apiData.controlListItem.url, postData).then(function (response) {
				updateModelWithCurrentVersion(true, response);
				deferred.resolve(response);
			}, function (error) {
				updateModelWithCurrentVersion(false);
				deferred.reject(error);
			});

			return deferred.promise;
		};

		$scope.addControlListItem = function (selectedType) {
			var postData = angular.copy(selectedType);
			postData.IndexPosition = $scope.model.ControlListItems.length === 0 ? 0 : $scope.model.ControlListItems[$scope.model.ControlListItems.length - 1].IndexPosition + 1;
			postData.GuidControlList = $scope.model.Guid;
			postData.isSaving = true;
			$scope.model.ControlListItems.push(postData);

			delete postData.icon;
			delete postData.Guid;

			if (postData.Data) postData.Data = JSON.stringify(postData.Data);

			createControlListItem(postData).then(function (response) {
				if (response.Data && typeof (response.Data) === "string") response.Data = JSON.parse(response.Data);
				var index = _.findIndex($scope.model.ControlListItems, { IndexPosition: response.IndexPosition });
				response.isSaving = false;
				$scope.model.ControlListItems[index] = response;
				$scope.setSelectedFormItem($scope.model.ControlListItems[index]);
				$scope.scrollToItem($scope.selectedFormItem);
			}, function (error) {
				swal('Error', error.Message, "error");
			});
		};

		$scope.copyFormItem = function (item, index) {
			var copiedItem = angular.copy(item);
			copiedItem.IndexPosition = item.IndexPosition + 1;
			delete copiedItem.Guid;

			if (copiedItem.Data) copiedItem.Data = JSON.stringify(copiedItem.Data);
			showNewVersionGenerationWarning();
			repository.createSingleDictionary(repository.apiData.controlListItem.url, copiedItem).then(function (response) {
				if (response.Data && typeof (response.Data) === "string")
					response.Data = JSON.parse(response.Data);
				updateModelWithCurrentVersion(true, response);
				$scope.model.ControlListItems.splice(index + 1, 0, response);
				$scope.selectedFormItem = $scope.model.ControlListItems[index + 1];
				updateIndexPositions(index + 1, $scope.model.ControlListItems.length - 1);
			}, function (error) {
				updateModelWithCurrentVersion(false);
			});
		};

		$scope.removeFormItem = function (item) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlListItem-message', "Kontrollpunktet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlListItem-button-confirm', 'Ja, fjern kontrollpunktet'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			}, function () {

				setTimeout(showNewVersionGenerationWarning, 300);

				repository.deleteSingle(repository.apiData.controlListItem.url, item.Guid).then(function (response) {
					repository.growl('Kontrollpunktet har blitt fjernet', 'success');
					//response does not
					if ($scope.model.RequireNewVersion) {
						var responseControlList = { ControlList: angular.copy($scope.model) };
						responseControlList.ControlList.Version += 1;
						updateModelWithCurrentVersion(true, responseControlList);
					} else {
						updateModelWithCurrentVersion(false);
					}

					if (item === $scope.selectedFormItem)
						$scope.selectedFormItem = null;

					var foundInFormItems = _.find($scope.model.ControlListItems, item);
					if (foundInFormItems) {
						var index = _.findIndex($scope.model.ControlListItems, foundInFormItems);
						$scope.model.ControlListItems.splice(index, 1);
						updateIndexPositions(index, $scope.model.ControlListItems.length - 1);
					}
					else {
						$scope.model.ControlListItems.forEach(function (formItem) {
							if (formItem.items) {
								if (_.find(formItem.items, item)) {
									formItem.items.splice(_.findIndex(formItem.items, item), 1);
								}
							}
						});
					}

					var affectedRules = _.filter($scope.model.ControlListRules, function (o) { return o.GuidControlListItem === item.Guid; });

					if (affectedRules.length > 0) {
						affectedRules.forEach(function (rule) {
							var index = _.findIndex($scope.model.ControlListRules, { Guid: rule.Guid });
							$scope.model.ControlListRules.splice(index, 1);
						});
					}
				}, function (error) {
					updateModelWithCurrentVersion(false);
					swal('Error', error.Message, "error");
				});
			});
		};

		var updateControlListItem = function (item) {
			var deferred = $q.defer();

			if (item.Data) item.Data = JSON.stringify(item.Data);
			showNewVersionGenerationWarning();
			repository.updateSingleDictionary(repository.apiData.controlListItem.url, item).then(function (response) {
				//Saving ControlListItem can create a new version of the controlList if it's in use. if we're on current version and a new version is created on server side update version
				updateModelWithCurrentVersion(true, response);
				deferred.resolve(response);
			}, function (error) {
				updateModelWithCurrentVersion(false);
				deferred.reject(error);
			});

			if (item.Data) item.Data = JSON.parse(item.Data);

			return deferred.promise;
		};

		var updateIndexPositions = function (startIndex, endIndex) {
			var promises = [];
			for (var i = startIndex; i <= endIndex; i++) {
				var item = $scope.model.ControlListItems[i];
				item.IndexPosition = i;

				promises.push(updateControlListItem(item));
			}

			$q.all(promises).then(function (result) {
				if (result) {
					result.forEach(function (item) {
						if (item.ControlList && item.ControlList.Version !== $scope.model.Version) {
							if ($scope.model.IsCurrentVersion && item.ControlList.IsCurrentVersion) {
								$scope.model.Version = item.ControlList.Version;
								$scope.model.IsChangesSlow = false;
							}
						}
					});
				}
			}, function (error) {
				swal('Error', error, 'error');
			});
		};

		//Add new item to array for form items that have several answer options
		$scope.addOption = function (index) {
			if ($scope.selectedFormItem.Data.Options) {
				$scope.selectedFormItem.Data.Options.splice(index + 1, 0, { value: '' });
			}
		};

		$scope.removeOption = function (index) {
			if ($scope.selectedFormItem.Data.Options) {
				if ($scope.selectedFormItem.Data.Options.length === 1) {
					swal('Du kan ikke slette siste alternativ', '', 'error');
				} else {
					$scope.selectedFormItem.Data.Options.splice(index, 1);
				}
			}
		};

		var reloadControlListRules = function () {
			repository.GetPaginated(repository.apiData.controlListRule.url, 0, 100, {}, { PropertyFilter: [{ Property: 'GuidControlList', Operator: '=', Value: $scope.model.Guid }] }, '').then(function (response) {
				$scope.model.ControlListRules = response.List;
				$scope.model.ControlListRules.forEach(function (item) {
					if (item.Conditions) item.Conditions = JSON.parse(item.Conditions);
					if (item.Parameters) item.Parameters = JSON.parse(item.Parameters);
				});
			});
		};

		$scope.addRule = function (selectedFormItem) {
			$modal.open({
				templateUrl: 'app/controlList/views/ruleModal.html',
				size: 'lg',
				controller: 'RuleModalController',
				resolve: {
					params: function () {
						return {
							selectedFormItem: selectedFormItem,
							controlListItems: $scope.model.ControlListItems
						};
					}
				}
			}).result.then(function (newRule) {
				newRule.GuidControlList = $scope.model.Guid;
				createNewRule(newRule);
			});
		};

		$scope.editRule = function (rule, selectedFormItem) {
			$modal.open({
				templateUrl: 'app/controlList/views/ruleModal.html',
				size: 'lg',
				controller: 'RuleModalController',
				resolve: {
					params: function () {
						return {
							selectedFormItem: selectedFormItem,
							controlListItems: $scope.model.ControlListItems,
							rule: rule
						};
					}
				}
			}).result.then(function (editedRule) {
				var postData = angular.copy(editedRule);
				postData.Conditions = JSON.stringify(postData.Conditions);
				postData.Parameters = JSON.stringify(postData.Parameters);
				showNewVersionGenerationWarning();
				repository.updateSingleDictionary(repository.apiData.controlListRule.url, postData).then(function (response) {
					if (response.Conditions) response.Conditions = JSON.parse(response.Conditions);
					if (response.Parameters) response.Parameters = JSON.parse(response.Parameters);
					editedRule = response;
					//Saving ControlListRule can create a new version of the controlList if it's in use. if we're on current version and a new version is created on server side update version
					updateModelWithCurrentVersion(true);
					reloadControlListRules();
				}, function (error) {
					updateModelWithCurrentVersion(false);
					swal('Error', error.Message, 'error');
				});
			});
		};

		var createNewRule = function (newRule) {
			if (typeof (newRule.Conditions) === 'object') newRule.Conditions = angular.toJson(newRule.Conditions);
			if (typeof (newRule.Parameters) === 'object') newRule.Parameters = angular.toJson(newRule.Parameters);
			setTimeout(showNewVersionGenerationWarning, 300);
			repository.createSingleDictionary(repository.apiData.controlListRule.url, newRule).then(function (response) {
				if (!$scope.model.ControlListRules) $scope.model.ControlListRules = [];
				if (typeof (response.Conditions) === 'string') response.Conditions = JSON.parse(response.Conditions);
				if (typeof (response.Parameters) === 'string') response.Parameters = JSON.parse(response.Parameters);
				$scope.model.ControlListRules.push(response);
				updateModelWithCurrentVersion(true);
			}, function (error) {
				updateModelWithCurrentVersion(false);
				swal('Error', error, 'error');
			});
		};

		$scope.removeRule = function (rule, index) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlListRule-message', "Regelen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlListRule-button-confirm', 'Ja, fjern regelen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true
			}, function () {

				setTimeout(showNewVersionGenerationWarning, 300);
				repository.deleteSingle(repository.apiData.controlListRule.url, rule.Guid)
					.then(function (result) {
						updateModelWithCurrentVersion(true);
						repository.growl(translationService.translate('web-swal-controlListRule-success', 'Regelen ble fjernet!'), 'success');
						if (!index) index = _.findIndex($scope.model.ControlListRules, { Guid: rule.Guid });
						$scope.model.ControlListRules.splice(index, 1);
					}, function (error) {
						updateModelWithCurrentVersion(false);
						swal('Error', error.Message, 'error');
					});
			});
		};

		$scope.controlListXEntityColumns = [
			'PeriodicTask.Id', 'PeriodicTask.Description', 'PeriodicTask.DueDate',
			'Equipment.Id', 'Equipment.Description', 'Area.Id', 'Area.Description',
			'PeriodicTask.Building.Id', 'PeriodicTask.Building.Description', 'PeriodicTask.Estate.Id', 'PeriodicTask.Estate.Description'
		];

		$scope.completedControlListColumns = [
			'User.RealName', 'ClosedDate',
			'WorkOrder.Id', 'WorkOrder.Description',
			'Equipment.Id', 'Equipment.Description', 'Area.Id', 'Area.Description',
			'ControlList.Version'
		];

		$scope.getLowestEntityCaption = function (entity) {
			if (entity.Equipment) return $scope.getEntityCaption(entity.Equipment);
			if (entity.Area) return $scope.getEntityCaption(entity.Area);

			if (entity.PeriodicTask) {
				return translationService.translate('web-controlListXEntity-directConnectionPeriodicTask', 'Direkte tilkoblet rutine');
			} else {
				return translationService.translate('web-controlListXEntity-directConnectionWorkOrder', 'Direkte tilkoblet arbeidsordre');
			}
		};

		$scope.getEntityIcon = function (entity) {
			if (entity.Equipment) return 'zmdi-settings c-bluegray';
			if (entity.Area) return 'zmdi-layers c-lightblue';
		};

		var getCardTables = function () {
			$scope.controlListXEntityTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'PeriodicTask.Id': 'desc' },
				filter: { PropertyFilter: [{ Property: 'GuidPeriodicTask', Operator: '<>', Value: null }, { Property: "GuidControlList", Operator: "=", Value: $stateParams.guid }, { Property: 'PeriodicTask.IsDeactivated', Operator: '=', Value: false }] }
			},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function ($defer, params) {
						repository.GetPaginated(repository.apiData.controlListXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify($scope.controlListXEntityColumns))
							.then(function (result) {
								$scope.controlListXEntityTable.settings().total = result.TotalCount;
								$scope.controlListXEntityTable.settings().filterDelay = 500;
								$defer.resolve(result.List);

								setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});

			$scope.completedControlListTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'ClosedDate': 'desc' },
				filter: { PropertyFilter: [{ Property: 'ControlList.GuidMasterRecord', Operator: '=', Value: $stateParams.guid }, { Property: "ClosedDate", Operator: "<>", Value: null }] }
			},
				{
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function ($defer, params) {
						repository.GetPaginated(repository.apiData.controlListXEntity.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify($scope.completedControlListColumns))
							.then(function (result) {
								$scope.completedControlListTable.settings().total = result.TotalCount;
								$scope.completedControlListTable.settings().filterDelay = 500;

								if (!$scope.controlLists)
									$scope.controlLists = [];

								if ($scope.controlLists) {
									while ($scope.controlLists.length > 0)
										$scope.controlLists.pop();
								}

								result.List.forEach(function (controlList) {
									$scope.controlLists.push(controlList);
								});

								$defer.resolve(result.List);
							},
								function (error) {
									repository.growl(error, 'danger');
								});
					}
				});
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		var columns = [

		];

		var getEntity = function () {
			// for either update (with existing GUID) or insert (with default null GUID), fetch the whole entity to get proper FieldRules, which do not only depend on the entity type, but also on the current entity properties
			// furthermore, some fields may be dynamically initialized for new entities, what may be difficult to maintain in front end code
			// (there is not performance loss compared to the old solution, since a new object already needed a request to fetch field rules anyway)
			return repository.getSingle(repository.apiData.controlList.url, ($scope.isUpdate ? $stateParams.guid : "00000000-0000-0000-0000-000000000000"), JSON.stringify(columns) + ($stateParams.guidEntityToCopy ? "&GuidCopyFrom=" + $stateParams.guidEntityToCopy : ""));
		};

		getEntity().then(function (response) {
			var controlList = response.Data;
			$scope.model = controlList;

			$scope.reload = true;

			if ($scope.isUpdate) {
				if ($scope.model.ControlListItems) {
					$scope.model.ControlListItems.forEach(function (item) {
						if (item.Data)
							item.Data = JSON.parse(item.Data);
					});
				} else
					$scope.model.ControlListItems = [];

				if ($scope.model.ControlListRules) {
					$scope.model.ControlListRules.forEach(function (item) {
						if (item.Conditions)
							item.Conditions = JSON.parse(item.Conditions);
						if (item.Parameters)
							item.Parameters = JSON.parse(item.Parameters);
					});
				} else
					$scope.model.ControlListRules = [];

				if ($scope.model.Conditions) $scope.columns = JSON.parse($scope.model.Conditions);
				getCardTables();
			}

			updateAccess();
		},
			function (error) {
				repository.growl(error, 'danger');
			});

		$scope.update = function (destination) {
			// continue with main control list saving process only if no error during saving of current item
			saveCurrentItem(function () {

				var modelCopy = angular.copy($scope.model);

				if ($scope.columns && $scope.columns.length > 0) modelCopy.Conditions = angular.toJson($scope.columns);
				else modelCopy.Conditions = undefined;


				delete modelCopy.ControlListItems;
				delete modelCopy.ControlListRules;

				var success = function (result) {
					repository.growl($scope.isUpdate ? translationService.translate('update-controlList-success', 'Kontrollisten ble oppdatert') : translationService.translate('create-controlList-success', 'Kontrollisten ble opprettet'), 'success');
					repository.commonService.setLastRegisterGuid(destination, result.Guid);
					updateModelWithCurrentVersion(true);
					$scope.goBack(destination, { guid: result.Guid, menuGuid: $scope.navigation.params.menuGuid });
				};

				var error = function (error) {
					updateModelWithCurrentVersion(false);
					repository.growl(error, 'danger');
				};

				if ($scope.isUpdate) {
					showNewVersionGenerationWarning();
					repository.updateSingleDictionary(repository.apiData.controlList.url, modelCopy).then(success).catch(error);
				}
				else {
					repository.createSingleDictionary(repository.apiData.controlList.url, modelCopy).then(success).catch(error);
				}
			});
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlList-message', "Kontrollisten vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlList-button-confirm', 'Ja, fjern kontrollisten'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {

				repository.deleteSingle(repository.apiData.controlList.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-controlList-success', 'Kontrollisten ble fjernet!'), result, "success");
						$scope.goBack('controlList.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {

						var swalMessage = error.Message;
						if (error.IsDeactivateable) {
							swalMessage += '<br/><br/>Vil du deaktivere kontrollisten?';
						}
						swal({
							title: "Kunne ikke slette kontrollisten!",
							text: swalMessage,
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: error.IsDeactivateable ? "Deaktiver" : "Ok",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.IsDeactivated = true;
								$scope.model.Name = '#' + $scope.model.Name;
								$scope.update('controlList.list');
							}
						});
					});
			});
		};

		$scope.removePeriodicTaskConnection = function (controlListXEntity) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlListXEntity-message', "Knytningen mellom kontrollisten og den periodiske rutinen vil bli fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlListXEntity-button-confirm', 'Ja, fjern knytning'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {

				repository.deleteSingle(repository.apiData.controlListXEntity.url, controlListXEntity.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-controlListXEntity-success', 'Knytningen ble fjernet!'), result, "success");
						$scope.controlListXEntityTable.reload();
					}, function (error) {
						swal('Error', error, "error");
					});
			});
		};

		$scope.isInvalidRule = function (rule) {
			return !rule.IsValid;
		};

		$scope.openIconModal = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/common/modal/views/selectIconModal.html',
				controller: 'SelectIconModalController'
			});

			modalInstance.result.then(function (selectedIcon) {
				$scope.model.Icon = selectedIcon;
			});
		};

		$scope.openControlList = function (item) {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'lg',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							workOrderCaption: item.WorkOrder.Caption,
							controlList: $scope.model,
							controlListXEntity: item,
							restrictEdit: true
						};
					}
				}
			}).result.then(function (result) {
				$scope.controlListTable.reload();
				if (result.newDeviations) {
					$scope.openControlListDeviationConfirmation(result.newDeviations);
				}
			}, function () {
				//cancel
			});
		};

		$scope.copyControlList = function () {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCopyModal.html',
				controller: 'ControlListCopyController',
				resolve: {
					params: function () {
						return {
							model: angular.copy($scope.model)
						};
					}
				}
			}).result.then(function (result) {
				result.GuidCopyFrom = $scope.model.Guid;
				repository.createSingleDictionary(repository.apiData.controlList.url, result).then(function (response) {
					$scope.navigation.go('controlList.edit', { guid: response.Guid });
				}, function (error) {
					repository.growl(error, 'danger');
				});
			});
		};

		//#region Conditions
		$scope.addNewColumn = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/common/views/addNewListColumnModal.html',
				controller: 'AddNewListColumnController',
				resolve: {
					params: function () {
						return {
							prefix: repository.apiData.workOrder.prefix,
							showAdvancedMode: true
						};
					}
				}
			});

			modalInstance.result.then(function (selectedColumns) {
				selectedColumns.forEach(function (column) {
					column.Filter = [{ Operator: '=', Property: column.Property }];
					$scope.columns.push(column);
					if (column.PropertyType === 'date') {
						$scope.datePickers[column.$$hashKey] = false;
					}
				});
			});
		};

		$scope.testingCondition = false;
		$scope.testConditions = function () {
			if (!$scope.columns || $scope.columns.length === 0 || $scope.testingCondition) return;

			var kpi = {
				Prefix: repository.apiData.workOrder.prefix,
				FromDate: new Date(0).toISOString(),
				ToDate: null,
				DateProperty: null,
				IncludePeriodicTask: false,
				Interval: null,
				PropertyFilter: [],
				Properties: [{ Property: 'Guid', Function: 'count' }]
			};

			$scope.columns.forEach(function (col) {
				if (col.Filter) {
					kpi.PropertyFilter.push(angular.copy(col.Filter[0]));
				}
			});

			$scope.testingCondition = true;
			repository.createSingle(repository.apiData.kpi.url, kpi).then(function (result) {
				if (result && result[0] && result[0].Result && result[0].Result[0]) {
					var res = result[0].Result[0];
					repository.growl(res.Value + " " + translationService.translate('web-controlList-conditions-testConditions-result', 'arbeidsordre oppfylte betingelsene'), 'info');
				}
				$scope.testingCondition = false;
			}, function (error) {
				repository.growl(error, 'danger');
				$scope.testingCondition = false;
			});
		};
		//#endregion

		$scope.scrollToItem = function (item) {
			if (!item) return;
			scrollService.scrollToElement(item.IndexPosition);
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

		$scope.openPeriodicModal = function () {
			if (!$scope.model.UseWithArea && !$scope.model.UseWithEquipment && !$scope.model.UseWithWorkOrder) {
				repository.growl(translationService.translate('web-controlList-useWith-error', 'Kan ikke koble periodiske rutiner fordi kontrollisten er satt opp til å ikke kunne kobles opp til arbeidsordre, anlegg eller areal'), 'danger');
				return;
			}

			$modal.open({
				templateUrl: 'app/controlList/views/controlListPeriodicTaskModal.html',
				controller: 'ControlListPeriodicTaskModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						return {
							guid: $scope.model.Guid,
							canConnect: {
								UseWithArea: $scope.model.UseWithArea,
								UseWithEquipment: $scope.model.UseWithEquipment,
								UseWithWorkOrder: $scope.model.UseWithWorkOrder
							}
						};
					}
				}
			}).result.then(function () {
				$scope.controlListXEntityTable.reload();
			});
		};

		$scope.$watch('selectedFormItem', function (newVal, oldVal) {
			if (newVal === oldVal) return;

			$scope.activateAutocomplete = false;
			setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
		});

		signalR.on('ControlListRequireNewVersion' + $stateParams.guid, function () {
			$scope.model.RequireNewVersion = true;
		});

		$scope.$on('$destroy', function () {
			signalR.off('ControlListRequireNewVersion' + $stateParams.guid);
		});
	}

})();
