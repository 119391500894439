(function () {
	angular.module('Plania').controller('SparePartsController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService) {
		$scope.isUpdate = $scope.navigation.current.name === 'sparePart.edit';
		$scope.viewMode = $scope.isUpdate ? true : false;
		$scope.reloadQrCodes = false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var columns = [
			'ComponentXSupplier.PartNumber',
			'ComponentXSupplier.GuidSupplier',
			'ComponentXSupplier.Supplier.Id',
			'ComponentXSupplier.Supplier.Description',
			'ComponentXSupplier.Supplier.Telephone',
			'ComponentXSupplier.Supplier.Email',
			'Component.Id',
			'Component.Guid',
			'Component.Description',
			'Component.ExplanatoryText',
			'Component.ComponentCategory.Description',
			'Component.Unit',
			'Component.GuidImage'
		];

		var updateAccess = function () {
			var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
			$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.SparePart, checkOtherDO);
			$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.SparePart, checkOtherDO);
		};

		$scope.purchaseOrderItemColumns = [
			{ Position: 1, Title: translationService.translate('web-purchaseOrder-Id'), Property: 'PurchaseOrder.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-supplier-Id', 'Leverandør navn'), Property: 'Supplier.Id', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-purchaseOrderItem-OrderedQuantity', 'Bestilt antall'), Property: 'OrderedQuantity', PropertyType: 'number' },
			{ Position: 4, Title: translationService.translate('web-purchaseOrderItem-UnitPrice', 'Enhetspris'), Property: 'UnitPrice', PropertyType: 'number' },
			{ Position: 5, Title: translationService.translate('web-purchaseOrderItem-Status', 'Status'), Property: 'Status', PropertyType: 'string' },
		];

		var getCardTables = function () {
			$scope.purchaseOrderItemTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { CreationDate: 'desc' },
				filter: {
					PropertyFilter: [
						{ Property: 'GuidSparePart', Operator: '=', Value: $stateParams.guid },
					]
				}
			},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					getData: function ($defer, params) {
						var columns = [];
						$scope.purchaseOrderItemColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.purchaseOrderItem.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(), '', JSON.stringify(columns))
							.then(function (result) {
								$scope.purchaseOrderItemTable.settings().total = result.TotalCount;
								$scope.purchaseOrderItemTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}
							);
					}
				});
		};

		var getSparePart = function () {
			repository.getMainModel(repository.apiData.sparePart.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					$scope.model = response.Data;
					$scope.reload = true;
					$scope.reloadQrCodes = true;
					updateAccess();
					getCardTables();
				}, function (error) {
					repository.growl(error, 'danger');
				}
			);
		};

		if ($scope.isUpdate) {
			getSparePart();
			getCardTables();
		}

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-sparePart-success', 'Reservedeler har blitt oppdatert.') : translationService.translate('create-sparePart-success', 'Reservedelen har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.isUpdate) {
				repository.updateSingleDictionary(repository.apiData.sparePart.url, $scope.model).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.sparePart.url, $scope.model).then(success, error);
			}
		};

		$scope.withdraw = function () {
			$modal.open({
				templateUrl: 'app/sparePart/views/sparePartObjectWithdrawalModal.html',
				controller: 'SparePartObjectWithdrawalModalController',
				resolve: {
					params: function () {
						return {
							GuidSparePart: $scope.model.Guid
						};
					}
				}
			}).result.then(function (item) {
				repository.createSingleDictionary(repository.apiData.sparePartWithdrawal.url, item).then(function (result) {
					repository.growl(translationService.translate('web-spare-part-withdrawn', 'Reservedel er tatt ut'), 'success');
					getSparePart();
				}, function (error) {
					repository.growl(error, "danger");
				});
			});
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-sparePart-message', "Reservedelen vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-sparePart-button-confirm', 'Ja, fjern Reservedelen'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.sparePart.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-sparePart-success', 'Reservedelen ble fjernet!'), result, "success");
						$scope.goBack('sparePart.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						//If deactivatable
						swal({
							title: "Kunne ikke slette reservedelen!",
							text: error.Message + "<br/><br/> Vil du deaktivere reservedelen?",
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: "Deaktiver",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.IsDeactivated = true;
								$scope.update('sparePart.list');
							}
						});
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});

	}
})();
